.liveScore-container{
    width: 100%;
    background-image: url('/static/media/top_searche_sbg.62cad5f8.png');
    height: 330px;
    .viewAll{
        color: white;
        font-size: 20px;
        display: flex;
    }
    .liveScoreInner {
        // width: 90%;
        // margin: 0 auto;
        // padding: 2% 0;
        margin: 3% 3%;
        color: black;
        .heading {
            margin-bottom: 1%;
            font-size: 32px;
            text-transform: uppercase;
            font-weight: bold;
            flex: 1 1;
            font-family: 'gilroy black';
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: white;
        }
        .fullScoreCard {
            width: 100%;
            height: 190px;
            background-color: #ffffffc7;
            padding: 1% 1%;
            border-radius: 5px;
            border: 10px double  #ffffffc7;
            background-clip: padding-box;
            .firstRow {
            display: flex;
            margin-bottom: 1%;
            .live {
                color: red;
                font-size: 20px;
                font-weight: 800;
                letter-spacing: 1px;
                flex: 1;
                display: flex;
                .dot{
                    width: 3%;
    height: 16px;
                    img{
                        width: 100%;
                height: 100%; 
                    }
                }
                .text{
                    margin-left: 2%;
                }
            }
            .Today {
                background-color: green;
                color: white;
                align-items: center;
                justify-content: center;
                display: flex;
                padding: 1% 2%;
                border-radius: 5px;
                letter-spacing: 1px;
                font-size: 12px;
                font-weight: 800;
            }
        }
        .secondRow{
            display: flex;
            margin-bottom: 1%;

            align-items: center;
    justify-content: center;
    
        }
        .logo{
            width: 6%;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .player{
            font-size: 20px;
            align-items: center;
            display: flex;
            margin-left: 2%;
            font-weight: 900;
            font-family: sans-serif;
        }
        .vs{
            font-size: 15px;
    align-items: center;
    display: flex;
    margin-left: 2%;
    font-weight: 900;
        }
        .score{
            font-size: 20px;
            align-items: center;
            justify-content: flex-end;
            display: flex;
            width: 44%;
        }
        .thirdRow{
            display: flex;
        }
        .fourthRow {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    }
    
@media (max-width:768px) {
    .firstRow {
        margin-bottom: 7% !important;
    }
    .marginSet {
        margin-bottom: 2% !important;
    }
    .fullScoreCard {
        height: 238px !important;
    }
}
    @media (max-width:426px) {
        .secondRow {
            flex-direction: column;
        }
        .firstRow {
            margin-bottom: 10% !important;
        }
        .firstRow .live {
            font-size: 12px !important;
        }
        .player {
            font-size: 15px !important;
            margin-left: 0% !important;
    }
    .fourthRow {
        font-size: 15px !important;
        text-align: center ;
    }
    .fullScoreCard {
        height: 250px !important;
    }
}
}