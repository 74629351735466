section {
  display: block;
}

.widget {
  // margin: 0 0 6.4rem;
  clear: both;
  margin: 1% 3%;
  .uniform-grid__section figure,
  .uniform-grid__section-ads figure {
    margin: 0;
    overflow: hidden;
  }

  .widget__header {
    position: relative;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3rem;
    clear: both;
    display: flex;
  }

  .widget__title {
    color: #232882;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    font-family: inherit;
    .subheading {
      font-weight: 900;
      font-family: "gilroy black";
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      .featured {
        color: #041b70;
      }
      .news {
        padding-left: 8px;
        color: #00baf2;
      }
    }
  }

  .widget__link {
    background-color: #ff3333;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    height: 44px;
    box-shadow: 0 5px 18px #ff3333;
    &:hover {
      color: white;
      box-shadow: none;
      text-decoration: none;
    }
  }

  .widget__header-desc {
    color: #000000;
    font-size: 12px;
    padding: 5px 0;
  }

  .uniform-grid {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
  }
  .box {
    display: flex;
    width: 100%;
  }
  .uniform-grid .uniform-grid__section_image {
    // display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    padding: 0 26px 0 0;
  }
  .shadowCard1 {
    //border: 1px solid #e9e9e9;
    //  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
    //  padding: .8rem;
    //  border-radius: 4px;
    width: 100%;
    height: 100%;
    //  display: -ms-flexbox;
    //  display: flex;
    //  -ms-flex-direction: column;
    //  flex-direction: column;
    // position: relative;
  }
  .uniform-grid .uniform-grid__section_image .thumbnail__container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 56.25% 0 0 0;
    border-radius: 5px;
  }
  .uniform-grid .uniform-grid__section_image .thumbnail__container img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .row1 {
    display: flex;
    flex-direction: row;
    width: 72%;
    margin-right: 10px;
    /* border: 2px solid black; */
    border: 1px solid #e9e9e9;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.8rem;
    border-radius: 4px;
  }
  .uniform-grid .uniform-grid__section_text {
    // display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 39%;
    padding: 0 24px 0 0;
    a { 
      color: inherit;
      text-decoration: none;
    } 
  }
  .uniform-grid .uniform-grid__section_text .thumbnail_container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 75% 0 0 0;
  }
  .uniform-grid .uniform-grid__section_text .thumbnail_container .thumbnail_container_text {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .uniform-grid .uniform-grid__section-ads {
    flex-direction: row;
    justify-content: space-between;
    width: 25%;
    // padding: 0 0 0 10px;
  // padding-left: 1%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1%;
  background-image: linear-gradient(45deg, #ff3333, #0d0f60);
  cursor: pointer;
  }
  .uniform-grid .uniform-grid__section-ads .thumbnail__container {
    display: block;
    width: 100%;
    //height: auto;
    // position: relative;
    // overflow: hidden;
    // padding: 103% 0 0 0;
  }
  .uniform-grid .uniform-grid__section-ads .thumbnail__container img {
    display: block;
    // max-width: 100%;
    // max-height: 100%;
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;

    width:100%;
    height: 100%;
  }

  .image {
    vertical-align: middle;
    border-style: none;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 1s;
    &:hover {
      transform: scale(1.1);
      border-radius: 5px;
    }
  }

  .viewall-container {
    margin-top: 1px;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
    min-height: 40px;
  }

  .ads-image {
    vertical-align: middle;
    border-style: none;
    width: 100%;
    border-radius: 5px;
    transition: transform 1s;
  }
  .show {
  }

  .uniform-grid .uniform-grid__section-below-line {
    // display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 25%;

    padding-right: 10px;
    padding-top: 10px;
  }

  .shadowCard {
    border: 1px solid #e9e9e9;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.8rem;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
  }
  .uniform-grid__section-below-line .hidee {
    overflow: hidden;

    display: block;
    width: 100%;
    height: auto;
    position: relative;
    padding: 75% 0 0 0;
    border-radius: 5px;
  }
  .play-button {
    position: absolute;
    left: 37.5%;
    top: 37.5%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1;
    &:hover {
      box-shadow: 0 0px 36px 18px red;
    }
  }
  .uniform-grid__section-below-line .hidee img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .uniform-grid__section-below-line:last-child {
    padding-right: 0;
  }

  .thumbnail__meta {
    position: absolute;
    bottom: 1.6rem;
    left: 1.6rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .thumbnail__meta .thumbnail__category,
  .thumbnail__meta .thumbnail__date-day {
    margin-right: 0.4rem;
  }
  .thumbnail__meta .thumbnail__date-year {
    color: #7e8991;
  }

  .date-month-year {
    font-size: 12px;
    font-weight: bold;
    clear: both;
    color: #000;
    padding: 0 0 5px 0;
    font-family: inherit;
  }

  .date-month-year-2nd-line {
    font-size: 12px;
    font-weight: bold;
    clear: both;
    color: #000;
    padding: 10px 0px 0px 0px;
    font-family: inherit;
  }

  .thumbnail__title {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
    font-family: inherit;
  }

  .thumbnail__title-2nd-line {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.2;
    color: #000;

    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .thumbnail__title-2nd-line:hover {
    text-decoration: none;
    color: #000;
    overflow: visible;
    position: relative;
    text-overflow: ellipsis;
    display: block;
  }
  .show a:hover .showMore {
    visibility: visible;
  }
  .show .link:hover .showMore {
    visibility: visible;
  }
  .thumbnail__summary {
    font-size: 1.5rem;

    text-align: justify;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    
  }
  /*.thumbnail__summary:hover {
    text-decoration: none;
    color: #000;
    overflow: visible;
    position: relative;
    text-overflow: ellipsis;
    display: block;
  }*/
  .viewall-mobile {
    display: none;
  }
  img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
  }
}

@media (max-width: 52.49em) {
  .widget {
    margin-bottom: 3.2rem;
    .widget__header {
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 1.6rem;
    }
  }
}

@media (max-width: 768px) {


  .widget {
    margin: 4%;
    width: 92%;
    .uniform-grid .row1 {
      flex-direction: column;
      width: 50%;
      margin-bottom: 5%;
    }
    .thumbnail__title {
      font-size: 14px;
    }
  
    .thumbnail__summary{
      margin-top: 3%;
      font-size: 11px;
     }
 
    .uniform-grid .row1 .uniform-grid__section_image {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      margin-bottom: 2%;
    }
    .uniform-grid .row1 .uniform-grid__section_text {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0;
    }
    .uniform-grid .uniform-grid__section-ads {
      flex-direction: row;
      justify-content: space-between;
      width: 49%;
      padding: 5px;
      margin-bottom: 5%;
      
    }
    .uniform-grid .uniform-grid__section-below-line {
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
      margin-bottom: 4%;
      padding: 5px;
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .thumbnail__title-2nd-line {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2;
      color: #000;
      padding: 5px 0;
      font-family: inherit;
    }
    .widget__link {
      display: none;
    }
    .viewall-mobile {
      display: flex;
    }
    .widget__link_mobile {
      background-color: #ff3333;
      color: #fff;
      font-size: 16px;
      border-radius: 50px;
      text-decoration: none;
      text-align: center;
      padding-top: 10px;
      width: 100%;
      margin-right: 20px;
      height: 44px;
      box-shadow: 0 5px 18px #ff3333;
      &:hover {
        background-color: white;
        color: #ff3333;
        box-shadow: 0 5px 18px -1px #ff3333;
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 425px) {
  .widget {
    .uniform-grid .row1 {
      width: 100%;
    }
    .uniform-grid .uniform-grid__section-ads {
      width: 100%;
    }
    .uniform-grid .uniform-grid__section-below-line {
      width: 100%;
    }
  }
}
