.all {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;

}
.Batting, .Bowling {
    padding: 30px;
}
.Batting h1,.Bowling h1 {
    margin-bottom: 10px ;
    color: rgb(48, 48, 48);
    letter-spacing: 1px;
}
.Batting hr {
    height: 2px;
    width: 10%;
    background-color:#c60f5e;
    margin-bottom: 10px;
    border: none;
}
.Bowling hr {
    height: 2px;
    width: 10%;
    background-color:#01579b;
    margin-bottom: 10px;
    border: none;

}
.Seperation {
    padding-bottom: 30px;
}
/* card */
.Header {
    display: flex;
    flex-direction: column;
}
.card_width {
    width: 60%;
    /* margin: 20px 50px; */
    height: 320px;
    box-shadow: 2px 3px 8px;
}
.heading  {
    width: 100%;
    text-align: center;
    margin: 10px  0 0 0;
}
.heading h3 {
    font-size: 24px;
    color: rgb(48, 48, 48);
    letter-spacing: 1px;
}
.ImgLive {
    width: 110px;
    height: 110px;
}
.ImgLive img {
    width: 120px;
    height: 120px;
}
.LiveName h3 {
    padding-left: 5px ;
    line-height: 55px;
    font-size: 16px;
    color: rgb(48, 48, 48);
}
.LiveScore {
    text-align: center;
}
.LiveScore h3 {
    line-height: 55px;
    font-size: 18px;
    color: rgb(48, 48, 48);
    padding-left: 4px;
}
.TeamLive {
    padding-bottom: 0;
}
.FooterLive h5 {
    font-size: 12px;
}
/* .FooterLive {
    margin-left: 32%;
} */
/* .BottomLive {
padding-top: 0;
margin-left: 14%;
} */
hr {
    height: 1px;
    width: 100%;
    background-color: rgb(221, 221, 221);
    border: none;
    margin-bottom: 8px;
}

@media only screen and (max-width: 700px) {
    .card_width {
        width: 70%;
        height: 270px;
    }
    .ImgLive {
        width: 80px;
        height: 80px;
    }
    .ImgLive img {
        width: 80px;
        height:80px;
    }
    .LiveName h3 {
        line-height: 50px;
        font-size: 12px;
    }
    .LiveScore h3 {
        line-height: 50px;
        font-size: 12px;
    }
    .heading h3 {
        font-size: 16px;
        padding-bottom: 4px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .FooterLive h5 {
        font-size: 10px;
    }
  }
  @media only screen and (max-width: 550px) {
    .card_width {
        width: 80%;
        height: 260px;
    }
    .ImgLive {
        width: 80px;
        height: 80px;
    }
    .ImgLive img {
        width: 80px;
        height:80px;
    }
    .LiveName h3 {
        line-height: 38px;
        font-size: 11px;
    }
    .LiveScore h3 {
        line-height: 38px;
        font-size: 11px;
    }
    .heading h3 {
        font-size: 14px;
        padding-bottom: 4px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .FooterLive h5 {
        font-size: 10px;
    }
  }
  @media only screen and (max-width: 450px) {
    .card_width {
        width: 70%;
        height: 240px;
    }
    .ImgLive {
        width: 70px;
        height: 70px;
    }
    .ImgLive img {
        width: 70px;
        height:70px;
    }
    .LiveName h3 {
        line-height: 35px;
        font-size: 10px;
    }
    .LiveScore h3 {
        line-height: 35px;
        font-size: 10px;
    }
    .heading h3 {
        font-size: 12px;
        padding-bottom: 4px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .FooterLive h5 {
        font-size: 8px;
    }
  }
  @media only screen and (max-width: 400px) {
    .card_width {
        width: 78%;
        height: 235px;
    }
  }
  @media only screen and (max-width: 350px) {
    .card_width {
        width: 80%;
        height: 230px;
    }
    .heading h3 {
        font-size: 10px;
        padding-bottom: 4px;
    }
    .ImgLive {
        width: 60px;
        height: 60px;
    }
    .ImgLive img {
        width: 60px;
        height:60px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .LiveScore h3 {
        padding-right: 15px;
    }
  }