.livematch {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1% 2%;
  background-image: url("../../Assets/CricView-Banner1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  .setMargin{
margin-left:2%
  }
  .card_1 {
    display: flex;
    justify-content: center;
  }
.borderCornerOne {
  background:
  linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 0,
  linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 100%,
  linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 0,
  linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 100%,
  linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 0 0,
  linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 100% 0,
  linear-gradient(to top, #c60f5e 7px, transparent 7px) 0 100%,
  linear-gradient(to top, #c60f5e 7px, transparent 7px) 100% 100%;

background-repeat: no-repeat;
background-size: 25px 25px;

}
.borderCornerTwo {
  background:
  linear-gradient(to right, #00aeef 7px, transparent 7px) 0 0,
  linear-gradient(to right, #00aeef 7px, transparent 7px) 0 100%,
  linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 0,
  linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 100%,
  linear-gradient(to bottom, #00aeef 7px, transparent 7px) 0 0,
  linear-gradient(to bottom, #00aeef 7px, transparent 7px) 100% 0,
  linear-gradient(to top, #00aeef 7px, transparent 7px) 0 100%,
  linear-gradient(to top, #00aeef 7px, transparent 7px) 100% 100%;

background-repeat: no-repeat;
background-size: 25px 25px;

}

  .boxOne{
    display: flex;
    width: 25%;
    position: relative;
  }
  .imageBoxCric{
    width: 8%;
    position: absolute;
    right: 19% !important;
    top: 8%;
    img{
      width: 100%;
    height: 100%;
    border-radius: 50%;
    }
  }
  .imageBox {
    width: 85% !important;
    // background-color: red;
    height: 150px;
    padding: 1.7%;
    img{
      
    
    background-image: linear-gradient(red,blue);
      width: 100%;
      height: 138px;
    }
}
@media(max-width: 1980px){
  .imageBox {
    padding: 1.3% 1.1% 1.3% 1.3%;

width: 85%;

  }
  .imageBoxCric {
    right: 18%;
}
}
@media(max-width: 1440px){
.imageBox {
  padding: 1.7%;
}
}
@media(max-width: 1024px){
  background-size: 1024px 156px;
  .boxOne {
    width: 28%;
  }
  .setMargin{
    margin-left:4%
      }
  .imageBox {
    height: 136px;
    padding: 2%;
   img {
      width: 100%;
      height: 125px;
   }
  }
}
@media(max-width: 768px){
.boxOne {
  width: 40%;
}
.imageBox {
  height: 135px;
}
.imageBoxCric { 
  top: 5%;
}
}
@media(max-width: 450px){
flex-direction: column;
height: 350px;
background-image: url("../../Assets/CricView-Banner-Mobile1.jpg");
background-repeat: no-repeat;
background-size: 425px 350px;
    .boxOne {
      width: 80%;
      margin-bottom: 5%;
      margin-left: 0%;
      display: flex;
    align-items: center;
    justify-content: center;
      
    }
    .imageBoxCric {
      top: 8%;
      right: 11% !important;
  }
    .imageBox {
      height: 137px;
      padding: 2% 1.9% 1.9% 1.9%;
  }
} 
@media(max-width: 375px){
  background-size: 375px 350px;
  .imageBox {
    height: 136px;
    padding: 2.2% 1.9% 1.9% 1.9%;
}
  }
@media(max-width: 320px){
  background-size: 320px 350px;
  .imageBox {
    height: 135px;
    padding: 2.2% 1.9% 1.9% 1.9%;
}
  } 
}

// @media only screen and (max-width: 600px) {
   
//      .livematch{
//         display: none !important
//      } 
//   }


//   @media only screen and (min-width: 768px) {
//     .livematch{
//         padding-top: 10px;
//         position: absolute;
//         padding-left: 20px;
//         left: 50%;
//         top: 0%;
//         height: 100px;
//         width: 100%;
//         margin-left: -350px;
//         border-radius: 10px;
//         color: white;
//         font-size: 15px;
//         display: inline;
//     }
    
//     .livematch img{
//         width: 350px;
//         margin: 1px;
//     }
    
//   }


