@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
.cricview-talnet-footer .container-talnet {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.cricview-talnet-footer .row-talnet {
  margin-right: -15px;
  margin-left: -15px; }

.cricview-talnet-footer .col-md-4 .col-md-2 .col-md-3 .col-md-6, .cricview-talnet-footer .col-lg-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.cricview-talnet-footer .col-md-4 {
  width: 33.33333333%; }

.cricview-talnet-footer .col-md-2 {
  width: 16.66666667%; }

.cricview-talnet-footer .col-md-3 {
  width: 25%; }

.cricview-talnet-footer .full-talnet {
  position: relative;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0; }

.cricview-talnet-footer .footer-talnet {
  min-height: auto;
  background: #111;
  float: left;
  width: 100%;
  padding: 0;
  border-top: solid #ddd 1px;
  position: relative;
  background-position: center top;
  background-size: cover;
  color: #fff; }

.cricview-talnet-footer .full-talnet .logo img {
  width: 120px; }

.cricview-talnet-footer .footer-widget-talnet {
  float: left;
  width: 100%;
  padding: 50px 0 10px; }

.cricview-talnet-footer .footer-logo-talnet img {
  width: 180px;
  padding-top: 10px; }

.cricview-talnet-footer .footer-widget-talnet p {
  font-size: 14px;
  color: #fff;
  margin: 20px 0 10px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 15px;
  text-align: left; }

.cricview-talnet-footer .footer-widget-talnet h3 {
  color: #fff;
  text-transform: none;
  font-weight: 500;
  font-size: 18px;
  float: left;
  width: 100%;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 20px;
  margin-bottom: 10px;
  border-bottom: solid #333 1px;
  padding-bottom: 15px;
  padding-top: 7px; }

.cricview-talnet-footer .footer-menu {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 32px; }

.cricview-talnet-footer .footer-menu li {
  font-weight: 300;
  font-size: 14px; }

.cricview-talnet-footer .footer-menu li .footer-menu-a {
  color: #fff; }

.cricview-talnet-footer .address-list {
  float: left;
  width: 100%;
  margin-top: 10px; }

.cricview-talnet-footer .address-list li {
  float: left;
  width: 100%;
  position: relative;
  line-height: 30px;
  padding-left: 35px;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 14px; }

.cricview-talnet-footer .address-list li img {
  font-size: 24px;
  width: 25px;
  text-align: center;
  position: absolute;
  left: 0; }

.cricview-talnet-footer .social-icons {
  list-style: none;
  float: left;
  width: auto;
  padding: 0;
  margin: 0; }

.cricview-talnet-footer .pull-left {
  float: left !important; }

.cricview-talnet-footer .social-icons li {
  float: left;
  margin: 0 15px 0 0; }

.cricview-talnet-footer .footer-bottom-talnet {
  float: left;
  width: 100%;
  text-align: center;
  min-height: 45px;
  line-height: 45px;
  border-top: solid #222 1px;
  background: #111;
  color: #fff; }

.cricview-talnet-footer .footer-bottom-talnet p {
  margin: 0;
  font-size: 14px;
  color: #fff;
  line-height: 50px;
  font-weight: 300; }

@media (max-width: 1200px) {
  .cricview-talnet-footer .container-talnet {
    width: 1170px; } }

@media (max-width: 992px) {
  .cricview-talnet-footer .container-talnet {
    width: 970px; }
  .cricview-talnet-footer .col-md-4 .col-md-2 {
    float: left; } }

@media (max-width: 768px) {
  .cricview-talnet-footer .full-talnet .logo img {
    width: 46px; }
  .cricview-talnet-footer .container-talnet {
    width: 90%; }
  .cricview-talnet-footer .col-md-1, .cricview-talnet-footer .col-md-2, .cricview-talnet-footer .col-md-3, .cricview-talnet-footer .col-md-4, .cricview-talnet-footer .col-md-5, .cricview-talnet-footer .col-md-6, .cricview-talnet-footer .col-md-7 {
    width: unset;
    height: 35%; } }

.cricview-talnet-header section {
  float: left;
  width: 100%; }

.cricview-talnet-header header {
  float: left;
  width: 100%;
  position: absolute;
  z-index: 1;
  min-height: 150px; }

.cricview-talnet-header .header-top {
  margin: 15px 0 10px;
  float: right;
  width: 100%; }

.cricview-talnet-header .col-md-6 {
  width: 50%; }

.cricview-talnet-header .logo {
  float: left;
  padding: 7px 0 6px;
  margin: 0 0 0 0px;
  position: relative; }

.cricview-talnet-header .right_top_section {
  float: right;
  padding: 17px 0 0; }

.cricview-talnet-header .login {
  float: right;
  margin: 0;
  padding: 0;
  list-style: none; }

.cricview-talnet-header .login li {
  float: left;
  margin: 0 0 0 10px;
  font-weight: 500; }

.cricview-talnet-header .login li .login-reg {
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
  background: #d8302f;
  border-radius: 50px;
  float: left; }

.cricview-talnet-header .login li .login-reg:hover, .cricview-talnet-header .login li .login-reg:focus {
  background: #fff;
  color: #333; }

.cricview-talnet-header .container-talnet {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.cricview-talnet-header .row-talnet {
  margin-right: -15px;
  margin-left: -15px; }

.cricview-talnet-header .full-talnet {
  position: relative;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0; }

.cricview-talnet-header .full-talnet .logo img {
  width: 120px; }

.cricview-talnet-header .social-icons {
  list-style: none;
  float: left;
  width: auto;
  padding: 0;
  margin: 0; }

.cricview-talnet-header .pull-left {
  float: left !important; }

.cricview-talnet-header .social-icons li {
  float: left;
  margin: 0 15px 0 0; }

.cricview-talnet-header .inner-page-banner {
  float: left;
  width: 100%;
  min-height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }

.cricview-talnet-header .inner-information-text {
  float: left;
  width: 100%;
  background: #111;
  color: #fff; }

.cricview-talnet-header .inner-information-text .container-talnet .heading {
  color: #fff;
  margin: 0;
  padding: 10px 0;
  float: left;
  text-transform: uppercase; }

.cricview-talnet-header .breadcrumb {
  padding: 11px 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #fff;
  float: right; }

.cricview-talnet-header .breadcrumb li {
  color: #fff;
  display: inline-block; }

.cricview-talnet-header .breadcrumb li a {
  color: #fff; }

.cricview-talnet-header .breadcrumb .red {
  color: #d8302f; }

@media (max-width: 1200px) {
  .cricview-talnet-header .container-talnet {
    width: 1170px; } }

@media (max-width: 992px) {
  .cricview-talnet-header .container-talnet {
    width: 970px; }
  .cricview-talnet-header .col-md-4 .col-md-2 {
    float: left; } }

@media (max-width: 768px) {
  .cricview-talnet-header .full-talnet .logo img {
    width: 120px; }
  .cricview-talnet-header .full-talnet .logo {
    float: left;
    padding: 15px 0 10px;
    margin: 0 0 0 0px;
    position: relative;
    width: 100%;
    text-align: center;
    left: 50%; }
  .cricview-talnet-header .container-talnet {
    width: 90%; }
  .cricview-talnet-header .col-md-1, .cricview-talnet-header .col-md-2, .cricview-talnet-header .col-md-3, .cricview-talnet-header .col-md-4, .cricview-talnet-header .col-md-5, .cricview-talnet-header .col-md-6, .cricview-talnet-header .col-md-7 {
    width: unset;
    height: 35%; }
  .cricview-talnet-header .right_top_section {
    float: right;
    padding: 17px 0 0;
    display: none; } }

::-webkit-scrollbar {
  height: 10px;
  width: 10px; }

::-webkit-scrollbar-track {
  background: rgba(12, 35, 123, 0.1);
  border: 0.5px solid #f1f1f1;
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, red, darkblue);
  border-radius: 10px; }

::-webkit-scrollbar-thumb:active {
  background-image: linear-gradient(45deg, red, darkblue); }

.search-module-conatiner .withHeader {
  height: 520px; }

.search-module-conatiner .cricview-latestvideo-container {
  background-color: #e9f6ff;
  background-image: url("/static/media/top_searche_sbg.62cad5f8.png");
  width: 100%;
  margin-bottom: 10px;
  margin-top: 40px; }
  .search-module-conatiner .cricview-latestvideo-container .showmore_button_container {
    margin: 1% 0;
    transform: translate(0px, -170%); }
  .search-module-conatiner .cricview-latestvideo-container .showmore_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
  .search-module-conatiner .cricview-latestvideo-container .showmore_button {
    background-color: #ff3333;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-right: 10px;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer; }
    .search-module-conatiner .cricview-latestvideo-container .showmore_button:hover {
      box-shadow: none; }
  .search-module-conatiner .cricview-latestvideo-container img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60); }
  .search-module-conatiner .cricview-latestvideo-container .cricview-latestvideo-wrapper {
    margin: 3% 3% 0%;
    position: relative; }
  .search-module-conatiner .cricview-latestvideo-container .latestvideo-header {
    display: flex;
    margin-bottom: 1%;
    align-items: center; }
  .search-module-conatiner .cricview-latestvideo-container .latestvideo-header-title {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    flex: 1 1;
    font-family: 'gilroy black';
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white; }
  .search-module-conatiner .cricview-latestvideo-container .latestvideo-header-title span {
    color: #00bcf3; }
  .search-module-conatiner .cricview-latestvideo-container .latestvideo-viewall {
    background-color: #ff3333;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    padding-top: 10px;
    width: 150px;
    margin-right: 20px;
    height: 44px;
    box-shadow: 0 5px 18px #ff3333; }
    .search-module-conatiner .cricview-latestvideo-container .latestvideo-viewall:hover {
      color: white;
      box-shadow: none; }
  .search-module-conatiner .cricview-latestvideo-container .latestvideos {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    overflow-wrap: normal;
    width: 100%;
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .search-module-conatiner .cricview-latestvideo-container .latestvideos::-webkit-scrollbar {
      height: 10px; }
    .search-module-conatiner .cricview-latestvideo-container .latestvideos::-webkit-scrollbar-track {
      background: rgba(12, 35, 123, 0.1);
      border: 0.5px solid #f1f1f1;
      border-radius: 5px; }
    .search-module-conatiner .cricview-latestvideo-container .latestvideos::-webkit-scrollbar-thumb {
      background-image: linear-gradient(45deg, red, darkblue);
      border-radius: 5px; }
    .search-module-conatiner .cricview-latestvideo-container .latestvideos::-webkit-scrollbar-thumb:active {
      background-image: linear-gradient(45deg, red, darkblue); }
  .search-module-conatiner .cricview-latestvideo-container .latestvideos-grid {
    display: flex;
    width: 200%;
    margin-bottom: 30px; }
  .search-module-conatiner .cricview-latestvideo-container .latestvideo-item {
    width: 25%;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    height: 100%; }
  .search-module-conatiner .cricview-latestvideo-container a:hover {
    text-decoration: none; }
  .search-module-conatiner .cricview-latestvideo-container .image-container {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    padding-bottom: 75%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center; }
  .search-module-conatiner .cricview-latestvideo-container .video-image {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 1s;
    object-fit: contain;
    border-radius: 5px; }
    .search-module-conatiner .cricview-latestvideo-container .video-image:hover {
      transform: scale(1.1);
      border-radius: 5px; }
  .search-module-conatiner .cricview-latestvideo-container .title-container {
    height: 150px;
    margin-bottom: 10px; }
  .search-module-conatiner .cricview-latestvideo-container .publishDate {
    padding-top: 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    height: 20%;
    color: white; }
  .search-module-conatiner .cricview-latestvideo-container .title {
    padding-top: 20px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: white; }
  .search-module-conatiner .cricview-latestvideo-container .title:hover {
    text-decoration: underline;
    overflow: visible;
    position: relative;
    text-overflow: ellipsis;
    display: block; }
  .search-module-conatiner .cricview-latestvideo-container .arrow-flex {
    display: flex;
    align-items: center; }
  .search-module-conatiner .cricview-latestvideo-container .arrow-left {
    cursor: pointer;
    margin-bottom: 150px;
    margin-right: 2%; }
  .search-module-conatiner .cricview-latestvideo-container .arrow-right {
    cursor: pointer;
    margin-bottom: 150px; }
  .search-module-conatiner .cricview-latestvideo-container .arrow-image {
    height: 100%; }
  .search-module-conatiner .cricview-latestvideo-container .time-container {
    float: right;
    margin-right: 20px;
    padding: 5px;
    width: 75px;
    text-align: center;
    position: relative; }
  .search-module-conatiner .cricview-latestvideo-container .time {
    font-size: 16px;
    color: red;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    background: white;
    width: 75px;
    border-radius: 20px; }
  .search-module-conatiner .cricview-latestvideo-container .play-button {
    position: absolute;
    left: 37.5%;
    top: 37.5%;
    width: 20%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1; }
  .search-module-conatiner .cricview-latestvideo-container .play-image {
    height: 100%;
    width: 100%;
    border-radius: 0px; }
  @media (max-width: 768px) {
    .search-module-conatiner .cricview-latestvideo-container .cricview-latestvideo-wrapper {
      margin: 4%; }
    .search-module-conatiner .cricview-latestvideo-container .arrow-left {
      margin-right: 0%; }
    .search-module-conatiner .cricview-latestvideo-container .arrow-left svg {
      height: 24px;
      width: 24px; }
    .search-module-conatiner .cricview-latestvideo-container .arrow-right svg {
      height: 24px;
      width: 24px; }
    .search-module-conatiner .cricview-latestvideo-container .ar
.latestvideo-header {
      margin-left: 20px; }
    .search-module-conatiner .cricview-latestvideo-container .latestvideo-item {
      width: 25%;
      margin-right: 20px;
      margin-top: 20px;
      margin-left: 20px;
      border-radius: 5px;
      color: white;
      height: 100%; }
    .search-module-conatiner .cricview-latestvideo-container .time {
      bottom: 200%;
      font-size: 14px; }
    .search-module-conatiner .cricview-latestvideo-container .publishDate {
      font-size: 12px; }
    .search-module-conatiner .cricview-latestvideo-container .title {
      font-size: 14px; }
    .search-module-conatiner .cricview-latestvideo-container .latestvideo-viewall {
      display: none; }
    .search-module-conatiner .cricview-latestvideo-container .viewall-mobile {
      display: flex;
      margin-top: 20px; }
      .search-module-conatiner .cricview-latestvideo-container .viewall-mobile .article-viewall-mobile {
        background-color: #ff3333;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        padding-top: 10px;
        width: 100%;
        margin-right: 0;
        height: 44px;
        box-shadow: 0 5px 18px #ff3333; }
        .search-module-conatiner .cricview-latestvideo-container .viewall-mobile .article-viewall-mobile:hover {
          background-color: white;
          color: #ff3333;
          box-shadow: 0 5px 18px -1px #ff3333; }
    .search-module-conatiner .cricview-latestvideo-container .latestvideos::-webkit-scrollbar {
      display: none; } }
  @media (max-width: 425px) {
    .search-module-conatiner .cricview-latestvideo-container .latestvideo-header-title {
      font-size: 25px; } }

@media (max-width: 1024px) {
  .search-module-conatiner .withHeader {
    height: 430px; } }

@media (max-width: 768px) {
  .search-module-conatiner .withHeader {
    height: 500px; } }

@media (max-width: 340px) {
  .search-module-conatiner .withHeader {
    height: 420px; } }

.cricview-header {
  border: 1px solid;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(85, 172, 239, 0.1);
  background-image: linear-gradient(102deg, #3e2ed5 14%, #5812bf 45%, #e60e42 106%); }
  .cricview-header a {
    color: inherit;
    cursor: pointer; }
    .cricview-header a:hover {
      text-decoration: none;
      color: inherit; }
  .cricview-header .cricview-navigation-desktop {
    display: flex;
    align-items: center;
    height: 74px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    color: white;
    text-transform: uppercase;
    position: relative;
    margin: 0 34px;
    transition: height 0.4s ease, box-shadow 0.1s ease; }
  .cricview-header .hover-list:hover {
    color: #ff3333; }
  .cricview-header .cricview-branding-container {
    cursor: pointer;
    text-align: left;
    display: flex;
    flex: 2 1;
    align-items: center;
    justify-content: center; }
    .cricview-header .cricview-branding-container .header-image {
      height: 100%;
      width: 100%; }
  .cricview-header .slash-line {
    width: 1px;
    height: 42px;
    border-radius: 100px;
    background-color: #ff6f6f;
    margin: 0 10px; }
  .cricview-header .branding-partner {
    width: 100px;
    height: 43px; }
  .cricview-header .poweredby {
    width: 56px;
    height: 11px;
    font-family: Gilroy;
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  .cricview-header .nexg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-image: linear-gradient(180deg, #0c237b, #ff3333);
    border-radius: 5px;
    padding: 5px;
    margin: -3% 0; }
  .cricview-header .cricview-navigation-container {
    flex: 5 1; }
  .cricview-header .cricview-navigation-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0; }
  .cricview-header .cricview-navigation-list-item {
    list-style-type: none;
    height: 100%;
    padding: 0 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: padding 0.4s ease, box-shadow 0.1s ease;
    border-radius: 5px;
    font-size: 16px; }
  .cricview-header .cricview-search-container {
    display: none; }
    .cricview-header .cricview-search-container:hover {
      fill: #ff3333;
      cursor: pointer; }
  .cricview-header .cricview-search-container-open {
    display: none;
    width: 0;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    margin-left: auto;
    transition: all 1s ease;
    z-index: 50; }
  .cricview-header .is-active {
    display: block;
    position: relative;
    width: 75%;
    margin-left: auto;
    background-color: #000;
    border-radius: 15px 15px 15px 15px; }
  .cricview-header .close {
    display: none; }
  .cricview-header .cricview-navigation-mobile {
    display: none; }
  .cricview-header .search-icon {
    cursor: pointer; }
  .cricview-header .hover:hover {
    height: 42px;
    width: 42px;
    fill: #ff3333; }
  .cricview-header .search-button-go {
    top: 50%;
    transition: all 0.2s ease;
    background: none;
    border: none;
    color: white;
    height: 45px; }
  .cricview-header .search-open-container {
    display: flex;
    background-color: #000;
    align-items: center; }
  .cricview-header .search-input-box {
    height: 100%;
    flex: 10 1; }
    .cricview-header .search-input-box .input {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      color: white;
      padding-left: 3rem; }
  .cricview-header .search-button-close {
    height: 100%;
    margin-right: 10px;
    fill: #ff3333; }
    .cricview-header .search-button-close:hover {
      cursor: pointer; }
  .cricview-header .deactivate {
    display: none; }
  .cricview-header .navigation__drop-down {
    min-width: 8rem;
    display: block;
    position: absolute;
    top: 68%;
    transform: scale(1) translateX(-15%);
    visibility: hidden;
    text-align: left;
    padding: 1.5rem;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    z-index: 210;
    cursor: default; }
  .cricview-header .navigation__drop-down {
    min-width: 19.6rem;
    border-radius: 5px;
    padding: 1rem 1.8rem 0.5rem;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15); }
  .cricview-header .cricview-navigation-list-item:hover .navigation__drop-down {
    visibility: visible;
    color: #2f2f2f; }
  .cricview-header .showList {
    visibility: visible;
    color: #2f2f2f; }
  .cricview-header .icon {
    transition: transform 0.2s ease; }
  .cricview-header .search-modal {
    display: none;
    position: absolute;
    left: 0;
    width: 100vw;
    height: calc(100vh - 50px);
    overflow: scroll;
    background-color: #fff;
    z-index: 10000; }
    .cricview-header .search-modal .no-search-results {
      display: none;
      font-size: 40px;
      color: red;
      font-weight: 600;
      position: relative;
      top: 10%;
      left: 40%; }
    .cricview-header .search-modal .loader-container {
      display: none;
      position: absolute;
      z-index: 100000;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: black;
      background-color: rgba(0, 0, 0, 0.4); }
    .cricview-header .search-modal #loader {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      margin: -75px 0 0 -75px;
      border: 10px solid #f3f3f3;
      border-radius: 50%;
      border-top: 10px solid #480b48;
      width: 100px;
      height: 100px;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .cricview-header .navigation__drop-down-link {
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: 1.3rem;
    color: #2f2f2f;
    text-transform: uppercase;
    line-height: 1;
    border-bottom: 1px solid #efefef; }
  @media (max-width: 1024px) {
    .cricview-header .cricview-navigation-desktop {
      height: 75px; }
    .cricview-header .branding-partner {
      width: 100px;
      height: 35px; }
    .cricview-header .nexg {
      width: 100%;
      object-fit: contain; }
    .cricview-header .cricview-branding-container {
      margin: 0 20px;
      cursor: pointer;
      text-align: left; }
      .cricview-header .cricview-branding-container .header-image {
        height: 35px; }
    .cricview-header .cricview-navigation-list {
      font-size: 12px; }
    .cricview-header .cricview-navigation-list-item {
      padding: 0 10px; } }
  @media only screen and (max-width: 768px) {
    .cricview-header .cricview-navigation-desktop {
      display: none; }
    .cricview-header .cricview-navigation-mobile {
      z-index: 999;
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
      color: white;
      font-size: 14px;
      position: relative; }
    .cricview-header .cricview-branding-container {
      height: 100%;
      margin: 0;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center; }
    .cricview-header .cricview-navigation-mobile-list {
      display: flex;
      width: 100%;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      justify-content: space-between; }
    .cricview-header .nexg {
      width: 100%;
      object-fit: contain; }
    .cricview-header .cricview-navigation-mobile-list-item {
      height: 100%; }
      .cricview-header .cricview-navigation-mobile-list-item:first-child {
        margin-left: 5%;
        text-align: left; }
      .cricview-header .cricview-navigation-mobile-list-item:last-child {
        margin-right: 5%;
        text-align: right; }
    .cricview-header a {
      color: inherit; }
      .cricview-header a:hover {
        text-decoration: none;
        color: inherit; }
    .cricview-header .title {
      vertical-align: middle;
      font-size: 12px; }
    .cricview-header .cricview-navigation-mobilemenu-container {
      display: none; }
    .cricview-header .openMenu {
      display: block;
      background-color: white;
      z-index: 1;
      position: absolute;
      top: 46px;
      width: 100%;
      color: #04064d; }
    .cricview-header .cricview-navigation-mobilemenu-list {
      list-style: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 1em;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px; }
    .cricview-header .close {
      display: none; }
    .cricview-header .cricview-navigation-mobilemenu-list-item {
      padding: 1em 0;
      border-bottom: 0.2px solid #333;
      text-align: center; }
    .cricview-header .deactivate {
      display: none; }
    .cricview-header .cricview-menu-search-container {
      display: none; }
    .cricview-header .activate {
      display: block; }
    .cricview-header .cricview-search-container {
      margin-right: 0; }
    .cricview-header .cricview-search-container-open {
      display: none;
      height: 3.6rem;
      width: 100%;
      overflow: hidden;
      margin-left: auto;
      transition: all 1s ease;
      z-index: 50; }
    .cricview-header .is-active {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      margin-right: 0;
      margin-left: 0;
      background-color: #000;
      border-radius: 0; }
    .cricview-header .search-open-container {
      display: flex;
      background-color: #000;
      height: 100%;
      align-items: center;
      width: 100%; }
    .cricview-header .search-input-box {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      flex: 10 1; }
      .cricview-header .search-input-box .input {
        width: 100%;
        background: none;
        outline: none;
        border: none;
        color: white;
        padding-left: 3rem;
        height: 100%; }
    .cricview-header .search-button-go {
      top: 50%;
      transition: all 0.2s ease;
      background: none;
      border: none;
      color: white;
      height: 100%; }
    .cricview-header .search-button-close {
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      line-height: normal;
      margin-right: 10px;
      fill: #ff3333; } }

.loader-container {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  margin: -75px 0 0 -75px;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #480b48;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s; }

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0; }
  to {
    bottom: 0px;
    opacity: 1; } }

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0; }
  to {
    bottom: 0;
    opacity: 1; } }

.language-bar-container {
  width: 100%;
  height: 40px;
  background-color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  font-size: 13px;
  font-weight: bold; }

.tab-container {
  padding: 0 10px; }
  .tab-container:first-child {
    margin-left: 34px;
    padding-left: 0; }
  .tab-container:last-child {
    margin-right: 34px;
    padding-right: 0; }

.flex-3 {
  flex: 3 1; }

.flex-6 {
  flex: 6 1; }

.flex-9 {
  flex: 9 1; }

.drop-down-cont {
  margin: 7px 20px;
  display: table-cell;
  flex: auto; }

.drop-down-cont select {
  border-radius: 4px;
  padding: 0 10px; }

.registration-container {
  float: right;
  margin-right: 10px; }

.rectangle {
  width: 80px;
  height: 30px;
  border-radius: 2px;
  background-color: #ff3333;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center; }

.black {
  height: 17px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00061a;
  text-align: center; }

.white {
  margin: 0 20px;
  opacity: 0.8;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff; }

@media only screen and (max-width: 768px) {
  .language-bar-container {
    /* width:100%; */
    min-height: 40px;
    background-color: #000;
    position: relative;
    /* display: inline; */ }
  .drop-down-cont {
    margin: 7px 10px;
    display: table-cell;
    /* flex:auto; */ }
  .drop-down-cont select {
    border-radius: 4px;
    padding: 0 10px; }
  .tab-container {
    padding: 0 10px; }
    .tab-container:first-child {
      margin-left: 34px;
      padding-left: 0; }
    .tab-container:last-child {
      margin-right: 14px;
      padding-right: 0; } }

.language-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: white;
  border-radius: 2px;
  display: flex;
  align-items: center; }

.language-container .icon {
  color: white;
  width: 2.5rem;
  height: 2.5rem; }

.team-icon {
  color: white;
  width: 1.5rem;
  height: 1.5rem; }

.language-container span {
  padding-left: 10px;
  color: white; }

.show {
  display: block; }

.hide {
  display: none; }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  color: black;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 6% 6%;
  z-index: 9999; }
  .dropdown-content li {
    list-style-type: none;
    border-bottom: 1px solid #CCC;
    padding: 2% 0; }

.dropdown:hover .dropdown-content {
  display: block; }

.sticky-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
  }
 
  

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

.footer button {
  background: none;
  border: none; }

.flex-grid {
  align-items: stretch;
  display: flex !important;
  flex-wrap: wrap; }

.inline-list {
  font-size: 0; }

.inline-list > li {
  display: inline-block; }

[class*="col-"] {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  float: left; }

.col--centered {
  margin: 0 auto;
  float: none; }

.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  background: #fff;
  color: #001036;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  font-size: 1.4em; }

:active,
:focus {
  outline: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0; }

figure {
  margin: 0; }

ol,
ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0; }

.footer ol li,
.footer ul li {
  list-style-type: none; }

.footer {
  background-color: #fff;
  position: relative;
  width: 100%; }

.footer:before {
  content: "";
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 4rem;
  right: 0;
  opacity: 0.4;
  background-image: radial-gradient(circle at 48% 0, #95a1a8, #fff); }

.footer.u-no-padding:before {
  height: 0; }

svg.icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor; }

[class*="flag-"] {
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2.2rem; }

h3 {
  font-size: 1.8rem; }

h4 {
  font-size: 1.6rem; }

h5 {
  font-size: 1.4rem; }

h6 {
  font-size: 1.2rem; }

p {
  line-height: 1.6; }

p,
p a {
  color: #001036; }

p a {
  text-decoration: none; }

p a:hover {
  text-decoration: underline; }

.footer:before {
  content: "";
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 4rem;
  right: 0;
  opacity: 0.4;
  background-image: radial-gradient(circle at 48% 0, #95a1a8, #fff); }

.global-footer__list {
  padding: 0 1.5rem;
  z-index: 10; }

.global-footer--active .global-footer__background:after,
.global-footer--active .global-footer__background:before {
  top: 0; }

.global-footer__background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  overflow: hidden;
  background-image: url(/static/media/footer_bg.024a6c8d.jpg); }

.global-footer__background_follow {
  content: "";
  position: absolute;
  width: 696px;
  height: 81px;
  right: 0%;
  top: 0px;
  background-repeat: no-repeat; }

.global-footer__branding {
  display: block; }

.wrapper {
  width: 100%;
  margin: 0 auto; }

.wrapper:after {
  content: "";
  display: table;
  clear: both; }

.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

.footer-logo {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 68px;
  padding: 20px 0 0;
  color: #fff;
  position: absolute; }

.slash-line {
  width: 1px;
  height: 42px;
  border-radius: 100px;
  background-color: #4976b6;
  margin: 0 10px; }

.poweredby {
  width: 56px;
  height: 11px;
  font-family: Gilroy;
  font-size: 9px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff; }

.nexg {
  width: 100%;
  object-fit: contain;
  height: 100%; }

.image-tiktok {
  width: 100%; }

.roww3 {
  padding: 0 3% 1% 3%; }

.social-follow-footer {
  display: flex;
  justify-content: center; }

.social-follow-footer:after {
  content: "";
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
  bottom: -3.2rem;
  right: 0;
  opacity: 0.3;
  background-image: radial-gradient(circle at 48% 0, #fff, rgba(255, 255, 255, 0)); }

.social-follow-footer:after {
  display: none; }

.social-follow-footer__subtitle {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;
  text-transform: uppercase; }

.follow-us-on {
  padding: 15px 0px 0px 0px;
  white-space: nowrap;
  text-align: -webkit-center;
  color: #ffff; }

.social-follow-footer__item {
  position: relative;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  margin-right: 1rem; }

.social-follow-footer__item:last-child {
  margin-right: 0; }

.social-follow-footer__link {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 50%;
  transition: all 0.2s ease-in-out; }

.social-follow-footer__link .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2.6rem;
  width: 2.6rem;
  transition: fill 0.2s ease-in-out; }

.social-follow-footer__link.facebook {
  background-color: #3b5998; }

.social-follow-footer__link.facebook:focus {
  background-color: #fff; }

.social-follow-footer__link.facebook:focus .icon {
  fill: #3b5998; }

.social-follow-footer-item {
  position: relative;
  border-radius: 50%;
  height: 5rem;
  width: 5rem; }

.showList:hover .dropp_down {
  visibility: visible;
  color: #2f2f2f; }

.drop_down_link {
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: 1.3rem;
  color: #2f2f2f;
  text-transform: uppercase;
  line-height: 1;
  border-bottom: 1px solid #efefef; }

.dropp_down {
  min-width: 8rem;
  display: block;
  position: absolute;
  transform: scale(1) translateX(-50%);
  visibility: hidden;
  text-align: left;
  padding: 1.5rem;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  z-index: 210;
  cursor: default; }

.global-footer__social-container {
  margin-bottom: 3rem;
  padding: 2px 0 0 515px; }

.global-footer__lists {
  width: 100%;
  padding: 9rem 8rem 0rem 8rem;
  display: flex;
  justify-content: space-between; }

.global-footer__lists hr.separator {
  display: none; }

.global-footer__title {
  color: #fff;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1rem; }

.global-footer__title:hover {
  color: #ff3333; }

.global-footer__item {
  margin-bottom: 0.8rem; }

.global-footer__link {
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none; }

.global-footer__link:focus,
.global-footer__link:hover {
  text-decoration: underline;
  color: #fff; }

.global-footer__link .icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin: -4px 0 0;
  fill: #fff; }

hr.separator {
  border-top: 0.1px dotted #fff;
  position: relative;
  opacity: 0.2; }

.global-footer-corporate {
  position: relative;
  height: 5.7rem;
  background: transparent; }

.global-footer-corporate__item {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 600; }

.global-footer-corporate__list {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  color: #51626f;
  opacity: 0.7; }

.global-footer-corporate__list {
  color: #fff;
  opacity: 1; }

.global-footer-corporate__link {
  color: #51626f;
  text-decoration: none; }

.global-footer-corporate__link,
.global-footer-corporate__list {
  color: #fff;
  opacity: 1; }

.global-footer-corporate__link:focus {
  text-decoration: underline;
  color: #fff; }

.social-follow-footer__link.twitter {
  background-color: #55acee; }

.social-follow-footer__link.twitter:focus {
  background-color: #fff; }

.social-follow-footer__link.twitter:focus .icon {
  fill: #55acee; }

.social-follow-footer__link.youtube {
  background-color: #b00; }

.social-follow-footer__link.youtube:focus {
  background-color: #fff; }

.social-follow-footer__link.youtube:focus .icon {
  fill: #b00; }

.social-follow-footer__link.tiktok {
  overflow: hidden; }

.social-follow-footer__link.instagram {
  background-color: #fff; }

.social-follow-footer__link.instagram .icon {
  z-index: 10; }

.social-follow-footer__link.instagram:focus:before {
  opacity: 0; }

.social-follow-footer__link.instagram:focus .icon {
  fill: #000; }

svg.icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor; }

.footer-corporate-2nd-line {
  display: contents;
  font-size: 1.5rem; }

.collapsible {
  display: none; }

.active {
  display: none; }

.content {
  display: block; }

@media (max-width: 1050px) {
  .collapsible {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAAZ0lEQVRIie3VsQ2AMAwF0cA6MA6IAvagZe8gXSokiHAVfReRbwA/u0lSijwCJg/kAm7gUCNPGgyYgcy3DGwKbPnBZJcF1jk2NliDtUPDzGoSrMY1eyB9I66P6im7xMC0yAvTf+VRXQHej5th/hZ9QgAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-color: transparent;
    width: 45px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    float: right;
    display: block; }
  .active {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAB30lEQVRIie3Uz28SURDA8e+8ZdObXvBHsFBLMRj15MVTkwKaaAxLtOHk3+epCVXQGJMCIfXcixpbbVOwFFM0Hrw17NvnAQryCkJt4ok5bfbNzOdNMrswi1n875CTB5N4dIHjuWdovSat0o/zNDWRbBjHyTN3/Fx23/zqQyaev4juvMVwD8w2xmTkoNj6J2TRu4JWG8AdYIvAfSDNtZ8KAN8vdhEAuYmoiol6kTMjUS+CVrUeAnAXp1MAUL2UsFWTRFTNRHLRqZFILoqoGpAcPpDwAPLNQ2DXqk0Qkk0TW41PROLZGCGpAgnrqIH42T4krZcHaJUagS1gdNXMe3aDAbL4dAHfqQJL1lEdxYo0SvuDiQA5LDRR7jLwaShdiKFk08Ryt04h86s30ME7wJ76C1otS/1FfdDGLr6ev0rQKQN24yPQGfla+tidxEuinTKYa6eQwE9J89Xh8H1HRG9FKyOwNoG5TyjUIdBlYHgzhc9oP20jYyHoraqoCvYWCd+7CVyySnYwQXrc9zcWgpPJnDKY23/Lm4TAH8sw8hb7xSNc0sCH8yATIQDZW2/jSmYMtoOo1DS/q4mQhb0fvDXbiEpJo/Btqh7TJPVbLz25TMe8BsCVx7K33j5L/SxmAcBvnmu0g39At3EAAAAASUVORK5CYII=);
    width: 36px;
    border: none;
    text-align: left;
    outline: none;
    background-repeat: no-repeat;
    background-color: transparent;
    display: block; }
  .copy-right {
    padding-top: 10px; }
  .content {
    display: none;
    clear: both;
    padding-top: 10px; }
  .global-footer__list {
    padding: 0;
    margin: 1rem 0; }
  .footer-menu-head {
    float: left; }
  .footer-top-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; }
  .global-footer__social-container {
    margin-bottom: 0rem;
    padding: 2px 0px; }
  .footer-logo {
    width: 100%;
    height: 61px;
    position: relative;
    padding: 0px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-align: center; }
  .footer-logo .image {
    vertical-align: middle;
    border-style: none;
    border-radius: 2px;
    transition: transform 1s;
    width: 86%; }
  .global-footer__background_follow {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    background-color: #00166c;
    background-image: none;
    padding: 12px; }
  .follow-us-on {
    width: 100%;
    padding: 0px 0 0 2rem;
    white-space: nowrap;
    text-align: left;
    color: #ffff;
    font-size: 1.2rem; }
  .social-follow-footer-item {
    position: relative;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    margin-left: 1rem; }
  .global-footer__lists {
    width: 100%;
    padding: 0px 1.5em;
    padding-left: 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
  .global-footer__lists hr.separator {
    display: block; }
  .global-footer__item {
    margin: 1rem 0 1rem 1.5rem; }
  .global-footer__item {
    margin-bottom: 0.8rem; }
  .global-footer__title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0rem; }
  .social-follow-footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 10;
    padding-right: 200px;
    padding-left: 200px; }
  .social-follow-footer__link {
    display: block;
    height: 83%;
    width: 83%;
    position: relative;
    border-radius: 50%;
    transition: all 0.2s ease-in-out; }
  .social-follow-footer__link .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    transition: fill 0.2s ease-in-out; }
  .global-footer-corporate {
    height: 100%;
    padding: 16px; }
  .footer-items {
    display: none; }
  .global-footer-corporate__list {
    flex-direction: column-reverse;
    width: 100%; }
  .global-footer-corporate__item {
    font-size: 1.2rem;
    line-height: 2;
    justify-content: center; }
  .footer-corporate-2nd-line {
    display: inline-flex;
    width: 90%;
    justify-content: flex-start; }
  .footer-corporate-2nd-line .item-name {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold; }
  .nexg {
    width: 85%;
    object-fit: contain; } }

@media (max-width: 768px) {
  .collapsible {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAAZ0lEQVRIie3VsQ2AMAwF0cA6MA6IAvagZe8gXSokiHAVfReRbwA/u0lSijwCJg/kAm7gUCNPGgyYgcy3DGwKbPnBZJcF1jk2NliDtUPDzGoSrMY1eyB9I66P6im7xMC0yAvTf+VRXQHej5th/hZ9QgAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-color: transparent;
    width: 45px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    float: right;
    display: block; }
  .active {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAB30lEQVRIie3Uz28SURDA8e+8ZdObXvBHsFBLMRj15MVTkwKaaAxLtOHk3+epCVXQGJMCIfXcixpbbVOwFFM0Hrw17NvnAQryCkJt4ok5bfbNzOdNMrswi1n875CTB5N4dIHjuWdovSat0o/zNDWRbBjHyTN3/Fx23/zqQyaev4juvMVwD8w2xmTkoNj6J2TRu4JWG8AdYIvAfSDNtZ8KAN8vdhEAuYmoiol6kTMjUS+CVrUeAnAXp1MAUL2UsFWTRFTNRHLRqZFILoqoGpAcPpDwAPLNQ2DXqk0Qkk0TW41PROLZGCGpAgnrqIH42T4krZcHaJUagS1gdNXMe3aDAbL4dAHfqQJL1lEdxYo0SvuDiQA5LDRR7jLwaShdiKFk08Ryt04h86s30ME7wJ76C1otS/1FfdDGLr6ev0rQKQN24yPQGfla+tidxEuinTKYa6eQwE9J89Xh8H1HRG9FKyOwNoG5TyjUIdBlYHgzhc9oP20jYyHoraqoCvYWCd+7CVyySnYwQXrc9zcWgpPJnDKY23/Lm4TAH8sw8hb7xSNc0sCH8yATIQDZW2/jSmYMtoOo1DS/q4mQhb0fvDXbiEpJo/Btqh7TJPVbLz25TMe8BsCVx7K33j5L/SxmAcBvnmu0g39At3EAAAAASUVORK5CYII=);
    width: 36px;
    border: none;
    text-align: left;
    outline: none;
    background-repeat: no-repeat;
    background-color: transparent;
    display: block; }
  .copy-right {
    padding-top: 10px; }
  .content {
    display: none;
    clear: both;
    padding-top: 10px; }
  .global-footer__list {
    padding: 0;
    padding: 1rem 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dotted #999; }
  .footer-menu-head {
    float: left; }
  .footer-top-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; }
  .global-footer__social-container {
    margin-bottom: 0rem;
    padding: 2px 0px; }
  .footer-logo {
    width: 100%;
    height: 61px;
    position: relative;
    padding: 0px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-align: center; }
  .footer-logo .image {
    vertical-align: middle;
    border-style: none;
    border-radius: 2px;
    transition: transform 1s;
    width: 86%; }
  .global-footer__background_follow {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    background-color: #00166c;
    background-image: none;
    padding: 12px; }
  .follow-us-on {
    width: 100%;
    padding: 0 0 0 14px;
    white-space: nowrap;
    text-align: left;
    color: #ffff;
    font-size: 1.2rem; }
  .social-follow-footer-item {
    position: relative;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    margin-left: 1rem; }
  .global-footer__lists {
    width: 100%;
    padding: 0px 1.5em;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
  .global-footer__lists hr.separator {
    display: block; }
  .global-footer__item {
    margin: 1rem 0 1rem 1.5rem; }
  .global-footer__item {
    margin-bottom: 0.8rem; }
  .global-footer__title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0rem; }
  .social-follow-footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 10;
    padding: 0px; }
  .social-follow-footer__link {
    display: block;
    height: 83%;
    width: 83%;
    position: relative;
    border-radius: 50%;
    transition: all 0.2s ease-in-out; }
  .social-follow-footer__link .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    transition: fill 0.2s ease-in-out; }
  .global-footer-corporate {
    height: 100%;
    padding: 16px 0 16px 0; }
  .footer-items {
    display: none; }
  .global-footer-corporate__list {
    flex-direction: column-reverse;
    width: 100%; }
  .global-footer-corporate__item {
    font-size: 1.2rem;
    line-height: 2;
    justify-content: center; }
  .footer-corporate-2nd-line {
    display: inline-flex;
    width: 90%;
    justify-content: flex-start; }
  .footer-corporate-2nd-line .item-name {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold; }
  .nexg {
    width: 85%;
    object-fit: contain; } }

.banner-container{
    background-color: #fff;
    position: relative;

}
.banner-info{
    position: absolute;
    color: white;
    padding:10px 10px;
    max-width: 50%;
    font-size: 16px;
    font-weight: 800;
    margin: 40px;
}
.banner-info-btn-con{
  padding:20px;
}
/* .banner-info-btn{ */
  .banner-info-btn-con a{
    border:1px solid #e42e6a;
    background-color: #e42e6a;
    border-radius: 30px;
    font-size: 14px;
    margin:20px;
    color: white;
    font-weight: 800;
    padding:5px 20px ;
    /* cursor: pointer; */
    text-decoration: none;
}
.modal-close {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
  }
  .img-close {
    height: 50%;
    width: 50%;
  }
  
  /* ----------------banner-css */
  .img-banner{
    width: 100%;       
    object-fit: fill;
  }
  .img-banner img{
    width: 100%;
    height: 100%;
        display: block; 
  }
  @media only screen and (max-width: 768px){
    .banner-info{
        max-width: 100% !important;
        padding-bottom: 0;
        margin-bottom: 0;
        bottom: 0;
    }
    .modal-close {
      position: absolute;
      left: 5px;
      top: 5px;
      cursor: pointer;
      border: none;
      z-index: 100;
    }
    .img-close {
      height: 25%;
      width: 25%;
    }
    /* .banner-container */
  }
.livematch {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1% 2%;
  background-image: url(/static/media/CricView-Banner1.4c540249.jpg);
  background-repeat: no-repeat;
  background-size: cover; }
  .livematch .setMargin {
    margin-left: 2%; }
  .livematch .card_1 {
    display: flex;
    justify-content: center; }
  .livematch .borderCornerOne {
    background: linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 0, linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 100%, linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 0, linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 100%, linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 0 0, linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 100% 0, linear-gradient(to top, #c60f5e 7px, transparent 7px) 0 100%, linear-gradient(to top, #c60f5e 7px, transparent 7px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 25px 25px; }
  .livematch .borderCornerTwo {
    background: linear-gradient(to right, #00aeef 7px, transparent 7px) 0 0, linear-gradient(to right, #00aeef 7px, transparent 7px) 0 100%, linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 0, linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 100%, linear-gradient(to bottom, #00aeef 7px, transparent 7px) 0 0, linear-gradient(to bottom, #00aeef 7px, transparent 7px) 100% 0, linear-gradient(to top, #00aeef 7px, transparent 7px) 0 100%, linear-gradient(to top, #00aeef 7px, transparent 7px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 25px 25px; }
  .livematch .boxOne {
    display: flex;
    width: 25%;
    position: relative; }
  .livematch .imageBoxCric {
    width: 8%;
    position: absolute;
    right: 19% !important;
    top: 8%; }
    .livematch .imageBoxCric img {
      width: 100%;
      height: 100%;
      border-radius: 50%; }
  .livematch .imageBox {
    width: 85% !important;
    height: 150px;
    padding: 1.7%; }
    .livematch .imageBox img {
      background-image: linear-gradient(red, blue);
      width: 100%;
      height: 138px; }
  @media (max-width: 1980px) {
    .livematch .imageBox {
      padding: 1.3% 1.1% 1.3% 1.3%;
      width: 85%; }
    .livematch .imageBoxCric {
      right: 18%; } }
  @media (max-width: 1440px) {
    .livematch .imageBox {
      padding: 1.7%; } }
  @media (max-width: 1024px) {
    .livematch {
      background-size: 1024px 156px; }
      .livematch .boxOne {
        width: 28%; }
      .livematch .setMargin {
        margin-left: 4%; }
      .livematch .imageBox {
        height: 136px;
        padding: 2%; }
        .livematch .imageBox img {
          width: 100%;
          height: 125px; } }
  @media (max-width: 768px) {
    .livematch .boxOne {
      width: 40%; }
    .livematch .imageBox {
      height: 135px; }
    .livematch .imageBoxCric {
      top: 5%; } }
  @media (max-width: 450px) {
    .livematch {
      flex-direction: column;
      height: 350px;
      background-image: url(/static/media/CricView-Banner-Mobile1.5a6a7100.jpg);
      background-repeat: no-repeat;
      background-size: 425px 350px; }
      .livematch .boxOne {
        width: 80%;
        margin-bottom: 5%;
        margin-left: 0%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .livematch .imageBoxCric {
        top: 8%;
        right: 11% !important; }
      .livematch .imageBox {
        height: 137px;
        padding: 2% 1.9% 1.9% 1.9%; } }
  @media (max-width: 375px) {
    .livematch {
      background-size: 375px 350px; }
      .livematch .imageBox {
        height: 136px;
        padding: 2.2% 1.9% 1.9% 1.9%; } }
  @media (max-width: 320px) {
    .livematch {
      background-size: 320px 350px; }
      .livematch .imageBox {
        height: 135px;
        padding: 2.2% 1.9% 1.9% 1.9%; } }

.livematch {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1% 2%;
  background-image: url(/static/media/CricView-Banner1.4c540249.jpg);
  background-repeat: no-repeat;
  background-size: cover; }

@-webkit-keyframes blinkingIpl {
  100% {
    text-shadow: 0 0 2px #cec1c1, 0 0 10px #b3a9a9, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101; } }

@keyframes blinkingIpl {
  100% {
    text-shadow: 0 0 2px #cec1c1, 0 0 10px #b3a9a9, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101; } }
  .livematch .team1 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    display: flex;
    width: 100%;
    padding: 1% 1% 1% 2%;
    height: 24%;
    color: white; }
    .livematch .team1 .name {
      width: 20%; }
    .livematch .team1 .score {
      line-height: 1.1;
      width: 80%;
      align-items: center;
      justify-content: flex-end;
      display: flex; }
  .livematch .scoreUpdate {
    height: 100%;
    background-image: radial-gradient(farthest-corner at 114% 55%, #fe0813, #3c7fff, #08255d, #08255d);
    border: 1px solid grey;
    width: 100%; }
    .livematch .scoreUpdate .comment {
      line-height: 1.1;
      font-size: 14px;
      padding: 0 1% 1% 1%;
      color: white;
      height: 23%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 900; }
    .livematch .scoreUpdate .status {
      text-shadow: 0 0 2px #e6e2e2, 0 0 10px #ebe6e6, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101;
      -webkit-animation: blinkingIpl 1.5s infinite alternate;
      animation: blinkingIpl 1.5s infinite alternate;
      letter-spacing: 1px;
      font-weight: 900;
      height: 16%;
      padding-left: 2%;
      font-size: 16px;
      color: red; }
    .livematch .scoreUpdate .location {
      font-size: 10px;
      padding-left: 2%;
      height: 12%;
      color: white;
      font-weight: 900; }
  .livematch .setMargin {
    margin-left: 2%; }
  .livematch .borderCornerOne {
    background: linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 0, linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 100%, linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 0, linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 100%, linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 0 0, linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 100% 0, linear-gradient(to top, #c60f5e 7px, transparent 7px) 0 100%, linear-gradient(to top, #c60f5e 7px, transparent 7px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 25px 25px; }
  .livematch .borderCornerTwo {
    background: linear-gradient(to right, #00aeef 7px, transparent 7px) 0 0, linear-gradient(to right, #00aeef 7px, transparent 7px) 0 100%, linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 0, linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 100%, linear-gradient(to bottom, #00aeef 7px, transparent 7px) 0 0, linear-gradient(to bottom, #00aeef 7px, transparent 7px) 100% 0, linear-gradient(to top, #00aeef 7px, transparent 7px) 0 100%, linear-gradient(to top, #00aeef 7px, transparent 7px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 25px 25px; }
  .livematch .boxOne {
    display: flex;
    width: 25%;
    position: relative; }
  .livematch .imageBoxCric {
    width: 7%;
    position: absolute;
    right: 18% !important;
    top: 8.5%; }
    .livematch .imageBoxCric img {
      width: 100%;
      height: 100%;
      border-radius: 50%; }
  .livematch .imageBox {
    width: 85% !important;
    height: 150px;
    padding: 1.7%; }
  @media (max-width: 1980px) {
    .livematch .imageBox {
      padding: 1.3% 1.1% 1.3% 1.3%;
      width: 85%; }
    .livematch .imageBoxCric {
      right: 18%; } }
  @media (max-width: 1440px) {
    .livematch .imageBox {
      padding: 1.7%; } }
  @media (max-width: 1024px) {
    .livematch {
      background-size: 1024px 156px; }
      .livematch .boxOne {
        width: 28%; }
      .livematch .setMargin {
        margin-left: 4%; }
      .livematch .imageBox {
        height: 136px;
        padding: 2%; } }
  @media (max-width: 768px) {
    .livematch .boxOne {
      width: 40%; }
    .livematch .imageBox {
      height: 135px; }
    .livematch .imageBoxCric {
      top: 5%; } }
  @media (max-width: 450px) {
    .livematch {
      flex-direction: column;
      height: 350px;
      background-image: url(/static/media/CricView-Banner-Mobile1.5a6a7100.jpg);
      background-repeat: no-repeat;
      background-size: 425px 350px; }
      .livematch .boxOne {
        width: 80%;
        margin-bottom: 5%;
        margin-left: 0%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .livematch .imageBoxCric {
        top: 8%;
        right: 11% !important; }
      .livematch .imageBox {
        height: 137px;
        padding: 2% 1.9% 1.9% 1.9%; } }
  @media (max-width: 375px) {
    .livematch {
      background-size: 375px 350px; }
      .livematch .imageBox {
        height: 136px;
        padding: 2.2% 1.9% 1.9% 1.9%; } }
  @media (max-width: 320px) {
    .livematch {
      background-size: 320px 350px; }
      .livematch .imageBox {
        height: 135px;
        padding: 2.2% 1.9% 1.9% 1.9%; } }

.cricview-talnet-pay .candidate_details {
  max-width: 255px;
  margin: auto;
  padding: 20px 0px;
  clear: both;
  color: #999;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.80857; }

.cricview-talnet-pay .razorpay_form {
  text-align: center; }

.cricview-talnet-pay input.razorpay-payment-button {
  background: #d8302f;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  transition: ease all 0.5s;
  max-width: 200px;
  text-align: center;
  width: 100%;
  margin: 20px 20px;
  margin: auto;
  margin: 20px 20px;
  padding: 10px;
  cursor: pointer; }

.cricview-talnet-page {
  width: 100%; }
  .cricview-talnet-page h1, .cricview-talnet-page h2, .cricview-talnet-page h3, .cricview-talnet-page h4, .cricview-talnet-page h5, .cricview-talnet-page h6 {
    letter-spacing: 0;
    font-weight: normal;
    position: relative;
    padding: 0 0 10px 0;
    font-weight: normal;
    line-height: normal;
    color: #1f1f1f;
    margin: 0; }
  .cricview-talnet-page section {
    float: left;
    width: 100%;
    position: relative; }
  .cricview-talnet-page header {
    float: left;
    width: 100%;
    position: absolute;
    z-index: 1;
    min-height: 150px; }
  .cricview-talnet-page .header-top {
    margin: 15px 0 10px;
    float: right;
    width: 100%; }
  .cricview-talnet-page .col-md-6 {
    width: 50%; }
  .cricview-talnet-page .logo {
    float: left;
    padding: 7px 0 6px;
    margin: 0 0 0 0px;
    position: relative; }
  .cricview-talnet-page .right_top_section {
    float: right;
    padding: 17px 0 0; }
  .cricview-talnet-page .login {
    float: right;
    margin: 0;
    padding: 0;
    list-style: none; }
  .cricview-talnet-page .login li {
    float: left;
    margin: 0 0 0 10px;
    font-weight: 500; }
  .cricview-talnet-page .login li .login-reg {
    padding: 9px 25px;
    color: #fff;
    font-size: 13px;
    background: #d8302f;
    border-radius: 50px;
    float: left; }
  .cricview-talnet-page .login li .login-reg:hover, .cricview-talnet-page .login li .login-reg:focus {
    background: #fff;
    color: #333; }
  .cricview-talnet-page .full-slider {
    position: relative; }
  .cricview-talnet-page .carousel {
    position: relative; }
  .cricview-talnet-page .carousel-indicators {
    bottom: 45px;
    margin-bottom: 0; }
  .cricview-talnet-page .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;
    border-radius: 10px; }
  .cricview-talnet-page .carousel-indicators .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background-color: #fff; }
  .cricview-talnet-page .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
  .cricview-talnet-page .carousel-inner .kNBmiK {
    margin: 0px; }
  .cricview-talnet-page .carousel-inner .jWpUJH {
    bottom: 0px; }
  .cricview-talnet-page .carousel-inner .kVtMtA {
    border-radius: 0%;
    left: 30px; }
  .cricview-talnet-page .carousel-inner .bhRnqO {
    border-radius: 0%;
    right: 30px; }
  .cricview-talnet-page .item {
    width: 100%;
    height: 1024px; }
  .cricview-talnet-page .item-image {
    position: absolute;
    width: 100%;
    height: 100%; }
  .cricview-talnet-page .carousel-caption {
    position: relative;
    bottom: 0;
    z-index: 10;
    padding: 0 5% 0 0;
    padding-bottom: 0px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    height: 100%;
    display: flex;
    align-items: center; }
  .cricview-talnet-page .col-lg-6 {
    width: 50%; }
  .cricview-talnet-page .slider-contant h3 {
    font-size: 58px;
    font-weight: 700;
    text-shadow: none;
    color: #fff;
    text-align: left;
    line-height: 52px;
    letter-spacing: -2px; }
  .cricview-talnet-page .slider-contant p {
    font-size: 2rem; }
  .cricview-talnet-page .carousel-caption p {
    text-align: left;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    font-size: 2rem; }
  .cricview-talnet-page .color-yellow {
    color: #ffcb05; }
  .cricview-talnet-page .slider-contant .btn-talent {
    float: left;
    background: #d8302f;
    color: #fff;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 50px;
    margin-top: 5px;
    font-weight: 500;
    transition: ease all 1s; }
  .cricview-talnet-page .carousel-caption .btn-talent {
    text-shadow: none;
    margin-top: 1em; }
  .cricview-talnet-page .btn-talent {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer; }
  .cricview-talnet-page .btn-talent:hover, .cricview-talnet-page .btn-talent:focus {
    background: #111;
    color: #fff !important;
    transition: ease a11 1s; }
  .cricview-talnet-page .feature-post {
    float: left;
    width: 100%;
    position: relative; }
  .cricview-talnet-page .feature-post.small-blog {
    background: #f9f9f9;
    padding: 15px 0;
    border: solid #dfdfdf 1px;
    margin-bottom: 30px; }
  .cricview-talnet-page .col-md-5 {
    width: 41.66666667%; }
  .cricview-talnet-page .feature-img {
    float: left;
    width: 100%;
    position: relative; }
  .cricview-talnet-page .img-responsive, .cricview-talnet-page .thumbnail a > img, .cricview-talnet-page .thumbnail > img {
    display: block;
    max-width: 100%;
    height: auto; }
  .cricview-talnet-page .col-md-7 {
    width: 58.33333333%; }
  .cricview-talnet-page .feature-cont {
    float: right;
    background: transparent;
    width: 100%;
    margin: 0px 0 25px 0;
    position: relative;
    z-index: 1;
    padding: 0 0; }
  .cricview-talnet-page .post-heading {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 15px; }
  .cricview-talnet-page .post-heading h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin: 0 0 15px 0;
    padding: 0; }
  .cricview-talnet-page .post-heading h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    margin: 0 0 15px 0;
    padding: 0; }
  .cricview-talnet-page .container-talnet {
    width: 95%;
    margin-right: auto;
    margin-left: auto; }
  .cricview-talnet-page .row-talnet {
    margin-right: -15px;
    margin-left: -15px; }
  .cricview-talnet-page .col-md-4 .col-md-2 .col-md-3 .col-md-6, .cricview-talnet-page .col-lg-6 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }
  .cricview-talnet-page .col-md-4 {
    width: 33.33333333%; }
  .cricview-talnet-page .col-md-2 {
    width: 16.66666667%; }
  .cricview-talnet-page .col-md-3 {
    width: 25%; }
  .cricview-talnet-page .full-talnet {
    position: relative;
    float: left;
    width: 100%;
    margin: 0;
    padding: 0; }
  .cricview-talnet-page .full-talnet .logo img {
    width: 120px; }
  .cricview-talnet-page .social-icons {
    list-style: none;
    float: left;
    width: auto;
    padding: 0;
    margin: 0; }
  .cricview-talnet-page .pull-left {
    float: left !important; }
  .cricview-talnet-page .social-icons li {
    float: left;
    margin: 0 15px 0 0; }
  @media (max-width: 1200px) {
    .cricview-talnet-page .container-talnet {
      width: 95%; } }
  @media (min-height: 768px) {
    .cricview-talnet-page .item-image {
      width: 100%; } }
  @media (max-width: 992px) {
    .cricview-talnet-page .container-talnet {
      width: 970px; }
    .cricview-talnet-page .col-md-4 .col-md-2 {
      float: left; } }
  @media (max-width: 768px) {
    .cricview-talnet-page .full-talnet .logo img {
      width: 46px; }
    .cricview-talnet-page .doJiSR {
      padding-left: 0px;
      grid-column-gap: 0;
      transform: translateX(0);
      grid-template-columns: repeat(3, 100%); }
    .cricview-talnet-page .item {
      height: 425px; }
    .cricview-talnet-page .carousel-caption {
      position: unset;
      z-index: 10;
      padding: 0 5%;
      padding-bottom: 0px;
      color: #fff;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
      height: 100%;
      display: flex;
      align-items: center;
      background: black; }
    .cricview-talnet-page .container-talnet {
      width: 90%; }
    .cricview-talnet-page .col-md-1, .cricview-talnet-page .col-md-2, .cricview-talnet-page .col-md-3, .cricview-talnet-page .col-md-4, .cricview-talnet-page .col-md-5, .cricview-talnet-page .col-md-6, .cricview-talnet-page .col-md-7 {
      width: unset;
      height: 35%; }
    .cricview-talnet-page .slider-contant h3 {
      font-size: 14px;
      font-weight: 700;
      text-shadow: none;
      color: #fff;
      text-align: left;
      line-height: 30px;
      letter-spacing: 2px; }
    .cricview-talnet-page .carousel-caption p {
      font-size: 12px;
      text-align: left;
      font-weight: 100;
      color: #fff;
      line-height: 20px; }
    .cricview-talnet-page .slider-contant .btn-talent {
      float: left;
      background: #d8302f;
      color: #fff;
      padding: 5px 15px;
      font-size: 13px;
      border-radius: 50px;
      margin-top: 5px;
      font-weight: 500;
      transition: ease all 1s; }
    .cricview-talnet-page .right_top_section {
      float: right;
      padding: 17px 0 0;
      display: none; }
    .cricview-talnet-page .post-heading {
      float: left;
      width: 100%;
      text-align: left;
      margin-top: 15px; }
    .cricview-talnet-page .post-heading h2 {
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      margin: 0 0 15px 0;
      padding: 0; }
    .cricview-talnet-page .post-heading h3 {
      font-size: 12px;
      font-weight: 600;
      line-height: 25px;
      margin: 0 0 15px 0;
      padding: 0; } }

.custom-ui {
  background: #08255d !important; }

.extra {
  background-clip: padding-box !important;
  color: #f5f0f0 !important;
  font-family: inherit !important;
  letter-spacing: 3px !important;
  width: 50% !important;
  margin: auto !important;
  border: 15px double #08255d !important;
  border-radius: 10px;
  padding: 1% 1%; }

.ui-text {
  color: white;
  font-size: 20px; }

.ui-email {
  color: red;
  font-size: 20px; }

.ui-separator {
  margin: 4%; }

.ui-process {
  background-color: #e3faff;
  padding: 2%;
  border-radius: 7px; }

.ui-process p {
  color: black;
  font-size: 14px;
  line-height: 2.6;
  padding: 0px 9px; }

.ui-process h3 {
  color: #08255d;
  text-align: center;
  font-size: 1.7rem; }

.ui-rounds {
  margin-top: 2%;
  padding: 0 4%; }

.comingSoon {
  text-align: left !important;
  color: red !important;
  font-size: 17px !important; }

.ui-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%; }

.ui-button {
  background: #348e43 !important;
  padding: 12px 18px !important;
  color: #fcfcfc !important;
  font-size: 17px !important;
  border: none;
  border-radius: 5px;
  cursor: pointer; }

@media (max-width: 768px) {
  .extra {
    width: 70% !important; } }

@media (max-width: 426px) {
  .col-md-6 {
    height: 65% !important; }
  .extra {
    width: 87% !important; }
  .ui-button {
    padding: 6px 18px !important; }
  .extra h2 {
    font-size: 1.5rem; }
  .ui-text {
    font-size: 13px; }
  .ui-email {
    font-size: 12px; }
  .ui-process h3 {
    font-size: 1.2rem; }
  .ui-process p {
    font-size: 11px;
    line-height: 1.9; }
  .comingSoon {
    font-size: 12px !important; } }

.arrowLeft {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #ff3333;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 1;
  transition: opacity 0.25s;
  left: 5px;
  right: auto;
  right: initial; }
  .arrowLeft::before {
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%) rotate(-135deg); }

.arrowRight {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #ff3333;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 1;
  transition: opacity 0.25s;
  left: auto;
  left: initial;
  right: 5px; }
  .arrowRight::before {
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-75%, -50%) rotate(45deg); }

.cricview-talnet-reg hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee; }

.cricview-talnet-reg b, .cricview-talnet-reg strong {
  font-weight: 700; }

.cricview-talnet-reg section#contant {
  float: left;
  width: 100%;
  padding: 50px 0 25px;
  background: #fff; }

.cricview-talnet-reg .main-heading {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 35px; }

.cricview-talnet-reg .row {
  margin-right: -15px;
  margin-left: -15px; }

.cricview-talnet-reg .container {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.cricview-talnet-reg .contact {
  float: left;
  width: 100%; }

.cricview-talnet-reg .col-md-12 {
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.cricview-talnet-reg .col-md-4 {
  width: 33.33333333%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.cricview-talnet-reg .contact-us {
  margin-top: 30px; }

.cricview-talnet-reg section.main-heading h2 {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 25px;
  line-height: 21px;
  font-weight: 600;
  color: #222;
  padding: 0;
  margin: 0;
  position: relative; }

.cricview-talnet-reg section.main-heading h2::after {
  width: 50px;
  height: 4px;
  background: #d8302f;
  margin: 15px auto 0;
  display: block;
  position: relative;
  content: ""; }

.cricview-talnet-reg .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.cricview-talnet-reg .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }

.cricview-talnet-reg .text-center {
  text-align: center; }

.cricview-talnet-reg section#contant h4 {
  float: left;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0 0 10px;
  font-size: 18px;
  color: #222; }

.cricview-talnet-reg section.team h4 {
  font-size: 21px !important;
  padding: 0 !important;
  font-weight: 600;
  margin-top: 10px !important; }

.cricview-talnet-reg .img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.cricview-talnet-reg section#contant p {
  float: left;
  width: 100%;
  line-height: 30px;
  font-size: 15px;
  text-align: left;
  font-weight: 300; }

.cricview-talnet-reg .main-heading p {
  color: #363636;
  margin-top: 0; }

.cricview-talnet-reg .contact-info {
  float: left;
  width: 100%;
  background: #f8f8f8;
  border: solid #ccc 1px;
  padding: 32px 35px 30px 35px;
  text-align: left;
  margin-top: 30px; }

.cricview-talnet-reg .contact-info div.kode-section-title {
  float: left;
  width: 100%; }

.cricview-talnet-reg .kode-form-list {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 30px; }

.cricview-talnet-reg .kode-form-list li {
  float: left;
  width: 100%; }

.cricview-talnet-reg .kode-form-list li img {
  font-size: 24px;
  width: 25px;
  text-align: center;
  position: absolute;
  left: 0; }

.cricview-talnet-reg section#contant p strong {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 600; }

@media (max-width: 992px) {
  .cricview-talnet-reg .img-responsive {
    display: block;
    max-width: 100%;
    height: auto; }
  .cricview-talnet-reg .row {
    margin-right: -15px;
    margin-left: -15px; } }

@media (max-width: 768px) {
  .cricview-talnet-reg .container {
    width: 90%; }
  .cricview-talnet-reg .row {
    margin-right: -15px;
    margin-left: 0px; }
  .cricview-talnet-reg .img-responsive {
    display: block;
    height: auto;
    position: relative; }
  .cricview-talnet-reg .col-md-1, .cricview-talnet-reg .col-md-2, .cricview-talnet-reg .col-md-3, .cricview-talnet-reg .col-md-4, .cricview-talnet-reg .col-md-5, .cricview-talnet-reg .col-md-6, .cricview-talnet-reg .col-md-7 {
    width: unset;
    height: 35%; }
  .cricview-talnet-reg section#contant p {
    float: left;
    width: 100%;
    line-height: 24px;
    font-size: 13px;
    text-align: left;
    font-weight: 400; }
  .cricview-talnet-reg .contact-info {
    padding: 20px 20px 20px 20px;
    margin-top: 30px;
    margin-bottom: 30px; } }

.cricview-talnet-contact .error {
  color: #d8302f;
  font-size: 20px;
  margin-bottom: 10px; }

.cricview-talnet-contact * {
  font-family: 'Poppins', sans-serif; }

.cricview-talnet-contact form {
  color: #999;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.80857; }

.cricview-talnet-contact hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee; }

.cricview-talnet-contact b, .cricview-talnet-contact strong {
  font-weight: 700; }

.cricview-talnet-contact .contact-us input:focus {
  border: 2px solid #1b1919;
  border-radius: 5px; }

.cricview-talnet-contact section#contant {
  float: left;
  width: 100%;
  padding: 50px 0 25px;
  background: #fff; }

.cricview-talnet-contact .main-heading {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 35px; }

.cricview-talnet-contact .row {
  margin-right: -15px;
  margin-left: -15px; }

.cricview-talnet-contact .container {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.cricview-talnet-contact .contact {
  float: left;
  width: 100%; }

.cricview-talnet-contact .col-md-12 {
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.cricview-talnet-contact .col-md-4 {
  width: 33.33333333%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.cricview-talnet-contact section.main-heading h2 {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 25px;
  line-height: 21px;
  font-weight: 600;
  color: #222;
  padding: 0;
  margin: 0;
  position: relative; }

.cricview-talnet-contact section.main-heading h2::after {
  width: 50px;
  height: 4px;
  background: #d8302f;
  margin: 15px auto 0;
  display: block;
  position: relative;
  content: ""; }

.cricview-talnet-contact .contact-us {
  margin-top: 30px; }

.cricview-talnet-contact #contactform {
  float: left;
  width: 100%; }

.cricview-talnet-contact #contactform ul {
  float: left;
  width: 100%; }

.cricview-talnet-contact #contactform ul li {
  float: left;
  width: 100%; }

.cricview-talnet-contact .contact-us input, .cricview-talnet-contact .contact-us textarea {
  float: left;
  width: 100%;
  height: 44px;
  font-size: 16px;
  padding: 8px 15px;
  border: solid #ccc 1px;
  margin-bottom: 25px; }

.cricview-talnet-contact .form-group {
  margin-bottom: 15px; }

.cricview-talnet-contact label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

.cricview-talnet-contact .pull-left {
  float: left !important; }

.cricview-talnet-contact .input-group {
  position: relative;
  display: table;
  border-collapse: separate; }

.cricview-talnet-contact .input-group .form-control, .cricview-talnet-contact .input-group-addon, .cricview-talnet-contact .input-group-btn {
  display: table-cell; }

.cricview-talnet-contact .input-group-addon, .cricview-talnet-contact .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

.cricview-talnet-contact .input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }

.cricview-talnet-contact .input-group-btn:first-child > .btn, .cricview-talnet-contact .input-group-btn:first-child > .btn-group {
  margin-right: -1px; }

.cricview-talnet-contact .input-group .form-control:first-child, .cricview-talnet-contact .input-group-addon:first-child, .cricview-talnet-contact .input-group-btn:first-child > .btn, .cricview-talnet-contact .input-group-btn:first-child > .btn-group > .btn, .cricview-talnet-contact .input-group-btn:first-child > .dropdown-toggle, .cricview-talnet-contact .input-group-btn:last-child > .btn-group:not(:last-child) > .btn, .cricview-talnet-contact .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.cricview-talnet-contact .input-group-btn > .btn {
  position: relative; }

.cricview-talnet-contact .btn-file {
  position: relative;
  overflow: hidden; }

.cricview-talnet-contact .btn {
  float: left;
  background: #d8302f;
  color: #fff;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 50px;
  margin-top: 5px;
  font-weight: 500;
  transition: ease all 1s; }

.cricview-talnet-contact .btn:hover, .cricview-talnet-contact .btn:focus {
  background: #111;
  color: #fff !important;
  transition: ease a11 1s; }

.cricview-talnet-contact .btn {
  border: 0 !important; }

.cricview-talnet-contact a, .cricview-talnet-contact .btn {
  text-decoration: none !important;
  outline: none !important;
  transition: all .3s ease-in-out; }

.cricview-talnet-contact .active {
  display: block;
  border-radius: 0%; }

.cricview-talnet-contact .btn.active, .cricview-talnet-contact .btn:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.cricview-talnet-contact .btn-primary.active, .cricview-talnet-contact .btn-primary:active, .cricview-talnet-contact .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
  background-image: none; }
  .cricview-talnet-contact .btn-primary.active #radio1, .cricview-talnet-contact .btn-primary:active #radio1, .cricview-talnet-contact .open > .dropdown-toggle.btn-primary #radio1 {
    border-radius: 0%;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%; }
  .cricview-talnet-contact .btn-primary.active #radio6, .cricview-talnet-contact .btn-primary:active #radio6, .cricview-talnet-contact .open > .dropdown-toggle.btn-primary #radio6 {
    border-radius: 0%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%; }

.cricview-talnet-contact .btn-group, .cricview-talnet-contact .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.cricview-talnet-contact #radioBtn .notActive {
  color: #3276b1;
  background-color: #fff;
  border: 2px solid #285e8e;
  border-radius: 0%; }

.cricview-talnet-contact #radioBtn {
  cursor: pointer; }
  .cricview-talnet-contact #radioBtn #radio1 {
    border-radius: 0%;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%; }
  .cricview-talnet-contact #radioBtn #radio6 {
    border-radius: 0%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%; }

.cricview-talnet-contact #radioBtn .notActive:hover {
  color: #fff;
  background: #285e8e; }

.cricview-talnet-contact .btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block; }

.cricview-talnet-contact .btnR {
  border-radius: 0%; }

.cricview-talnet-contact #radio1 {
  border-radius: 0%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%; }

.cricview-talnet-contact #radio6 {
  border-radius: 0%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%; }

.cricview-talnet-contact #img-upload {
  width: 40%; }

.cricview-talnet-contact .input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }

.cricview-talnet-contact .income_radio .btn {
  border-radius: 50px; }

.cricview-talnet-contact #contactform ul li .thbg-color {
  height: 46px;
  background: #d8302f;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  transition: ease all 0.5s;
  text-align: center;
  padding: 10px;
  cursor: pointer; }

.cricview-talnet-contact #contactform ul li .thbg-color:hover, .cricview-talnet-contact #contactform ul li .thbg-color:focus {
  background: #111; }

.cricview-talnet-contact .btn-primary.active:hover {
  background-color: #204d74; }

.cricview-talnet-contact .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; }

.cricview-talnet-contact .col-md-6 {
  width: 50%; }

.cricview-talnet-contact section#contant h4 {
  float: left;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0 0 10px;
  font-size: 18px;
  color: #222; }

.cricview-talnet-contact section.team h4 {
  font-size: 21px !important;
  padding: 0 !important;
  font-weight: 600;
  margin-top: 10px !important; }

.cricview-talnet-contact section#contant p {
  float: left;
  width: 100%;
  line-height: 30px;
  font-size: 15px;
  text-align: left;
  font-weight: 300; }

.cricview-talnet-contact .contact-info {
  float: left;
  width: 100%;
  background: #f8f8f8;
  border: solid #ccc 1px;
  padding: 32px 35px 30px 35px;
  text-align: left;
  margin-top: 30px; }

.cricview-talnet-contact .contact-info div.kode-section-title {
  float: left;
  width: 100%; }

.cricview-talnet-contact .kode-form-list {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 30px; }

.cricview-talnet-contact .kode-form-list li {
  float: left;
  width: 100%; }

.cricview-talnet-contact .kode-form-list li img {
  font-size: 24px;
  width: 25px;
  text-align: center;
  position: absolute;
  left: 0; }

.cricview-talnet-contact section#contant p strong {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 600; }

@media (max-width: 768px) {
  .cricview-talnet-contact .container {
    width: 90%; }
  .cricview-talnet-contact .main-heading {
    margin-bottom: 0; }
  .cricview-talnet-contact .btnR .btn {
    width: 20%; }
  .cricview-talnet-contact .income_radio .btn {
    width: 100%; }
  .cricview-talnet-contact .row {
    margin-right: -15px;
    margin-left: 0px; }
  .cricview-talnet-contact .col-md-1, .cricview-talnet-contact .col-md-2, .cricview-talnet-contact .col-md-3, .cricview-talnet-contact .col-md-4, .cricview-talnet-contact .col-md-5, .cricview-talnet-contact .col-md-6, .cricview-talnet-contact .col-md-7 {
    width: unset;
    height: 35%; }
  .cricview-talnet-contact section#contant p {
    float: left;
    width: 100%;
    line-height: 24px;
    font-size: 13px;
    text-align: left;
    font-weight: 400; }
  .cricview-talnet-contact .contact-info {
    padding: 20px 20px 20px 20px;
    margin-top: 30px;
    margin-bottom: 30px; } }

.appcms {
  background: #ffffff;
  color: #002f87;
  font-family: inherit; }
  .appcms .twitter-tweet {
    margin: auto; }
  .appcms .content-external p {
    color: #002f87;
    font-size: 16px; }
  .appcms .content img,
  .appcms .content-external img {
    max-width: 100%;
    height: 100%;
    margin: auto; }

.site-background-color {
  background-color: #002f87; }

.icon {
  width: 36px; }

.infinite-article-container .article-container .article__body .content img {
  display: block;
  padding: 10px; }

.infinite-article-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  background: inherit; }

.infinite-article-container .article-container {
  background: inherit;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0; }

.infinite-article-container .article-container .article__header-container {
  width: 100%;
  position: relative; }

.infinite-article-container .article-container .article__header-container.bg-img {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; }

.infinite-article-container .article-container .article__header-container img {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.article__ad .rectangle-ad {
  width: 300px;
  height: 600px; }

.infinite-article-container .article-container .article__header-container .img-gradient {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(transparent, transparent 60%, rgba(0, 0, 0, 0.7)); }

.infinite-article-container .article-container .article__header {
  padding: 10px 15px;
  font-size: 12px;
  width: 100%; }

.infinite-article-container .article-container .article__header .title {
  color: #585858;
  font-weight: 800;
  font-size: 3em;
  margin-bottom: 10px;
  text-align: left; }

.infinite-article-container .article-container .article__header.title-under-image .title {
  color: black; }

.infinite-article-container .article-container .article__header .details {
  color: #a1a1a1;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column; }

.infinite-article-container .article-container .article__header .details-left {
  display: flex;
  margin-bottom: 5px; }

.infinite-article-container .article-container .article__header .details-left div:first-child ~ div:before {
  content: "|";
  margin: 0 8px; }

.infinite-article-container .article-container .article__header .author {
  color: #7a7a7a;
  font-style: italic; }

.infinite-article-container .article-container .article__header .divider {
  height: 1px;
  width: 30%; }

.infinite-article-container .article-container .article__body-external {
  width: 100%;
  padding: 10px 15px 10px 15px; }

.infinite-article-container .article-container .article__body {
  width: 100%;
  padding: 0px 15px 10px 15px;
  font-size: 16px; }
  .infinite-article-container .article-container .article__body .content {
    display: block;
    word-wrap: break-word;
    text-align: justify; }
    .infinite-article-container .article-container .article__body .content h1 {
      font-size: 2em; }
    .infinite-article-container .article-container .article__body .content h2 {
      font-size: 1.5em; }
    .infinite-article-container .article-container .article__body .content h3 {
      font-size: 1.17em; }
    .infinite-article-container .article-container .article__body .content h4 {
      font-size: 1em; }
    .infinite-article-container .article-container .article__body .content h5 {
      font-size: 0.83em; }
    .infinite-article-container .article-container .article__body .content h6 {
      font-size: 0.67em; }
    .infinite-article-container .article-container .article__body .content li {
      display: list-item; }
    .infinite-article-container .article-container .article__body .content a {
      text-decoration: underline;
      color: #23527c; }
    .infinite-article-container .article-container .article__body .content strong:before {
      content: "" !important; }
    .infinite-article-container .article-container .article__body .content p {
      margin-bottom: 25px;
      line-height: 26px;
      color: inherit; }
    .infinite-article-container .article-container .article__body .content .row {
      justify-content: center; }
    .infinite-article-container .article-container .article__body .content iframe {
      max-width: 400px;
      height: 100%;
      margin-bottom: 1.5em !important; }

.infinite-article-container .article-container .article__ad {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  justify-content: center; }

.infinite-article-container .sharing-footer {
  width: 1000px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background: inherit; }

.infinite-article-container .sharing-footer .line {
  margin-top: 5px;
  height: 1px;
  width: 80px; }

.infinite-article-container .sharing-footer .share-prompt {
  margin-right: 30px; }

.infinite-article-container .sharing-footer .share-prompt .text {
  font-size: 16px; }

.infinite-article-container .sharing-footer .share-btns {
  display: flex;
  flex: 1 1; }

.infinite-article-container .sharing-footer .share-btns .share-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  width: 30px;
  cursor: pointer; }

.infinite-article-container .sharing-footer .share-btns .share-btn-container:last-child {
  margin-right: 0; }

.infinite-article-container .sharing-footer .share-btns i {
  font-size: 36px; }

.infinite-article-container .sharing-footer .share-btns .underline {
  height: 2px;
  width: 0%;
  transition: width 0.1s ease-out; }

.infinite-article-container .sharing-footer .share-btns .share-btn-container:hover .underline {
  width: 100%;
  transition: width 0.1s ease-out; }

.infinite-article-container .sharing-footer .up-next .label {
  font-weight: 800; }

.infinite-article-container .sharing-footer .up-next .next-article {
  text-transform: uppercase; }

.infinite-article-container .sharing-footer .up-next .line {
  float: right; }

@media (min-width: 1025px) {
  .infinite-article-container .article-container .article__header-container {
    width: 66%; }
  .infinite-article-container .article-container .article__header {
    font-size: 16px; }
  .infinite-article-container .article-container .article__header-container.bg-img .article__header {
    position: absolute;
    bottom: 0; }
  .infinite-article-container .article-container .article__header-container.bg-img .article__header.title-under-image {
    position: static;
    position: initial; }
  .infinite-article-container .article-container .article__header-container .img-gradient {
    display: block; }
  .infinite-article-container .article-container .article__header-container.bg-img .title {
    color: white; }
  .infinite-article-container
.article-container
.article__header-container.bg-img
.article__header.title-under-image
.title {
    color: black; }
  .infinite-article-container .article-container .article__header .details {
    justify-content: space-between;
    flex-direction: row; }
  .infinite-article-container .article-container .article__header .details-left {
    margin-bottom: 0; }
  .infinite-article-container .article-container .article__header .divider {
    margin-bottom: 0; }
  .infinite-article-container .article-container .article__body {
    padding-top: 10px; }
  .infinite-article-container .article-container .article__body {
    width: 100%; }
  .infinite-article-container .article-container .article__ad {
    width: 33%; } }

@media (max-width: 375px) {
  .content img,
  .content-external img {
    width: 300px !important;
    height: 300px !important; }
  .infinite-article-container .article-container .article__header .details {
    font-size: 10px; }
  .infinite-article-container .article-container .article__header .title {
    font-size: 16px; }
  .infinite-article-container .article-container .article__header {
    font-size: 16px; }
  .infinite-article-container .article-container .article__header-container.bg-img .title {
    font-size: 2.5em; } }

.infinite-article-container .fr-emoticon {
  background-repeat: no-repeat !important;
  padding-right: 1em; }

.infinite-article-container blockquote {
  border-left: solid 2px;
  border-color: inherit;
  color: inherit;
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: inherit; }

@media (max-width: 425px) {
  .content img,
  .content-external img {
    width: 350px !important;
    height: 350px !important; } }

@media (min-width: 768px) {
  .infinite-article-container .subscription-prompt .overlay .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around; } }

@media (max-width: 1024px) {
  .infinite-article-container .article-container .article__body li {
    margin-left: 20px; } }

.editArticle {
  height: 30px;
  display: flex;
  align-items: center;
  background: black;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 10px 10px 10px auto;
  padding: 10px;
  color: white;
  font-size: 16px;
  cursor: pointer; }

.carousel-container {
  width: 100%; }
  .carousel-container .carousel-header-title {
    font-weight: 900;
    font-family: "gilroy black";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #232882;
    font-size: 32px;
    text-transform: uppercase;
    margin: 2% 0; }
    .carousel-container .carousel-header-title span {
      color: #00baf2; }
  .carousel-container .iframe-container {
    padding-top: 100%;
    max-width: 550px;
    position: relative;
    margin: 0 auto;
    min-height: 400px;
    height: 550px; }
    .carousel-container .iframe-container iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
      .carousel-container .iframe-container iframe html body {
        overflow: hidden; }
  @media (max-width: 768px) {
    .carousel-container .carousel-header-title {
      font-size: 24px; } }
  .carousel-container .placeholder {
    width: 100%;
    height: 628px;
    background-image: linear-gradient(45deg, darkblue, red);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center; }

.cricview-interviews-container {
  background-image: url(/static/media/Must_read_bg.e97c3d1b.svg);
  background-repeat: no-repeat;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  background-size: 100%;
  margin: 1% 0 0 0;
  background-color: #0c237b; }
  .cricview-interviews-container img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60); }
  .cricview-interviews-container .cricview-interviews-wrapper {
    margin: 4% 0;
    margin-left: 3%; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews-header {
      display: flex;
      margin-bottom: 3%;
      margin-left: 7%;
      align-items: center; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-header-title {
        color: #fff;
        font-family: "gilroy black";
        font-size: 32px;
        text-transform: uppercase;
        font-weight: 900;
        flex: 1 1; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-header-title span {
          color: #00bcf3; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-viewall {
        background-color: #ff3333;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        padding-top: 10px;
        width: 150px;
        margin-right: 20px;
        height: 44px;
        box-shadow: 0 5px 18px #ff3333; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-viewall:hover {
          box-shadow: none; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews {
      display: flex;
      width: 100%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews a {
        text-decoration: none;
        color: inherit; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-flex {
          display: flex;
          width: 100%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-large {
          width: 75%;
          padding: 0 1em; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-medium {
          width: 31%;
          margin-right: 1.5%;
          border-radius: 5px;
          background-color: white; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-small {
          width: 23%;
          margin-right: 1.5%;
          margin-top: 20px;
          margin-bottom: 20px;
          border-radius: 5px;
          background-color: #fff; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small {
          overflow: hidden;
          border-radius: 5px 5px 0 0;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          padding-bottom: 75%; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small .image {
            position: absolute; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
          margin-bottom: 10px;
          border-radius: 0px 0px 10px 10px;
          padding: 10px; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium {
          border-radius: 5px 5px 0 0;
          overflow: hidden;
          position: relative;
          padding-bottom: 75%; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium .image {
            position: absolute; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
          margin-bottom: 10px;
          padding: 10px; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate {
          padding: 10px 20px 0;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          height: 20%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate-small {
          padding: 10px 20px 0;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          height: 20%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title {
          padding: 10px 20px 0;
          font-size: 16px;
          font-weight: 800;
          font-weight: bold;
          height: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title:hover {
          overflow: visible;
          position: relative;
          text-overflow: ellipsis;
          display: block; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small {
          padding: 10px 20px 0;
          font-size: 14px;
          font-weight: 800;
          font-weight: bold;
          height: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small:hover {
          overflow: visible;
          position: relative;
          text-overflow: ellipsis;
          display: block; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image {
          width: 100%;
          height: 100%;
          border-radius: 5px 5px 0 0;
          transition: transform 1s; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image:hover {
            transform: scale(1.1);
            border-radius: 5px 5px 0 0; }
        @media (max-width: 1024px) {
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate {
            font-size: 14px; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate-small {
            font-size: 12px; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            -webkit-box-orient: vertical; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            -webkit-box-orient: vertical; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
            margin-bottom: 10px; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
            margin-bottom: 10px;
            padding: 10px; } }
    .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile {
      display: none; }
  .cricview-interviews-container .play-button {
    position: absolute;
    left: 40%;
    top: 40%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1; }
    .cricview-interviews-container .play-button:hover {
      box-shadow: 0 0px 36px 18px red; }

@media (max-width: 768px) {
  .border1 {
    background-size: 21px 4px, 0px 0px; }
  .cricview-interviews-container {
    background-color: darkblue;
    background-image: url(/static/media/mustreadbg.ddfa68b8.png); }
    .cricview-interviews-container .cricview-interviews-wrapper {
      margin: 4% 0;
      margin-left: 4%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header {
        margin-left: 0; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-viewall {
          display: none; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-medium {
        width: 46%;
        margin: 2% 0;
        height: unset;
        margin-right: 3%;
        margin-top: 20px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-small {
        width: 46%;
        margin: 2% 0;
        margin-right: 3%;
        margin-top: 20px;
        height: unset; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
        height: 150px;
        margin-bottom: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
        height: 150px;
        margin-bottom: 10px;
        padding: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile {
        display: flex; }
        .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile .interviews-viewall-mobile {
          background-color: #ff3333;
          color: #fff;
          font-size: 16px;
          border-radius: 50px;
          text-decoration: none;
          text-align: center;
          padding-top: 10px;
          width: 100%;
          margin-right: 0;
          height: 44px;
          box-shadow: 0 5px 18px #ff3333; }
          .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile .interviews-viewall-mobile:hover {
            box-shadow: none; }
    .cricview-interviews-container .play-button {
      position: absolute;
      left: 40%;
      top: 40%;
      border-radius: 50%;
      transition: box-shadow 0.5s;
      z-index: 1; }
      .cricview-interviews-container .play-button:hover {
        box-shadow: 0 0px 36px 18px red; } }

@media (max-width: 425px) {
  .cricview-interviews-container .cricview-interviews-wrapper {
    margin: 4% 3%; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews-header {
      margin-left: 0; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-header-title {
        font-size: 25px; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-flex {
        display: flex;
        width: 100%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-large {
        width: 75%;
        padding: 0 1em; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-medium {
        width: 100%;
        border-radius: 5px;
        margin-right: 0px;
        background-color: white; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-small {
        width: 100%;
        margin-right: 0px;
        border-radius: 5px;
        background-color: #fff; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small {
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        padding-bottom: 75%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small .image {
          position: absolute; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
        margin-bottom: 10px;
        border-radius: 0px 0px 10px 10px;
        padding: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium {
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        position: relative;
        padding-bottom: 75%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium .image {
          position: absolute; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
        margin-bottom: 10px;
        padding: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate {
        padding: 10px 20px 0;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        height: 20%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate-small {
        padding: 10px 20px 0;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        height: 20%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title {
        padding: 10px 20px 0;
        font-size: 16px;
        font-weight: 800;
        font-weight: bold;
        height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title:hover {
        overflow: visible;
        position: relative;
        text-overflow: ellipsis;
        display: block; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small {
        padding: 10px 20px 0;
        font-size: 14px;
        font-weight: 800;
        font-weight: bold;
        height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small:hover {
        overflow: visible;
        position: relative;
        text-overflow: ellipsis;
        display: block; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image {
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0 0;
        transition: transform 1s; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image:hover {
          transform: scale(1.1);
          border-radius: 5px 5px 0 0; } }

section {
  display: block; }

.widget {
  clear: both;
  margin: 1% 3%;
  /*.thumbnail__summary:hover {
    text-decoration: none;
    color: #000;
    overflow: visible;
    position: relative;
    text-overflow: ellipsis;
    display: block;
  }*/ }
  .widget .uniform-grid__section figure,
  .widget .uniform-grid__section-ads figure {
    margin: 0;
    overflow: hidden; }
  .widget .widget__header {
    position: relative;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3rem;
    clear: both;
    display: flex; }
  .widget .widget__title {
    color: #232882;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    font-family: inherit; }
    .widget .widget__title .subheading {
      font-weight: 900;
      font-family: "gilroy black";
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
      .widget .widget__title .subheading .featured {
        color: #041b70; }
      .widget .widget__title .subheading .news {
        padding-left: 8px;
        color: #00baf2; }
  .widget .widget__link {
    background-color: #ff3333;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    height: 44px;
    box-shadow: 0 5px 18px #ff3333; }
    .widget .widget__link:hover {
      color: white;
      box-shadow: none;
      text-decoration: none; }
  .widget .widget__header-desc {
    color: #000000;
    font-size: 12px;
    padding: 5px 0; }
  .widget .uniform-grid {
    display: flex;
    width: 100%;
    flex-flow: row wrap; }
  .widget .box {
    display: flex;
    width: 100%; }
  .widget .uniform-grid .uniform-grid__section_image {
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    padding: 0 26px 0 0; }
  .widget .shadowCard1 {
    width: 100%;
    height: 100%; }
  .widget .uniform-grid .uniform-grid__section_image .thumbnail__container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 56.25% 0 0 0;
    border-radius: 5px; }
  .widget .uniform-grid .uniform-grid__section_image .thumbnail__container img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .widget .row1 {
    display: flex;
    flex-direction: row;
    width: 72%;
    margin-right: 10px;
    /* border: 2px solid black; */
    border: 1px solid #e9e9e9;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.8rem;
    border-radius: 4px; }
  .widget .uniform-grid .uniform-grid__section_text {
    flex-direction: row;
    justify-content: space-between;
    width: 39%;
    padding: 0 24px 0 0; }
    .widget .uniform-grid .uniform-grid__section_text a {
      color: inherit;
      text-decoration: none; }
  .widget .uniform-grid .uniform-grid__section_text .thumbnail_container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 75% 0 0 0; }
  .widget .uniform-grid .uniform-grid__section_text .thumbnail_container .thumbnail_container_text {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .widget .uniform-grid .uniform-grid__section-ads {
    flex-direction: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1%;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    cursor: pointer; }
  .widget .uniform-grid .uniform-grid__section-ads .thumbnail__container {
    display: block;
    width: 100%; }
  .widget .uniform-grid .uniform-grid__section-ads .thumbnail__container img {
    display: block;
    width: 100%;
    height: 100%; }
  .widget .image {
    vertical-align: middle;
    border-style: none;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 1s; }
    .widget .image:hover {
      transform: scale(1.1);
      border-radius: 5px; }
  .widget .viewall-container {
    margin-top: 1px;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
    min-height: 40px; }
  .widget .ads-image {
    vertical-align: middle;
    border-style: none;
    width: 100%;
    border-radius: 5px;
    transition: transform 1s; }
  .widget .uniform-grid .uniform-grid__section-below-line {
    flex-direction: row;
    justify-content: space-between;
    width: 25%;
    padding-right: 10px;
    padding-top: 10px; }
  .widget .shadowCard {
    border: 1px solid #e9e9e9;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.8rem;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative; }
  .widget .uniform-grid__section-below-line .hidee {
    overflow: hidden;
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    padding: 75% 0 0 0;
    border-radius: 5px; }
  .widget .play-button {
    position: absolute;
    left: 37.5%;
    top: 37.5%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1; }
    .widget .play-button:hover {
      box-shadow: 0 0px 36px 18px red; }
  .widget .uniform-grid__section-below-line .hidee img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .widget .uniform-grid__section-below-line:last-child {
    padding-right: 0; }
  .widget .thumbnail__meta {
    position: absolute;
    bottom: 1.6rem;
    left: 1.6rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase; }
  .widget .thumbnail__meta .thumbnail__category,
  .widget .thumbnail__meta .thumbnail__date-day {
    margin-right: 0.4rem; }
  .widget .thumbnail__meta .thumbnail__date-year {
    color: #7e8991; }
  .widget .date-month-year {
    font-size: 12px;
    font-weight: bold;
    clear: both;
    color: #000;
    padding: 0 0 5px 0;
    font-family: inherit; }
  .widget .date-month-year-2nd-line {
    font-size: 12px;
    font-weight: bold;
    clear: both;
    color: #000;
    padding: 10px 0px 0px 0px;
    font-family: inherit; }
  .widget .thumbnail__title {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
    font-family: inherit; }
  .widget .thumbnail__title-2nd-line {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.2;
    color: #000;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .widget .thumbnail__title-2nd-line:hover {
    text-decoration: none;
    color: #000;
    overflow: visible;
    position: relative;
    text-overflow: ellipsis;
    display: block; }
  .widget .show a:hover .showMore {
    visibility: visible; }
  .widget .show .link:hover .showMore {
    visibility: visible; }
  .widget .thumbnail__summary {
    font-size: 1.5rem;
    text-align: justify;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical; }
  .widget .viewall-mobile {
    display: none; }
  .widget img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60); }

@media (max-width: 52.49em) {
  .widget {
    margin-bottom: 3.2rem; }
    .widget .widget__header {
      align-items: center;
      margin-bottom: 1.6rem; } }

@media (max-width: 768px) {
  .widget {
    margin: 4%;
    width: 92%; }
    .widget .uniform-grid .row1 {
      flex-direction: column;
      width: 50%;
      margin-bottom: 5%; }
    .widget .thumbnail__title {
      font-size: 14px; }
    .widget .thumbnail__summary {
      margin-top: 3%;
      font-size: 11px; }
    .widget .uniform-grid .row1 .uniform-grid__section_image {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      margin-bottom: 2%; }
    .widget .uniform-grid .row1 .uniform-grid__section_text {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0; }
    .widget .uniform-grid .uniform-grid__section-ads {
      flex-direction: row;
      justify-content: space-between;
      width: 49%;
      padding: 5px;
      margin-bottom: 5%; }
    .widget .uniform-grid .uniform-grid__section-below-line {
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
      margin-bottom: 4%;
      padding: 5px; }
    .widget .image {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .widget .thumbnail__title-2nd-line {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2;
      color: #000;
      padding: 5px 0;
      font-family: inherit; }
    .widget .widget__link {
      display: none; }
    .widget .viewall-mobile {
      display: flex; }
    .widget .widget__link_mobile {
      background-color: #ff3333;
      color: #fff;
      font-size: 16px;
      border-radius: 50px;
      text-decoration: none;
      text-align: center;
      padding-top: 10px;
      width: 100%;
      margin-right: 20px;
      height: 44px;
      box-shadow: 0 5px 18px #ff3333; }
      .widget .widget__link_mobile:hover {
        background-color: white;
        color: #ff3333;
        box-shadow: 0 5px 18px -1px #ff3333;
        text-decoration: none; } }

@media (max-width: 425px) {
  .widget .uniform-grid .row1 {
    width: 100%; }
  .widget .uniform-grid .uniform-grid__section-ads {
    width: 100%; }
  .widget .uniform-grid .uniform-grid__section-below-line {
    width: 100%; } }

.cricview-interviews-container {
  background-image: url(/static/media/Must_read_bg.e97c3d1b.svg);
  background-repeat: no-repeat;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  background-size: 100%;
  margin: 1% 0 0 0;
  background-color: #0c237b; }
  .cricview-interviews-container img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60); }
  .cricview-interviews-container .cricview-interviews-wrapper {
    margin: 4% 0;
    margin-left: 3%; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews-header {
      display: flex;
      margin-bottom: 3%;
      margin-left: 7%;
      align-items: center; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-header-title {
        color: #fff;
        font-family: "gilroy black";
        font-size: 32px;
        text-transform: uppercase;
        font-weight: 900;
        flex: 1 1; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-header-title span {
          color: #00bcf3; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-viewall {
        background-color: #ff3333;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        padding-top: 10px;
        width: 150px;
        margin-right: 20px;
        height: 44px;
        box-shadow: 0 5px 18px #ff3333; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-viewall:hover {
          box-shadow: none; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews {
      display: flex;
      width: 100%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews a {
        text-decoration: none;
        color: inherit; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-flex {
          display: flex;
          width: 100%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-large {
          width: 75%;
          padding: 0 1em; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-medium {
          width: 31%;
          margin-right: 1.5%;
          border-radius: 5px;
          background-color: white; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-small {
          width: 23%;
          margin-right: 1.5%;
          margin-top: 20px;
          margin-bottom: 20px;
          border-radius: 5px;
          background-color: #fff; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small {
          overflow: hidden;
          border-radius: 5px 5px 0 0;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          padding-bottom: 75%; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small .image {
            position: absolute; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
          margin-bottom: 10px;
          border-radius: 0px 0px 10px 10px;
          padding: 10px; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium {
          border-radius: 5px 5px 0 0;
          overflow: hidden;
          position: relative;
          padding-bottom: 75%; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium .image {
            position: absolute; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
          margin-bottom: 10px;
          padding: 10px; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate {
          padding: 10px 20px 0;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          height: 20%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate-small {
          padding: 10px 20px 0;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          height: 20%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title {
          padding: 10px 20px 0;
          font-size: 16px;
          font-weight: 800;
          font-weight: bold;
          height: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title:hover {
          overflow: visible;
          position: relative;
          text-overflow: ellipsis;
          display: block; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small {
          padding: 10px 20px 0;
          font-size: 14px;
          font-weight: 800;
          font-weight: bold;
          height: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small:hover {
          overflow: visible;
          position: relative;
          text-overflow: ellipsis;
          display: block; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image {
          width: 100%;
          height: 100%;
          border-radius: 5px 5px 0 0;
          transition: transform 1s; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image:hover {
            transform: scale(1.1);
            border-radius: 5px 5px 0 0; }
        @media (max-width: 1024px) {
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate {
            font-size: 14px; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate-small {
            font-size: 12px; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            -webkit-box-orient: vertical; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            -webkit-box-orient: vertical; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
            margin-bottom: 10px; }
          .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
            margin-bottom: 10px;
            padding: 10px; } }
    .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile {
      display: none; }
  .cricview-interviews-container .play-button {
    position: absolute;
    left: 40%;
    top: 40%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1; }
    .cricview-interviews-container .play-button:hover {
      box-shadow: 0 0px 36px 18px red; }

@media (max-width: 768px) {
  .border1 {
    background-size: 21px 4px, 0px 0px; }
  .cricview-interviews-container {
    background-color: darkblue;
    background-image: url(/static/media/mustreadbg.ddfa68b8.png); }
    .cricview-interviews-container .cricview-interviews-wrapper {
      margin: 4% 0;
      margin-left: 4%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header {
        margin-left: 0; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-viewall {
          display: none; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-medium {
        width: 46%;
        margin: 2% 0;
        height: unset;
        margin-right: 3%;
        margin-top: 20px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-small {
        width: 46%;
        margin: 2% 0;
        margin-right: 3%;
        margin-top: 20px;
        height: unset; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
        height: 150px;
        margin-bottom: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
        height: 150px;
        margin-bottom: 10px;
        padding: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile {
        display: flex; }
        .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile .interviews-viewall-mobile {
          background-color: #ff3333;
          color: #fff;
          font-size: 16px;
          border-radius: 50px;
          text-decoration: none;
          text-align: center;
          padding-top: 10px;
          width: 100%;
          margin-right: 0;
          height: 44px;
          box-shadow: 0 5px 18px #ff3333; }
          .cricview-interviews-container .cricview-interviews-wrapper .viewall-mobile .interviews-viewall-mobile:hover {
            box-shadow: none; }
    .cricview-interviews-container .play-button {
      position: absolute;
      left: 40%;
      top: 40%;
      border-radius: 50%;
      transition: box-shadow 0.5s;
      z-index: 1; }
      .cricview-interviews-container .play-button:hover {
        box-shadow: 0 0px 36px 18px red; } }

@media (max-width: 425px) {
  .cricview-interviews-container .cricview-interviews-wrapper {
    margin: 4% 3%; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews-header {
      margin-left: 0; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews-header .interviews-header-title {
        font-size: 25px; }
    .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-flex {
        display: flex;
        width: 100%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-large {
        width: 75%;
        padding: 0 1em; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-medium {
        width: 100%;
        border-radius: 5px;
        margin-right: 0px;
        background-color: white; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .interviews-item-small {
        width: 100%;
        margin-right: 0px;
        border-radius: 5px;
        background-color: #fff; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small {
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        padding-bottom: 75%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-small .image {
          position: absolute; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-small {
        margin-bottom: 10px;
        border-radius: 0px 0px 10px 10px;
        padding: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium {
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        position: relative;
        padding-bottom: 75%; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image-container-medium .image {
          position: absolute; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-container-medium {
        margin-bottom: 10px;
        padding: 10px; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate {
        padding: 10px 20px 0;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        height: 20%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .publishDate-small {
        padding: 10px 20px 0;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        height: 20%; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title {
        padding: 10px 20px 0;
        font-size: 16px;
        font-weight: 800;
        font-weight: bold;
        height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title:hover {
        overflow: visible;
        position: relative;
        text-overflow: ellipsis;
        display: block; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small {
        padding: 10px 20px 0;
        font-size: 14px;
        font-weight: 800;
        font-weight: bold;
        height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .title-small:hover {
        overflow: visible;
        position: relative;
        text-overflow: ellipsis;
        display: block; }
      .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image {
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0 0;
        transition: transform 1s; }
        .cricview-interviews-container .cricview-interviews-wrapper .interviews .interviews-grid .image:hover {
          transform: scale(1.1);
          border-radius: 5px 5px 0 0; } }

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Main_Card {
    background-color: white;
    width: 400px;
    height: 250px;
    position: relative;
    color: black;
}
hr {
    width: 100%;
    margin-bottom: 8px;
}
.Header {
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 100%;
}
.Live{
  color: black;
  padding-bottom: 10px;
  background-color: white;
  margin: 7px auto 0 auto;
}
.Live h3 {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(15, 15, 15);
}
.Live span {
    color: #c60f5e;
    font-family: 'Poppins', sans-serif;
}
/* .Img {
    width: 10%;

} */
.Footer span {
    color: #c60f5e;
    font-family: 'Poppins', sans-serif;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.center span {
    color: #c60f5e;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-left: 8%;
}

.Game span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c60f5e;
    font-size: 14px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
}
.Img img {
    width: 30px;
    height: 30px;
} 
.images {
    display: flex;
    flex-direction: row;
}
.BeforeImg {
    max-width: 100px;
    max-height: 100px;
    margin: auto;
    padding-top: 10px;
}
.BeforeImg img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    box-shadow: 2px 3px 8px rgb(95, 95, 95);
}
.Venue, .Footer {
    color: rgb(71, 71, 71);
    font-size: 10px;
    letter-spacing: 1px;
    padding-bottom: 7px;
    padding-left: 2px;
}
.Score {
    padding-bottom: 7px;
    font-size: 14px;
    letter-spacing: 1px;
    padding-left: 2px;
}
.Team_Score {
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
    width: 50%;
    align-items: center;
    justify-content: center;
}
.border_right {
    border-right: 1.5px solid rgb(221, 221, 221);
}
.scoreCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Team_1, .Team_2 {
    width: 70%;
    padding-bottom: 7px;
    font-family: 'Poppins', sans-serif;
    /* padding-left: 2px; */
    color: black;
}
.Team_1 h3, .Team_2 h3 {
    font-size: 13px;
    /* line-height: 30px; */
    font-weight: bold;
    color: rgb(43, 43, 43);
}
.TeamImg {
    width: 80px;
    height: 80px;
    margin: 0 4px 0 8px;
    padding: 2px;
}
.TeamImg img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-image: none;
    box-shadow: 1px 2px 4px rgb(95, 95, 95);
}
.Score_1 , .Score_2 {
    width: 10%;
}
.Score_1 h3, .Score_2 h3 {
    font-size: 13px;
    font-weight: bold;
    /* padding-left: 13px; */
    /* margin-top: 7px; */
    margin: 7px auto 0 auto;
    padding-right: 2px;
    line-height: 27px;
    color: rgb(43, 43, 43);
    font-family: 'Poppins', sans-serif;
}
.Footer{
    /* background-color: rgb(218, 218, 218); */
    padding-bottom: 0;
    padding-left: 8px;
    display: flex;
    justify-content: left;
    width: 100%;
    /* margin-left: 22%; */
}
.Footer span {
    font-weight:bold;
}
.Row {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 4px;
}
.Bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 75px;
    /* background-color:rgb(218, 218, 218); */
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 10px;
    justify-content:center;
}
.Footer h5 {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
   padding: 2px;
   font-size: 10px;
   /* font-weight: lighter; */
   color: rgb(59, 59, 59);
}
.border {
    margin: 20px;
    padding: 7px;

}
.Gradient {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    width: 100%;
    box-shadow: 1px 2px 4px;
    height: 100%;
    border-radius: 5px;
}
.width_G {
    width: 35%;
}

@media only screen and (max-width: 1000px) {
    .Main_Card {
        width: 360px;
        height: 250px;
    }
}
@media only screen and (max-width: 950px) {
    .Main_Card {
        width: 340px;
        height: 250px;
    }
    .Live h3 {
        font-size: 14px;
    }
}
@media only screen and (max-width: 750px) {
    .Main_Card {
        width: 320px;
        height: 250px;
    }
    .Live h3 {
        font-size: 14px;
    }
    .Gradient {
        height: 90%;
    }
}
@media only screen and (max-width: 700px) {
    .Main_Card {
        width: 300px;
        height: 250px;
    }
    .Live h3 {
        font-size: 13.5px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Gradient {
        height: 90%;
    }
}

@media only screen and (max-width: 650px) {
    .Main_Card {
        width: 260px;
        height: 230px;
    }
    .Live h3 {
        font-size: 11.5px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Gradient {
        height: 90%;
    }
    .TeamImg {
        width: 70px;
        height: 70px;
    }
    .TeamImg img {
        width: 70px;
        height: 70px;
    }
    .Footer h5 {
        width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
        font-size: 9px;
     }
}

@media only screen and (max-width: 550px) {
    .Main_Card {
        width: 240px;
        height: 230px;
    }
    .Live h3 {
        font-size: 10.5px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Score_1, .Score_2 {
        width: 15%;
    }
    .Gradient {
        height: 90%;
    }
    .TeamImg {
        width: 70px;
        height: 70px;
    }
    .TeamImg img {
        width: 70px;
        height: 70px;
    }
    .Footer h5 {
        width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
        font-size: 8px;
     }
}
@media only screen and (max-width: 450px) {
    .Main_Card {
        width: 350px;
        height: 260px;
        box-shadow: 1px 2px 4px;
        border-radius: 5px;
    }
    .Live h3 {
        font-size: 15px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Gradient {
        height: 90%;
        box-shadow: none;
    }
    .TeamImg {
        width: 90px;
        height: 90px;
    }
    .TeamImg img {
        width: 90px;
        height: 90px;
    }
    .Footer h5 {
        width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
        font-size: 11px;
     }
}


.all {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;

}
.Batting, .Bowling {
    padding: 30px;
}
.Batting h1,.Bowling h1 {
    margin-bottom: 10px ;
    color: rgb(48, 48, 48);
    letter-spacing: 1px;
}
.Batting hr {
    height: 2px;
    width: 10%;
    background-color:#c60f5e;
    margin-bottom: 10px;
    border: none;
}
.Bowling hr {
    height: 2px;
    width: 10%;
    background-color:#01579b;
    margin-bottom: 10px;
    border: none;

}
.Seperation {
    padding-bottom: 30px;
}
/* card */
.Header {
    display: flex;
    flex-direction: column;
}
.card_width {
    width: 60%;
    /* margin: 20px 50px; */
    height: 320px;
    box-shadow: 2px 3px 8px;
}
.heading  {
    width: 100%;
    text-align: center;
    margin: 10px  0 0 0;
}
.heading h3 {
    font-size: 24px;
    color: rgb(48, 48, 48);
    letter-spacing: 1px;
}
.ImgLive {
    width: 110px;
    height: 110px;
}
.ImgLive img {
    width: 120px;
    height: 120px;
}
.LiveName h3 {
    padding-left: 5px ;
    line-height: 55px;
    font-size: 16px;
    color: rgb(48, 48, 48);
}
.LiveScore {
    text-align: center;
}
.LiveScore h3 {
    line-height: 55px;
    font-size: 18px;
    color: rgb(48, 48, 48);
    padding-left: 4px;
}
.TeamLive {
    padding-bottom: 0;
}
.FooterLive h5 {
    font-size: 12px;
}
/* .FooterLive {
    margin-left: 32%;
} */
/* .BottomLive {
padding-top: 0;
margin-left: 14%;
} */
hr {
    height: 1px;
    width: 100%;
    background-color: rgb(221, 221, 221);
    border: none;
    margin-bottom: 8px;
}

@media only screen and (max-width: 700px) {
    .card_width {
        width: 70%;
        height: 270px;
    }
    .ImgLive {
        width: 80px;
        height: 80px;
    }
    .ImgLive img {
        width: 80px;
        height:80px;
    }
    .LiveName h3 {
        line-height: 50px;
        font-size: 12px;
    }
    .LiveScore h3 {
        line-height: 50px;
        font-size: 12px;
    }
    .heading h3 {
        font-size: 16px;
        padding-bottom: 4px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .FooterLive h5 {
        font-size: 10px;
    }
  }
  @media only screen and (max-width: 550px) {
    .card_width {
        width: 80%;
        height: 260px;
    }
    .ImgLive {
        width: 80px;
        height: 80px;
    }
    .ImgLive img {
        width: 80px;
        height:80px;
    }
    .LiveName h3 {
        line-height: 38px;
        font-size: 11px;
    }
    .LiveScore h3 {
        line-height: 38px;
        font-size: 11px;
    }
    .heading h3 {
        font-size: 14px;
        padding-bottom: 4px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .FooterLive h5 {
        font-size: 10px;
    }
  }
  @media only screen and (max-width: 450px) {
    .card_width {
        width: 70%;
        height: 240px;
    }
    .ImgLive {
        width: 70px;
        height: 70px;
    }
    .ImgLive img {
        width: 70px;
        height:70px;
    }
    .LiveName h3 {
        line-height: 35px;
        font-size: 10px;
    }
    .LiveScore h3 {
        line-height: 35px;
        font-size: 10px;
    }
    .heading h3 {
        font-size: 12px;
        padding-bottom: 4px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .FooterLive h5 {
        font-size: 8px;
    }
  }
  @media only screen and (max-width: 400px) {
    .card_width {
        width: 78%;
        height: 235px;
    }
  }
  @media only screen and (max-width: 350px) {
    .card_width {
        width: 80%;
        height: 230px;
    }
    .heading h3 {
        font-size: 10px;
        padding-bottom: 4px;
    }
    .ImgLive {
        width: 60px;
        height: 60px;
    }
    .ImgLive img {
        width: 60px;
        height:60px;
    }
    .LiveTeam {
        padding-bottom: 4px;
    }
    .LiveScore h3 {
        padding-right: 15px;
    }
  }
.blogs-container .kNBmiK {
  max-height: 850px !important; }

.blogs-container .sc-dnqmqq.bVoXXg {
  background: whitesmoke; }

.cricview-article-container {
  background-image: url(/static/media/Must_read_bg.e97c3d1b.svg);
  background-repeat: no-repeat;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  background-size: 100%;
  margin: 1% 0 0 0;
  background-color: #0c237b; }
  .cricview-article-container img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60); }
  .cricview-article-container .cricview-article-wrapper {
    margin: 4% 0;
    margin-left: 3%; }
    .cricview-article-container .cricview-article-wrapper .article-header {
      display: flex;
      margin-bottom: 3%;
      margin-left: 7%;
      align-items: center; }
      .cricview-article-container .cricview-article-wrapper .article-header .article-header-title {
        color: #fff;
        font-family: "gilroy black";
        font-size: 32px;
        text-transform: uppercase;
        font-weight: 900;
        flex: 1 1; }
        .cricview-article-container .cricview-article-wrapper .article-header .article-header-title span {
          color: #00bcf3; }
      .cricview-article-container .cricview-article-wrapper .article-header .article-viewall {
        background-color: #ff3333;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        padding-top: 10px;
        width: 150px;
        margin-right: 20px;
        height: 44px;
        box-shadow: 0 5px 18px #ff3333; }
        .cricview-article-container .cricview-article-wrapper .article-header .article-viewall:hover {
          box-shadow: none; }
    .cricview-article-container .cricview-article-wrapper .articles {
      display: flex;
      width: 100%; }
      .cricview-article-container .cricview-article-wrapper .articles a {
        text-decoration: none;
        color: inherit; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-flex {
          display: flex;
          width: 100%; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-large {
          width: 75%;
          padding: 0 1em; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-medium {
          width: 31%;
          margin-right: 1.5%;
          border-radius: 5px;
          background-color: white; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-small {
          width: 23%;
          margin-right: 1.5%;
          margin-top: 20px;
          margin-bottom: 20px;
          border-radius: 5px;
          background-color: #fff; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-small {
          overflow: hidden;
          border-radius: 5px 5px 0 0;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          padding-bottom: 75%; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-small .image {
            position: absolute; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-small {
          margin-bottom: 10px;
          border-radius: 0px 0px 10px 10px;
          padding: 10px; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-medium {
          border-radius: 5px 5px 0 0;
          overflow: hidden;
          position: relative;
          padding-bottom: 75%; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-medium .image {
            position: absolute; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-medium {
          margin-bottom: 10px;
          padding: 10px; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .publishDate {
          padding: 10px 20px 0;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          height: 20%; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .publishDate-small {
          padding: 10px 20px 0;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          height: 20%; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .title {
          padding: 10px 20px 0;
          font-size: 16px;
          font-weight: 800;
          font-weight: bold;
          height: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .title:hover {
          overflow: visible;
          position: relative;
          text-overflow: ellipsis;
          display: block; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-small {
          padding: 10px 20px 0;
          font-size: 14px;
          font-weight: 800;
          font-weight: bold;
          height: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-small:hover {
          overflow: visible;
          position: relative;
          text-overflow: ellipsis;
          display: block; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .image {
          width: 100%;
          height: 100%;
          border-radius: 5px 5px 0 0;
          transition: transform 1s; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .image:hover {
            transform: scale(1.1);
            border-radius: 5px 5px 0 0; }
        @media (max-width: 1024px) {
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .publishDate {
            font-size: 14px; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .publishDate-small {
            font-size: 12px; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .title {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            -webkit-box-orient: vertical; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-small {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            -webkit-box-orient: vertical; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-small {
            margin-bottom: 10px; }
          .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-medium {
            margin-bottom: 10px;
            padding: 10px; } }
    .cricview-article-container .cricview-article-wrapper .viewall-mobile {
      display: none; }
  .cricview-article-container .play-button {
    position: absolute;
    left: 40%;
    top: 40%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1; }
    .cricview-article-container .play-button:hover {
      box-shadow: 0 0px 36px 18px red; }

@media (max-width: 768px) {
  .border1 {
    background-size: 21px 4px, 0px 0px; }
  .cricview-article-container {
    background-color: darkblue;
    background-image: url(/static/media/mustreadbg.ddfa68b8.png); }
    .cricview-article-container .cricview-article-wrapper {
      margin: 4% 0;
      margin-left: 4%; }
      .cricview-article-container .cricview-article-wrapper .article-header {
        margin-left: 0; }
        .cricview-article-container .cricview-article-wrapper .article-header .article-viewall {
          display: none; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-medium {
        width: 46%;
        margin: 2% 0;
        height: unset;
        margin-right: 3%;
        margin-top: 20px; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-small {
        width: 46%;
        margin: 2% 0;
        margin-right: 3%;
        margin-top: 20px;
        height: unset; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-small {
        height: 150px;
        margin-bottom: 10px; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-medium {
        height: 150px;
        margin-bottom: 10px;
        padding: 10px; }
      .cricview-article-container .cricview-article-wrapper .viewall-mobile {
        display: flex; }
        .cricview-article-container .cricview-article-wrapper .viewall-mobile .article-viewall-mobile {
          background-color: #ff3333;
          color: #fff;
          font-size: 16px;
          border-radius: 50px;
          text-decoration: none;
          text-align: center;
          padding-top: 10px;
          width: 100%;
          margin-right: 0;
          height: 44px;
          box-shadow: 0 5px 18px #ff3333; }
          .cricview-article-container .cricview-article-wrapper .viewall-mobile .article-viewall-mobile:hover {
            box-shadow: none; }
    .cricview-article-container .play-button {
      position: absolute;
      left: 40%;
      top: 40%;
      border-radius: 50%;
      transition: box-shadow 0.5s;
      z-index: 1; }
      .cricview-article-container .play-button:hover {
        box-shadow: 0 0px 36px 18px red; } }

@media (max-width: 425px) {
  .cricview-article-container .cricview-article-wrapper {
    margin: 4% 3%; }
    .cricview-article-container .cricview-article-wrapper .article-header {
      margin-left: 0; }
      .cricview-article-container .cricview-article-wrapper .article-header .article-header-title {
        font-size: 25px; }
    .cricview-article-container .cricview-article-wrapper .articles .article-grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-flex {
        display: flex;
        width: 100%; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-large {
        width: 75%;
        padding: 0 1em; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-medium {
        width: 100%;
        border-radius: 5px;
        margin-right: 0px;
        background-color: white; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .article-item-small {
        width: 100%;
        margin-right: 0px;
        border-radius: 5px;
        background-color: #fff; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-small {
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        padding-bottom: 75%; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-small .image {
          position: absolute; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-small {
        margin-bottom: 10px;
        border-radius: 0px 0px 10px 10px;
        padding: 10px; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-medium {
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        position: relative;
        padding-bottom: 75%; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .image-container-medium .image {
          position: absolute; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-container-medium {
        margin-bottom: 10px;
        padding: 10px; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .publishDate {
        padding: 10px 20px 0;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        height: 20%; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .publishDate-small {
        padding: 10px 20px 0;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        height: 20%; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title {
        padding: 10px 20px 0;
        font-size: 16px;
        font-weight: 800;
        font-weight: bold;
        height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title:hover {
        overflow: visible;
        position: relative;
        text-overflow: ellipsis;
        display: block; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-small {
        padding: 10px 20px 0;
        font-size: 14px;
        font-weight: 800;
        font-weight: bold;
        height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .title-small:hover {
        overflow: visible;
        position: relative;
        text-overflow: ellipsis;
        display: block; }
      .cricview-article-container .cricview-article-wrapper .articles .article-grid .image {
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0 0;
        transition: transform 1s; }
        .cricview-article-container .cricview-article-wrapper .articles .article-grid .image:hover {
          transform: scale(1.1);
          border-radius: 5px 5px 0 0; } }

.topsearches-marquee {
  display: flex;
  width: 100%;
  align-items: center;
  color: #d8d8d8;
  height: 46px;
  background-color: #2e2087; }
  .topsearches-marquee a {
    color: #d8d8d8; }
    .topsearches-marquee a:hover {
      color: #d8d8d8;
      text-decoration: underline; }
  .topsearches-marquee .marquee-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #2e2087;
    height: 44px; }
    .topsearches-marquee .marquee-title-container .title-text {
      color: #fff;
      font-size: 17px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 1%;
      width: 150px;
      margin-left: 34px; }
      .topsearches-marquee .marquee-title-container .title-text span {
        color: #00bcf3; }
    .topsearches-marquee .marquee-title-container .title-image {
      padding: 2%;
      cursor: pointer; }
  .topsearches-marquee .marquee {
    flex: 1 1;
    color: #1565c0;
    font-size: 16px;
    font-weight: bold; }
  .topsearches-marquee .margin-span {
    margin: 0 100px; }

.Blank {
  width: 1px;
  height: 100%; }

.download {
  display: flex;
  flex-direction: row;
  align-items: center; }

.download a {
  margin-left: 2.5rem;
  cursor: pointer; }

.download_Text {
  margin-left: 1rem;
  margin-right: 0.7rem; }

.cricview-topsearch-container {
  border-radius: 100%/0 0 100px 100px;
  background-image: url(/static/media/top_searche_sbg.62cad5f8.png);
  background-size: 100%;
  width: 100%;
  font-size: 14px;
  color: white; }
  .cricview-topsearch-container .jWpUJH {
    bottom: -12px; }
  .cricview-topsearch-container a {
    color: inherit; }
    .cricview-topsearch-container a:hover {
      color: inherit;
      text-decoration: underline; }
  .cricview-topsearch-container .cricview-topsearch-wrapper {
    margin: 1% 3%;
    padding-bottom: 2%; }
    .cricview-topsearch-container .cricview-topsearch-wrapper .topsearch-header {
      display: flex;
      align-items: flex-end;
      flex-direction: row; }
      .cricview-topsearch-container .cricview-topsearch-wrapper .topsearch-header .topsearch-header-container {
        display: flex;
        flex-direction: column;
        margin-right: 2%; }
        .cricview-topsearch-container .cricview-topsearch-wrapper .topsearch-header .topsearch-header-container .header-view {
          font-size: 28px; }
      .cricview-topsearch-container .cricview-topsearch-wrapper .topsearch-header .topsearch-banner-container {
        height: 100%;
        width: 100%;
        flex: 1 1;
        margin: 0 20%; }
        .cricview-topsearch-container .cricview-topsearch-wrapper .topsearch-header .topsearch-banner-container .topsearch-banner {
          width: 100%;
          height: 100%; }
    .cricview-topsearch-container .cricview-topsearch-wrapper .topsearchs {
      width: 100%;
      padding-bottom: 0;
      margin-top: 2%; }
  .cricview-topsearch-container img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    width: 100%;
    height: 100%; }
  .cricview-topsearch-container .Bg_color {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }
  .cricview-topsearch-container .featuredTag {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIQAAAAoCAYAAAA/mlIyAAAAAXNSR0IArs4c6QAAA4RJREFUeAHtXE1oE0EU/t421dr8HBUs6MGDhwrSUw9aoU1a8KylRVF7qErpRXtSQepFEURPLXjwKognEUFImraIPVgo/qF4EaRQxIoo3dRim2T6Nmtqm6Y22ewmm+ybS3ZnZ+a975uPmXmbnSHkpnG9GSn0c3YXoPZBIZBbRO5rkQH6Do2GaA3aB7UDc4l7fD/AQtDW8uXCOwwQLfkyaE0xPGchdHgHvSDdxICCMgWRGRlEDJsI8lIGURKgAYKxZkjinUwTXur9HKxEc6hTPegITfnMBaSsGXIo8s4tURyNOIUjoXkDtDFlcDQhyXMMECnGfBORwDCI0ln8LAgOLSV5iwHCD2jaGYT9HEhsTD55z7CREA/cTQP13Qjvms2H1Ywy8j2RvBpkgEbRFBhCMy1vBU4EsRUztZRPSPA64TwiwUfbwRJBbMdQtT8n+gifOoH24KdCoIggCmGpWssQPcTuwEUcpsVCIYggCmWqqsrRH3b3EjqD94t1WwRRLGPuL/8FPu0kOvwzVlwVQVhhza11CM/QEDyLNvpp1UURhFXmXFWPUtDUdYSDtzmaMN5AWk4iCMvUuaQi0Tf2pBeR0KQdHokg7GCxYm3QC+zUenHM/9UuF0QQdjFZznaMaUHRHXT6r/EUkbLTtAjCTjbL0RbhF3/heA5d/qdOmJNvJ51g1ck2FcWwpzHulAkRhFPMOtau6sa8/hZjC21OmBBBOMGq020qHECaJhFduIsJ1WCnORGEnWyWta3MZ49DSOpvMP671S7TIgi7mKxUOwoHkUxNIZq4BWM7RYlJBFEige6oruqA9FXeaDWD2GJLKT6JIEphz3V11SEWxjRi+jCvLSy9UhBBuK5TS3RIsRCUuoFkYhoTOgukuCSCKI6v6imtVAtWwFPIwhUWCE8phSXi0KWkf8cKMyOlKszAK9SjD+2hbT+jkxGiwj1VJvOtSNJrxBKXebT4b58TDym67M0oU7e4wQzRS2j1fQg3fM7nDquFZvM9kLwaZUCpo0gt86tvfZBHi3/ng/yFawwf0RqFLrC2ZsCPtBrhKWQM8aX964tpqMMDHiXWNnuufyjXtc4AnwmSXnnPgUV/Fqk5ZET1Ed70O5jNlF8PMkD0BHsDPaYg5EghDyogD2TSTpshiLH5sylwnKeOUZk+8hDloaxNq8zMEUNyLKGHJMBQ+fQ5jjgeoyl4YRWaXtLScdDMZwAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center; }
  .cricview-topsearch-container .featuredtitle {
    margin: 0 10px; }
  .cricview-topsearch-container .topsearch-item-small {
    width: 100%;
    /* margin: 0 1% 1% 0; */
    position: relative;
    border-radius: 5px;
    height: 280px; }
    .cricview-topsearch-container .topsearch-item-small .topsearch-small-image-container {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      display: flex;
      /* padding-bottom: 75%; */
      height: 100%;
      cursor: pointer; }
    .cricview-topsearch-container .topsearch-item-small .topsearch-small-title-container {
      position: absolute;
      padding: 0 4%;
      width: 100%;
      border-radius: 5px;
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 23%, rgba(12, 35, 123, 0.81) 59%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 50%; }
      .cricview-topsearch-container .topsearch-item-small .topsearch-small-title-container .title-container-image {
        margin: 2% 0; }
      .cricview-topsearch-container .topsearch-item-small .topsearch-small-title-container .title-container-date {
        margin: 2% 0;
        font-size: 14px; }
      .cricview-topsearch-container .topsearch-item-small .topsearch-small-title-container .title-container-text {
        margin: 2% 0;
        font-weight: bold;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
        .cricview-topsearch-container .topsearch-item-small .topsearch-small-title-container .title-container-text:hover {
          text-decoration: underline; }
      @media (max-width: 1024px) {
        .cricview-topsearch-container .topsearch-item-small .topsearch-small-title-container .title-container-date {
          margin: 2% 0;
          font-size: 10px; }
        .cricview-topsearch-container .topsearch-item-small .topsearch-small-title-container .title-container-text {
          margin: 2% 0;
          font-weight: bold;
          font-size: 12px; } }
    .cricview-topsearch-container .topsearch-item-small .image {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: transform 1s;
      object-fit: contain;
      border-radius: 5px; }
      .cricview-topsearch-container .topsearch-item-small .image:hover {
        transform: scale(1.1);
        border-radius: 5px; }
  .cricview-topsearch-container .extra-width {
    width: 500px; }

@media (max-width: 1024px) {
  .extra-width {
    width: 100% !important; } }

@media (max-width: 768px) {
  .topsearches-marquee .marquee-title-container .title-text {
    font-size: 9px;
    width: 75px;
    margin-left: 25px; }
  .topsearches-marquee .marquee {
    font-size: 12px; }
  .cricview-topsearch-container {
    background-image: url(/static/media/group-6.8ba3eede.png);
    background-size: 132%; }
    .cricview-topsearch-container .cricview-topsearch-wrapper {
      margin: 4%; }
      .cricview-topsearch-container .cricview-topsearch-wrapper .topsearch-header {
        display: flex;
        align-items: flex-end;
        flex-direction: row; }
        .cricview-topsearch-container .cricview-topsearch-wrapper .topsearch-header .topsearch-banner-container {
          margin: 0; }
  .extra-width {
    width: 100% !important; } }

.withHeader {
  height: 520px; }

.withoutHeader {
  height: 470px; }

.cricview-latestvideo-container {
  background-color: #e9f6ff;
  background-image: url("/static/media/top_searche_sbg.62cad5f8.png");
  width: 100%;
  margin-bottom: 50px; }
  .cricview-latestvideo-container .showmore_button_container {
    margin: 1% 0;
    transform: translate(0px, -170%); }
  .cricview-latestvideo-container .showmore_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cricview-latestvideo-container .showmore_button {
    background-color: #ff3333;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-right: 10px;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer; }
    .cricview-latestvideo-container .showmore_button:hover {
      box-shadow: none; }
  .cricview-latestvideo-container img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60); }
  .cricview-latestvideo-container .cricview-latestvideo-wrapper {
    margin: 3% 3% 0%;
    position: relative; }
  .cricview-latestvideo-container .latestvideo-header {
    display: flex;
    margin-bottom: 1%;
    align-items: center; }
  .cricview-latestvideo-container .latestvideo-header-title {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    flex: 1 1;
    font-family: 'gilroy black';
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white; }
  .cricview-latestvideo-container .latestvideo-header-title span {
    color: #00bcf3; }
  .cricview-latestvideo-container .latestvideo-viewall {
    background-color: #ff3333;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    padding-top: 10px;
    width: 150px;
    margin-right: 20px;
    height: 44px;
    box-shadow: 0 5px 18px #ff3333; }
    .cricview-latestvideo-container .latestvideo-viewall:hover {
      color: white;
      box-shadow: none; }
  .cricview-latestvideo-container .latestvideos {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    overflow-wrap: normal;
    width: 100%;
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .cricview-latestvideo-container .latestvideos::-webkit-scrollbar {
      height: 10px; }
    .cricview-latestvideo-container .latestvideos::-webkit-scrollbar-track {
      background: rgba(12, 35, 123, 0.1);
      border: 0.5px solid #f1f1f1;
      border-radius: 5px; }
    .cricview-latestvideo-container .latestvideos::-webkit-scrollbar-thumb {
      background-image: linear-gradient(45deg, red, darkblue);
      border-radius: 5px; }
    .cricview-latestvideo-container .latestvideos::-webkit-scrollbar-thumb:active {
      background-image: linear-gradient(45deg, red, darkblue); }
  .cricview-latestvideo-container .latestvideos-grid {
    display: flex;
    width: 200%;
    margin-bottom: 30px; }
  .cricview-latestvideo-container .latestvideo-item {
    width: 25%;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    height: 100%; }
  .cricview-latestvideo-container a:hover {
    text-decoration: none; }
  .cricview-latestvideo-container .image-container {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    padding-bottom: 75%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center; }
  .cricview-latestvideo-container .video-image {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 1s;
    object-fit: contain;
    border-radius: 5px; }
    .cricview-latestvideo-container .video-image:hover {
      transform: scale(1.1);
      border-radius: 5px; }
  .cricview-latestvideo-container .title-container {
    height: 150px;
    margin-bottom: 10px; }
  .cricview-latestvideo-container .publishDate {
    padding-top: 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    height: 20%;
    color: white; }
  .cricview-latestvideo-container .title {
    padding-top: 20px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: white; }
  .cricview-latestvideo-container .title:hover {
    text-decoration: underline;
    overflow: visible;
    position: relative;
    text-overflow: ellipsis;
    display: block; }
  .cricview-latestvideo-container .arrow-flex {
    display: flex;
    align-items: center; }
  .cricview-latestvideo-container .arrow-left {
    cursor: pointer;
    margin-bottom: 150px;
    margin-right: 2%; }
  .cricview-latestvideo-container .arrow-right {
    cursor: pointer;
    margin-bottom: 150px; }
  .cricview-latestvideo-container .arrow-image {
    height: 100%; }
  .cricview-latestvideo-container .time-container {
    float: right;
    margin-right: 20px;
    padding: 5px;
    width: 75px;
    text-align: center;
    position: relative; }
  .cricview-latestvideo-container .time {
    font-size: 16px;
    color: red;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    background: white;
    width: 75px;
    border-radius: 20px; }
  .cricview-latestvideo-container .play-button {
    position: absolute;
    left: 37.5%;
    top: 37.5%;
    width: 20%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1; }
  .cricview-latestvideo-container .play-image {
    height: 100%;
    width: 100%;
    border-radius: 0px; }
  @media (max-width: 768px) {
    .cricview-latestvideo-container .cricview-latestvideo-wrapper {
      margin: 4%; }
    .cricview-latestvideo-container .arrow-left {
      margin-right: 0%; }
    .cricview-latestvideo-container .arrow-left svg {
      height: 24px;
      width: 24px; }
    .cricview-latestvideo-container .arrow-right svg {
      height: 24px;
      width: 24px; }
    .cricview-latestvideo-container .ar
.latestvideo-header {
      margin-left: 20px; }
    .cricview-latestvideo-container .latestvideo-item {
      width: 100% !important;
      margin-right: 20px;
      margin-top: 20px;
      margin-left: 20px;
      border-radius: 5px;
      color: white;
      height: 100%; }
    .cricview-latestvideo-container .time {
      bottom: 200%;
      font-size: 14px; }
    .cricview-latestvideo-container .publishDate {
      font-size: 12px; }
    .cricview-latestvideo-container .title {
      font-size: 14px; }
    .cricview-latestvideo-container .latestvideo-viewall {
      display: none; }
    .cricview-latestvideo-container .viewall-mobile {
      display: flex;
      margin-top: 20px; }
      .cricview-latestvideo-container .viewall-mobile .article-viewall-mobile {
        background-color: #ff3333;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        padding-top: 10px;
        width: 100%;
        margin-right: 0;
        height: 44px;
        box-shadow: 0 5px 18px #ff3333; }
        .cricview-latestvideo-container .viewall-mobile .article-viewall-mobile:hover {
          background-color: white;
          color: #ff3333;
          box-shadow: 0 5px 18px -1px #ff3333; }
    .cricview-latestvideo-container .latestvideos::-webkit-scrollbar {
      display: none; } }

@media (max-width: 1024px) {
  .withHeader {
    height: 430px; }
  .withoutHeader {
    height: 395px; } }

@media (max-width: 768px) {
  .withHeader {
    height: 500px; }
  .withoutHeader {
    height: 450px; } }

@media (max-width: 340px) {
  .withHeader {
    height: 420px; }
  .withoutHeader {
    height: 380px; } }

.liveTrayBackground {
  background-image: url("/static/media/top_searche_sbg.62cad5f8.png");
  width: 100%;
  min-height: 320px; }
  .liveTrayBackground .card:hover .sharing-footer {
    display: block !important; }
  .liveTrayBackground #whatsApforMobile {
    display: none; }
  .liveTrayBackground .sharing-footer {
    z-index: 99999;
    display: none !important;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none; }
    .liveTrayBackground .sharing-footer .share-btn-container {
      margin-right: 10px !important;
      width: 15px !important; }
    .liveTrayBackground .sharing-footer .share-btns {
      flex-direction: column;
      margin-top: 59px;
      background-color: #ebedf087;
      padding: 20px 0 10px 11px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    .liveTrayBackground .sharing-footer .share-btns i {
      font-size: 20px; }
  .liveTrayBackground .score {
    color: white;
    font-size: 18px;
    padding: 1% 10%;
    margin-bottom: 3%;
    border-bottom: 1px solid white; }
  .liveTrayBackground .matchName * {
    color: white !important;
    text-decoration: none !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-family: sans-serif !important;
    font-style: normal !important; }
  .liveTrayBackground .liveTray-container {
    width: 100%;
    padding: 0 1%; }
    .liveTrayBackground .liveTray-container .marquee-block {
      position: relative; }
    .liveTrayBackground .liveTray-container .centerAlign {
      display: flex;
      justify-content: center; }
    .liveTrayBackground .liveTray-container .voteText {
      display: flex;
      font-size: 25px;
      font-weight: 900;
      align-items: center;
      justify-content: center;
      padding: 1% 0; }
      .liveTrayBackground .liveTray-container .voteText .vote {
        margin-right: 1%;
        width: 8%; }
        .liveTrayBackground .liveTray-container .voteText .vote img {
          width: 100%;
          display: block; }
      .liveTrayBackground .liveTray-container .voteText .now {
        margin-right: 1%;
        font-size: 35px;
        color: white;
        font-weight: 900;
        font-family: 'gilroy black'; }
      .liveTrayBackground .liveTray-container .voteText .ex {
        font-size: 40px;
        color: red;
        font-weight: 900;
        font-family: 'gilroy black'; }
    .liveTrayBackground .liveTray-container .marquee-container {
      overflow: hidden;
      height: 275px;
      background-color: #ff360030;
      border-radius: 5px; }
    .liveTrayBackground .liveTray-container .marquee-inner {
      display: block;
      width: 100%;
      position: absolute;
      top: 0; }
    .liveTrayBackground .liveTray-container .totalCards {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      position: relative;
      margin-top: 0.7%; }
    .liveTrayBackground .liveTray-container .flexx {
      display: flex; }
    .liveTrayBackground .liveTray-container .play {
      -webkit-animation-duration: 25s;
              animation-duration: 25s;
      -webkit-animation-name: marquee;
              animation-name: marquee;
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear; }
    .liveTrayBackground .liveTray-container .play:hover {
      -webkit-animation-play-state: paused;
              animation-play-state: paused; }

@-webkit-keyframes marquee {
  0% {
    left: 70%; }
  100% {
    left: -100%; } }

@keyframes marquee {
  0% {
    left: 70%; }
  100% {
    left: -100%; } }
    .liveTrayBackground .liveTray-container .card {
      cursor: pointer;
      transition: all 0.2s ease-out;
      margin-right: 1%;
      background: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 8%;
      border-radius: 5px;
      border: 1px solid #585858;
      border-radius: 0.5rem;
      box-sizing: border-box;
      box-shadow: 0 1em 1em -1em #daaeae;
      background-image: linear-gradient(150deg, #0f0949 50%, #4c0c4d 50%);
      max-width: 253px;
      margin: 0 auto; }
      .liveTrayBackground .liveTray-container .card .cricView {
        width: 30%;
        border-radius: 50%;
        overflow: hidden; }
        .liveTrayBackground .liveTray-container .card .cricView img {
          width: 100%;
          display: block; }
      .liveTrayBackground .liveTray-container .card .title {
        overflow: hidden;
        height: 42px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 18px;
        font-weight: 900;
        text-align: center;
        margin: 5% 5% 2% 5%; }
        .liveTrayBackground .liveTray-container .card .title p {
          color: white;
          line-height: 1.2; }
      .liveTrayBackground .liveTray-container .card .countDown {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        padding-bottom: 5%;
        width: 100%;
        color: white; }
      .liveTrayBackground .liveTray-container .card .endText {
        text-align: center; }
      .liveTrayBackground .liveTray-container .card:hover {
        border: 1px solid white;
        background-image: linear-gradient(150deg, #4c0c4d 50%, #0f0949 50%); }
    .liveTrayBackground .liveTray-container .liveTray {
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
      flex: 1 1;
      font-family: 'gilroy black';
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: white;
      padding: 1%;
      width: 100%;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      .liveTrayBackground .liveTray-container .liveTray .liveGif {
        width: 12%;
        margin-right: 1%;
        margin-left: 1%; }
        .liveTrayBackground .liveTray-container .liveTray .liveGif img {
          width: 100%;
          display: block; }
  @media (max-width: 768px) {
    .liveTrayBackground .sharing-footer {
      display: block !important; }
    .liveTrayBackground .voteText .vote {
      margin-right: 2% !important;
      width: 13% !important; }
    .liveTrayBackground .liveGif {
      width: 20% !important; }
    @-webkit-keyframes marquee {
      0% {
        left: 40%; }
      100% {
        left: -100%; } }
    @keyframes marquee {
      0% {
        left: 40%; }
      100% {
        left: -100%; } } }
  @media (max-width: 425px) {
    .liveTrayBackground #whatsApforMobile {
      display: block; }
    .liveTrayBackground #whatsApforDesktop {
      display: none; }
    .liveTrayBackground .sharing-footer {
      display: block !important; }
    .liveTrayBackground .voteText .vote {
      margin-right: 2% !important;
      width: 27% !important; }
    .liveTrayBackground .liveGif {
      width: 35% !important; }
    .liveTrayBackground .marquee-container {
      overflow: hidden;
      height: 280px;
      background-color: white;
      border-radius: 5px; }
    .liveTrayBackground .totalCards {
      margin-top: 0.5%; }
    @-webkit-keyframes marquee {
      0% {
        left: 20%; }
      100% {
        left: -100%; } }
    @keyframes marquee {
      0% {
        left: 20%; }
      100% {
        left: -100%; } } }

.editor-Images-carousel {
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .editor-Images-carousel .contain {
    width: 100%; }
  .editor-Images-carousel .loader-container-new {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: #fcfcfcbf; }
  .editor-Images-carousel #loader-new {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -29px 0 0 -32px;
    border: 5px solid #08255d;
    border-radius: 50%;
    border-top: 5px solid #d6011f;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px #ba2e2e; }
  .editor-Images-carousel .rowww__inner {
    transition: 450ms transform;
    white-space: nowrap;
    margin: 70.3125px 0;
    padding-bottom: 10px; }
  .editor-Images-carousel .tile {
    position: relative;
    display: inline-block;
    width: 300px;
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
    transition: 450ms all;
    transform-origin: center left; }
  .editor-Images-carousel .tile:hover .hid {
    margin-left: 40%;
    position: relative; }
  .editor-Images-carousel .tile__img {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: contain; }
  .editor-Images-carousel .tile__media {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; }
  .editor-Images-carousel .row__inner:hover {
    transform: translate3d(-62.5px, 0, 0); }
  .editor-Images-carousel .row__inner:hover .tile {
    opacity: 0.3; }
  .editor-Images-carousel .row__inner:hover .tile:hover {
    transform: scale(1.1);
    opacity: 1; }
  .editor-Images-carousel .tile:hover ~ .tile {
    transform: translate3d(125px, 0, 0); }
  .editor-Images-carousel .tile__title {
    position: absolute;
    bottom: 0;
    padding: 10px; }
  .editor-Images-carousel .rowww {
    overflow-y: hidden;
    width: 100%; }
  .editor-Images-carousel .rowww__inner:hover .tile {
    opacity: 0.3; }
    .editor-Images-carousel .rowww__inner:hover .tile:hover {
      transform: scale(1.1, 1.1);
      opacity: 1; }
    .editor-Images-carousel .rowww__inner:hover .tile .tile:hover ~ .tile {
      transform: scale(1.5); }
  .editor-Images-carousel .circle {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #5812bf;
    position: relative; }
  .editor-Images-carousel .plus {
    padding-left: 4%;
    margin-bottom: 3%; }
  .editor-Images-carousel .circle::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    height: 10px;
    margin-top: -5px;
    top: 50%;
    left: 5px;
    right: 5px;
    z-index: 9; }
  .editor-Images-carousel .circle::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    width: 10px;
    margin-left: -5px;
    left: 50%;
    top: 5px;
    bottom: 5px;
    z-index: 9; }
  .editor-Images-carousel .container1 {
    display: flex;
    align-items: center; }
  .editor-Images-carousel .showmore_button {
    background-color: #ff3333;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-right: 10px;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer; }
  .editor-Images-carousel .butn {
    justify-content: center;
    align-items: center;
    display: flex; }
  .editor-Images-carousel .showmore_button_disabled {
    background-color: black;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-right: 10px;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer; }
  .editor-Images-carousel .full {
    margin-top: 5%; }
  .editor-Images-carousel .container2 {
    display: flex;
    justify-content: center;
    margin-top: 39%;
    position: absolute;
    width: 83%; }
  .editor-Images-carousel .tile input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .editor-Images-carousel .hid {
    display: block;
    margin-left: 40%;
    margin-top: 4%;
    position: relative; }
  .editor-Images-carousel .checkmark {
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee; }
  .editor-Images-carousel .tile:hover input ~ .checkmark {
    background-color: #ccc; }
  .editor-Images-carousel .tile input:checked ~ .checkmark {
    background-color: #2196F3; }
  .editor-Images-carousel .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .editor-Images-carousel .tile input:checked ~ .checkmark:after {
    display: block; }
  .editor-Images-carousel .tile .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg); }

.container2 {
  display: flex;
  justify-content: center;
  width: 100%; }
  .container2 input[type=submit]:enabled {
    background-color: red;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-right: 10px;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer; }
  .container2 input[type=submit]:disabled {
    background-color: #aa6b6b;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-right: 10px;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: not-allowed; }

.categories {
  margin-top: 0.5%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #66615b;
  line-height: normal;
  font-size: 14px;
  transition: color .3s ease-in-out,border-color .3s ease-in-out,background-color .3s ease-in-out;
  box-shadow: none;
  width: 100%;
  height: unset;
  padding: 10px 10px 10px 10px; }

.butn {
  justify-content: center;
  align-items: center;
  display: flex; }

.title_style input {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #66615b;
  line-height: normal;
  font-size: 14px;
  transition: color .3s ease-in-out,border-color .3s ease-in-out,background-color .3s ease-in-out;
  box-shadow: none;
  width: 100%;
  height: unset;
  padding: 10px 10px 10px 10px; }

.title_style label {
  font-size: 1.8571em;
  color: black;
  font-weight: 600; }

.col10 {
  margin: 0 auto;
  width: 83.33333%; }

.title_style {
  margin-top: 4%; }

.cricview-talnet-reg hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee; }

.cricview-talnet-reg b,
.cricview-talnet-reg strong {
  font-weight: 700; }

.cricview-talnet-reg section#contant {
  float: left;
  width: 100%;
  padding: 50px 0 25px;
  background: #fff; }

.cricview-talnet-reg .main-heading {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 35px; }

.cricview-talnet-reg .row {
  margin-right: -15px;
  margin-left: -15px; }

.cricview-talnet-reg .container {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.cricview-talnet-reg .contact {
  float: left;
  width: 100%; }

.cricview-talnet-reg .col-md-12 {
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.cricview-talnet-reg .col-md-4 {
  width: 33.33333333%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.cricview-talnet-reg .contact-us {
  margin-top: 30px; }

.cricview-talnet-reg section.main-heading h2 {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 25px;
  line-height: 21px;
  font-weight: 600;
  color: #222;
  padding: 0;
  margin: 0;
  position: relative; }

.cricview-talnet-reg section.main-heading h2::after {
  width: 50px;
  height: 4px;
  background: #d8302f;
  margin: 15px auto 0;
  display: block;
  position: relative;
  content: ""; }

.cricview-talnet-reg .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.cricview-talnet-reg .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }

.cricview-talnet-reg .text-center {
  text-align: center; }

.cricview-talnet-reg section#contant h4 {
  float: left;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0 0 10px;
  font-size: 18px;
  color: #222; }

.cricview-talnet-reg section.team h4 {
  font-size: 21px !important;
  padding: 0 !important;
  font-weight: 600;
  margin-top: 10px !important; }

.cricview-talnet-reg .img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.cricview-talnet-reg section#contant p {
  float: left;
  width: 100%;
  line-height: 30px;
  font-size: 15px;
  text-align: left;
  font-weight: 300; }

.cricview-talnet-reg .main-heading p {
  color: #363636;
  margin-top: 0; }

.cricview-talnet-reg .contact-info {
  float: left;
  width: 100%;
  background: #f8f8f8;
  border: solid #ccc 1px;
  padding: 32px 35px 30px 35px;
  text-align: left;
  margin-top: 30px; }

.cricview-talnet-reg .contact-info div.kode-section-title {
  float: left;
  width: 100%; }

.cricview-talnet-reg .kode-form-list {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 30px; }

.cricview-talnet-reg .kode-form-list li {
  float: left;
  width: 100%; }

.cricview-talnet-reg .kode-form-list li img {
  font-size: 24px;
  width: 25px;
  text-align: center;
  position: absolute;
  left: 0; }

.cricview-talnet-reg section#contant p strong {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 600; }

@media (max-width: 992px) {
  .cricview-talnet-reg .img-responsive {
    display: block;
    max-width: 100%;
    height: auto; }
  .cricview-talnet-reg .row {
    margin-right: -15px;
    margin-left: -15px; } }

@media (max-width: 768px) {
  .cricview-talnet-reg .container {
    width: 90%; }
  .cricview-talnet-reg .row {
    margin-right: -15px;
    margin-left: 0px; }
  .cricview-talnet-reg .img-responsive {
    display: block;
    height: auto;
    position: relative; }
  .cricview-talnet-reg .col-md-1,
  .cricview-talnet-reg .col-md-2,
  .cricview-talnet-reg .col-md-3,
  .cricview-talnet-reg .col-md-4,
  .cricview-talnet-reg .col-md-5,
  .cricview-talnet-reg .col-md-6,
  .cricview-talnet-reg .col-md-7 {
    width: unset;
    height: 35%; }
  .cricview-talnet-reg section#contant p {
    float: left;
    width: 100%;
    line-height: 24px;
    font-size: 13px;
    text-align: left;
    font-weight: 400; }
  .cricview-talnet-reg .contact-info {
    padding: 20px 20px 20px 20px;
    margin-top: 30px;
    margin-bottom: 30px; } }

.marginT {
  margin-top: 2% !important; }

.leaderboard-container .main {
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  /* background: #4b4168; */
  color: #333;
  position: relative;
  display: flex;
  padding: 50px; }
  .leaderboard-container .main .center {
    flex-direction: column;
    display: flex;
    padding: 0 3% 3%;
    box-shadow: 0px 0px 5px 2px #bfb8bf;
    align-items: center;
    /* left: 32%; */
    justify-content: center;
    /* transform: translate(-50%, -50%); */
    /* z-index: 10; */
    font-family: "Red Hat Display", sans-serif; }
  .leaderboard-container .main .leaderboard-name {
    font-size: 3.1rem;
    background-image: linear-gradient(to right, red, #2f0070, #2f0070, #2f0070, #2f0070, #2f0070, #2f0070, #2f0070, #2f0070, #2f0070, #2f0070, #2f0070, red);
    color: white;
    padding: 1%;
    width: 100%;
    border-radius: 4rem;
    margin: -25px; }
  .leaderboard-container .main .top3 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #4b4168;
    padding-top: 20%;
    width: 100%; }
  .leaderboard-container .main .top3 .item {
    box-sizing: border-box;
    position: relative;
    background-image: linear-gradient(45deg, red, darkblue);
    color: white;
    width: 10rem;
    height: 11rem;
    text-align: center;
    padding: 2.8rem 0 0;
    margin: 1rem 1rem 2rem;
    border-radius: 0.5rem;
    transform-origin: bottom;
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1), 0 1rem 2rem -1rem rgba(0, 0, 0, 0.3); }
  .leaderboard-container .main .top3 .item .pic {
    position: absolute;
    top: -3rem;
    left: 2.5rem;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-right: 1rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2), 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3); }
  .leaderboard-container .main .top3 .item .pos {
    font-weight: 900;
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
  .leaderboard-container .main .top3 .item .name {
    font-size: 1.1rem;
    margin-bottom: 0.5rem; }
  .leaderboard-container .main .top3 .item .score {
    color: white; }
  .leaderboard-container .main .top3 .item .score:after {
    display: block;
    content: "points";
    opacity: 0.7;
    color: antiquewhite; }
  .leaderboard-container .main .top3 .item.one {
    width: 12rem;
    height: 13rem;
    padding-top: 3.5rem; }
  .leaderboard-container .main .top3 .item.one .pic {
    width: 7rem;
    height: 7rem;
    top: -4rem;
    left: 2.5rem; }
  .leaderboard-container .main .top3 .item:hover {
    transform: scale(1.05); }
  .leaderboard-container .main .list {
    padding-left: 2rem;
    margin: 0 auto; }
  .leaderboard-container .main .list .item {
    position: relative;
    display: flex;
    width: 48rem;
    align-items: center;
    color: white;
    height: 5rem;
    border-radius: 4rem;
    margin-bottom: 2rem;
    background-image: linear-gradient(45deg, darkblue, red);
    transform-origin: left;
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1), 0 1rem 2rem -1rem rgba(0, 0, 0, 0.3); }
  .leaderboard-container .main .list .item .pos {
    font-weight: 900;
    position: absolute;
    left: -2rem;
    text-align: center;
    font-size: 1.25rem;
    width: 1.5rem;
    color: black;
    opacity: 0.6;
    transition: opacity 200ms ease-in-out; }
  .leaderboard-container .main .list .item .pic {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-right: 1rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2), 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3); }
  .leaderboard-container .main .list .item .name {
    flex-grow: 2;
    flex-basis: 10rem;
    font-size: 1.6rem; }
  .leaderboard-container .main .list .item .score {
    margin-right: 1.5rem;
    color: white;
    font-size: 1.4rem; }
  .leaderboard-container .main .list .item .score:after {
    margin-right: 1rem;
    content: " points";
    opacity: 0.7;
    color: antiquewhite; }
  .leaderboard-container .main .list .item:hover {
    transform: scale(1.05); }
  .leaderboard-container .main .list .item:hover .pos {
    opacity: 0.8; }
  .leaderboard-container .main .pagination {
    display: inline-block; }
  .leaderboard-container .main .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd; }
  .leaderboard-container .main .pagination a.active {
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50; }
  .leaderboard-container .main .pagination a:hover:not(.active) {
    background-color: #ddd; }

@media (max-width: 426px) {
  .leaderboard-container .main {
    height: 100% !important;
    width: 100% !important;
    padding: 20px !important; }
  .leaderboard-container .list .item .name {
    font-size: 1.1rem !important; }
  .leaderboard-container .list .item .score {
    margin-right: 1.5rem;
    font-size: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .leaderboard-container .list .item .score:after {
    margin-right: 0rem !important; }
  .leaderboard-container .center {
    width: 100% !important;
    padding: 3% 3% !important; }
  .leaderboard-container .leaderboard-name {
    font-size: 1.5rem !important; }
  .leaderboard-container .top3 {
    padding-top: 30% !important; }
  .leaderboard-container .list {
    width: 100% !important; }
  .leaderboard-container .top3 .item {
    flex: 1 1;
    width: 100% !important; }
  .leaderboard-container .top3 .item .pic {
    left: 8% !important;
    width: 85% !important;
    height: 50% !important;
    top: -2.5rem !important; }
  .leaderboard-container .top3 .item.one .pic {
    width: 90% !important;
    height: 50% !important;
    top: -3.5rem !important;
    left: 6% !important; }
  .leaderboard-container .list .item {
    width: 100% !important; } }

.match_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  font-size: 16px;
  width: 90%;
  margin: 0 auto;
  background-image: url(/static/media/vs.4056e2d6.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }
  .match_container .instaValue {
    font-size: 23px;
    font-weight: 800;
    display: flex;
    color: red;
    align-items: center;
    justify-content: center; }
    .match_container .instaValue a {
      color: white; }
  .match_container .skillsValue {
    min-height: 60px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    font-size: 18px;
    -webkit-box-orient: vertical; }
  .match_container .listButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    margin: 10px 20px 10px 0;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
    color: #FFF;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    transition: all 0.2s;
    box-shadow: 0px 5px 0px 0px #710505;
    cursor: pointer; }
  .match_container .listButton:hover {
    margin-top: 15px;
    margin-bottom: 5px;
    box-shadow: 0px 0px 0px 0px #710505; }
  .match_container .punjab {
    width: 118px !important;
    height: 105px !important;
    top: -26%;
    left: -50% !important;
    background-image: none !important; }
  .match_container .cpl-image {
    width: 25%; }
  .match_container .cplFlag {
    width: 200px !important;
    height: 135px !important;
    top: -26%;
    left: -50% !important;
    background-image: none !important; }
  .match_container .skills {
    width: 90%;
    display: flex;
    color: white;
    margin: 5% auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 2px; }
    .match_container .skills .text {
      margin-right: 1%; }
  .match_container .sixlogo {
    width: 150px !important;
    height: 123px !important; }
  .match_container .sevenlogo {
    width: 240px !important;
    height: 140px !important; }
  .match_container .eightlogo {
    width: 270px !important;
    height: 180px !important; }
  .match_container .ninelogo {
    width: 320px !important;
    height: 265px !important; }
  .match_container .fixImage {
    width: 200px;
    height: 200px; }
  .match_container .nostart {
    font-size: 23px;
    color: #47fff7; }
  .match_container .marq {
    background-color: #e5142f;
    width: 69%;
    border-radius: 5px; }
  .match_container .endShare {
    width: 100%;
    display: flex;
    position: relative; }
  .match_container .endButton {
    flex: 1 1;
    padding: 2% 0 2% 2%;
    display: flex; }
    .match_container .endButton .end {
      padding: 9px 18px;
      color: white;
      border: none;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 3px;
      background-color: red;
      cursor: pointer;
      margin-right: 10px; }
  .match_container .sponser {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%; }
    .match_container .sponser .text {
      color: white;
      font-size: 20px;
      font-weight: 900;
      border-bottom: 1px solid white;
      width: 62%;
      text-align: center;
      margin-bottom: 1%;
      padding-bottom: 1%; }
    .match_container .sponser .images {
      display: flex;
      width: 62%;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(45deg, black, black); }
      .match_container .sponser .images img {
        width: 100%;
        height: 100%;
        display: block; }
  .match_container .uiCount {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%; }
  .match_container .countdown__section {
    display: inline-block;
    margin-left: 10px;
    padding: 5px 5px;
    background-color: #08255d; }
  .match_container .countdown__section:first-child {
    margin-left: 0; }
  .match_container .countdown__section > .value {
    background-color: #f00;
    border-radius: 10px;
    font-size: 26px;
    color: #f1f1f1;
    text-align: center; }
  .match_container .countdown__label {
    font-size: 11px;
    padding-top: 4px;
    text-align: center; }
  .match_container .winner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    justify-content: center; }
  .match_container .winnerName {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%; }
  .match_container .winName {
    font-size: 26px;
    color: #ffd400;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .match_container .congo {
    transition: all 0.5s;
    font-size: 15px;
    font-size: 25px;
    font-weight: 900;
    color: #ff0000;
    letter-spacing: 3px;
    -webkit-animation: neon2 1.5s ease-in-out infinite alternate;
    animation: neon2 1.5s ease-in-out infinite alternate; }

@-webkit-keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228DFF, 0 0 70px #228DFF, 0 0 80px #228DFF, 0 0 100px #228DFF, 0 0 150px #228DFF; }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228DFF, 0 0 35px #228DFF, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF; } }

@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228DFF, 0 0 70px #228DFF, 0 0 80px #228DFF, 0 0 100px #228DFF, 0 0 150px #228DFF; }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228DFF, 0 0 35px #228DFF, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF; } }
  .match_container .loader-container-new {
    display: none;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: #fcfcfcbf; }
  .match_container .loadingText {
    font-size: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0% -8%;
    font-weight: 700; }
  .match_container #loader-new {
    position: absolute;
    left: 50%;
    top: 50%;
    border: 5px solid #08255d;
    border-radius: 50%;
    border-top: 5px solid #d6011f;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px #ba2e2e; }
  .match_container .flex {
    flex: 2 1; }
  .match_container .together {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 0 2%; }
  .match_container .scoreCard {
    margin-bottom: 2%;
    width: 300px;
    display: flex;
    flex-direction: row;
    transition: 1s;
    position: relative;
    height: 90px;
    border-radius: 10px;
    overflow: hidden; }
  .match_container .scoreCard:hover {
    transform: scale(1.3);
    z-index: 2; }
  .match_container .score1 {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10%;
    left: 12.5%;
    justify-content: center; }
  .match_container .score1 span {
    font-size: 27px;
    font-weight: 900; }
  .match_container .score2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    right: 12.5%; }
  .match_container .score2 span {
    font-size: 27px;
    font-weight: 900; }
  .match_container .matchEndTimer {
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;
    margin-bottom: 4%; }
  .match_container .matchEndText {
    font-size: 19px;
    margin-bottom: 5%;
    border-bottom: 1px solid white;
    text-align: center; }
  .match_container .glowLive {
    color: red;
    width: 22%;
    letter-spacing: 2px;
    font-size: 26px;
    text-shadow: 0 0 2px #e6e2e2, 0 0 10px #ebe6e6, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101;
    -webkit-animation: blinking 1.5s infinite alternate;
    animation: blinking 1.5s infinite alternate; }

@-webkit-keyframes blinking {
  100% {
    text-shadow: 0 0 2px #cec1c1, 0 0 10px #b3a9a9, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101; } }

@keyframes blinking {
  100% {
    text-shadow: 0 0 2px #cec1c1, 0 0 10px #b3a9a9, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101; } }
  .match_container .match_background {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .match_container .name1 {
    text-transform: capitalize;
    background: linear-gradient(to right, #175df6, #4eff14);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 21px;
    position: absolute;
    text-align: start;
    font-weight: 900;
    top: 10%;
    left: 13.5%; }
  .match_container .name2 {
    text-transform: capitalize;
    background: linear-gradient(to right, #de5af6, #aec064);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 21px;
    font-weight: 900;
    position: absolute;
    top: 10%;
    text-align: end;
    right: 13.5%; }
  .match_container .player1Score {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: white; }
  .match_container .player2Score {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #d4d4d4f5; }
  .match_container .flagName img {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(45deg, #e43737, blue);
    position: absolute;
    left: 0; }
  .match_container .flagName {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .match_container .flagName .name {
      font-size: 25px; }
    .match_container .flagName .state {
      font-size: 16px; }
  .match_container .flag-image {
    width: 15%;
    margin-right: 3%;
    height: 75px;
    position: relative; }
  .match_container .sharing-footer {
    float: right;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background: none;
    color: #ffffff; }
  .match_container .sharing-footer .line {
    margin-top: 5px;
    height: 1px;
    width: 80px; }
  .match_container .sharing-footer .share-prompt {
    margin-right: 30px; }
  .match_container .sharing-footer .share-prompt .text {
    font-size: 16px; }
  .match_container .sharing-footer .share-btns {
    display: flex; }
  .match_container .sharing-footer .share-btns .share-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    width: 30px;
    cursor: pointer; }
  .match_container .sharing-footer .share-btns .share-btn-container:last-child {
    margin-right: 0; }
  .match_container .sharing-footer .share-btns i {
    font-size: 36px; }
  .match_container .sharing-footer .share-btns .underline {
    height: 2px;
    width: 0%;
    transition: width 0.1s ease-out; }
  .match_container .sharing-footer .share-btns .share-btn-container:hover .underline {
    width: 100%;
    transition: width 0.1s ease-out; }
  .match_container .sharing-footer .up-next .label {
    font-weight: 800; }
  .match_container .sharing-footer .up-next .next-article {
    text-transform: uppercase; }
  .match_container .sharing-footer .up-next .line {
    float: right; }
  .match_container .matchLive {
    font-family: sans-serif;
    min-height: 40px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px;
    font-size: 22px; }
  .match_container .liveContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .match_container .liveGif {
    width: 15%;
    margin-right: 0.2%;
    margin-left: 1%; }
  .match_container .liveGif img {
    width: 100%;
    display: block; }
  .match_container .timerGif {
    display: flex;
    align-items: center;
    justify-content: center; }
  .match_container .matchTime {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    color: #fff;
    font-size: 20px; }
  .match_container .match_header {
    overflow-wrap: break-word;
    min-height: 40px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #fff;
    margin-bottom: 2%;
    font-size: 26px; }
  .match_container .match_header p, .match_container p a {
    color: white; }
  .match_container .match_question {
    min-height: 40px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #ff7f12;
    font-size: 30px;
    margin-bottom: 3%;
    font-family: sans-serif; }
  .match_container .match_totalScore {
    min-height: 40px;
    width: 100%;
    padding: 10px;
    text-align: center;
    color: white;
    font-family: cursive;
    font-weight: 900;
    font-size: 20px; }
  .match_container .tim {
    border-radius: 4px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background-color: white;
    color: black;
    margin-left: 2%;
    padding: 1px 5px;
    margin-right: 0.5%; }
  .match_container .containerQuestion {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    min-height: 300px; }
  .match_container .mashaal {
    width: 10%;
    display: flex;
    position: relative; }
  .match_container .mashaal1 {
    width: 100%;
    transform: scaleX(-1);
    position: absolute; }
  .match_container .mashaal2 {
    width: 100%;
    position: absolute; }
  .match_container .boxes {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .match_container .match_versus {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    overflow: hidden; }
  .match_container .flex1 {
    width: 50%; }
  .match_container .flex2 {
    width: 50%; }
  .match_container .center_vs {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    background-image: url(/static/media/versus.bbce38aa.png);
    background-size: contain; }
  .match_container .design_video1 {
    background-image: linear-gradient(45deg, red, darkblue);
    position: absolute;
    border-radius: 4%; }
  .match_container .design_video2 {
    background-image: linear-gradient(45deg, darkblue, red);
    position: absolute;
    border-radius: 4%; }
  .match_container .versus-left {
    position: relative;
    padding-bottom: 75%;
    border-radius: 4%;
    width: 90%;
    margin: 10px auto; }
  .match_container .i-button {
    margin-bottom: 2%;
    margin-top: -2%;
    width: 15%;
    text-align: center; }
  .match_container .i-button button {
    padding: 4% 9%;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    background-color: white;
    border: none;
    border-radius: 7px; }
  .match_container .divide {
    border-right: 8px solid black;
    border-radius: 5px;
    height: 90%;
    width: 0px;
    margin: 0 auto; }
  .match_container .versus-right {
    position: relative;
    padding-bottom: 75%;
    border-radius: 4%;
    width: 90%;
    margin: 10px auto; }
  .match_container .fireworks {
    position: relative; }
  .match_container .fireworks::before, .match_container .fireworks::after {
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
    -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards;
    animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards; }
  .match_container .fireworks::after {
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s; }

@-webkit-keyframes bang {
  to {
    box-shadow: -74px -62.3333333333px #00ff4d, 122px -12.3333333333px #0022ff, 47px 11.6666666667px #ff0088, -95px -183.3333333333px #3300ff, 13px -181.3333333333px #ff003c, -19px -200.3333333333px #ff0055, -98px -194.3333333333px #0dff00, 44px -169.3333333333px #1e00ff, 10px -130.3333333333px #00ff9d, 125px 35.6666666667px #8000ff, -109px -157.3333333333px #0044ff, 65px -170.3333333333px #00ffd0, 107px -207.3333333333px #ff0011, 78px -127.3333333333px #ff007b, -114px 40.6666666667px #ff0400, 108px -142.3333333333px #0048ff, 42px -198.3333333333px #ff00cc, -70px -122.3333333333px #001aff, 3px 21.6666666667px #9500ff, 86px -180.3333333333px #00ffb3, 28px -100.3333333333px #8000ff, 24px -206.3333333333px #fb00ff, -46px -104.3333333333px darkorange, -69px -200.3333333333px #0900ff, 99px -98.3333333333px #80ff00, 34px -50.3333333333px #00ff8c, -44px -17.3333333333px #ff00f2, 49px -26.3333333333px #b700ff, -46px -76.3333333333px #00ff1e, 81px -190.3333333333px #00ffdd, 6px 40.6666666667px #8000ff, -104px -168.3333333333px #00aeff, -18px -69.3333333333px #ffee00, 64px -173.3333333333px #9900ff, -101px -109.3333333333px #ff8800, 43px -148.3333333333px #9d00ff, -67px -155.3333333333px #fffb00, -31px -112.3333333333px #ff002b, -27px -195.3333333333px #ff00d9, -86px -149.3333333333px #ff00aa, -8px -104.3333333333px #00ddff, -94px -13.3333333333px #ff1500, -112px -91.3333333333px #0099ff, 89px -198.3333333333px #fff200, 12px -150.3333333333px #2b00ff, 38px -196.3333333333px #00ffa2, 57px -100.3333333333px #ff003c, 112px -59.3333333333px #ffbb00, 117px -131.3333333333px #00fffb, 69px -187.3333333333px #eaff00, -52px -120.3333333333px #0066ff; } }

@keyframes bang {
  to {
    box-shadow: -74px -62.3333333333px #00ff4d, 122px -12.3333333333px #0022ff, 47px 11.6666666667px #ff0088, -95px -183.3333333333px #3300ff, 13px -181.3333333333px #ff003c, -19px -200.3333333333px #ff0055, -98px -194.3333333333px #0dff00, 44px -169.3333333333px #1e00ff, 10px -130.3333333333px #00ff9d, 125px 35.6666666667px #8000ff, -109px -157.3333333333px #0044ff, 65px -170.3333333333px #00ffd0, 107px -207.3333333333px #ff0011, 78px -127.3333333333px #ff007b, -114px 40.6666666667px #ff0400, 108px -142.3333333333px #0048ff, 42px -198.3333333333px #ff00cc, -70px -122.3333333333px #001aff, 3px 21.6666666667px #9500ff, 86px -180.3333333333px #00ffb3, 28px -100.3333333333px #8000ff, 24px -206.3333333333px #fb00ff, -46px -104.3333333333px darkorange, -69px -200.3333333333px #0900ff, 99px -98.3333333333px #80ff00, 34px -50.3333333333px #00ff8c, -44px -17.3333333333px #ff00f2, 49px -26.3333333333px #b700ff, -46px -76.3333333333px #00ff1e, 81px -190.3333333333px #00ffdd, 6px 40.6666666667px #8000ff, -104px -168.3333333333px #00aeff, -18px -69.3333333333px #ffee00, 64px -173.3333333333px #9900ff, -101px -109.3333333333px #ff8800, 43px -148.3333333333px #9d00ff, -67px -155.3333333333px #fffb00, -31px -112.3333333333px #ff002b, -27px -195.3333333333px #ff00d9, -86px -149.3333333333px #ff00aa, -8px -104.3333333333px #00ddff, -94px -13.3333333333px #ff1500, -112px -91.3333333333px #0099ff, 89px -198.3333333333px #fff200, 12px -150.3333333333px #2b00ff, 38px -196.3333333333px #00ffa2, 57px -100.3333333333px #ff003c, 112px -59.3333333333px #ffbb00, 117px -131.3333333333px #00fffb, 69px -187.3333333333px #eaff00, -52px -120.3333333333px #0066ff; } }

@-webkit-keyframes gravity {
  to {
    transform: translateY(75px);
    opacity: 0; } }

@keyframes gravity {
  to {
    transform: translateY(75px);
    opacity: 0; } }
  .match_container .flag-image-country {
    width: 10vh;
    margin-right: 2%;
    height: 60px;
    position: relative; }
    .match_container .flag-image-country img {
      height: 100%;
      width: 100%;
      display: block; }
  .match_container .player_info {
    text-align: center;
    height: 100%;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 auto 3%;
    color: white;
    text-transform: capitalize; }
  .match_container .vote1 {
    width: 100%;
    margin: 3% auto;
    display: flex;
    align-items: center;
    justify-content: center; }
  .match_container :root {
    --line1color: white;
    --line2color: rgb(0, 153, 253);
    --line1width: 0%;
    --line2width: 0%; }
  .match_container .question {
    padding-top: 1%;
    background-color: #08255d;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  .match_container .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 8px;
    position: relative;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 30px black;
    background-image: linear-gradient(45deg, #a2107d, #beb537);
    z-index: 10; }
  .match_container .question h1 {
    text-align: center;
    color: #fcfcfcab; }
  .match_container .question i {
    color: black;
    font-size: 2em;
    margin-left: 0.3em;
    cursor: pointer;
    transition: color 0.2s; }
  .match_container .question i:hover {
    color: blue; }
  .match_container .ans-text {
    text-transform: uppercase; }
  .match_container .poll .bar {
    width: 100%;
    height: 6px;
    border: 2px solid #6b2861;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 9px;
    padding: 2px;
    position: relative; }
  .match_container .bar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background-color: var(--line1color);
    transition: width 0.7s linear;
    border-radius: 2px; }
  .match_container .ans1 .ans-text {
    display: inline;
    color: var(--line1color); }
  .match_container .ans2 .ans-text {
    display: inline;
    color: var(-line2color); }
  .match_container .ans1,
  .match_container .ans2 {
    position: relative; }
  .match_container .poll-content {
    padding: 20px; }
  .match_container .line1 {
    border: 1px solid var(--line1color); }
  .match_container .line1::before {
    width: 0%;
    background-color: var(--line1color);
    transition: width 0.5s linear; }
  .match_container .line2 {
    border: 1px solid var(--line2color); }
  .match_container .line2::before {
    background-color: var(--line2color);
    width: var(--line2width); }
  .match_container .ans1 h4 {
    display: inline;
    position: absolute;
    bottom: 18px;
    right: 0;
    font-size: 16px;
    font-family: sans-serif; }
  .match_container .ans2 h4 {
    display: inline;
    position: absolute;
    bottom: 18px;
    right: 0;
    font-size: 16px;
    font-family: sans-serif; }
  .match_container .totalVotes {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 30px; }
  .match_container .scoreDetails {
    margin-bottom: 1%;
    margin-top: 1%; }
  .match_container .totalVotes h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 500; }

@keyframes blink {
  0% {
    box-shadow: 0 0 15px white; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 15px white; } }

@-webkit-keyframes blink {
  0% {
    box-shadow: 0 0 15px white; }
  50% {
    box-shadow: 0 0 0; }
  100% {
    box-shadow: 0 0 15px white; } }
  .match_container .container {
    -webkit-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite; }
  .match_container .blinkText {
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
    color: red; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes blinker {
  50% {
    opacity: 0; } }
  .match_container #confetti {
    display: none;
    position: absolute;
    left: 0;
    margin-top: 9%;
    height: 100%;
    width: 100%; }
  .match_container .voteText {
    display: flex;
    font-size: 25px;
    font-weight: 900;
    align-items: center;
    justify-content: center;
    padding: 1% 0; }
    .match_container .voteText .voteNow {
      margin-right: 1%;
      width: 18%; }
      .match_container .voteText .voteNow img {
        width: 100%;
        display: block; }
    .match_container .voteText .now {
      margin-right: 1%;
      font-size: 35px;
      color: white;
      font-weight: 900;
      font-family: 'gilroy black'; }
    .match_container .voteText .ex {
      font-size: 40px;
      color: red;
      font-weight: 900;
      font-family: 'gilroy black'; }
  .match_container .namef {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%; }
  .match_container .vote {
    font-size: 25px;
    padding: 2% 12%;
    text-align: center;
    background-color: #00ce0e;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 900;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer; }
  .match_container .vote1 button:disabled {
    background-color: #7cf585 !important;
    cursor: no-drop !important; }
  .match_container #whatsApforMobile {
    display: none; }
  @media (max-width: 1024px) {
    .match_container .punjab {
      width: 88px !important;
      height: 63px !important;
      top: 0;
      left: -12% !important; }
    .match_container .cplFlag {
      width: 179px !important;
      height: 108px !important; }
    .match_container .vote {
      font-size: 21px; }
    .match_container .flag-image-country {
      width: 9vh;
      height: 53px; }
    .match_container .flag-image {
      height: 60px; } }
  .match_container .ninelogo {
    width: 253px !important;
    height: 227px !important; }
  @media (max-width: 768px) {
    .match_container .instaValue {
      flex-direction: column; }
    .match_container .cplFlag {
      left: -75% !important; }
    .match_container .flag-image {
      width: 20%; }
    .match_container .endButton {
      padding: 2% 0 4% 2%; }
    .match_container .flag-image-country {
      width: 9vh;
      height: 50px; }
    .match_container .containerQuestion {
      min-height: 178px; }
    .match_container .center_vs {
      flex: 1 1 4%;
      padding-top: 6%; }
    .match_container .together {
      width: 48%; }
    .match_container .glowLive {
      width: 30%; }
    .match_container .glow {
      font-size: 50px; }
    .match_container .question h1 {
      font-size: 2rem; } }
  @media (min-width: 426px) and (max-width: 450px) {
    .match_container .listButton {
      line-height: 31px;
      letter-spacing: 1px;
      font-size: 13px; }
    .match_container .skills {
      font-size: 15px;
      flex-direction: column; }
      .match_container .skills .skillsValue {
        min-height: 30px;
        overflow: hidden;
        font-size: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
    .match_container .sponser .images {
      width: 90%; }
    .match_container .share-btns {
      flex: 0 1 !important; }
    .match_container .sharing-footer {
      justify-content: center;
      width: 100%; }
    .match_container .muteContainer {
      position: absolute;
      right: 5%;
      top: 5%; }
    .match_container .endShare {
      flex-direction: column; }
    .match_container .voteNow {
      width: 33% !important; }
    .match_container .liveGif {
      width: 31%;
      margin-right: 0;
      margin-left: 0; }
    .match_container .flag-image {
      width: 30% !important;
      height: 50px !important;
      margin-right: 0 !important; }
    .match_container .flagName {
      flex-direction: column; }
      .match_container .flagName .name {
        font-size: 15px; }
    .match_container .images .image img {
      width: 50px !important;
      height: 50px !important; }
    .match_container .vote {
      font-size: 10px !important;
      padding: 4% 8%; }
    .match_container .instaValue {
      font-size: 12px; } }
  @media (max-width: 426px) {
    .match_container .instaValue {
      font-size: 12px; }
    .match_container .listButton {
      line-height: 31px;
      letter-spacing: 1px;
      font-size: 13px; }
    .match_container .congo {
      font-size: 16px; }
    .match_container .nameState {
      margin-top: 12%; }
    .match_container .punjab {
      top: 32% !important;
      width: 55px !important;
      height: 44px !important;
      left: -10% !important; }
    .match_container .i-button {
      margin-top: 5%; }
    .match_container .cplFlag {
      width: 131px !important;
      height: 74px !important;
      left: -93% !important;
      top: 0% !important; }
    .match_container .flag-image {
      width: 25% !important;
      height: 40px !important;
      margin-right: 0 !important; }
    .match_container .skills {
      font-size: 10px;
      flex-direction: column; }
      .match_container .skills .skillsValue {
        min-height: 30px;
        overflow: hidden;
        font-size: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
    .match_container .scoreDetails {
      font-size: 16px; }
    .match_container .flagName .name {
      font-size: 11px; }
    .match_container .flag-image-country {
      width: 6vh !important;
      height: 38px !important; }
    .match_container #whatsApforMobile {
      display: block; }
    .match_container #whatsApforDesktop {
      display: none; }
    .match_container .image img {
      width: 100px !important;
      height: 100px !important; }
    .match_container .match_question {
      font-size: 18px; }
    .match_container .nostart {
      font-size: 18px;
      color: #47fff7; }
    .match_container .match_header {
      font-size: 21px;
      margin-bottom: 0; }
    .match_container .marq {
      width: 100%; }
    .match_container .share-btns {
      flex: 0 1 !important; }
    .match_container .voteNow {
      width: 33% !important; }
    .match_container .sponser .images {
      width: 90%; }
    .match_container .countdown__section {
      padding: 4% 3%; }
    .match_container .countdown__section > .value {
      font-size: 18px; }
    .match_container .countdown__label {
      font-size: 9px;
      padding-top: 10%; }
    .match_container .scoreCard {
      height: 70px;
      width: 70%;
      margin-bottom: 5%; }
      .match_container .scoreCard .score1 * {
        font-size: 20px; }
      .match_container .scoreCard .score2 * {
        font-size: 20px; }
    .match_container .state {
      font-size: 10px !important; }
    .match_container .match_versus {
      width: 90%; }
    .match_container .sharing-footer {
      justify-content: center;
      width: 100%; }
    .match_container .endShare {
      flex-direction: column; }
    .match_container .endButton {
      width: 100%;
      display: flex; }
    .match_container .muteContainer {
      position: absolute;
      right: 5%;
      top: 5%; }
    .match_container .mashaal1 {
      position: absolute; }
    .match_container .vote1 {
      width: 100%; }
    .match_container .vote {
      font-size: 10px !important;
      padding: 4% 8%;
      width: 75%; }
    .match_container .i-button {
      width: 50%; }
    .match_container .liveContain {
      flex-direction: column; }
    .match_container .liveGif {
      width: 31%;
      margin-right: 0;
      margin-left: 0; }
    .match_container .matchTime {
      flex-direction: column; }
    .match_container .together {
      width: 100% !important; }
    .match_container .glowLive {
      width: 100% !important; }
    .match_container .flex {
      padding: 2% 5%; }
    .match_container .center_vs {
      justify-content: center;
      background-image: none; }
    .match_container .glow {
      font-size: 30px; }
    .match_container .container {
      display: none;
      width: 80%;
      top: 90%; }
    .match_container .flagName {
      flex-direction: column; } }
  @media (max-width: 375px) {
    .match_container .cplFlag {
      width: 131px !important;
      height: 74px !important;
      left: -115% !important; } }
  @media (max-width: 321px) {
    .match_container .vote {
      font-size: 8px !important;
      width: 81% !important; }
    .match_container .flagName {
      flex-direction: column;
      justify-content: unset; }
    .match_container .name {
      font-size: 12px; } }
  @media (max-width: 320px) {
    .match_container .cplFlag {
      width: 98px !important;
      height: 70px !important;
      left: -96% !important; } }

.goTo {
  padding: 11px 30px;
  color: white;
  border: none;
  font-size: 15px;
  letter-spacing: 3px;
  background-image: linear-gradient(45deg, #ff3333, #0d0f60);
  box-shadow: 0 10px 6px -6px #777;
  margin-top: 10%;
  cursor: pointer;
  font-weight: 700; }

.matchEnd {
  display: flex;
  flex-direction: column;
  font-size: 26px;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
  text-transform: capitalize; }

.loader-container-new {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fcfcfced; }

.popUp-container {
  position: absolute;
  width: 50%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  font-size: 20px; }
  .popUp-container .tablevote {
    max-height: 400px;
    padding: 1% 0;
    overflow: hidden;
    overflow-y: auto; }
    .popUp-container .tablevote table {
      table-layout: fixed; }
  .popUp-container .closeVote {
    width: 4%;
    margin-left: 99%;
    cursor: pointer; }
    .popUp-container .closeVote img {
      width: 100%;
      display: block; }
  .popUp-container .srNo {
    flex: 1 1; }
  .popUp-container .player1 {
    flex: 1 1; }
  .popUp-container .player2 {
    flex: 1 1; }
  .popUp-container thead th {
    background: linear-gradient(#d6369d, #08255d); }
  .popUp-container caption {
    font-weight: bold;
    padding: 10px;
    background: linear-gradient(#d6369d, #08255d); }
  .popUp-container .customer-table {
    margin: 0 auto;
    box-shadow: 0 2px 2px #a9a9a9; }

.centerPop {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@media (max-width: 426px) {
  .popUp-container {
    width: 80%; }
  .closeVote {
    width: 12% !important; }
  #loader-new {
    margin: -30px 0 0 -28px; }
  caption {
    font-size: 15px; }
  th {
    font-size: 12px; } }

caption {
  font-weight: bold;
  padding: 10px;
  color: white;
  background: linear-gradient(#d6369d, #08255d); }

caption p {
  color: white !important; }

thead {
  color: white; }

.customer-table {
  font-size: 0.625em;
  font-family: "Noto Sans KR", sans-serif;
  width: 100%;
  max-width: 1024px;
  margin: 20px auto;
  border-collapse: collapse;
  text-align: center;
  box-shadow: 0 2px 2px #e6e6e6; }

.matchNamePopup * {
  text-transform: uppercase;
  color: white !important;
  text-decoration: none !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: sans-serif !important;
  font-style: normal !important; }

.muteContainer {
  display: flex; }
  .muteContainer .speaker {
    height: 30px;
    width: 30px;
    position: relative;
    overflow: hidden;
    display: inline-block; }
    .muteContainer .speaker span {
      display: block;
      width: 8px;
      height: 8px;
      background: #fff;
      margin: 11px 0 0 2px; }
      .muteContainer .speaker span:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent #fff transparent transparent;
        border-width: 10px 14px 10px 15px;
        left: -13px;
        top: 5px; }
      .muteContainer .speaker span:before {
        transform: rotate(45deg);
        border-radius: 0 50px 0 0;
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-style: double;
        border-color: #fff;
        border-width: 7px 7px 0 0;
        left: 18px;
        top: 9px;
        transition: all 0.2s ease-out; }
    .muteContainer .speaker:hover span:before {
      transform: scale(0.8) translate(-3px, 0) rotate(42deg); }
    .muteContainer .speaker.mute span:before {
      transform: scale(0.5) translate(-15px, 0) rotate(36deg);
      opacity: 0; }

.createMatch {
  margin: 0 auto;
  width: 90%;
  background: aliceblue;
  padding-top: 2%; }
  .createMatch .checkboxes {
    display: flex;
    width: 83.33%;
    margin: 1% auto 0; }
    .createMatch .checkboxes #isGuestMatch {
      zoom: 1.5; }
    .createMatch .checkboxes #isShowVoters {
      zoom: 1.5; }
  .createMatch .cpl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-bottom: 4%;
    margin: 25px auto 0; }
    .createMatch .cpl label {
      font-size: 20px !important;
      margin-left: 1%; }
  .createMatch #isCPL {
    zoom: 1.5; }
  .createMatch .timePicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 4% auto; }
    .createMatch .timePicker .chooseTime {
      width: 50%;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      position: relative;
      color: #000000cc; }
  .createMatch .loader-container-new {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: #fcfcfcbf; }
  .createMatch #loader-new {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -37px 0 0 -50px;
    border: 10px solid #08255d;
    border-radius: 50%;
    border-top: 10px solid #d6011f;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px #ba2e2e; }
  .createMatch .audio-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%; }
  .createMatch .uploadAudio {
    margin-top: 1%; }
  .createMatch #dis:disabled {
    background: red !important;
    opacity: 0.4 !important;
    cursor: not-allowed !important; }
  .createMatch .disabledUploadButton {
    opacity: 0.6;
    cursor: not-allowed !important;
    background-color: #5982f7;
    box-shadow: 0 6px #3e0ae0;
    transition: none; }
    .createMatch .disabledUploadButton:hover {
      box-shadow: 0 6px #3e0ae0 !important;
      top: unset !important; }
  .createMatch .disabledd:disabled {
    background-color: red; }
  .createMatch .question_style {
    text-align: left;
    width: 83.33%;
    box-shadow: 0 10px 6px -6px #777;
    margin: 25px auto 0; }
  .createMatch .header_style input {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    width: 100%;
    height: unset;
    padding: 10px 10px 10px 10px; }
  .createMatch .header_style {
    text-align: center;
    background-color: #5982f7;
    width: 83.33%;
    box-shadow: 0 10px 6px -6px #777;
    margin: 0 auto; }
  .createMatch .header_style label {
    font-size: 2.5em;
    color: white;
    font-weight: 600; }
  .createMatch .vs-box {
    display: flex;
    flex-direction: row;
    margin-top: 3%; }
  .createMatch .vs-left {
    width: 48%;
    align-items: center;
    display: flex;
    background-color: #f5f5f563;
    flex-direction: column;
    margin-right: 4%;
    padding-top: 2%;
    box-shadow: 2px 4px 22px -6px #62656a;
    padding-bottom: 2%;
    border: 1px solid lightgrey;
    border-radius: 10px; }
  .createMatch .vs-right {
    width: 48%;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f563;
    padding-top: 2%;
    box-shadow: 2px 4px 22px -6px #62656a;
    padding-bottom: 2%;
    border: 1px solid lightgrey;
    border-radius: 10px; }
  .createMatch .preview {
    width: 70%;
    padding: 2%;
    height: 300px;
    border: 1px solid green;
    margin-bottom: 5%;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60); }
  .createMatch .preview-right {
    width: 70%;
    padding: 2%;
    height: 300px;
    border: 1px solid green;
    margin-bottom: 5%;
    background-image: linear-gradient(45deg, #0d0f60, #ff3333); }
  .createMatch .preview video {
    width: 100%;
    height: 100%; }
  .createMatch .preview-right video {
    width: 100%;
    height: 100%; }
  .createMatch .hidden {
    display: none; }
  .createMatch .labell {
    font-size: 1.5em;
    color: #000000cc;
    font-weight: 600; }
  .createMatch .inputt {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    width: 100%;
    height: unset;
    padding: 8px 8px 8px 9px; }
  .createMatch .player1 {
    margin-bottom: 4%; }
  .createMatch .player1 input:focus {
    border: 1px solid #b3aaaa !important; }
  .createMatch .state1 input:focus {
    border: 1px solid #b3aaaa !important; }
  .createMatch .player2 {
    margin-bottom: 4%; }
  .createMatch .player2 input:focus {
    border: 1px solid #b3aaaa !important; }
  .createMatch .state2 input:focus {
    border: 1px solid #b3aaaa !important; }
  .createMatch .submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    padding-bottom: 2%; }
  .createMatch .submit-button input {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    background-color: red;
    border: none;
    border-bottom: 2px solid darkred;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    padding: 13px 32px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer; }
  .createMatch #dis[disabled="disabled"],
  .createMatch #dis:disabled {
    background: #dddddd; }
  .createMatch .skills {
    margin-bottom: 4%; }
  .createMatch .box1 {
    display: block;
    width: 70%;
    max-width: 900px;
    margin: 1% auto;
    padding: 1%; }
  .createMatch .box1 h1 {
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    color: #000000cc; }
  .createMatch .box1 textarea {
    border: 1px solid #ddd !important;
    width: 100%;
    color: #66615b;
    font-size: 14px !important;
    min-height: 8rem;
    padding: 1rem 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    background: #ffffff;
    border-radius: 1rem;
    transition: ease 0.2s; }
  .createMatch .box1 textarea::-webkit-input-placeholder {
    color: #9fa8da; }
  .createMatch .box1 textarea:-ms-input-placeholder {
    color: #9fa8da; }
  .createMatch .box1 textarea::-ms-input-placeholder {
    color: #9fa8da; }
  .createMatch .box1 textarea::placeholder {
    color: #9fa8da; }
  .createMatch .box1 textarea:focus {
    border: 1px solid #b3aaaa !important; }
  .createMatch textarea:-ms-input-placeholder {
    border: 1px solid #ddd; }
  .createMatch textarea:placeholder-shown {
    border: 1px solid #ddd; }
  .createMatch [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0; }
  .createMatch [type="file"] + label {
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-weight: 600;
    /* margin-bottom: 1rem; */
    outline: none;
    padding: 1rem 23px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle; }
    .createMatch [type="file"] + label.btn-1 {
      background-color: #5982f7;
      box-shadow: 0 6px #3e0ae0;
      transition: none; }
      .createMatch [type="file"] + label.btn-1:hover {
        box-shadow: 0 4px #100e8e;
        top: 2px; }
  .createMatch .country label {
    font-size: 1.4em;
    color: #000000cc;
    font-weight: 600; }
  .createMatch .country select {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    width: 100%;
    height: unset;
    padding: 8px 8px 8px 9px; }
  .createMatch .widthSame {
    width: 70%;
    margin-bottom: 4%; }
  .createMatch .audio-file {
    margin-top: 2%; }
  .createMatch #audioPlayer {
    width: 565px;
    background-color: #f1f3f4;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1); }
  .createMatch #audioPlayer:before,
  .createMatch .effect8:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 10px;
    bottom: 10px;
    left: 0;
    right: 0;
    border-radius: 100px / 10px; }
  .createMatch #audioPlayer:after {
    right: 10px;
    left: auto;
    transform: skew(8deg) rotate(3deg); }

@media (max-width: 426px) {
  .checkboxes {
    flex-direction: column; }
  #audioPlayer {
    width: 245px; }
  .vs-box {
    display: flex;
    flex-direction: column !important;
    margin-top: 3%; }
  .vs-left {
    width: 100% !important;
    padding-bottom: 6% !important;
    margin-bottom: 10%;
    padding-top: 5%; }
  .vs-right {
    width: 100% !important;
    padding-bottom: 6% !important;
    padding-top: 5%; }
  [type="file"] + label {
    font-size: 13px;
    padding: 9px 13px; }
  .submit-button input {
    font-size: 13px;
    padding: 10px 24px; }
  .timePicker {
    flex-direction: column !important; }
  .chooseTime {
    width: 100% !important;
    margin-bottom: 2%; } }

.popUpforMatch {
  height: 500px;
  width: 90%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.copyUrl {
  text-align: center;
  font-size: 1.5rem;
  padding-right: 2%;
  font-weight: 900;
  text-transform: uppercase; }

.matchButton {
  padding: 11px 30px;
  color: white;
  border: none;
  font-size: 15px;
  letter-spacing: 3px;
  background-image: linear-gradient(45deg, #ff3333, #0d0f60);
  box-shadow: 0 10px 6px -6px #777;
  margin-bottom: 2%;
  cursor: pointer; }

.pathOne {
  text-align: center;
  padding-top: 1%;
  font-size: 2rem;
  padding-bottom: 1%;
  cursor: pointer; }

.urll {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
  width: 90%; }

.rdw-editor-wrapper {
  background-color: white; }

.rdw-editor-main {
  padding: 0 2%;
  font-size: 15px; }

.sharing-footer {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background: inherit; }

.sharing-footer .line {
  margin-top: 5px;
  height: 1px;
  width: 80px; }

.sharing-footer .share-prompt {
  margin-right: 30px; }

.sharing-footer .share-prompt .text {
  font-size: 16px; }

.sharing-footer .share-btns {
  display: flex;
  flex: 1 1; }

.sharing-footer .share-btns .share-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  width: 30px;
  cursor: pointer; }

.sharing-footer .share-btns .share-btn-container:last-child {
  margin-right: 0; }

.sharing-footer .share-btns i {
  font-size: 36px; }

.sharing-footer .share-btns .underline {
  height: 2px;
  width: 0%;
  transition: width 0.1s ease-out; }

.sharing-footer .share-btns .share-btn-container:hover .underline {
  width: 100%;
  transition: width 0.1s ease-out; }

.sharing-footer .up-next .label {
  font-weight: 800; }

.sharing-footer .up-next .next-article {
  text-transform: uppercase; }

.sharing-footer .up-next .line {
  float: right; }

/* Popup container - can be anything you want */
.popup {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  bottom: 63%;
  left: 46%; }

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s; }

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.allMatches-container {
  display: flex;
  width: 90%;
  margin: auto;
  /* Table */
  /* Media Queries */ }
  .allMatches-container .votersList {
    background: linear-gradient(#d6369d, #08255d);
    color: white;
    font-size: 18px;
    letter-spacing: 2px; }
  .allMatches-container .namePhone {
    background: linear-gradient(#d6369d, #08255d);
    color: white; }
  .allMatches-container .matchNamePopup * {
    text-transform: uppercase;
    color: white !important;
    text-decoration: none !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-family: sans-serif !important;
    font-style: normal !important; }
  .allMatches-container .showVotes {
    background-color: #3b841d !important; }
  .allMatches-container .popUp-container {
    position: absolute;
    width: 50%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    font-size: 20px; }
    .allMatches-container .popUp-container .tablevote {
      max-height: 400px;
      padding: 1% 0;
      overflow: hidden;
      overflow-y: auto; }
      .allMatches-container .popUp-container .tablevote table {
        table-layout: fixed; }
    .allMatches-container .popUp-container .closeVote {
      width: 4%;
      margin-left: 99%;
      cursor: pointer; }
      .allMatches-container .popUp-container .closeVote img {
        width: 100%;
        display: block; }
    .allMatches-container .popUp-container .srNo {
      flex: 1 1; }
    .allMatches-container .popUp-container .player1 {
      flex: 1 1; }
    .allMatches-container .popUp-container .player2 {
      flex: 1 1; }
    .allMatches-container .popUp-container thead th {
      background: linear-gradient(#d6369d, #08255d); }
    .allMatches-container .popUp-container caption {
      font-weight: bold;
      padding: 10px;
      background: linear-gradient(#d6369d, #08255d); }
    .allMatches-container .popUp-container .customer-table {
      margin: 0 auto;
      box-shadow: 0 2px 2px #a9a9a9; }
  .allMatches-container .centerPop {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .allMatches-container .matchName * {
    text-transform: uppercase;
    color: black !important;
    text-decoration: none !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-family: sans-serif !important;
    font-style: normal !important; }
  .allMatches-container .notStartedLive {
    width: 100%;
    display: flex;
    flex-direction: column; }
  .allMatches-container .loader-container-new {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #fcfcfced; }
  .allMatches-container #loader-new {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    border: 5px solid #08255d;
    border-radius: 50%;
    border-top: 5px solid #d6011f;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px #ba2e2e; }
  .allMatches-container .delete {
    padding: 9px 18px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    background-color: red;
    box-shadow: 0 10px 6px -6px #777;
    cursor: pointer; }
  .allMatches-container .table1 {
    width: 100%; }
  .allMatches-container .table1 th {
    text-align: center; }
  .allMatches-container .centerText {
    text-align: center; }
  .allMatches-container .slider.round {
    border-radius: 34px; }
  .allMatches-container .slider.round:before {
    border-radius: 50%; }
  .allMatches-container .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px; }
  .allMatches-container .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .allMatches-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s; }
  .allMatches-container .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s; }
  .allMatches-container input:checked + .slider {
    background-color: #2196F3; }
  .allMatches-container input:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }
  .allMatches-container input:checked + .slider:before {
    transform: translateX(26px); }
  .allMatches-container .slider.round {
    border-radius: 34px; }
  .allMatches-container .slider.round:before {
    border-radius: 50%; }
  .allMatches-container .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px; }
  .allMatches-container .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .allMatches-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s; }
  .allMatches-container .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s; }
  .allMatches-container input:checked + .slider {
    background-color: #2196F3; }
  .allMatches-container input:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }
  .allMatches-container input:checked + .slider:before {
    transform: translateX(26px); }
  .allMatches-container .noLiveData {
    padding: 2% 2%;
    font-size: 20px; }
  .allMatches-container .table {
    overflow-x: auto; }
  .allMatches-container .customer-table {
    font-size: 0.625em;
    font-family: "Noto Sans KR", sans-serif;
    width: 100%;
    max-width: 1024px;
    margin: 20px auto;
    border-collapse: collapse;
    text-align: center;
    box-shadow: 0 2px 2px #e6e6e6; }
  .allMatches-container caption {
    font-weight: bold;
    padding: 10px;
    background: linear-gradient(#3682d6, #08255d);
    font-size: 21px;
    letter-spacing: 1px; }
  .allMatches-container thead {
    font-size: 15px; }
  .allMatches-container thead th {
    background: linear-gradient(#3682d6, #08255d); }
  .allMatches-container caption,
  .allMatches-container thead th {
    color: #fff; }
  .allMatches-container th {
    padding: 10px 5px;
    font-size: 14px; }
  .allMatches-container tfoot {
    text-align: left;
    background-color: #e7dfd5;
    font-weight: lighter;
    font-size: 0.8em; }
  .allMatches-container tbody th,
  .allMatches-container tbody td {
    text-align: center; }
  .allMatches-container tbody tr {
    border-bottom: 2px solid transparent; }
  .allMatches-container tbody tr:hover {
    border-bottom: 2px solid #174485; }
  .allMatches-container tbody tr:nth-of-type(even) {
    background-color: #e7dfd5; }
  .allMatches-container .go-to-match {
    padding: 9px 18px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    background-color: black;
    box-shadow: 0 10px 6px -6px #777;
    cursor: pointer;
    margin: 5px; }
  @media (max-width: 320px) {
    .allMatches-container .customer-table {
      font-size: 0.55em; } }
  @media (min-width: 411px) {
    .allMatches-container .customer-table {
      font-size: 0.71875em;
      /*11.5px*/ } }
  @media (max-width: 426px) {
    .allMatches-container .closeVote {
      width: 12% !important; }
    .allMatches-container #loader-new {
      margin: -30px 0 0 -28px; }
    .allMatches-container caption {
      font-size: 15px; }
    .allMatches-container th {
      font-size: 12px; } }
  @media (min-width: 768px) {
    .allMatches-container body {
      font-size: 1.125em;
      /*16px*/ }
    .allMatches-container caption {
      padding: 20px; } }
  @media (min-width: 1024px) {
    .allMatches-container body {
      font-size: 1.25em;
      /*20px*/ } }

.errorApi {
  position: fixed;
  z-index: 99999;
  top: 13%;
  left: 0;
  right: 0;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 800;
  color: #f5eeee;
  background-color: #333c;
  padding: 1% 3%;
  margin: auto; }

.noData {
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
  color: red;
  font-size: 24px; }

.upload-container {
  width: 35%;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  flex-direction: column;
  background-color: white; }
  .upload-container .uploadText {
    padding: 5%;
    font-size: 25px;
    font-weight: 900; }
  .upload-container .uploadText span {
    letter-spacing: 1px; }
  .upload-container .uploadImage {
    width: 50%;
    padding: 2%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto; }
  .upload-container .uploadImage img {
    object-fit: contain;
    width: 100%; }
  .upload-container .uploadButton {
    text-align: center;
    margin-top: 10%; }
  .upload-container .uploadButton button {
    background-color: #1f9cdf;
    border: none;
    color: white;
    width: 90%;
    margin-bottom: 2%;
    padding: 3% 0%;
    border-radius: 4px;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 700; }

.userProfile-container {
  display: flex;
  background-color: white;
  width: 100%;
  padding: 3%;
  flex-direction: row; }
  .userProfile-container .userInfo {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: 4%;
    padding-top: 2%; }
  .userProfile-container .userInfo-One {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    padding-bottom: 1%; }
  .userProfile-container .userInfor-two {
    display: flex;
    flex-direction: row; }
  .userProfile-container .basicc {
    font-size: 12px;
    color: #808080a6;
    flex: 1 1 2%;
    border-right: 1px solid;
    text-align: center; }
  .userProfile-container .basiccTwo {
    color: black;
    font-size: 18px; }
  .userProfile-container .userName {
    font-size: 25px;
    font-weight: 900;
    color: #08255d;
    padding-right: 2%; }
  .userProfile-container .userImage {
    width: 15vw;
    height: 15vw;
    padding: 0%;
    border-radius: 100%;
    border: 1px solid #808080b3;
    overflow: hidden; }
  .userProfile-container .userImage img {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .userProfile-container .userId {
    font-size: 15px;
    margin-left: 5%;
    background-color: #18bda6;
    color: white;
    padding: 1% 4%;
    align-items: center;
    justify-content: center;
    display: flex; }

.profile-container {
  background-color: #ebedf0; }
  .profile-container .partTwo {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    margin-top: 5%; }
  .profile-container .upload-container {
    width: 100%; }
  .profile-container .uploadV {
    margin-right: 2%; }

.liveScore-container {
  width: 100%;
  background-image: url("/static/media/top_searche_sbg.62cad5f8.png");
  height: 330px; }
  .liveScore-container .viewAll {
    color: white;
    font-size: 20px;
    display: flex; }
  .liveScore-container .liveScoreInner {
    margin: 3% 3%;
    color: black; }
    .liveScore-container .liveScoreInner .heading {
      margin-bottom: 1%;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
      flex: 1 1;
      font-family: 'gilroy black';
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: white; }
    .liveScore-container .liveScoreInner .fullScoreCard {
      width: 100%;
      height: 190px;
      background-color: #ffffffc7;
      padding: 1% 1%;
      border-radius: 5px;
      border: 10px double  #ffffffc7;
      background-clip: padding-box; }
      .liveScore-container .liveScoreInner .fullScoreCard .firstRow {
        display: flex;
        margin-bottom: 1%; }
        .liveScore-container .liveScoreInner .fullScoreCard .firstRow .live {
          color: red;
          font-size: 20px;
          font-weight: 800;
          letter-spacing: 1px;
          flex: 1 1;
          display: flex; }
          .liveScore-container .liveScoreInner .fullScoreCard .firstRow .live .dot {
            width: 3%;
            height: 16px; }
            .liveScore-container .liveScoreInner .fullScoreCard .firstRow .live .dot img {
              width: 100%;
              height: 100%; }
          .liveScore-container .liveScoreInner .fullScoreCard .firstRow .live .text {
            margin-left: 2%; }
        .liveScore-container .liveScoreInner .fullScoreCard .firstRow .Today {
          background-color: green;
          color: white;
          align-items: center;
          justify-content: center;
          display: flex;
          padding: 1% 2%;
          border-radius: 5px;
          letter-spacing: 1px;
          font-size: 12px;
          font-weight: 800; }
      .liveScore-container .liveScoreInner .fullScoreCard .secondRow {
        display: flex;
        margin-bottom: 1%;
        align-items: center;
        justify-content: center; }
      .liveScore-container .liveScoreInner .fullScoreCard .logo {
        width: 6%; }
        .liveScore-container .liveScoreInner .fullScoreCard .logo img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
      .liveScore-container .liveScoreInner .fullScoreCard .player {
        font-size: 20px;
        align-items: center;
        display: flex;
        margin-left: 2%;
        font-weight: 900;
        font-family: sans-serif; }
      .liveScore-container .liveScoreInner .fullScoreCard .vs {
        font-size: 15px;
        align-items: center;
        display: flex;
        margin-left: 2%;
        font-weight: 900; }
      .liveScore-container .liveScoreInner .fullScoreCard .score {
        font-size: 20px;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        width: 44%; }
      .liveScore-container .liveScoreInner .fullScoreCard .thirdRow {
        display: flex; }
      .liveScore-container .liveScoreInner .fullScoreCard .fourthRow {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center; }
  @media (max-width: 768px) {
    .liveScore-container .firstRow {
      margin-bottom: 7% !important; }
    .liveScore-container .marginSet {
      margin-bottom: 2% !important; }
    .liveScore-container .fullScoreCard {
      height: 238px !important; } }
  @media (max-width: 426px) {
    .liveScore-container .secondRow {
      flex-direction: column; }
    .liveScore-container .firstRow {
      margin-bottom: 10% !important; }
    .liveScore-container .firstRow .live {
      font-size: 12px !important; }
    .liveScore-container .player {
      font-size: 15px !important;
      margin-left: 0% !important; }
    .liveScore-container .fourthRow {
      font-size: 15px !important;
      text-align: center; }
    .liveScore-container .fullScoreCard {
      height: 250px !important; } }

.fullScore-container {
  width: 60%;
  margin: 5% auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.089); }
  .fullScore-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.479); }
  .fullScore-container .headingScore {
    background-color: rgba(0, 0, 0, 0.061);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2%; }
    .fullScore-container .headingScore .headingText {
      font-size: 34px;
      font-weight: 900;
      color: #0b287a; }
    .fullScore-container .headingScore .cricImage {
      width: 4%;
      margin-left: 1%; }
      .fullScore-container .headingScore .cricImage img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%; }
  .fullScore-container .scoreImage {
    padding: 0 4%; }
    .fullScore-container .scoreImage img {
      width: 100%;
      height: 100%;
      display: block; }
  .fullScore-container .scoreFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7%;
    font-size: 20px;
    color: red;
    font-weight: 900; }
  @media (max-width: 768px) {
    .fullScore-container {
      width: 80%; }
      .fullScore-container .cricImage {
        width: 6% !important;
        margin-left: 2% !important; } }
  @media (max-width: 450px) {
    .fullScore-container {
      width: 95%; }
      .fullScore-container .scoreFooter {
        font-size: 10px; }
      .fullScore-container .headingText {
        font-size: 20px !important; }
      .fullScore-container .cricImage {
        width: 7% !important;
        margin-left: 2% !important; } }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.excel {
    height: 100vh;
    width: 100%;
}
/* Navbar */
.Menu {
    margin: 5rem 6rem;
}
.border {
    border: .2rem solid #c60f5e; 
    border-radius: 5px;
    padding: 0 2rem;
}
.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
}
.Navbar2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
}
.navItems a {
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 5px;
    transition: 100ms;
    font-weight: bold;
    color: black;
    letter-spacing: 1px;
}
.navItems a:hover {
    border: .2rem solid #c60f5e; 
    padding: 2px 5px;
    font-size: 1.6rem;
}
.navItems {
    display: flex;
    justify-content: space-around;
}
.Heading {
    padding: 5rem 8rem 4rem 8rem;
}
.Heading h1 {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4rem;
}
.Heading h5 {
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
    color: rgb(116, 116, 116);
    margin-bottom: 1rem;
}
.Heading hr {
    width: 100%;
}
/* Telegram Links */

.TelegramLinks {
    padding: 5rem 2rem;
}
.TelegramLinks hr {
    width: 25%;
    background-color: #01579b;
}
.componentStyling {
    padding: 0 2rem 0 2rem;
}
.componentStyling hr {
    background-color: #c60f5e;
}
.round {
    border-radius: 8px;
}
h1 {
letter-spacing: 1px;
}
hr {
    height: 3px;
    width: 33%;
    background-color:#c60f5e;
    margin-bottom: 1rem;
    border: none;
}
.TelegramLinks h5 {
    letter-spacing: 1px;
    color: rgb(116, 116, 116);
    margin-bottom: 1rem;
}

/* Media Query */

@media (max-width: 1024px) {
    html {
        font-size: 50%;
    }
    hr {
        width: 38%;
    }
    .TelegramLinks hr {
        width: 28%;
    }
}
@media (max-width: 768px) {
    html {
        font-size: 40%;
    }
    hr {
        width: 40%;
    }
    .TelegramLinks hr {
        width: 30%;
    }
}
@media (max-width: 600px) {
    html {
        font-size: 30%;
    }
    hr {
        width: 45%;
    }
    .TelegramLinks hr {
        width: 35%;
    }
}
@media (max-width: 480px) {
    html {
        font-size: 20%;
    }
    hr {
        width: 35%;
    }
    .Heading h1 {
        font-size: 6rem;
    }
    .Heading h5 {
        font-size: 2rem;
    }
    .TelegramLinks hr {
        width: 25%;
    }
}
@media (max-width: 340px) {
    html {
        font-size: 15%;
    }
    .Heading h1 {
        font-size: 6rem;
    }
    .Heading h5 {
        font-size: 2.5rem;
    }
    .TelegramLinks h1, .componentStyling h1 {
        font-size: 4rem;
    }
    hr {
        width: 57%;
    }
    .TelegramLinks hr {
        width: 40%;
    }
}

.cricview-register-container {
  width: 100%; }
  .cricview-register-container .forgetPassword {
    color: white;
    margin-top: 8%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer; }
    .cricview-register-container .forgetPassword:hover {
      color: #d0d3a9; }
  .cricview-register-container .centerError {
    text-align: center; }
  .cricview-register-container .emailLog {
    margin-top: 11%;
    text-align: center;
    width: 100%;
    padding: 4% 2%;
    font-size: 17px;
    font-weight: bold;
    background-color: black;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer; }
    .cricview-register-container .emailLog:hover {
      background-color: rgba(0, 0, 0, 0.76);
      color: wheat; }
  .cricview-register-container .emailForm {
    width: 50%;
    position: relative;
    padding: 0% 2% 5% 2%;
    color: #212121; }
  .cricview-register-container .input-cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%; }
    .cricview-register-container .input-cont label {
      color: white;
      font-size: 17px; }
    .cricview-register-container .input-cont input {
      height: 30px;
      font-size: 16px; }
  .cricview-register-container .input-cont input:focus {
    outline: none; }
  .cricview-register-container .loginWith {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center; }
    .cricview-register-container .loginWith .textOr {
      color: white; }
    .cricview-register-container .loginWith .emailLogin {
      height: 50px;
      color: white;
      margin: 2%;
      background: #df2828;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      letter-spacing: 1px; }
  .cricview-register-container .cricview-register-wrapper {
    background-color: #fefefe;
    background-image: linear-gradient(45deg, darkblue, darkred);
    margin: 0 auto;
    border: 1px solid #888;
    border-radius: 5px;
    width: 560px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .cricview-register-container .login-wrapper {
    margin: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
  .cricview-register-container .welcome-class {
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 1%;
    color: whitesmoke; }
  .cricview-register-container .form-class {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 3%;
    color: whitesmoke; }
  .cricview-register-container .register-login-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    color: whitesmoke; }
  .cricview-register-container .otp-message {
    font-size: 20px; }
  .cricview-register-container .input-container {
    margin: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cricview-register-container .input-otp {
    border: none;
    outline: none;
    height: 40px;
    width: 20%;
    padding: 0 5px;
    border-radius: 5px;
    background-color: whitesmoke;
    font-weight: bold;
    border: 1px solid black;
    margin: 2%; }
  .cricview-register-container .input {
    border: none;
    outline: none;
    height: 40px;
    width: 60%;
    font-size: 16px;
    padding: 0 5px;
    border-radius: 5px;
    background-color: whitesmoke;
    font-weight: bold;
    border: 1px solid black; }
  .cricview-register-container .role-dropdown {
    display: flex;
    width: 60%;
    padding: 2% 0; }
  .cricview-register-container .role-value {
    background-color: whitesmoke;
    width: 100%;
    margin: 0 15px 0 10px;
    border-radius: 5px; }
    .cricview-register-container .role-value span {
      background-color: grey;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
      border-bottom: 1px solid; }
  .cricview-register-container .role {
    font-size: 16px;
    color: whitesmoke;
    padding: 8px; }
  .cricview-register-container .role-list {
    display: flex;
    flex-direction: column; }
  .cricview-register-container .role-list-item {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #000; }
    .cricview-register-container .role-list-item:hover {
      background-color: #333;
      color: white; }
  .cricview-register-container .dropbtn {
    background-color: white;
    color: black;
    padding: 8px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
  .cricview-register-container .dropdown {
    position: relative;
    display: inline-block; }
  .cricview-register-container .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; }
  .cricview-register-container .dropdown-content li {
    color: black;
    padding: 6px 8px;
    text-decoration: none;
    display: block;
    cursor: pointer; }
  .cricview-register-container .dropdown-content li:hover {
    background-color: #000000;
    color: #fff;
    border-radius: 5px; }
  .cricview-register-container .dropdown:hover .dropdown-content {
    display: block;
    display: block;
    padding: 0;
    border-radius: 5px; }
  .cricview-register-container .dropdown:hover .dropbtn {
    background-color: #000;
    color: white; }
  .cricview-register-container .login-button {
    background-color: black;
    height: 40px;
    color: white;
    margin: 2%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer; }
    .cricview-register-container .login-button:hover {
      opacity: 0.8; }
  .cricview-register-container .fbb-login-button {
    background-color: black;
    height: 50px;
    color: white;
    margin: 2%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer; }
    .cricview-register-container .fbb-login-button:hover {
      opacity: 0.8; }
  .cricview-register-container .close-login {
    position: absolute;
    right: 25px;
    top: 0;
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 4%; }
  .cricview-register-container .dont-register {
    color: black;
    font-size: 14px; }
  .cricview-register-container .signup-link {
    text-decoration: underline; }
  .cricview-register-container .show {
    display: flex; }
  .cricview-register-container .otp-wrapper {
    margin: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5% 0;
    font-size: 16px; }
  .cricview-register-container .otp-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0; }
  .cricview-register-container .otp-message {
    color: whitesmoke; }
  .cricview-register-container .verify-otp {
    background-color: black;
    height: 40px;
    color: white;
    margin: 2%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer; }
    .cricview-register-container .verify-otp:hover {
      opacity: 0.8; }
  .cricview-register-container .hide {
    display: none; }
  .cricview-register-container .error {
    color: red;
    font-size: 12px; }
  .cricview-register-container .countrycode {
    width: 10%; }
  .cricview-register-container .mobilenumber {
    width: 50%; }
  .cricview-register-container .account {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center; }
  .cricview-register-container .account-change {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 10px; }
  @media (max-width: 726px) {
    .cricview-register-container {
      height: 100%; }
      .cricview-register-container .cricview-register-wrapper {
        width: 90%;
        height: 80%;
        margin-bottom: 5%;
        margin-top: 5%; }
      .cricview-register-container .otp-wrapper {
        height: 200px; }
      .cricview-register-container .input {
        width: 100%; }
      .cricview-register-container .countrycode {
        width: 20%; }
      .cricview-register-container .mobilenumber {
        width: 80%; }
      .cricview-register-container .role-dropdown {
        width: 100%;
        height: 120px; }
      .cricview-register-container .role-value {
        height: 40px; }
      .cricview-register-container .role-list {
        background-color: whitesmoke;
        color: grey; }
      .cricview-register-container .login-button {
        width: 100%;
        margin-top: 10%; }
      .cricview-register-container .fbb-login-button {
        width: 100%;
        margin-top: 10%; }
      .cricview-register-container .otp-message {
        color: whitesmoke; }
      .cricview-register-container .otp-div {
        color: whitesmoke;
        font-size: 14px;
        padding-right: 10px; }
      .cricview-register-container .verify-otp {
        width: 75%; } }
  @media (max-width: 450px) {
    .cricview-register-container .account {
      font-size: 15px; }
    .cricview-register-container .emailForm {
      width: 100%; }
    .cricview-register-container .loginWith {
      flex-direction: column; }
      .cricview-register-container .loginWith .emailLogin {
        width: 100%; } }
  @media (width: 320px) {
    .cricview-register-container {
      height: 100%; }
      .cricview-register-container .cricview-register-wrapper {
        width: 90%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-top: 6.5%; }
      .cricview-register-container .otp-wrapper {
        height: 372px; }
      .cricview-register-container .input {
        width: 100%; }
      .cricview-register-container .countrycode {
        width: 20%; }
      .cricview-register-container .mobilenumber {
        width: 80%; }
      .cricview-register-container .role-dropdown {
        width: 100%;
        height: 130px; }
      .cricview-register-container .role-value {
        height: 40px; }
      .cricview-register-container .role-list {
        background-color: whitesmoke;
        color: grey; }
      .cricview-register-container .login-button {
        width: 100%;
        margin-top: 10%; }
      .cricview-register-container .fbb-login-button {
        width: 100%;
        margin-top: 10%; }
      .cricview-register-container .otp-message {
        color: whitesmoke; }
      .cricview-register-container .otp-div {
        color: whitesmoke;
        font-size: 14px;
        padding-right: 10px; }
      .cricview-register-container .verify-otp {
        width: 75%; } }


.play-box {
  margin: 10px;
  height: 100%;
  display: flex;
  border: 15px double #08255d;
  align-items: center;
  background-clip: padding-box;
  flex-direction: column;
  justify-content: center;
  background-color: #08255d; }
  .play-box .flexx {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center; }
  .play-box .part1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%; }
  .play-box .part2 {
    display: flex;
    align-items: center;
    justify-content: center; }
  .play-box .welcome {
    margin: 5% auto;
    color: white;
    display: flex;
    flex-direction: row;
    width: 100%; }
  .play-box .welcome h1 {
    letter-spacing: 8px; }
  .play-box .welcomme-inner1 {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .play-box .welcomme-inner2 {
    display: flex;
    flex-direction: column;
    /* width: 75%; */
    margin-left: 14%;
    align-items: center;
    justify-content: center; }
  .play-box .flexx {
    margin: 5% auto; }
  .play-box .btn,
  .play-box a.btn {
    display: inline-block;
    text-transform: none;
    cursor: pointer;
    text-decoration: none;
    line-height: 1em;
    transition: all 0.35s cubic-bezier(0.1, 0, 0, 1);
    border-radius: 6px;
    padding: 15px 20px;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
    margin: auto 30px;
    font-family: "Fredoka One";
    letter-spacing: .1em;
    /*border: 1px solid rgba(0, 0, 0, 0);*/
    text-shadow: 0 0 rgba(0, 0, 0, 0); }
  .play-box .btn,
  .play-box a.btn:hover {
    border-radius: 8px;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
    /*border: 1px solid rgba(0, 0, 0, .1);*/
    transform: scale(1.06);
    text-shadow: 0 -1px rgba(0, 0, 0, 0.1); }
  .play-box .btn,
  .play-box a.btn:active {
    transform: scale(0.98);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.3);
    /*border: 1px solid rgba(0, 0, 0, .1);*/ }
  .play-box .btn,
  .play-box a.btn:visited {
    transform: scale(1); }
  .play-box .blue,
  .play-box a.blue {
    color: #08255d;
    font-weight: bold;
    background: white;
    white-space: nowrap;
    font-size: 17px;
    position: relative;
    font-family: inherit; }
  .play-box .blue, .play-box a.image {
    background-image: url(/static/media/cricview_logo-192x192.3347558d.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 50px; }
  .play-box .image:hover {
    background-image: url(/static/media/cricview_logo-192x192.3347558d.png) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    padding-left: 50px !important; }
  .play-box .blue,
  .play-box a.blue:hover {
    background: white; }
  .play-box .blue,
  .play-box a.blue:active {
    background: #f2f2f2; }
  @media (max-width: 426px) {
    .play-box .welcomme-inner2 {
      flex-direction: column;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-left: 0 !important; }
    .play-box .flexx {
      flex-direction: column; }
    .play-box .welcome {
      flex-direction: column; }
    .play-box .welcomme-inner1 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; } }

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Regular.ae5e7255.ttf);
}
@font-face {
  font-family: "Gilroy Black";
  src: url(/static/media/Gilroy-Black.d59719bc.ttf);
}
a {
  text-decoration: none;
}
body {
  margin: 0;
  font-family: "Gilroy";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.add-banner-1 {
  width: 768px;
  margin: auto;
  height: 100%;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.border-animation-mobile {
  display: none;
}
.border-animation-desktop {
  width: 100%;
  display: block;
  height: 42px;
}
@media (max-width: 768px) {
  .add-banner-1 {
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
  }
  .border-animation-mobile {
    display: block;
    width: 100%;
  }
  .border-animation-desktop {
    display: none;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.row_1 {
  width: 1440px;
  margin: 0 auto;
}
.bannerImage {
  width: 1440px;
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .row {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .row {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .row {
    width: 100%;
  }
  .row_1 {
    width: 100%;
  }
  .bannerImage {
    width: 100%;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
@media (max-height: 500px) {
  .modal-video-body {
    max-width: 940px;
    width: 65%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
.playnow-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.playnow-modal-content {
  background-image: linear-gradient(45deg, red, darkblue);
  margin: auto;
  width: 728px;
  position: relative;
  padding-bottom: 75%;
  border-radius: 10px;
}
.playnow-close {
  color: black;
  float: right;
  text-align: center;
  padding: 5px;
}

.playnow-close:hover,
.playnow-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.ReactModal__Overlay {
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframe__class {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  position: absolute;
  padding: 10px;
}
.ReactModal__Content {
  position: unset;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: none;
  background: none;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90% !important;
  /* overflow: hidden !important; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ReactModal__Content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ReactModal__Content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-confirm-alert-body > h1 {
  font-size: 2rem;
}
.react-confirm-alert-body {
  background: #08255d !important;
}
.react-confirm-alert-body {
  border: 15px double #08255d !important;
  background-clip: padding-box !important;
  color: #f5f0f0 !important;
  font-family: inherit !important;
  letter-spacing: 3px !important;
  width: 50% !important;
  margin: auto !important;
  border: 15px double #08255d !important;
}
.react-confirm-alert-button-group {
  display: flex;
  justify-content: center;
}
.react-confirm-alert-button-group > button {
  flex: 1 1 !important;
  background: #348e43 !important;
  padding: 12px 18px !important;
  color: #fcfcfc !important;
  margin-right: 21px !important;
  font-size: 17px !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
  margin-top: 25px !important;
}
.react-confirm-alert-button-group > button:nth-child(2) {
  background: #d12929 !important;
  margin-right: 0 !important;
}
.react-confirm-alert-overlay {
 
  z-index: 99999;
} 
.react-confirm-alert {
  width: 100%;
}
@media (max-width: 426px) {
  .instaa div iframe {
    min-width: 0 !important;
    max-width: 500px;
  }
  .react-confirm-alert-body {
    width: 90% !important;
}
}

@media (max-height: 500px) {
  .modal-video-body {
    max-width: 940px;
    width: 65%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
@media (max-height: 568px) {
  .ReactModal__Content {
    align-items: normal;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ReactModal__Content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 768px) {
  .row {
    width: 100%;
  }
  .row_1 {
    width: 100%;
  }
  .bannerImage {
    width: 100%;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
@media (max-width: 1024px) {
  .row {
    width: 100%;
  }
}
@media (max-width: 1440px) {
  .row {
    width: 100%;
  }
}
.loader {
  border: 5px solid #000000;
  border-radius: 50%;
  border-top: 5px solid #db3434;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.react-datetime-picker__wrapper {
  border: thin solid #8080804a;
  background-color: white;
  box-shadow: 2px 4px 22px -6px #62656a;
}
@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Regular.ae5e7255.ttf);
}
@font-face {
  font-family: "Gilroy Black";
  src: url(/static/media/Gilroy-Black.d59719bc.ttf);
}
body {
  margin: 0;
  font-family: "Gilroy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.center {
  text-align: center;
}

.kNBmiK {
  margin: 0px !important;
}
.modal-close {
  z-index: 1;
}
.jWpUJH {
  bottom: 10px;
}
.dots-bottom .jWpUJH {
  bottom: 10px !important;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
.viewall-button {
  background-color: #ff3333;
  border: none;
  color: #fff;
  font-size: 16px;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  width: 150px;
  margin-right: 20px;
  height: 44px;
  margin-top: 15px; }
  .viewall-button:hover {
    box-shadow: none;
    cursor: pointer; }

.border1 {
  width: 100%;
  margin-bottom: 0px;
  padding: 1em;
  background-image: radial-gradient(circle at -3.5px, #000 0.25px, rgba(255, 255, 255, 0) 2.5px), radial-gradient(circle, #000 -29.75px, rgba(255, 255, 255, 0) 2.5px), radial-gradient(circle at 2.5px, #000 1.25px, rgba(255, 255, 255, 0) 2.5px), radial-gradient(circle, #000 1.25px, rgba(255, 255, 255, 0) 2.5px);
  background-position: bottom;
  background-size: 24px 4px, 0px 0px;
  background-repeat: repeat-x, repeat-y; }

.viewall-container {
  width: 100%;
  text-align: center; }

* {
  outline: none !important; }

body {
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  body::-webkit-scrollbar {
    height: 10px;
    width: 10px; }
  body::-webkit-scrollbar-track {
    background: rgba(12, 35, 123, 0.1);
    border: 0.5px solid #f1f1f1;
    border-radius: 10px; }
  body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, red, darkblue);
    border-radius: 10px; }
  body::-webkit-scrollbar-thumb:active {
    background-image: linear-gradient(45deg, red, darkblue); }

