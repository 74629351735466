.cricview-talnet-header{
    section {
        float: left;
        width: 100%;
    }
    header {
        float: left;
        width: 100%;
        position: absolute;
        z-index: 1;
        min-height: 150px;
    }
    .header-top {
        margin: 15px 0 10px;
        float: right;
        width: 100%;
    }
    .col-md-6 {
        width: 50%;
    }
    .logo {
        float: left;
        padding: 7px 0 6px;
        margin: 0 0 0 0px;
        position: relative;
    }
    .right_top_section {
        float: right;
        padding: 17px 0 0;
    }
    .login {
        float: right;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .login li {
        float: left;
        margin: 0 0 0 10px;
        font-weight: 500;
    }
    .login li .login-reg {
        padding: 9px 25px;
        color: #fff;
        font-size: 13px;
        background: #d8302f;
        border-radius: 50px;
        float: left;
    }

    .login li .login-reg:hover, .login li .login-reg:focus {
        background: #fff;
        color: #333;
    }
    .container-talnet {
        width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .row-talnet {
        margin-right: -15px;
        margin-left: -15px;
    }
    .full-talnet {
        position: relative;

        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
    }
   
    .full-talnet .logo img{
        width: 120px;
    }
    
    .social-icons {
        list-style: none;
        float: left;
        width: auto;
        padding: 0;
        margin: 0;
        
    }
    .pull-left {
        float: left!important;
    }
    .social-icons li {
        float: left;
        margin: 0 15px 0 0;
    }




    .inner-page-banner {
        float: left;
        width: 100%;
        min-height: 250px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .inner-information-text {
        float: left;
        width: 100%;
        background: #111;
        color: #fff;
    }
    .inner-information-text .container-talnet .heading {
        color: #fff;
        margin: 0;
        padding: 10px 0;
        float: left;
        text-transform: uppercase;
    }
    .breadcrumb {
        padding: 11px 0;
        margin-bottom: 0;
        list-style: none;
        background-color: transparent;
        border-radius: 0;
        border: none;
        color: #fff;
        float: right;
    }
    .breadcrumb li {
        color: #fff;
        display: inline-block;
    }
    .breadcrumb li a {
        color: #fff;
    }
    .breadcrumb .red {
        color: #d8302f;
    }

    @media (max-width: 1200px){
        .container-talnet {
            width: 1170px;
        }
    }
   
    @media (max-width: 992px){
        .container-talnet {
            width: 970px;
        }
        .col-md-4 .col-md-2{
            float: left;
        }
    }   
    @media (max-width: 768px){
        .full-talnet .logo img{
            width: 120px;
        }
        .full-talnet .logo {
            float: left;
            padding: 15px 0 10px;
            margin: 0 0 0 0px;
            position: relative;
            width: 100%;
            text-align: center;
            left: 50%;
        }
        .container-talnet {
            width: 90%;
        }
        .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7{
            width: unset;
            height: 35%;
        }
        
        .right_top_section {
            float: right;
            padding: 17px 0 0;
            display: none;
        }
    }
}