.viewall-button {
  background-color: #ff3333;
  border: none;
  color: #fff;
  font-size: 16px;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  width: 150px;
  margin-right: 20px;
  height: 44px;
  margin-top: 15px;
  &:hover {
    box-shadow: none;
    cursor: pointer;
  }
}
.border1 {
  width: 100%;
  margin-bottom: 0px;
  padding: 1em;
  background-image: radial-gradient(circle at -3.5px, #000 0.25px, rgba(255, 255, 255, 0) 2.5px),
    radial-gradient(circle, #000 -29.75px, rgba(255, 255, 255, 0) 2.5px), radial-gradient(circle at 2.5px, #000 1.25px, rgba(255, 255, 255, 0) 2.5px),
    radial-gradient(circle, #000 1.25px, rgba(255, 255, 255, 0) 2.5px);
  background-position: bottom;
  background-size: 24px 4px, 0px 0px;
  background-repeat: repeat-x, repeat-y;
}
.viewall-container {
  width: 100%;
  text-align: center;
}

* {
  outline: none !important;
}
body {
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(12, 35, 123, 0.1);
    border: 0.5px solid #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, red, darkblue);
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:active {
    background-image: linear-gradient(45deg, red, darkblue);
  }
}
