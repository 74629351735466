.withHeader{
  height:520px;
}
.withoutHeader{
  height:470px;
}
.cricview-latestvideo-container {
  // padding: 3% 0;
  background-color: #e9f6ff;
  background-image: url('/static/media/top_searche_sbg.62cad5f8.png');
  width: 100%;
  margin-bottom: 50px;
  .showmore_button_container {
    margin: 1% 0;
    transform: translate(0px, -170%);
  }

  .showmore_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .showmore_button {
    background-color: #ff3333;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-right: 10px;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      box-shadow: none;
    }
  }
  img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
  }

  .cricview-latestvideo-wrapper {
    margin: 3% 3% 0%;
    position: relative;
  }
  .latestvideo-header {
    display: flex;
    margin-bottom: 1%;
    align-items: center;
  }
  .latestvideo-header-title {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    flex: 1 1;
    font-family: 'gilroy black';
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:white;
  }
  .latestvideo-header-title span {
    color: #00bcf3;
  }
  .latestvideo-viewall {
    background-color: #ff3333;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    padding-top: 10px;
    width: 150px;
    margin-right: 20px;
    height: 44px;
    box-shadow: 0 5px 18px #ff3333;
    &:hover {
      color: white;
      box-shadow: none;
    }
  }
  .latestvideos {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    overflow-wrap: normal;
    width: 100%;
    &::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(12, 35, 123, 0.1);
      border: 0.5px solid #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-image: linear-gradient(45deg, red, darkblue);
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:active {
      background-image: linear-gradient(45deg, red, darkblue);
    }
  }
  .latestvideos-grid {
    display: flex;
    width: 200%;
    margin-bottom: 30px;
  }
  .latestvideo-item {
    width: 25%;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    height: 100%;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .image-container {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    padding-bottom: 75%; // 4:3 Aspect Ratio
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .video-image {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 1s;
    object-fit: contain;
    border-radius: 5px;
    &:hover {
      transform: scale(1.1);
      border-radius: 5px;
    }
  }
  .title-container {
    height: 150px;
    margin-bottom: 10px;
  }
  .publishDate {
    padding-top: 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    height: 20%;
    color: white;
  }
  .title {
    padding-top: 20px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: white;
  }
  .title:hover {
    text-decoration: underline;
    overflow: visible;
    position: relative;
    text-overflow: ellipsis;
    display: block;
  }
  .arrow-flex {
    display: flex;
   
    align-items: center;
  }
  .arrow-left {
    cursor: pointer;
    margin-bottom: 150px;
    margin-right: 2%;
  }
  .arrow-right {
    cursor: pointer;
    margin-bottom: 150px;
  }
  .arrow-image {
    height: 100%;
  }
  .time-container {
    float: right;
    margin-right: 20px;
    padding: 5px;
    width: 75px;
    text-align: center;
    position: relative;
  }
  .time {
    font-size: 16px;
    color: red;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    background: white;
    width: 75px;
    border-radius: 20px;
  }
  .play-button {
    position: absolute;
    left: 37.5%;
    top: 37.5%;
    width: 20%;
    border-radius: 50%;
    transition: box-shadow 0.5s;
    z-index: 1;
    
  }
  .play-image {
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }

  @media (max-width: 768px) {
    .cricview-latestvideo-wrapper {
      margin: 4%;
    }
  
    .arrow-left{
      margin-right: 0%;
    }
    .arrow-left svg {
    height: 24px;
    width: 24px;
    }
    .arrow-right svg {
      height: 24px;
    width: 24px;
    }
    .ar
    .latestvideo-header {
      margin-left: 20px;
    }
    .latestvideo-item {
      width: 100% !important;
      margin-right: 20px;
      margin-top: 20px;
      margin-left: 20px;
      border-radius: 5px;
      color: white;
      height: 100%;
    }
    .time {
      bottom: 200%;
      font-size: 14px;
    }
    .publishDate {
      font-size: 12px;
    }
    .title {
      font-size: 14px;
    }
    .latestvideo-viewall {
      display: none;
    }
    .viewall-mobile {
      display: flex;
      margin-top: 20px;
      .article-viewall-mobile {
        background-color: #ff3333;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        padding-top: 10px;
        width: 100%;
        margin-right: 0;
        height: 44px;
        box-shadow: 0 5px 18px #ff3333;
        &:hover {
          background-color: white;
          color: #ff3333;
          box-shadow: 0 5px 18px -1px #ff3333;
        }
      }
    }
    .latestvideos {
     
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
@media (max-width: 1024px){
  .withHeader{
    height:430px;
  }
  .withoutHeader{
    height:395px;
  
  }
}
@media (max-width: 768px){
  .withHeader{
    height:500px;
  }
  .withoutHeader{
    height:450px;
  
  }
}

@media (max-width: 340px) {
  .withHeader{
    height:420px;
  }
  .withoutHeader{
    height:380px;
  
  }
}

