.match_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  font-size: 16px;
  width: 90%;
  margin: 0 auto;
  background-image: url("../../Assets/vs.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  .instaValue {
    font-size: 23px;
    font-weight: 800;
    display: flex;
    color: red;
    align-items: center;
    justify-content: center;
    a{
      color: white;
    }
}

.skillsValue{
  min-height: 60px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 18px;
  -webkit-box-orient: vertical;
}
  .listButton{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    margin: 10px 20px 10px 0;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
    color: #FFF;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    transition: all 0.2s;
    box-shadow: 0px 5px 0px 0px #710505;
    cursor: pointer;
    }
    .listButton:hover {
      margin-top: 15px;
      margin-bottom: 5px;
      box-shadow: 0px 0px 0px 0px #710505;
    }
  .punjab{
       width: 118px !important;
      height: 105px !important;
      top: -26%;
    left: -50% !important;
    background-image: none !important;
  }
  .cpl-image{
    width: 25%;
  }
  .cplFlag{
    width: 200px !important;
    height: 135px !important;
    top: -26%;
    left: -50% !important;
    background-image: none !important;
  }
  .skills {
    width: 90%;
    display: flex;
    color: white;
    margin: 5% auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 2px;
    .text{
      margin-right: 1%;
    }
}
  .sixlogo{
    width: 150px !important;
    height: 123px !important;
  }
  .sevenlogo{
    width: 240px !important;
    height: 140px !important;
  }
  .eightlogo{
    width: 270px !important;
    height: 180px !important;
  }
  .ninelogo{
    width: 320px !important;
    height: 265px !important;
  }
  .fixImage{
    width: 200px;
    height: 200px;
  }
  .nostart{
    font-size: 23px;
    color: #47fff7;
  }
  .marq{
    background-color: #e5142f;
    width: 69%;
    border-radius: 5px;
  }
  .endShare{
    width: 100%;
    display: flex;
    position: relative;
  }
  .endButton{
    flex: 1 1;
    padding: 2% 0 2% 2%;
    display: flex;
    .end{
         padding: 9px 18px;
       
          color: white;
          border: none;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 3px;
          background-color: red;
          cursor: pointer;
          margin-right: 10px;
    }
  }
  .sponser{
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
.text{
  color: white;
  font-size: 20px;
  font-weight: 900;
  border-bottom: 1px solid white;
  width: 62%;
  text-align: center;
  margin-bottom: 1%;
  padding-bottom: 1%;
}
    .images{
      // padding: 2% 0 0 0;
      // width: 90%;
      // height: 336px;
      // flex-wrap: wrap;
      display: flex;
      width: 62%;
      // flex-direction: row;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(45deg, black, black);
      // .image{
      //   display: flex;
      //   width: 16%;
      //   margin: 0 2%;
        img{
          width: 100%;
          height: 100%;
          display: block;
        }
    // }
      }
    }
  
  .uiCount{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  
.countdown__section {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 5px;
    background-color: #08255d;
}
.countdown__section:first-child {
  margin-left: 0;
}
.countdown__section > .value {
  background-color: #f00;
  border-radius: 10px;
  font-size: 26px;
  color: #f1f1f1;
  text-align: center;
}
.countdown__label {
  font-size: 11px;
  padding-top: 4px;
  text-align: center;
}

  .winner{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    justify-content: center;
  }
  .winnerName{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .winName{font-size: 26px;
    color: #ffd400;
    text-transform: uppercase;
    letter-spacing: 1px;}
  .congo{
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 15px;
    font-size: 25px;
    font-weight: 900;
    color: #ff0000;
    // font-family: fantasy;
    letter-spacing: 3px;
    -webkit-animation: neon2 1.5s ease-in-out infinite alternate;
    -moz-animation: neon2 1.5s ease-in-out infinite alternate;
    animation: neon2 1.5s ease-in-out infinite alternate;
  }
  
@-moz-keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228DFF, 0 0 70px #228DFF, 0 0 80px #228DFF, 0 0 100px #228DFF, 0 0 150px #228DFF;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228DFF, 0 0 35px #228DFF, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF;
  }
}
  @-webkit-keyframes neon2 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228DFF, 0 0 70px #228DFF, 0 0 80px #228DFF, 0 0 100px #228DFF, 0 0 150px #228DFF;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228DFF, 0 0 35px #228DFF, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF;
    }
  }
  @keyframes neon2 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228DFF, 0 0 70px #228DFF, 0 0 80px #228DFF, 0 0 100px #228DFF, 0 0 150px #228DFF;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228DFF, 0 0 35px #228DFF, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF;
    }
  }
  .loader-container-new {
    display: none;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: #fcfcfcbf;
  }
  .loadingText {
    font-size: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0% -8%;
  font-weight: 700;
  }
  #loader-new {
    position: absolute;
    left: 50%;
    top: 50%;
    border: 5px solid #08255d;
    border-radius: 50%;
    border-top: 5px solid #d6011f;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px #ba2e2e;
  }
  .flex{
    flex:2
  }
  .together{
    width: max-content;
    padding: 0 2%;
  }
  .scoreCard{
    
    margin-bottom: 2%;
    // width: 22%;
    width: 300px;
    display: flex;
    flex-direction: row;

    transition: 1s;
    position: relative;
    height: 90px;
    border-radius: 10px;
    overflow: hidden;
  }
  .scoreCard:hover{
		transform: scale(1.3);
		z-index: 2;
  }
  .score1{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10%;
    left: 12.5%;
    justify-content: center;
  }
  .score1 span{
    font-size: 27px;
    // font-family: fantasy;
    font-weight: 900;
  }
  .score2{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    right: 12.5%;
  }
  .score2 span{
    font-size: 27px;
    font-weight: 900;
  }
  .matchEndTimer{
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;
    margin-bottom: 4%;
  }
  .matchEndText{
    font-size: 19px;
    margin-bottom: 5%;
    border-bottom: 1px solid white;
    text-align: center;
  }
  //***************************************
  .glowLive {
    color: red;
    width: 22%;
    letter-spacing: 2px;
    font-size: 26px;
    text-shadow: 0 0 2px #e6e2e2, 0 0 10px #ebe6e6, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101;
    -webkit-animation: blinking 1.5s infinite alternate;
            animation: blinking 1.5s infinite alternate;
  }
  
  
  @keyframes blinking {
    100% {
      text-shadow: 0 0 2px #cec1c1, 0 0 10px #b3a9a9, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101;
       }
  }
  
    //***************************************
  .match_background {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .name1{
    text-transform: capitalize;
    background: linear-gradient(to right, #175df6, #4eff14);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 21px;
    // font-family: fantasy;
    position: absolute;
    text-align: start;
    font-weight: 900;
    top: 10%;
    left: 13.5%;
  }
  .name2{
    text-transform: capitalize;
    background: linear-gradient(to right, #de5af6, #aec064);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 21px;
    // font-family: fantasy;
    font-weight: 900;
    position: absolute;
    top: 10%;
    text-align: end;
    right: 13.5%;
  }
  .player1Score{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: white;
  }
  .player2Score{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #d4d4d4f5;
  }

  .flagName img{
    // height: 60px;
    // width: 76px;
    // background-image: linear-gradient(45deg,rgb(228, 55, 55),blue);
    height: 100%;
    width: 100%;
    background-image: linear-gradient(45deg, #e43737, blue);
    position: absolute;
    left: 0;
  }
  
  .flagName{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    .name{
      font-size: 25px;
    }
    .state{
      font-size: 16px;
    }
  }
  .flag-image{
    // width: 8vh;
    // margin-right: 3%;
    
    // height: 60px;
    width: 15%;
    margin-right: 3%;
    height: 75px;
    position: relative;
  }
  .sharing-footer {
    float: right;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background: none;
    color: #ffffff;
  }

  .sharing-footer .line {
    margin-top: 5px;
    height: 1px;
    width: 80px;
  }

  .sharing-footer .share-prompt {
    margin-right: 30px;
  }
  .sharing-footer .share-prompt .text {
    font-size: 16px;
  }

  .sharing-footer .share-btns {
    display: flex;
    // flex: 1;
  }

  .sharing-footer .share-btns .share-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    width: 30px;
    cursor: pointer;
  }

  .sharing-footer .share-btns .share-btn-container:last-child {
    margin-right: 0;
  }

  .sharing-footer .share-btns i {
    font-size: 36px;
  }

  .sharing-footer .share-btns .underline {
    height: 2px;
    width: 0%;
    transition: width 0.1s ease-out;
  }

  .sharing-footer .share-btns .share-btn-container:hover .underline {
    width: 100%;
    transition: width 0.1s ease-out;
  }

  .sharing-footer .up-next .label {
    font-weight: 800;
  }

  .sharing-footer .up-next .next-article {
    text-transform: uppercase;
  }

  .sharing-footer .up-next .line {
    float: right;
  }
  .matchLive{
    font-family: sans-serif;
    min-height: 40px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px;
    font-size: 22px;    
  }
  .liveContain{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .liveGif{
    width: 15%;
    margin-right: 0.2%;
    margin-left: 1%;
  }
  .liveGif img{
    width: 100%;
    display: block;

  }
  .timerGif{
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  .matchTime{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
  }
  .match_header {
    overflow-wrap: break-word;
    min-height: 40px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #fff;
    margin-bottom: 2%;
    font-size: 26px;
  }
  .match_header p, p a {
    color:white;
}
  .match_question {
    min-height: 40px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #ff7f12;
    font-size: 30px;
    margin-bottom: 3%;
    font-family: sans-serif;
  }
  .match_totalScore {
    min-height: 40px;
    width: 100%;
    padding: 10px;
    text-align: center;
    color: white;
    font-family: cursive;
    font-weight: 900;
    font-size: 20px;
  }
  .tim{
    border-radius: 4px;
    width: max-content;
    background-color: white;
    color: black;
    margin-left: 2%;
    padding: 1px 5px;
    margin-right: 0.5%;
  }
  .containerQuestion{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    min-height: 300px;
  }
  .mashaal{
    width: 10%;
    display: flex;
    position: relative;
  }
  .mashaal1{
    width: 100%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    position: absolute;
  }
  .mashaal2{
    width: 100%;
    position: absolute;
  }
  .boxes{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .match_versus {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    overflow: hidden;
  }
  .flex1{
        width: 50%;
  }
  .flex2{
    width: 50%;
  }
  .center_vs {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    background-image: url(/static/media/versus.bbce38aa.png);
    background-size: contain;
  }
  .design_video1 {
    background-image: linear-gradient(45deg, red, darkblue);
    position: absolute;
    border-radius: 4%;
  }
  .design_video2 {
    background-image: linear-gradient(45deg, darkblue, red);
    position: absolute;
    border-radius: 4%;
  }
  .versus-left {
    position: relative;
    padding-bottom: 75%;
    border-radius: 4%;
    width: 90%;
    margin: 10px auto;
      
  }
  .i-button{
    margin-bottom: 2%;
    margin-top: -2%;
width: 15%;
    text-align: center;
  }
  .i-button button {
    padding: 4% 9%;
    max-width: max-content;
    background-color: white;
    border: none;
    border-radius: 7px;
  }
  .divide {
    border-right: 8px solid black;
    border-radius: 5px;
    height: 90%;
    width: 0px;
    margin: 0 auto;
  }
  .versus-right {
    position: relative;
    padding-bottom: 75%;
    border-radius: 4%;
    width: 90%;
    margin: 10px auto;
  }
  //decorration start



.fireworks {
  position: relative;;
}
.fireworks::before, .fireworks::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards;
          animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards;
}
.fireworks::after {
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
          animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
          animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -74px -62.3333333333px #00ff4d, 122px -12.3333333333px #0022ff, 47px 11.6666666667px #ff0088, -95px -183.3333333333px #3300ff, 13px -181.3333333333px #ff003c, -19px -200.3333333333px #ff0055, -98px -194.3333333333px #0dff00, 44px -169.3333333333px #1e00ff, 10px -130.3333333333px #00ff9d, 125px 35.6666666667px #8000ff, -109px -157.3333333333px #0044ff, 65px -170.3333333333px #00ffd0, 107px -207.3333333333px #ff0011, 78px -127.3333333333px #ff007b, -114px 40.6666666667px #ff0400, 108px -142.3333333333px #0048ff, 42px -198.3333333333px #ff00cc, -70px -122.3333333333px #001aff, 3px 21.6666666667px #9500ff, 86px -180.3333333333px #00ffb3, 28px -100.3333333333px #8000ff, 24px -206.3333333333px #fb00ff, -46px -104.3333333333px darkorange, -69px -200.3333333333px #0900ff, 99px -98.3333333333px #80ff00, 34px -50.3333333333px #00ff8c, -44px -17.3333333333px #ff00f2, 49px -26.3333333333px #b700ff, -46px -76.3333333333px #00ff1e, 81px -190.3333333333px #00ffdd, 6px 40.6666666667px #8000ff, -104px -168.3333333333px #00aeff, -18px -69.3333333333px #ffee00, 64px -173.3333333333px #9900ff, -101px -109.3333333333px #ff8800, 43px -148.3333333333px #9d00ff, -67px -155.3333333333px #fffb00, -31px -112.3333333333px #ff002b, -27px -195.3333333333px #ff00d9, -86px -149.3333333333px #ff00aa, -8px -104.3333333333px #00ddff, -94px -13.3333333333px #ff1500, -112px -91.3333333333px #0099ff, 89px -198.3333333333px #fff200, 12px -150.3333333333px #2b00ff, 38px -196.3333333333px #00ffa2, 57px -100.3333333333px #ff003c, 112px -59.3333333333px #ffbb00, 117px -131.3333333333px #00fffb, 69px -187.3333333333px #eaff00, -52px -120.3333333333px #0066ff;
  }
}

@keyframes bang {
  to {
    box-shadow: -74px -62.3333333333px #00ff4d, 122px -12.3333333333px #0022ff, 47px 11.6666666667px #ff0088, -95px -183.3333333333px #3300ff, 13px -181.3333333333px #ff003c, -19px -200.3333333333px #ff0055, -98px -194.3333333333px #0dff00, 44px -169.3333333333px #1e00ff, 10px -130.3333333333px #00ff9d, 125px 35.6666666667px #8000ff, -109px -157.3333333333px #0044ff, 65px -170.3333333333px #00ffd0, 107px -207.3333333333px #ff0011, 78px -127.3333333333px #ff007b, -114px 40.6666666667px #ff0400, 108px -142.3333333333px #0048ff, 42px -198.3333333333px #ff00cc, -70px -122.3333333333px #001aff, 3px 21.6666666667px #9500ff, 86px -180.3333333333px #00ffb3, 28px -100.3333333333px #8000ff, 24px -206.3333333333px #fb00ff, -46px -104.3333333333px darkorange, -69px -200.3333333333px #0900ff, 99px -98.3333333333px #80ff00, 34px -50.3333333333px #00ff8c, -44px -17.3333333333px #ff00f2, 49px -26.3333333333px #b700ff, -46px -76.3333333333px #00ff1e, 81px -190.3333333333px #00ffdd, 6px 40.6666666667px #8000ff, -104px -168.3333333333px #00aeff, -18px -69.3333333333px #ffee00, 64px -173.3333333333px #9900ff, -101px -109.3333333333px #ff8800, 43px -148.3333333333px #9d00ff, -67px -155.3333333333px #fffb00, -31px -112.3333333333px #ff002b, -27px -195.3333333333px #ff00d9, -86px -149.3333333333px #ff00aa, -8px -104.3333333333px #00ddff, -94px -13.3333333333px #ff1500, -112px -91.3333333333px #0099ff, 89px -198.3333333333px #fff200, 12px -150.3333333333px #2b00ff, 38px -196.3333333333px #00ffa2, 57px -100.3333333333px #ff003c, 112px -59.3333333333px #ffbb00, 117px -131.3333333333px #00fffb, 69px -187.3333333333px #eaff00, -52px -120.3333333333px #0066ff;
  }
}
@-webkit-keyframes gravity {
  to {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    opacity: 0;
  }
}

.flag-image-country {
    width: 10vh;
    margin-right: 2%;
    height: 60px;
    position: relative;

img {
  height: 100%;
  width: 100%;
  display: block;
 
}
}
  //decoration end
  .player_info {
    text-align: center;
    height: 100%;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 auto 3%;
    color: white;
    text-transform: capitalize;
  }
 
  .vote1 {
    width: 100%;
    margin: 3% auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //voting polls

  :root {
    --line1color: white;
    --line2color: rgb(0, 153, 253);
    --line1width: 0%;
    --line2width: 0%;
  }
  .question {
    padding-top: 1%;
    background-color: #08255d;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 8px;
    position: relative;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 30px black;
    background-image: linear-gradient(45deg, #a2107d, #beb537);
    z-index: 10;
  }
  .question h1 {
    text-align: center;
    color: #fcfcfcab;
  }
  .question i {
    color: black;
    font-size: 2em;
    margin-left: 0.3em;
    cursor: pointer;
    transition: color 0.2s;
  }

  .question i:hover {
    color: blue;
  }

  .ans-text {
    text-transform: uppercase;
  }

  .poll .bar {
    width: 100%;
    height: 6px;
    border: 2px solid #6b2861;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 9px;
    padding: 2px;
    position: relative;
  }
  .bar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background-color: var(--line1color);
    transition: width 0.7s linear;
    border-radius: 2px;
  }

  .ans1 .ans-text {
    display: inline;
    color: var(--line1color);
  }

  .ans2 .ans-text {
    display: inline;
    color: var(-line2color);
  }
  .ans1,
  .ans2 {
    position: relative;
  }

  .poll-content {
    padding: 20px;
  }
  .line1 {
    border: 1px solid var(--line1color);
  }

  .line1::before {
    width: 0%;
    background-color: var(--line1color);
    transition: width 0.5s linear;
  }

  .line2 {
    border: 1px solid var(--line2color);
  }

  .line2::before {
    background-color: var(--line2color);
    width: var(--line2width);
  }

  .ans1 h4 {
    display: inline;
    position: absolute;
    bottom: 18px;
    right: 0;
    font-size: 16px;
    font-family: sans-serif;
  }
  .ans2 h4 {
    display: inline;
    position: absolute;
    bottom: 18px;
    right: 0;
    font-size: 16px;
    font-family: sans-serif;
  }

  .totalVotes {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 30px;
  }
  .scoreDetails{
    margin-bottom: 1%;
    margin-top: 1%;
  }

  .totalVotes h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  //score card blinking
  @keyframes blink {
    0% {
      box-shadow: 0 0 15px white;
    }
    50% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0 0 15px white;
    }
  }

  @-webkit-keyframes blink {
    0% {
      box-shadow: 0 0 15px white;
    }
    50% {
      box-shadow: 0 0 0;
    }
    100% {
      box-shadow: 0 0 15px white;
    }
  }

  .container {
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    -ms-animation: blink 1s linear infinite;
    -o-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
  }
.blinkText
  {
    animation: blinker 1s linear infinite;
    color: red;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  #confetti {
    display: none;
    position: absolute;
    left: 0;
    margin-top: 9%;
    height: 100%;
    width: 100%;
  }
  .voteText{
    display: flex;
    font-size: 25px;
    font-weight: 900;
    align-items: center;
    justify-content: center;
    padding: 1% 0;
    .voteNow{
        margin-right: 1%;
        width: 18%;
        img{
            width: 100%;
            display: block;
        }
    }
    .now{
        margin-right: 1%;
        font-size: 35px;
        color: white;;
        font-weight: 900;
        font-family: 'gilroy black';
    }
    .ex{
        font-size: 40px;
        color: red;
        font-weight: 900;
        font-family: 'gilroy black';
    }
}
  //*************************votebutton*******************
.namef{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}
  .vote{
    // font-size: 20px;
    //padding: 10px 36px;
    // padding: 3% 11%;
    font-size: 25px;
    padding: 2% 12%;
    text-align: center;
    background-color: #00ce0e;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 900;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
  }

 .vote1 button:disabled {
  background-color: #7cf585 !important;
  cursor: no-drop !important;
}
#whatsApforMobile{
  display: none;
}



// //    @media  (min-width: 757px){
// // .nameState{
// //    margin-left: 2%;
// // }
//    }
  @media (max-width: 1024px) {
    .punjab {
      width: 88px !important;
      height: 63px !important;
      top: 0;
      left: -12% !important;
  }
    .cplFlag {
      width: 179px !important;
      height: 108px !important;
    }
  .vote {
    font-size: 21px;
  }
  .flag-image-country {
    width: 9vh;
    height: 53px;
  }
  .flag-image {
    height: 60px;
}
}
.ninelogo {
  width: 253px !important;
  height: 227px !important;

}
  @media (max-width: 768px) {
    .instaValue{
    flex-direction: column;
    }
    .cplFlag {
      left: -75% !important;
    }
    .flag-image {
      width: 20%;
  }
    .endButton {
      padding: 2% 0 4% 2%;
    }
   .flag-image-country {
    width: 9vh;
    height: 50px;
    };
    .containerQuestion {
      min-height: 178px;
    }
    .center_vs {
      flex: 1 1 4%;
      padding-top: 6%;
    }
    .together {
      width: 48%;
  }
    .glowLive{
      width: 30%;
    }
    .glow {
      font-size: 50px;
    }
    .question h1 {
      font-size: 2rem;
    }
  }
  @media (min-width: 426px) and (max-width: 450px) { 
    .listButton{
      line-height: 31px;
    letter-spacing: 1px;
    font-size: 13px;
    }
    .skills {
       font-size: 15px;
        flex-direction: column;
        .skillsValue{
                min-height: 30px;
                overflow: hidden;
                font-size: 10px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
        }
     }
    .sponser{
     .images{
       width: 90%;
      }
 }
    .share-btns {
     flex: 0 !important;
 }
    .sharing-footer {
     justify-content: center;
     width: 100%;
     }
    .muteContainer {
     position: absolute;
     right: 5%;
     top: 5%;
 }
    .endShare {
     flex-direction: column;
   }
    .voteNow {
     width: 33% !important;
   }
    .liveGif {
     width: 31% ;
    margin-right: 0;
    margin-left: 0
 }
    .flag-image {
     width: 30% !important;
     height: 50px !important;
     margin-right: 0 !important;
 }
 
 .flagName{
   flex-direction: column;
   .name{
     font-size: 15px;
   }
 }
 .images .image img {
   width: 50px !important;
   height: 50px !important;
 }
 .vote {
   font-size: 10px !important;
   padding: 4% 8%;
 }
 .instaValue {
  font-size: 12px;
}
    }
  @media (max-width: 426px) {
   .instaValue {
      font-size: 12px;
    }
    .listButton{
      line-height: 31px;
    letter-spacing: 1px;
    font-size: 13px;
    }
    .congo{
        font-size: 16px;
    }
    .nameState{
      margin-top: 12%;
    }
   .punjab {
    // top: -15%  !important;
    //   width: 55px !important;
    //   height: 100% !important;
    //   left: -10% !important;
    top: 32% !important;
    width: 55px !important;
    height: 44px !important;
    left: -10% !important;
  }
.i-button {
  margin-top: 5%;
}
    .cplFlag {
      width: 131px !important;
      height: 74px !important;
      left: -93% !important;
      // top: -32% !important;
      top: 0% !important;
  }
     .flag-image {
      width: 25% !important;
      height: 40px !important;
      margin-right: 0 !important;
  }
    .skills {
      font-size: 10px;
         flex-direction: column;
         .skillsValue{
          min-height: 30px;
          overflow: hidden;
          font-size: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
  }
    }
    .scoreDetails {
      font-size: 16px;
    }
    .flagName{
      .name{
        font-size: 11px;
      }
    }
    .flag-image-country {
      width: 6vh !important;
      height: 38px !important;
  }
    #whatsApforMobile{
      display: block;
    }
    #whatsApforDesktop{
      display: none;
    }
   .image img {
      width: 100px !important;
      height: 100px !important;
  }
    .match_question {
      font-size: 18px;
    }
    .nostart{
      font-size: 18px;
      color: #47fff7;
    }
.match_header {
      font-size: 21px;
      margin-bottom: 0;
    }
    .marq {
      width: 100%;
    }
   .share-btns {
      flex: 0 !important;
  }
    .voteNow {
      width: 33% !important;
    }
    .sponser{
          .images{
            width: 90%;
           }
    }
   .countdown__section {
        padding: 4% 3%;
    }
    .countdown__section > .value {
      font-size: 18px;
    }
    .countdown__label {
      font-size: 9px;
      padding-top: 10%;
    }
    .scoreCard{
      height: 70px;
        width: 70%;
        margin-bottom: 5%;
        .score1 * {
            font-size: 20px;
        }
        .score2 * {
          font-size: 20px;
      }
    }
    .state {
      font-size: 10px !important;
    }
    .match_versus {
      width: 90%;
    }
    .sharing-footer {
    justify-content: center;
    width: 100%;
    }
    
    .endShare {
      flex-direction: column;
    }
    .endButton {
      width: 100%;
      display: flex;
    }
    .muteContainer {
      position: absolute;
      right: 5%;
      top: 5%;
  }
    .mashaal1{
      position: absolute;
    }
    .vote1 {
      width: 100%;
    }
    .vote {
      font-size: 10px !important;
      padding: 4% 8%;
      // width: 50%;
      width: 75%;
    }
   .i-button {
      width: 50%;
    }
    .liveContain{
      flex-direction: column;
    }
    .liveGif {
      width: 31% ;
     margin-right: 0;
     margin-left: 0
  }
   
    .matchTime{
      flex-direction: column;
    }
    .together {
      width: 100% !important;
  }
    .glowLive{
        width: 100% !important;
    }
    .flex {
      padding: 2% 5%;
    }
    .center_vs {
      justify-content: center;
      background-image: none;
    }
    .glow {
      font-size: 30px;
    }
    .container {
      display: none;
      width: 80%;
      top: 90%;
    }
 .flagName {
      flex-direction: column;
    }
  }
  @media (max-width: 375px) {
  .cplFlag {
    width: 131px !important;
    height: 74px !important;
    left: -115% !important;
}
}
@media (max-width: 321px){

  .vote {
      font-size: 8px !important;
      width: 81% !important;
  }
  .flagName {
    flex-direction: column;
  
    justify-content: unset;
  }
  
  .name {
  font-size: 12px;
  }
  }
@media (max-width: 320px) {
.cplFlag {
  width: 98px !important;
  height: 70px !important;
  left: -96% !important;
}
}
}
.goTo{
  padding: 11px 30px;
    color: white;
    border: none;
    font-size: 15px;
    letter-spacing: 3px;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    box-shadow: 0 10px 6px -6px #777;
    margin-top: 10%;
    cursor: pointer;
    font-weight: 700;
}
.matchEnd{
  display: flex;
  flex-direction: column;
  font-size: 26px;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
  text-transform: capitalize;
}


.loader-container-new {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fcfcfced;
}

.popUp-container{
  position: absolute;
  width: 50%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  .tablevote{
    max-height: 400px;
    padding: 1% 0;
    overflow: hidden;
    overflow-y: auto;
    table{
      table-layout: fixed;
    }
  }
  .closeVote{
    width: 4%;
    margin-left: 99%;
    cursor: pointer;
    img{
      width: 100%;
      display: block;
    }
  }
  .srNo{
    flex:1
  }
  .player1{
    flex:1
  }
  .player2{
    flex:1
  }
  thead th {
    background: linear-gradient(#d6369d, #08255d);
}caption {
  font-weight: bold;
  padding: 10px;
  background: linear-gradient(#d6369d, #08255d);
 }
.customer-table {
  margin: 0 auto;
  box-shadow: 0 2px 2px #a9a9a9;
}}

.centerPop{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media(max-width:426px){
  .popUp-container{
    width: 80%;
  }
  .closeVote {
    width: 12% !important;
  }
  #loader-new {
    margin: -30px 0 0 -28px;
  }
  caption {
    font-size: 15px;
  }
  th {
    font-size: 12px;
  }
}

caption {
  font-weight: bold;
  padding: 10px;
  color: white;
  background: linear-gradient(#d6369d, #08255d);
}

caption p {
  color: white !important;
}

thead{
  color: white;
}

.customer-table {
	font-size: 0.625em;
	font-family: "Noto Sans KR", sans-serif;
	width: 100%;
	max-width: 1024px;
	margin: 20px auto;
	border-collapse: collapse;
	text-align: center;
	box-shadow: 0 2px 2px #e6e6e6;
}
.matchNamePopup * {
  text-transform: uppercase;
  color:white !important;
  text-decoration: none !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: sans-serif !important;
  font-style: normal !important;
}