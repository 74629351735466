
.cricview-register-container {
    width: 100%;
    .forgetPassword{
        color: white;
        margin-top: 8%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    &:hover{
        color: rgb(208, 211, 169);
    }
    }
    .centerError{
        text-align: center;
    }
    .emailLog{
        margin-top: 11%;
        text-align: center;
            width: 100%;
            padding: 4% 2%;
            font-size: 17px;
            font-weight: bold;
            background-color: black;
            border: none;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            &:hover{
                background-color: rgba(0, 0, 0, 0.76); 
                color: wheat;
            
        }
    }
        .emailForm {
            width: 50%;
            position: relative;
            padding: 0% 2% 5% 2%;
            color: #212121;
        }
    
    .input-cont{
        display: flex;
        flex-direction: column;
        margin-bottom: 5%;
        label{
            color:white;
            font-size: 17px;
            
        }
        input{
            height: 30px;
            font-size: 16px;
        }
    }
    .input-cont input:focus {
        outline: none;
      }
    .loginWith {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .textOr{
            color: white;
        }
        .emailLogin{
            height: 50px;
            color: white;
            margin: 2%;
            background: rgb(223, 40, 40);
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            // font-size: 12px;
            letter-spacing: 1px;
        }
    }
    .cricview-register-wrapper {
        background-color: #fefefe;
        // background-image: url("22.png");
        background-image: linear-gradient(45deg, darkblue, darkred);
        margin: 0 auto;
        border: 1px solid #888;
        border-radius: 5px;
        width: 560px;
        // height: calc(100% - 200px); /* Could be more or less, depending on screen size */
        height: fit-content;
    }
    .login-wrapper {
        margin: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .welcome-class {
        font-size: 28px;
        font-weight: bold;
        padding-bottom: 1%;
        color: whitesmoke;
    }
    .form-class {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 3%;
        color: whitesmoke;
    }
    .register-login-form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        color: whitesmoke;
    }
    .otp-message {
        font-size: 20px;
    }
    .input-container {
        margin: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .input-otp {
        border: none;
        outline: none;
        height: 40px;
        width: 20%;
        padding: 0 5px;
        border-radius: 5px;
        background-color: whitesmoke;
        font-weight: bold;
        border: 1px solid black;
        margin: 2%;
    }
    .input {
        border: none;
        outline: none;
        height: 40px;
        width: 60%;
        font-size: 16px;
        padding: 0 5px;
        border-radius: 5px;
        background-color: whitesmoke;
        font-weight: bold;
        border: 1px solid black;
    }
    .role-dropdown {
        display: flex;
        width: 60%;
        padding: 2% 0;
    }
    .role-value {
        background-color: whitesmoke;
        width: 100%;
        margin: 0 15px 0 10px;
        border-radius: 5px;
        span {
            background-color: grey;
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            border-bottom: 1px solid;
        }
    }
    .role {
        font-size: 16px;
        color: whitesmoke;
        padding: 8px;
    }
    .role-list {
        display: flex;
        flex-direction: column;
    }
    .role-list-item {
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #000;
        &:hover {
            background-color: #333;
            color: white;
        }
    }
    .dropbtn {
        background-color: white;
        color: black;
        padding: 8px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 60px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content li {
        color: black;
        padding: 6px 8px;
        text-decoration: none;
        display: block;
        cursor: pointer;
    }

    .dropdown-content li:hover {
        background-color: #000000;
        color: #fff;
        border-radius: 5px;
    }

    .dropdown:hover .dropdown-content {
        display: block;
        display: block;
        padding: 0;
        border-radius: 5px;
    }

    .dropdown:hover .dropbtn {
        background-color: #000;
        color: white;
    }
    .login-button {
        background-color: black;
        height: 40px;
        color: white;
        margin: 2%;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .fbb-login-button {
        background-color: black;
        height: 50px;
        color: white;
        margin: 2%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }


    .close-login {
        position: absolute;
        right: 25px;
        top: 0;
        color: #fff;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 4%;
    }
    .dont-register {
        color: black;
        font-size: 14px;
    }
    .signup-link {
        text-decoration: underline;
    }
    .show {
        display: flex;
    }
    .otp-wrapper {
        margin: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 5% 0;
        font-size: 16px;
    }
    .otp-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
    }
    .otp-message {
        color: whitesmoke;
    }
    .verify-otp {
        background-color: black;
        height: 40px;
        color: white;
        margin: 2%;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }
    .hide {
        display: none;
    }
    .error {
        color: red;
        font-size: 12px;
    }

    .countrycode {
        width: 10%;
    }
    .mobilenumber {
        width: 50%;
    }
    .account {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .account-change {
        text-decoration: underline;
        cursor: pointer;
        margin: 0 10px;
    }
    @media (max-width: 726px) {
        height: 100%;

        .cricview-register-wrapper {
            width: 90%;

            height: 80%;
            margin-bottom: 5%;
            margin-top: 5%;
        }
        .otp-wrapper {
            height: 200px;
        }
        .input {
            width: 100%;
        }
        .countrycode {
            width: 20%;
        }
        .mobilenumber {
            width: 80%;
        }
        .role-dropdown {
            width: 100%;
            height: 120px;
        }
        .role-value {
            height: 40px;
        }
        .role-list {
            background-color: whitesmoke;
            color: grey;
        }
        .login-button {
            width: 100%;
            margin-top: 10%;
        }

        .fbb-login-button {
            width: 100%;
            margin-top: 10%;
        }
        .otp-message {
            color: whitesmoke;
        }
        .otp-div {
            color: whitesmoke;
            font-size: 14px;
            padding-right: 10px;
        }
        .verify-otp {
            width: 75%;
        }
    }
    @media (max-width: 450px){
        // .mobilenumber:focus{
        //     font-size: 16px !important;
        //   }
        .account{
            font-size: 15px;
        }
        .emailForm {
            width: 100%;
        }
        .loginWith {
            flex-direction: column;
            .emailLogin{
                width: 100%;
            }
        }
    }
    @media (width: 320px) {
        height: 100%;

        .cricview-register-wrapper {
            width: 90%;

            height: fit-content;
            // margin-bottom: 5%;
            margin-top: 6.5%;
        }
        .otp-wrapper {
            height: 372px;
        }
        .input {
            width: 100%;
        }
        .countrycode {
            width: 20%;
        }
        .mobilenumber {
            width: 80%;
        }
        .role-dropdown {
            width: 100%;
            height: 130px;
        }
        .role-value {
            height: 40px;
        }
        .role-list {
            background-color: whitesmoke;
            color: grey;
        }
        .login-button {
            width: 100%;
            margin-top: 10%;
        }

        .fbb-login-button {
            width: 100%;
            margin-top: 10%;
        }
        
        .otp-message {
            color: whitesmoke;
        }
        .otp-div {
            color: whitesmoke;
            font-size: 14px;
            padding-right: 10px;
        }
        .verify-otp {
            width: 75%;
        }
    }
}
