.upload-container{
    width: 35%;
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    display: flex;
    flex-direction: column;
    background-color: white;
    .uploadText {
        padding: 5%;
        font-size: 25px;
        font-weight: 900;
    }
    .uploadText span{
        letter-spacing: 1px;
    }
    .uploadImage{
        width: 50%;
    padding: 2%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    }
    .uploadImage img{
        object-fit: contain;
    width: 100%;
    }
    .uploadButton{
        text-align: center;
        margin-top: 10%;
    }
    .uploadButton button{
        background-color: #1f9cdf;
    border: none;
    color: white;
    width: 90%;
    margin-bottom: 2%;
    padding: 3% 0%;
    border-radius: 4px;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 700;
    }
}