.arrowLeft{
    position: absolute;
    top: calc(50% - 17.5px);
    height: 35px;
    width: 35px;
    background: #ff3333;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: 5px;
    right: initial;
&::before{
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-25%,-50%) rotate(-135deg);
    -ms-transform: translate(-25%,-50%) rotate(-135deg);
    transform: translate(-25%,-50%) rotate(-135deg);
}
}
.arrowRight{
    position: absolute;
    top: calc(50% - 17.5px);
    height: 35px;
    width: 35px;
    background: #ff3333;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: initial;
    right: 5px;

    &::before{
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-75%,-50%) rotate(45deg);
    -ms-transform: translate(-75%,-50%) rotate(45deg);
    transform: translate(-75%,-50%) rotate(45deg);
    }
}
