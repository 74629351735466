.cricview-header {
  border: 1px solid;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(85, 172, 239, 0.1);
  background-image: linear-gradient(102deg, #3e2ed5 14%, #5812bf 45%, #e60e42 106%);

  a {
    color: inherit;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
  .cricview-navigation-desktop {
    display: flex;
    align-items: center;
    height: 74px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    color: white;
    text-transform: uppercase;
    // font-family: sans-serif;
    position: relative;
    margin: 0 34px;
    transition: height 0.4s ease, box-shadow 0.1s ease;
  }
  .hover-list {
    &:hover {
      color: #ff3333;
    }
  }
  .cricview-branding-container {
    cursor: pointer;
    // width: 25%;
    text-align: left;
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
    .header-image {
      height: 100%;
      width: 100%;
    }
  }
  .slash-line {
    width: 1px;
    height: 42px;
    border-radius: 100px;
    background-color: #ff6f6f;
    margin: 0 10px;
  }
  .branding-partner {
    width: 100px;
    height: 43px;
  }
  .poweredby {
    width: 56px;
    height: 11px;
    font-family: Gilroy;
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .nexg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-image: linear-gradient(180deg, #0c237b, #ff3333);
    border-radius: 5px;
    padding: 5px;
    margin: -3% 0;
  }
  .cricview-navigation-container {
    flex: 5;
  }
  .cricview-navigation-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .cricview-navigation-list-item {
    list-style-type: none;
    height: 100%;
    padding: 0 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: padding 0.4s ease, box-shadow 0.1s ease;
    border-radius: 5px;
    font-size: 16px;
  }
  .cricview-search-container {
    display: none;
    &:hover {
      fill: #ff3333;
      cursor: pointer;
    }
  }
  .cricview-search-container-open {
    display: none;
    width: 0;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    margin-left: auto;
    transition: all 1s ease;
    z-index: 50;
  }
  .is-active {
    display: block;
    position: relative;
    width: 75%;
    margin-left: auto;
    background-color: #000;
    border-radius: 15px 15px 15px 15px;
  }
  .close {
    display: none;
  }
  .cricview-navigation-mobile {
    display: none;
  }
  .search-icon {
    cursor: pointer;
  }
  .hover {
    &:hover {
      height: 42px;
      width: 42px;
      fill: #ff3333;
    }
  }
  .search-button-go {
    top: 50%;
    transition: all 0.2s ease;
    background: none;
    border: none;
    color: white;
    height: 45px;
  }
  .search-open-container {
    display: flex;
    background-color: #000;
    align-items: center;
  }
  .search-input-box {
    height: 100%;
    flex: 10;
    .input {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      color: white;
      padding-left: 3rem;
    }
  }
  .search-button-close {
    height: 100%;
    margin-right: 10px;
    fill: #ff3333;
    &:hover {
      cursor: pointer;
    }
  }
  .deactivate {
    display: none;
  }
  .navigation__drop-down {
    min-width: 8rem;
    display: block;
    position: absolute;
    top: 68%;
    transform: scale(1) translateX(-15%);
    visibility: hidden;
    text-align: left;
    padding: 1.5rem;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    z-index: 210;
    cursor: default;
  }
  .navigation__drop-down {
    min-width: 19.6rem;
    border-radius: 5px;
    padding: 1rem 1.8rem 0.5rem;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  }
  .cricview-navigation-list-item:hover .navigation__drop-down {
    visibility: visible;
    color: #2f2f2f;
  }
  .showList {
    visibility: visible;
    color: #2f2f2f;
  }
  .icon {
    transition: transform 0.2s ease;
  }
  // .cricview-navigation-list-item:hover .icon {
  //   transform: rotate(180deg);
  // }
  .search-modal {
    display: none;
    position: absolute;
    //  top: 297px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 50px);
    overflow: scroll;
    //  padding: 10px 20px;
    background-color: #fff;
    z-index: 10000;
    .no-search-results {
      display: none;
      font-size: 40px;
      color: red;
      font-weight: 600;
      position: relative;
      top: 10%;
      left: 40%;
    }
    .loader-container {
      display: none;
      position: absolute;
      z-index: 100000;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: black;
      background-color: rgba(0, 0, 0, 0.4);
    }
    #loader {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      margin: -75px 0 0 -75px;
      border: 10px solid #f3f3f3;
      border-radius: 50%;
      border-top: 10px solid #480b48;
      width: 100px;
      height: 100px;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .navigation__drop-down-link {
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: 1.3rem;
    color: #2f2f2f;
    text-transform: uppercase;
    line-height: 1;
    border-bottom: 1px solid #efefef;
  }

  @media (max-width: 1024px) {
    .cricview-navigation-desktop {
      height: 75px;
    }
    .branding-partner {
      width: 100px;
      height: 35px;
    }
    .nexg {
      width: 100%;
      object-fit: contain;
    }
    .cricview-branding-container {
      margin: 0 20px;
      cursor: pointer;
      //width: 15%;
      text-align: left;
      .header-image {
        height: 35px;
      }
    }
    .cricview-navigation-list {
      font-size: 12px;
    }
    .cricview-navigation-list-item {
      padding: 0 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .cricview-navigation-desktop {
      display: none;
    }

    .cricview-navigation-mobile {
      z-index: 999;
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
      color: white;
      font-size: 14px;
      position: relative;
    }
    .cricview-branding-container {
      height: 100%;
      margin: 0;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
    }
    .cricview-navigation-mobile-list {
      display: flex;
      width: 100%;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      justify-content: space-between;
    }
    .nexg {
      width: 100%;
      object-fit: contain;
    }
    .cricview-navigation-mobile-list-item {
      height: 100%;
      &:first-child {
        margin-left: 5%;
        text-align: left;
      }
      &:last-child {
        margin-right: 5%;
        text-align: right;
      }
    }

    a {
      color: inherit;
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
    .title {
      vertical-align: middle;
      font-size: 12px;
    }
    .cricview-navigation-mobilemenu-container {
      display: none;
    }
    .openMenu {
      display: block;
      background-color: white;
      z-index: 1;
      position: absolute;
      top: 46px;
      width: 100%;
      color: #04064d;
    }
    .cricview-navigation-mobilemenu-list {
      list-style: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 1em;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
    }
    .close {
      display: none;
    }
    .cricview-navigation-mobilemenu-list-item {
      padding: 1em 0;
      border-bottom: 0.2px solid #333;
      text-align: center;
    }
    .deactivate {
      display: none;
    }
    .cricview-menu-search-container {
      display: none;
    }
    .activate {
      display: block;
    }
    .cricview-search-container {
      margin-right: 0;
    }
    .cricview-search-container-open {
      display: none;
      height: 3.6rem;
      width: 100%;
      overflow: hidden;
      margin-left: auto;
      transition: all 1s ease;
      z-index: 50;
    }
    .is-active {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      margin-right: 0;
      margin-left: 0;
      background-color: #000;
      border-radius: 0;
    }

    .search-open-container {
      display: flex;
      background-color: #000;
      height: 100%;
      align-items: center;
      width: 100%;
    }
    .search-input-box {
      height: fit-content;
      flex: 10 1;
      .input {
        width: 100%;
        background: none;
        outline: none;
        border: none;
        color: white;
        padding-left: 3rem;
        height: 100%;
      }
    }
    .search-button-go {
      top: 50%;
      transition: all 0.2s ease;
      background: none;
      border: none;
      color: white;
      height: 100%;
    }
    .search-button-close {
      height: max-content;
      line-height: normal;
      margin-right: 10px;
      fill: #ff3333;
    }
  }
}
