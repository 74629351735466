.topsearches-marquee {
  display: flex;
  width: 100%;
  align-items: center;
  color: #d8d8d8;
  height: 46px;
  background-color: #2e2087;
  a {
    color: #d8d8d8;
    &:hover {
      color: #d8d8d8;
      text-decoration: underline;
    }
  }

  .marquee-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #2e2087;
    height: 44px;
    .title-text {
      color: #fff;
      font-size: 17px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 1%;
      width: 150px;
      margin-left: 34px;
      span {
        // color: #232882;
        color: #00bcf3;
      }
    }
    .title-image {
      padding: 2%;
      cursor: pointer;
    }
  }
  .marquee {
    flex: 1 1;
    color: #1565c0;
    font-size: 16px;
    font-weight: bold;
  }
  .margin-span {
    margin: 0 100px;
  }
}
.Blank {
  width: 1px;
  height: 100%;

}

.download {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.download a {
margin-left: 2.5rem;
cursor: pointer;
}
.download_Text {
  margin-left: 1rem;
  margin-right: 0.7rem;
}

.cricview-topsearch-container {
  .jWpUJH {
    bottom: -12px;
  }
  border-radius: 100%/0 0 100px 100px;
  background-image: url("../../Assets/top_searche_sbg.png");
  background-size: 100%;
  width: 100%;
  font-size: 14px;
  color: white;
  a {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
  .cricview-topsearch-wrapper {
    margin: 1% 3%;
    padding-bottom: 2%;
    .topsearch-header {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      .topsearch-header-container {
        display: flex;
        flex-direction: column;
        margin-right: 2%;
        .header-view {
          font-size: 28px;
        }
        .span-view {
        }
      }
      .topsearch-banner-container {
        height: 100%;
        width: 100%;
        flex: 1;
        margin: 0 20%;
        .topsearch-banner {
          width: 100%;
          height: 100%;
        }
      }
    }
    .topsearchs {
      // display: flex;
      width: 100%;
      padding-bottom: 0;
      margin-top: 2%;
    }
  }

  img {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    width: 100%;
    height: 100%;
  }
  .Bg_color {
    object-fit: contain;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .featuredTag {
    background-image: url("../../Assets/featured.png");
    background-repeat: no-repeat;
    // background-size: 40%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .featuredtitle {
    margin: 0 10px;
  }
  .topsearch-item-small {
    width: 100%;
    /* margin: 0 1% 1% 0; */
    position: relative;
    border-radius: 5px;
    height: 280px;

    .topsearch-small-image-container {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      display: flex;
      /* padding-bottom: 75%; */
      height: 100%;
      cursor: pointer;
    }
    .topsearch-small-title-container {
      position: absolute;
      padding: 0 4%;
      width: 100%;
      border-radius: 5px;
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 23%, rgba(12, 35, 123, 0.81) 59%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 50%;
      .title-container-image {
        margin: 2% 0;
      }
      .title-container-date {
        margin: 2% 0;
        font-size: 14px;
      }
      .title-container-text {
        margin: 2% 0;
        font-weight: bold;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
        &:hover {
          text-decoration: underline;
        }
      }
      @media (max-width: 1024px) {
        .title-container-date {
          margin: 2% 0;
          font-size: 10px;
        }
        .title-container-text {
          margin: 2% 0;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: transform 1s;
      object-fit: contain;
      border-radius: 5px;
      &:hover {
        transform: scale(1.1);
        border-radius: 5px;
      }
    }
  }
  .extra-width {
    width: 500px;
  }
}
@media (max-width: 1024px) {
  .extra-width {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .topsearches-marquee {
    .marquee-title-container {
      .title-text {
        font-size: 9px;
        width: 75px;
        margin-left: 25px;
      }
    }
    .marquee {
      font-size: 12px;
    }
  }
  .cricview-topsearch-container {
    background-image: url("../../Assets/group-6.png");
    background-size: 132%;
    .cricview-topsearch-wrapper {
      margin: 4%;
      .topsearch-header {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        .topsearch-banner-container {
          margin: 0;
        }
      }
    }
  }
  .extra-width {
    width: 100% !important;
  }
}
