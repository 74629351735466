.carousel-container {
    width: 100%;
    .carousel-header-title {
        font-weight: 900;
        font-family: "gilroy black";
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #232882;
        font-size: 32px;
        text-transform: uppercase;
        margin: 2% 0;
        span {
            color: #00baf2;
        }
    }
    .iframe-container {
        padding-top: 100%;
        max-width: 550px;
        position: relative;
        margin: 0 auto;
        min-height: 400px;
        height: 550px;

        iframe {
            border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            html {
                body {
                    overflow: hidden;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .carousel-header-title {
            font-size: 24px;
        }
    }
    .placeholder {
        width: 100%;
        height: 628px;
        background-image: linear-gradient(45deg, darkblue, red);
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
