@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Main_Card {
    background-color: white;
    width: 400px;
    height: 250px;
    position: relative;
    color: black;
}
hr {
    width: 100%;
    margin-bottom: 8px;
}
.Header {
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 100%;
}
.Live{
  color: black;
  padding-bottom: 10px;
  background-color: white;
  margin: 7px auto 0 auto;
}
.Live h3 {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(15, 15, 15);
}
.Live span {
    color: #c60f5e;
    font-family: 'Poppins', sans-serif;
}
/* .Img {
    width: 10%;

} */
.Footer span {
    color: #c60f5e;
    font-family: 'Poppins', sans-serif;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.center span {
    color: #c60f5e;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-left: 8%;
}

.Game span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c60f5e;
    font-size: 14px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
}
.Img img {
    width: 30px;
    height: 30px;
} 
.images {
    display: flex;
    flex-direction: row;
}
.BeforeImg {
    max-width: 100px;
    max-height: 100px;
    margin: auto;
    padding-top: 10px;
}
.BeforeImg img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    box-shadow: 2px 3px 8px rgb(95, 95, 95);
}
.Venue, .Footer {
    color: rgb(71, 71, 71);
    font-size: 10px;
    letter-spacing: 1px;
    padding-bottom: 7px;
    padding-left: 2px;
}
.Score {
    padding-bottom: 7px;
    font-size: 14px;
    letter-spacing: 1px;
    padding-left: 2px;
}
.Team_Score {
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
    width: 50%;
    align-items: center;
    justify-content: center;
}
.border_right {
    border-right: 1.5px solid rgb(221, 221, 221);
}
.scoreCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Team_1, .Team_2 {
    width: 70%;
    padding-bottom: 7px;
    font-family: 'Poppins', sans-serif;
    /* padding-left: 2px; */
    color: black;
}
.Team_1 h3, .Team_2 h3 {
    font-size: 13px;
    /* line-height: 30px; */
    font-weight: bold;
    color: rgb(43, 43, 43);
}
.TeamImg {
    width: 80px;
    height: 80px;
    margin: 0 4px 0 8px;
    padding: 2px;
}
.TeamImg img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-image: none;
    box-shadow: 1px 2px 4px rgb(95, 95, 95);
}
.Score_1 , .Score_2 {
    width: 10%;
}
.Score_1 h3, .Score_2 h3 {
    font-size: 13px;
    font-weight: bold;
    /* padding-left: 13px; */
    /* margin-top: 7px; */
    margin: 7px auto 0 auto;
    padding-right: 2px;
    line-height: 27px;
    color: rgb(43, 43, 43);
    font-family: 'Poppins', sans-serif;
}
.Footer{
    /* background-color: rgb(218, 218, 218); */
    padding-bottom: 0;
    padding-left: 8px;
    display: flex;
    justify-content: left;
    width: 100%;
    /* margin-left: 22%; */
}
.Footer span {
    font-weight:bold;
}
.Row {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 4px;
}
.Bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 75px;
    /* background-color:rgb(218, 218, 218); */
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 10px;
    justify-content:center;
}
.Footer h5 {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
   padding: 2px;
   font-size: 10px;
   /* font-weight: lighter; */
   color: rgb(59, 59, 59);
}
.border {
    margin: 20px;
    padding: 7px;

}
.Gradient {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
    width: 100%;
    box-shadow: 1px 2px 4px;
    height: 100%;
    border-radius: 5px;
}
.width_G {
    width: 35%;
}

@media only screen and (max-width: 1000px) {
    .Main_Card {
        width: 360px;
        height: 250px;
    }
}
@media only screen and (max-width: 950px) {
    .Main_Card {
        width: 340px;
        height: 250px;
    }
    .Live h3 {
        font-size: 14px;
    }
}
@media only screen and (max-width: 750px) {
    .Main_Card {
        width: 320px;
        height: 250px;
    }
    .Live h3 {
        font-size: 14px;
    }
    .Gradient {
        height: 90%;
    }
}
@media only screen and (max-width: 700px) {
    .Main_Card {
        width: 300px;
        height: 250px;
    }
    .Live h3 {
        font-size: 13.5px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Gradient {
        height: 90%;
    }
}

@media only screen and (max-width: 650px) {
    .Main_Card {
        width: 260px;
        height: 230px;
    }
    .Live h3 {
        font-size: 11.5px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Gradient {
        height: 90%;
    }
    .TeamImg {
        width: 70px;
        height: 70px;
    }
    .TeamImg img {
        width: 70px;
        height: 70px;
    }
    .Footer h5 {
        width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
        font-size: 9px;
     }
}

@media only screen and (max-width: 550px) {
    .Main_Card {
        width: 240px;
        height: 230px;
    }
    .Live h3 {
        font-size: 10.5px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Score_1, .Score_2 {
        width: 15%;
    }
    .Gradient {
        height: 90%;
    }
    .TeamImg {
        width: 70px;
        height: 70px;
    }
    .TeamImg img {
        width: 70px;
        height: 70px;
    }
    .Footer h5 {
        width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
        font-size: 8px;
     }
}
@media only screen and (max-width: 450px) {
    .Main_Card {
        width: 350px;
        height: 260px;
        box-shadow: 1px 2px 4px;
        border-radius: 5px;
    }
    .Live h3 {
        font-size: 15px;
    }
    .Score_1 h3, .Score_2 h3 {
        margin: 7px auto 0 auto;
    }
    .Gradient {
        height: 90%;
        box-shadow: none;
    }
    .TeamImg {
        width: 90px;
        height: 90px;
    }
    .TeamImg img {
        width: 90px;
        height: 90px;
    }
    .Footer h5 {
        width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
        font-size: 11px;
     }
}

