.liveTrayBackground{
    background-image: url('/static/media/top_searche_sbg.62cad5f8.png');
    width: 100%;
    min-height: 320px;
    .card:hover .sharing-footer {
        display : block !important;

    }
    #whatsApforMobile{
        display: none;
      }
    .sharing-footer {
        z-index: 99999;
        display: none !important;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: none;
        .share-btn-container {
            margin-right: 10px !important;
            width: 15px !important;
        }
        .share-btns{
            flex-direction: column;
            margin-top: 59px;
    background-color: #ebedf087;
    padding: 20px 0 10px 11px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
        }
        .share-btns i {
            font-size: 20px;
        }
    }
    .score {
        color: white;
        font-size: 18px;
        padding: 1% 10%;
        margin-bottom: 3%;
        border-bottom: 1px solid white;
    }
    .matchName * {
        color:white !important;
        text-decoration: none !important;
        font-size: 18px !important;
        font-weight: normal !important;
        font-family: sans-serif !important;
        font-style: normal !important;
      }
.liveTray-container{
    width: 100%;
    padding: 0 1%;
    .marquee-block {
        position: relative;
    }
    .centerAlign{
        display: flex;
    justify-content: center;
    }
    .voteText{
        display: flex;
        font-size: 25px;
        font-weight: 900;
        align-items: center;
        justify-content: center;
        padding: 1% 0;
        .vote{
            margin-right: 1%;
            width: 8%;
            img{
                width: 100%;
                display: block;
            }
        }
        .now{
            margin-right: 1%;
            font-size: 35px;
            color: white;;
            font-weight: 900;
            font-family: 'gilroy black';
        }
        .ex{
            font-size: 40px;
            color: red;
            font-weight: 900;
            font-family: 'gilroy black';
        }
    }
    .marquee-container {
        overflow: hidden;
        height: 275px;
       
    background-color: #ff360030;
        border-radius: 5px;
    }
    .marquee-inner {
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
      }

    .totalCards {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        margin-top: 0.7%;
       
    }
    .flexx{
        display: flex;
    }
    .play {
      
        animation-duration: 25s;
        animation-name: marquee;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        
      }
      
      .play:hover{
        animation-play-state: paused;
      }
      
      
      @keyframes marquee {
    
        0% {
            left: 70%;
          }
          100% {
            left: -100%;
          }
      }
    .card {
        cursor: pointer;
        // float: left;
        transition: all 0.2s ease-out;
        // width: 250px;
        margin-right: 1%;
        background: white;
        position: relative;
        // height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8%; 
        border-radius: 5px;
        border: 1px solid rgb(88, 88, 88);
        border-radius: 0.5rem;
        box-sizing: border-box;
        box-shadow: 0 1em 1em -1em #daaeae;
        background-image: linear-gradient(150deg, #0f0949 50%, #4c0c4d 50%);
        max-width: 253px;
        margin: 0 auto;
        .cricView {
            width: 30%;
            border-radius: 50%;
    overflow: hidden;
            img{
                width: 100%;
                display: block;
            }
        }
        .title{
            overflow: hidden;
            height: 42px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 18px;
            font-weight: 900;
            text-align:center;
            margin: 5% 5% 2% 5%;
        
            p{
                color:white ;
                line-height: 1.2;
            }
        }
        .countDown{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 700;
            padding-bottom: 5%;
            width: 100%;
            color: white;
           
        }
        .endText{
            text-align: center;
            }
        &:hover{
            border: 1px solid white;
            background-image: linear-gradient(150deg, #4c0c4d 50%, #0f0949 50%);
          }
    }
    .liveTray{
        font-size: 32px;
        text-transform: uppercase;
        font-weight: bold;
        flex: 1 1;
        font-family: 'gilroy black';
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
       
        padding: 1%;
        width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .liveGif{
        width: 12%;
        margin-right: 1%;
        margin-left: 1%;


        img{
            width: 100%;
            display: block;
        }
    }
}


}

@media(max-width:1024px){
    // height: 455px;
  }
@media(max-width:768px){
    .sharing-footer {
        display : block !important;
    }
    // height: 425px;
    .voteText .vote {
        margin-right: 2%!important;
        width: 13%!important;
    }
    .liveGif{
        width: 20% !important;
    }
       
    @keyframes marquee {
    
        0% {
            left: 40%;
          }
          100% {
            left: -100%;
          }
      }
}
@media (max-width: 425px){
    #whatsApforMobile{
        display: block;
      }
      #whatsApforDesktop{
        display: none;
      }
    .sharing-footer {
        display : block !important;
    }
    //  height: 500px;
    .voteText .vote {
        margin-right: 2%!important;
        width: 27%!important;
    }
    .liveGif {
        width: 35% !important;
    }
    .marquee-container {
        overflow: hidden;
        height: 280px;
        background-color: white;
        border-radius: 5px;
    }
    .totalCards {
        margin-top: 0.5%;
       
    }
    @keyframes marquee {
        
        0% {
            left: 20%;
          }
          100% {
            left: -100%;
          }
      }
    }
    @media (max-width: 340px){
        // height: 435px;
    }

}
