.cricview-talnet-pay{



    .candidate_details {
        max-width: 255px;
        margin: auto;
        padding: 20px 0px;
        clear: both;
        color: #999;
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        line-height: 1.80857;
    }
    .razorpay_form {
        text-align: center;
    }
    input.razorpay-payment-button {
        background: #d8302f;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        transition: ease all 0.5s;
        max-width: 200px;
        text-align: center;
        width: 100%;
        margin: 20px 20px;
        margin: auto;
        margin: 20px 20px;
        padding: 10px;
        cursor: pointer;
    }
}