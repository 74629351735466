.cricview-article-container {
	background-image: url("../../Assets/Must_read_bg.svg");
	background-repeat: no-repeat;
	border-top-left-radius: 4px;
    border-top-right-radius: 4px;
	width: 100%;
	background-size: 100%;
	margin: 1% 0 0 0;
	background-color: #0c237b;

	img {
		object-fit: contain;
		background-image: linear-gradient(45deg, #ff3333, #0d0f60);
	}

	.cricview-article-wrapper {
		margin: 4% 0;
		margin-left:3%;
		.article-header {
			display: flex;
			margin-bottom: 3%;
			margin-left: 7%;
			align-items: center;
			.article-header-title {
				color: #fff;
				font-family: "gilroy black";
				font-size: 32px;
				text-transform: uppercase;
				font-weight: 900;
				flex: 1;
				span {
					// color: #232882;
					color: #00bcf3;
				}
			}
			.article-viewall {
				background-color: #ff3333;
				color: #fff;
				font-size: 16px;
				border-radius: 50px;
				text-decoration: none;
				text-align: center;
				padding-top: 10px;
				width: 150px;
				margin-right: 20px;
				height: 44px;
				box-shadow: 0 5px 18px #ff3333;
				&:hover {
					box-shadow: none;
				}
			}
		}
		.articles {
			display: flex;
			width: 100%;
			a {
				text-decoration: none;
				color: inherit;
			}
			.article-grid {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				.article-flex {
					display: flex;
					width: 100%;
				}
				.article-item-large {
					width: 75%;
					padding: 0 1em;
				}
				.article-item-medium {
					width: 31%;
					margin-right: 1.5%;
					border-radius: 5px;
					background-color: white;
					// height: 100%;
				}
				.article-item-small {
					width: 23%;
					margin-right: 1.5%;
					margin-top: 20px;
					margin-bottom: 20px;
					border-radius: 5px;
					background-color: #fff;

				}
				.image-container-small {
					overflow: hidden;
					border-radius: 5px 5px 0 0;
					position: relative;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: top center;
					padding-bottom: 75%;
					.image {
						position: absolute;
					}
				}
				.title-container-small {
					margin-bottom: 10px;
					border-radius: 0px 0px 10px 10px;
					padding: 10px;

				}
				.image-container-medium {
					border-radius: 5px 5px 0 0;
					overflow: hidden;
					position: relative;
					padding-bottom: 75%;
					.image {
						position: absolute;
					}
				}
				.title-container-medium {
					margin-bottom: 10px;
					padding: 10px;
				}
				.publishDate {
					padding: 10px 20px 0;
					font-size: 14px;
					font-weight: bold;
					text-transform: uppercase;
					height: 20%;
				}
				.publishDate-small {
					padding: 10px 20px 0;
					font-size: 12px;
					font-weight: bold;
					text-transform: uppercase;
					height: 20%;
				}
				.title {
					padding: 10px 20px 0;
					font-size: 16px;
					font-weight: 800;
					font-weight: bold;
					height: 80%;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2; /* number of lines to show */
					-webkit-box-orient: vertical;
				}
				.title:hover {
					overflow: visible;
					position: relative;
					text-overflow: ellipsis;
					display: block;
				}
				.title-small {
					padding: 10px 20px 0;
					font-size: 14px;
					font-weight: 800;
					font-weight: bold;
					height: 80%;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2; /* number of lines to show */
					-webkit-box-orient: vertical;
				}
				.title-small:hover {
					overflow: visible;
					position: relative;
					text-overflow: ellipsis;
					display: block;
				}
				.image {
					width: 100%;
					height: 100%;
					border-radius: 5px 5px 0 0;
					transition: transform 1s;
					&:hover {
						transform: scale(1.1);
						border-radius: 5px 5px 0 0;
					}
				}
				@media (max-width: 1024px) {
					.publishDate {
						font-size: 14px;
					}
					.publishDate-small {
						font-size: 12px;
					}
					.title {
						font-size: 16px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3; /* number of lines to show */
						-webkit-box-orient: vertical;
					}
					.title-small {
						font-size: 14px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3; /* number of lines to show */
						-webkit-box-orient: vertical;
					}
					.title-container-small {
						margin-bottom: 10px;
					}
					.title-container-medium {
						margin-bottom: 10px;
						padding: 10px;
					}
				}
			}
		}
		.viewall-mobile {
			display: none;
		}
	}
	.play-button {
		position: absolute;
		left: 40%;
		top: 40%;
		border-radius: 50%;
		transition: box-shadow 0.5s;
		z-index: 1;
		&:hover {
			box-shadow: 0 0px 36px 18px red;
		}
	}
}
@media (max-width: 768px) {
	.border1 {
		background-size: 21px 4px, 0px 0px;
	}
	.cricview-article-container {
		background-color: darkblue;
		background-image: url("../../Assets/mustreadbg.png");
		.cricview-article-wrapper {
			margin: 4% 0;
			margin-left:4%;
			.article-header {
				margin-left: 0;
				.article-viewall {
					display: none;
				}
			}
			.articles {
				.article-grid {
					
					.article-item-medium {
						width: 46%;
						margin: 2% 0;
						height: unset;
						margin-right: 3%;
						margin-top: 20px;

					}
					.article-item-small {
						width: 46%;
						margin: 2% 0;
						margin-right: 3%;
						margin-top: 20px;
						height: unset;
					}
					.title-container-small {
						height: 150px;
						margin-bottom: 10px;
					}
					.title-container-medium {
						height: 150px;
						margin-bottom: 10px;
						padding: 10px;
					}
				}
			}
			.viewall-mobile {
				display: flex;
				.article-viewall-mobile {
					background-color: #ff3333;
					color: #fff;
					font-size: 16px;
					border-radius: 50px;
					text-decoration: none;
					text-align: center;
					padding-top: 10px;
					width: 100%;
					margin-right: 0;
					height: 44px;
					box-shadow: 0 5px 18px #ff3333;
					&:hover {
						box-shadow: none;
					}
				}
			}
		}
		.play-button {
			position: absolute;
			left: 40%;
			top: 40%;
			border-radius: 50%;
			transition: box-shadow 0.5s;
			z-index: 1;
			&:hover {
				box-shadow: 0 0px 36px 18px red;
			}
		}
	}
}
@media (max-width: 425px) {
	
	.cricview-article-container {
		.cricview-article-wrapper {
			margin: 4% 3%;
			.article-header {
				margin-left: 0;
				.article-header-title {
					font-size: 25px;
				}
			}
			.articles {
				.article-grid {
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					.article-flex {
						display: flex;
						width: 100%;
					}
					.article-item-large {
						width: 75%;
						padding: 0 1em;
					}
					.article-item-medium {
						width: 100%;
						border-radius: 5px;
						margin-right: 0px;
						background-color: white;
					}
					.article-item-small {
						width: 100%;
						margin-right: 0px;
						border-radius: 5px;
						background-color: #fff;

					}
					.image-container-small {
						overflow: hidden;
						border-radius: 5px 5px 0 0;
						position: relative;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: top center;
						padding-bottom: 75%;
						.image {
							position: absolute;
						}
					}
					.title-container-small {
						margin-bottom: 10px;
						border-radius: 0px 0px 10px 10px;
						padding: 10px;

					}
					.image-container-medium {
						border-radius: 5px 5px 0 0;
						overflow: hidden;
						position: relative;
						padding-bottom: 75%;
						.image {
							position: absolute;
						}
					}
					.title-container-medium {
						margin-bottom: 10px;
						padding: 10px;
					}
					.publishDate {
						padding: 10px 20px 0;
						font-size: 14px;
						font-weight: bold;
						text-transform: uppercase;
						height: 20%;
					}
					.publishDate-small {
						padding: 10px 20px 0;
						font-size: 12px;
						font-weight: bold;
						text-transform: uppercase;
						height: 20%;
					}
					.title {
						padding: 10px 20px 0;
						font-size: 16px;
						font-weight: 800;
						font-weight: bold;
						height: 80%;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2; /* number of lines to show */
						-webkit-box-orient: vertical;
					}
					.title:hover {
						overflow: visible;
						position: relative;
						text-overflow: ellipsis;
						display: block;
					}
					.title-small {
						padding: 10px 20px 0;
						font-size: 14px;
						font-weight: 800;
						font-weight: bold;
						height: 80%;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2; /* number of lines to show */
						-webkit-box-orient: vertical;
					}
					.title-small:hover {
						overflow: visible;
						position: relative;
						text-overflow: ellipsis;
						display: block;
					}
					.image {
						width: 100%;
						height: 100%;
						border-radius: 5px 5px 0 0;
						transition: transform 1s;
						&:hover {
							transform: scale(1.1);
							border-radius: 5px 5px 0 0;
						}
					}
				}
			}
		}
	}
}
