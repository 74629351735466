.container2{
    display: flex;
    justify-content: center;
    width: 100%;
    input[type=submit]:enabled {
        background-color: red;
        border: none;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        width: 150px;
        margin-right: 10px;
        padding: 10px 20px;
        font-weight: bold;
        margin-top: 20px;
        cursor: pointer;
      }
      
      input[type=submit]:disabled {
        background-color: rgb(170, 107, 107);
        border: none;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        text-decoration: none;
        text-align: center;
        width: 150px;
        margin-right: 10px;
        padding: 10px 20px;
        font-weight: bold;
        margin-top: 20px;
        cursor: not-allowed ;
      }

}
.categories{
  margin-top: 0.5%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #66615b;
  line-height: normal;
  font-size: 14px;
  transition: color .3s ease-in-out,border-color .3s ease-in-out,background-color .3s ease-in-out;
  box-shadow: none;
  width: 100%;
  height: unset;
  padding: 10px 10px 10px 10px;
}
.butn{
    justify-content: center;
    align-items: center;
    display: flex;
}
.title_style input{
  background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color .3s ease-in-out,border-color .3s ease-in-out,background-color .3s ease-in-out;
    box-shadow: none;
    width: 100%;
    height: unset;
    padding: 10px 10px 10px 10px;
  }
.title_style label{
  font-size: 1.8571em;
  color: black;
  font-weight: 600;
}
.col10{
  margin: 0 auto;
  width: 83.33333%;
}
.title_style {
  margin-top: 4%;
}
