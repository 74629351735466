.cricview-talnet-reg {
    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
    }
    b,
    strong {
        font-weight: 700;
    }
    section#contant {
        float: left;
        width: 100%;
        padding: 50px 0 25px;
        background: #fff;
    }
    .main-heading {
        display: inline-block;
        text-align: center;
        width: 100%;
        margin-bottom: 35px;
    }
    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
    .container {
        width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .contact {
        float: left;
        width: 100%;
    }
    .col-md-12 {
        width: 100%;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .col-md-4 {
        width: 33.33333333%;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .contact-us {
        margin-top: 30px;
    }
    section.main-heading h2 {
        float: left;
        width: 100%;
        text-align: center;
        font-size: 25px;
        line-height: 21px;
        font-weight: 600;
        color: #222;
        padding: 0;
        margin: 0;
        position: relative;
    }
    section.main-heading h2::after {
        width: 50px;
        height: 4px;
        background: #d8302f;
        margin: 15px auto 0;
        display: block;
        position: relative;
        content: "";
    }

    .btn-lg {
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
    }

    .btn-danger {
        color: #fff;
        background-color: #d9534f;
        border-color: #d43f3a;
    }
    .text-center {
        text-align: center;
    }

    section#contant h4 {
        float: left;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0 0 10px;
        font-size: 18px;
        color: #222;
    }
    section.team h4 {
        font-size: 21px !important;
        padding: 0 !important;
        font-weight: 600;
        margin-top: 10px !important;
    }
    .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }
    section#contant p {
        float: left;
        width: 100%;
        line-height: 30px;
        font-size: 15px;
        text-align: left;
        font-weight: 300;
    }
    .main-heading p {
        color: #363636;
        margin-top: 0;
    }

    .contact-info {
        float: left;
        width: 100%;
        background: #f8f8f8;
        border: solid #ccc 1px;
        padding: 32px 35px 30px 35px;
        text-align: left;
        margin-top: 30px;
    }
    .contact-info div.kode-section-title {
        float: left;
        width: 100%;
    }
    .kode-form-list {
        float: left;
        width: 100%;
        position: relative;
        padding-left: 30px;
    }
    .kode-form-list li {
        float: left;
        width: 100%;
    }
    .kode-form-list li img {
        font-size: 24px;
        width: 25px;
        text-align: center;
        position: absolute;
        left: 0;
    }
    section#contant p strong {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        font-weight: 600;
    }
    @media (max-width: 992px) {
        .img-responsive {
            display: block;
            max-width: 100%;
            height: auto;
        }
        .row {
            margin-right: -15px;
            margin-left: -15px;
        }
    }
    @media (max-width: 768px) {
        .container {
            width: 90%;
        }
        .row {
            margin-right: -15px;
            margin-left: 0px;
        }
        .img-responsive {
            display: block;
            // max-width: 150%;
            height: auto;
            position: relative;
            // right: 40px;
        }
        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7 {
            width: unset;
            height: 35%;
        }
        section#contant p {
            float: left;
            width: 100%;
            line-height: 24px;
            font-size: 13px;
            text-align: left;
            font-weight: 400;
        }
        .contact-info {
            padding: 20px 20px 20px 20px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}
.marginT {
    margin-top: 2% !important;
}
