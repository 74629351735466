.cricview-talnet-page{
    width: 100%;
    h1,h2,h3,h4,h5,h6{
            letter-spacing: 0;
            font-weight: normal;
            position: relative;
            padding: 0 0 10px 0;
            font-weight: normal;
            line-height: normal;
            color: #1f1f1f;
            margin: 0
    }
    section {
        float: left;
        width: 100%;
        position: relative;
    }
    header {
        float: left;
        width: 100%;
        position: absolute;
        z-index: 1;
        min-height: 150px;
    }
    .header-top {
        margin: 15px 0 10px;
        float: right;
        width: 100%;
    }
    .col-md-6 {
        width: 50%;
    }
    .logo {
        float: left;
        padding: 7px 0 6px;
        margin: 0 0 0 0px;
        position: relative;
    }
    .right_top_section {
        float: right;
        padding: 17px 0 0;
    }
    .login {
        float: right;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .login li {
        float: left;
        margin: 0 0 0 10px;
        font-weight: 500;
    }
    .login li .login-reg {
        padding: 9px 25px;
        color: #fff;
        font-size: 13px;
        background: #d8302f;
        border-radius: 50px;
        float: left;
    }
    .login li .login-reg:hover, .login li .login-reg:focus {
        background: #fff;
        color: #333;
    }
    .full-slider {
        position: relative;
    }
    .carousel {
        position: relative;
    }
    .carousel-indicators {
        bottom: 45px;
        margin-bottom: 0;
    }
    .carousel-indicators li {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 1px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #000;
        background-color: rgba(0,0,0,0);
        border: 1px solid #fff;
        border-radius: 10px;
    }
    .carousel-indicators .active {
        width: 12px;
        height: 12px;
        margin: 0;
        background-color: #fff;
    }
    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .carousel-inner{
        .kNBmiK {
            margin: 0px;
        }
        .jWpUJH{
            bottom: 0px;
        }
        .kVtMtA{
            border-radius: 0%;
            left: 30px;
        }
        .bhRnqO{
            border-radius: 0%;
            right: 30px;
        }
    }
    .item {
        width: 100%;
        height: 1024px;
   }
   .item-image{
        position: absolute;
        width: 100%;
        height: 100%;
   }
   .carousel-caption {
        position: relative;
        bottom: 0;
        z-index: 10;
        padding: 0 5% 0 0;
        padding-bottom: 0px;
        color: #fff;
        text-align: center;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        height: 100%;
        display: flex;
        align-items: center;
    }
    .col-lg-6{
        width: 50%;
    }
    .slider-contant h3 {
        font-size: 58px;
        font-weight: 700;
        text-shadow: none;
        color: #fff;
        text-align: left;
        line-height: 52px;
        letter-spacing: -2px;
   }
   .slider-contant p {
        font-size: 2rem;
    }
   .carousel-caption p {
        text-align: left;
        font-weight: 400;
        color: #fff;
        line-height: 24px;
        font-size: 2rem;
    }
    .color-yellow {
        color:#ffcb05;
   }
    .slider-contant .btn-talent {
        float: left;
        background: #d8302f;
        color: #fff;
        padding: 10px 20px;
        font-size: 15px;
        border-radius: 50px;
        margin-top: 5px;
        font-weight: 500;
        transition: ease all 1s;
    }
    .carousel-caption .btn-talent {
        text-shadow: none;
        margin-top: 1em;
    }
    .btn-talent{
        display: inline-block;
        margin-bottom: 0; 
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
    }
    .btn-talent:hover,.btn-talent:focus {
        background:#111;
        color:#fff !important;
        transition:ease a11 1s;
   }
    .feature-post {
        float: left;
        width: 100%;
        position: relative;
    }
    .feature-post.small-blog {
        background: #f9f9f9;
        padding: 15px 0;
        border: solid #dfdfdf 1px;
        margin-bottom: 30px;
    }
    .col-md-5 {
        width: 41.66666667%;
    }
    .feature-img {
        float: left;
        width: 100%;
        position: relative;
    }
    .img-responsive, .thumbnail a>img, .thumbnail>img {
        display: block;
        max-width: 100%;
        height: auto;
    }
    .col-md-7 {
        width: 58.33333333%;
    }
    .feature-cont {
        float: right;
        background: transparent;
        width: 100%;
        margin: 0px 0 25px 0;
        position: relative;
        z-index: 1;
        padding: 0 0;
    }
    .post-heading {
        float: left;
        width: 100%;
        text-align: left;
        margin-top: 15px;
    }
    .post-heading h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin: 0 0 15px 0;
        padding: 0;
    }
    .post-heading h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        margin: 0 0 15px 0;
        padding: 0;
    }
    .container-talnet {
        width: 95%;
        margin-right: auto;
        margin-left: auto;
    }
    .row-talnet {
        margin-right: -15px;
        margin-left: -15px;
    }
    .col-md-4 .col-md-2 .col-md-3 .col-md-6,.col-lg-6{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .col-md-4 {
        width: 33.33333333%;
    }
    .col-md-2 {
        width: 16.66666667%;
    }
    .col-md-3{
        width: 25%;
    }
    .full-talnet {
        position: relative;
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .full-talnet .logo img{
        width: 120px;
    }
    .social-icons {
        list-style: none;
        float: left;
        width: auto;
        padding: 0;
        margin: 0;
    }
    .pull-left {
        float: left!important;
    }
    .social-icons li {
        float: left;
        margin: 0 15px 0 0;
    }
    @media (max-width: 1200px){
        .container-talnet {
            width:95% ;
        }
    }
    @media (min-height: 768px){
        .item-image{
            width: 100%;
        }
    }
    @media (max-width: 992px){
        .container-talnet {
            width: 970px;
        }
        .col-md-4 .col-md-2{
            float: left;
        }
    }   
    @media (max-width: 768px){
        .full-talnet .logo img{
            width: 46px;
        }
        .doJiSR{
               padding-left: 0px; 
    grid-column-gap: 0;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
             grid-template-columns: repeat(3,100%);
        }
        .item {
            height: 425px;
        }
        .carousel-caption {
            position: unset;
            z-index: 10;
            padding: 0 5%;
            padding-bottom: 0px;
            color: #fff;
            text-align: center;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
            height: 100%;
            display: flex;
            align-items: center;
            background: black;
        }
        .container-talnet {
            width: 90%;
        }
        .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7{
            width: unset;
            height: 35%;
        }
        .slider-contant h3 {
            font-size: 14px;
            font-weight: 700;
            text-shadow: none;
            color: #fff;
            text-align: left;
            line-height: 30px;
            letter-spacing: 2px;
        }
        .carousel-caption p {
            font-size: 12px;
            text-align: left;
            font-weight: 100;
            color: #fff;
            line-height: 20px;
        }
        .slider-contant .btn-talent {
            float: left;
            background: #d8302f;
            color: #fff;
            padding: 5px 15px;
            font-size: 13px;
            border-radius: 50px;
            margin-top: 5px;
            font-weight: 500;
            transition: ease all 1s;
        }
        .right_top_section {
            float: right;
            padding: 17px 0 0;
            display: none;
        }
        .post-heading {
            float: left;
            width: 100%;
            text-align: left;
            margin-top: 15px;
        }
        .post-heading h2 {
            font-size: 14px;
            font-weight: 600;
            line-height: 25px;
            margin: 0 0 15px 0;
            padding: 0;
        }
        .post-heading h3 {
            font-size: 12px;
            font-weight: 600;
            line-height: 25px;
            margin: 0 0 15px 0;
            padding: 0;
        }
    }
}
.custom-ui{
    background: #08255d !important;
}
.extra{
    background-clip: padding-box !important;
    color: #f5f0f0 !important;
    font-family: inherit !important;
    letter-spacing: 3px !important;
    width: 50% !important;
    margin: auto !important;
    border: 15px double #08255d !important;
    border-radius: 10px;
    padding: 1% 1%;
}
.ui-text{
    color: white;
    font-size: 20px;
}
.ui-email{
    color: red;
    font-size: 20px;
}
.ui-separator{
    margin: 4%;
}
.ui-process{
    background-color: #e3faff;
    padding: 2%;
    border-radius: 7px;;
}
.ui-process p{
    color: black;
    font-size: 14px;
    line-height: 2.6;
    padding: 0px 9px;
}
.ui-process h3{
    color: #08255d;
    text-align: center;
    font-size: 1.7rem;
}
.ui-rounds{
    margin-top: 2%;
    padding: 0 4%;
}
.comingSoon{
    text-align: left !important;
    color: red !important;
    font-size: 17px !important
}
.ui-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;    
}
.ui-button{
    background: #348e43 !important;
    padding: 12px 18px !important;
    color: #fcfcfc !important;
    font-size: 17px !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
@media (max-width: 768px){
  
    .extra{
        width:70% !important;
    }
}
@media (max-width: 426px){
    .col-md-6{
        height: 65% !important;
    }
    .extra{
        width:87% !important;
    }
    .ui-button {
        padding: 6px 18px !important;
    }
    .extra h2 {
        font-size: 1.5rem;
    }
    .ui-text {
        font-size: 13px;
    }
    .ui-email {
        font-size: 12px;
    }
    .ui-process h3 {
        font-size: 1.2rem;
    }
    .ui-process p {
        font-size: 11px;
        line-height: 1.9;
    }
    .comingSoon {
        font-size: 12px !important;
    }
}