.createMatch {
  margin: 0 auto;
  width: 90%;
  background: aliceblue;
  padding-top: 2%;
  .checkboxes {
    display: flex;
    width: 83.33%;
    margin: 1% auto 0;
    #isGuestMatch{
      zoom: 1.5;
    }
    #isShowVoters{
      zoom: 1.5;
    }
}
  .cpl{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-bottom: 4%;                                                                                                                                                                                      
  margin: 25px auto 0;
  label{
    font-size: 20px !important;
    margin-left: 1%;
  }
  }
  #isCPL{
    zoom: 1.5;
  }
  .timePicker{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 4% auto;
    .chooseTime{
      width: 50%;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    position: relative;
    color: #000000cc;
    }
  }
  
  .loader-container-new {

    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: #fcfcfcbf;
  }
  #loader-new {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
   
    margin: -37px 0 0 -50px;
    border: 10px solid #08255d;
    border-radius: 50%;
    border-top: 10px solid #d6011f;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
    box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px rgb(186, 46, 46);
  }
  .audio-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .uploadAudio {
    margin-top: 1%;
  }
  #dis:disabled {
    background: red !important;
    opacity: 0.4 !important;
    cursor: not-allowed !important;
  }
  .disabledUploadButton {
    opacity: 0.6;
    cursor: not-allowed !important;
    background-color: #5982f7;
    box-shadow: 0 6px darken(#5a28f5, 10%);
    transition: none;
    &:hover {
      box-shadow: 0 6px #3e0ae0 !important;
      top: unset !important;
    }
  }
  .disabledd:disabled {
    background-color: red;
  }
  .question_style {
    text-align: left;
    width: 83.33%;
    box-shadow: 0 10px 6px -6px #777;
    margin: 25px auto 0;
  }
  .header_style input {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    width: 100%;
    height: unset;
    padding: 10px 10px 10px 10px;
  }
  .header_style {
    text-align: center;
    background-color: #5982f7;
    width: 83.33%;
    box-shadow: 0 10px 6px -6px #777;
    margin: 0 auto;
  }
  .header_style label {
    font-size: 2.5em;
    color: white;
    font-weight: 600;
  }
  .vs-box {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }
  .vs-left {
    width: 48%;
    align-items: center;
    display: flex;
    background-color: #f5f5f563;
    flex-direction: column;
    margin-right: 4%;
    padding-top: 2%;
    box-shadow: 2px 4px 22px -6px #62656a;
    padding-bottom: 2%;
    border: 1px solid lightgrey;
    border-radius: 10px;
  }

  .vs-right {
    width: 48%;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f563;
    padding-top: 2%;
    box-shadow: 2px 4px 22px -6px #62656a;
    padding-bottom: 2%;
    border: 1px solid lightgrey;
    border-radius: 10px;
  }
  .preview {
    width: 70%;
    padding: 2%;
    height: 300px;
    border: 1px solid green;
    margin-bottom: 5%;
    background-image: linear-gradient(45deg, #ff3333, #0d0f60);
  }

  .preview-right {
    width: 70%;
    padding: 2%;
    height: 300px;
    border: 1px solid green;
    margin-bottom: 5%;
    background-image: linear-gradient(45deg, #0d0f60, #ff3333);
  }
  .preview video {
    width: 100%;
    height:100%;
  }
  .preview-right video {
    width: 100%;
    height:100%;
  }
  .hidden {
    display: none;
  }
  .labell {
    font-size: 1.5em;
    color: #000000cc;
    font-weight: 600;
  }
  .inputt {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    width: 100%;
    height: unset;
    padding: 8px 8px 8px 9px;
  }
  .player1 {
    margin-bottom: 4%;
  }
  .player1 input:focus {
    border: 1px solid #b3aaaa !important;
  }
  .state1 input:focus {
    border: 1px solid #b3aaaa !important;
  }
  .player2 {
    margin-bottom: 4%;
  }
  .player2 input:focus {
    border: 1px solid #b3aaaa !important;
  }
  .state2 input:focus {
    border: 1px solid #b3aaaa !important;
  }
  .submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    padding-bottom: 2%;
  }
  .submit-button input {
    max-width: max-content;
    background-color: red;
    border: none;
    border-bottom: 2px solid darkred;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    padding: 13px 32px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
  }

  #dis[disabled="disabled"],
  #dis:disabled {
    background: #dddddd;
  }

  .skills {
    margin-bottom: 4%;
  }
  //******************************************textarea*************************
  .box1 {
    display: block;
    width: 70%;
    max-width: 900px;
    margin: 1% auto;
    padding: 1%;
  }

  .box1 h1 {
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    color: #000000cc;
  }

  .box1 textarea {
    border: 1px solid #ddd !important;
    width: 100%;
    color: #66615b;
    font-size: 14px !important;
    min-height: 8rem;
    padding: 1rem 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    background: #ffffff;
    border-radius: 1rem;
    transition: ease 0.2s;
  }

  .box1 textarea::placeholder {
    color: #9fa8da;
  }

  .box1 textarea:focus {
    border: 1px solid #b3aaaa !important;
  }
  textarea:placeholder-shown {
    border: 1px solid #ddd;
  }

  //******************************************textarea*************************

  ////**************for upload button************************

  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  [type="file"] + label {
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-weight: 600;
    /* margin-bottom: 1rem; */
    outline: none;
    padding: 1rem 23px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;

    &.btn-1 {
      background-color: #5982f7;
      box-shadow: 0 6px darken(#5a28f5, 10%);
      transition: none;

      &:hover {
        box-shadow: 0 4px darken(#1513bc, 10%);
        top: 2px;
      }
    }
  }
  .country label {
    font-size: 1.4em;
    color: #000000cc;
    font-weight: 600;
  }

  .country select {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    width: 100%;
    height: unset;
    padding: 8px 8px 8px 9px;
  }
  .widthSame {
    width: 70%;
    margin-bottom: 4%;
  }
  .audio-file {
    margin-top: 2%;
  }
  #audioPlayer {
    width: 565px;
    background-color: #f1f3f4;
    position: relative;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1);
  }
  #audioPlayer:before,
  .effect8:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 10px;
    bottom: 10px;
    left: 0;
    right: 0;
    -moz-border-radius: 100px / 10px;
    border-radius: 100px / 10px;
  }
  #audioPlayer:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
    -moz-transform: skew(8deg) rotate(3deg);
    -ms-transform: skew(8deg) rotate(3deg);
    -o-transform: skew(8deg) rotate(3deg);
    transform: skew(8deg) rotate(3deg);
  }
}
@media (max-width: 426px) {
  .checkboxes {
    flex-direction: column;
  }
  #audioPlayer {
    width: 245px;
  }
  .vs-box {
    display: flex;
    flex-direction: column !important;
    margin-top: 3%;
  }
  .vs-left {
    width: 100% !important;
    padding-bottom: 6% !important;
    margin-bottom: 10%;
    padding-top: 5%;
  }
  .vs-right {
    width: 100% !important;
    padding-bottom: 6% !important;
    padding-top: 5%;
  }
  [type="file"] + label {
    font-size: 13px;
    padding: 9px 13px;
  }
  .submit-button input {
    font-size: 13px;
    padding: 10px 24px;
  }
  .timePicker {
    flex-direction: column !important;
  }
  .chooseTime {
    width: 100% !important;
    margin-bottom: 2%
  }
}

.popUpforMatch {
  height: 500px;
  width: 90%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.copyUrl {
  text-align: center;
  font-size: 1.5rem;
  padding-right: 2%;
  font-weight: 900;
  text-transform: uppercase;
}
.matchButton {
  padding: 11px 30px;
  color: white;
  border: none;
  font-size: 15px;
  letter-spacing: 3px;
  background-image: linear-gradient(45deg, #ff3333, #0d0f60);
  box-shadow: 0 10px 6px -6px #777;
  margin-bottom: 2%;
  cursor: pointer;
}

.pathOne {
  text-align: center;
  padding-top: 1%;
  font-size: 2rem;
  padding-bottom: 1%;
  cursor: pointer;
}
.urll {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
  width: 90%;
}
.rdw-editor-wrapper {
  background-color: white;
}
.rdw-editor-main {
  padding: 0 2%;
  font-size: 15px;
}
//******************SHARE BUTTON******************

.sharing-footer {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background: inherit;
}

.sharing-footer .line {
  margin-top: 5px;
  height: 1px;
  width: 80px;
}

.sharing-footer .share-prompt {
  margin-right: 30px;
}
.sharing-footer .share-prompt .text {
  font-size: 16px;
}

.sharing-footer .share-btns {
  display: flex;
  flex: 1;
}

.sharing-footer .share-btns .share-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  width: 30px;
  cursor: pointer;
}

.sharing-footer .share-btns .share-btn-container:last-child {
  margin-right: 0;
}

.sharing-footer .share-btns i {
  font-size: 36px;
}

.sharing-footer .share-btns .underline {
  height: 2px;
  width: 0%;
  transition: width 0.1s ease-out;
}

.sharing-footer .share-btns .share-btn-container:hover .underline {
  width: 100%;
  transition: width 0.1s ease-out;
}

.sharing-footer .up-next .label {
  font-weight: 800;
}

.sharing-footer .up-next .next-article {
  text-transform: uppercase;
}

.sharing-footer .up-next .line {
  float: right;
}
/* Popup container - can be anything you want */
.popup {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: max-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  bottom: 63%;
  left: 46%;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//***********************************************

