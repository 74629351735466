.cricview-talnet-footer{
    .container-talnet {
        width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .row-talnet {
        margin-right: -15px;
        margin-left: -15px;
    }
    .col-md-4 .col-md-2 .col-md-3 .col-md-6,.col-lg-6{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

    }
    .col-md-4 {
        width: 33.33333333%;
    }
    .col-md-2 {
        width: 16.66666667%;
    }
    .col-md-3{
        width: 25%;
    }

    .full-talnet {
        position: relative;

        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .footer-talnet {
        min-height: auto;
        background: #111;
        float: left;
        width: 100%;
        padding: 0;
        border-top: solid #ddd 1px;
        position: relative;
        background-position: center top;
        background-size: cover;
        color: #fff;
    }
    .full-talnet .logo img{
        width: 120px;
    }
    .footer-widget-talnet {
        float: left;
        width: 100%;
        padding: 50px 0 10px;
    }
    .footer-logo-talnet img {
        width: 180px;
        padding-top: 10px;
    }
    .footer-widget-talnet p {
        font-size: 14px;
        color: #fff;
        margin: 20px 0 10px;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 15px;
        text-align: left;
    }
    .footer-widget-talnet h3 {
        color: #fff;
        text-transform: none;
        font-weight: 500;
        font-size: 18px;
        float: left;
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
        line-height: 20px;
        margin-bottom: 10px;
        border-bottom: solid #333 1px;
        padding-bottom: 15px;
        padding-top: 7px;
    }
    .footer-menu {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 32px;
    }
    .footer-menu li {
        font-weight: 300;
        font-size: 14px;
    }
    .footer-menu li .footer-menu-a {
        color: #fff;
    }
    .address-list {
        float: left;
        width: 100%;
        margin-top: 10px;
    }
    .address-list li {
        float: left;
        width: 100%;
        position: relative;
        line-height: 30px;
        padding-left: 35px;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 14px;
    }
    .address-list li img {
        font-size: 24px;
        width: 25px;
        text-align: center;
        position: absolute;
        left: 0;
    }
    .social-icons {
        list-style: none;
        float: left;
        width: auto;
        padding: 0;
        margin: 0;
        
    }
    .pull-left {
        float: left!important;
    }
    .social-icons li {
        float: left;
        margin: 0 15px 0 0;
    }

    .footer-bottom-talnet {
        float: left;
        width: 100%;
        text-align: center;
        min-height: 45px;
        line-height: 45px;
        border-top: solid #222 1px;
        background: #111;
        color: #fff;
    }
    .footer-bottom-talnet p {
        margin: 0;
        font-size: 14px;
        color: #fff;
        line-height: 50px;
        font-weight: 300;
    }
    @media (max-width: 1200px){
        .container-talnet {
            width: 1170px;
        }
    }
    @media (max-width: 992px){
        .container-talnet {
            width: 970px;
        }
        .col-md-4 .col-md-2{
            float: left;
        }
    }   
    @media (max-width: 768px){
        .full-talnet .logo img{
            width: 46px;
        }
       
        .container-talnet {
            width: 90%;
        }
        .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7{
            width: unset;
            height: 35%;
        }
    }
}
