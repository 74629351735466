.leaderboard-container {
  .main {
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
    //  overflow: hidden; 
    /* background: #4b4168; */
    color: #333;
    position: relative;
    display: flex;
    padding: 50px;

    .center {
      flex-direction: column;
      display: flex;
      padding: 0 3% 3%;
      box-shadow: 0px 0px 5px 2px rgba(191, 184, 191, 1);
      align-items: center;
      /* left: 32%; */
      justify-content: center;
      /* transform: translate(-50%, -50%); */
      /* z-index: 10; */
      font-family: "Red Hat Display", sans-serif;
    }
    .leaderboard-name {
      font-size: 3.1rem;
      background-image: linear-gradient(
        to right,
        red,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        #2f0070,
        red
      );
      color: white;
      padding: 1%;
      width: 100%;
      border-radius: 4rem;
      margin: -25px;
    }
    .top3 {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: end;
      align-items: flex-end;
      color: #4b4168;
      padding-top: 20%;
      width: 100%;
    }
    .top3 .item {
      box-sizing: border-box;
      position: relative;
      background-image: linear-gradient(45deg, red, darkblue);
      color: white;
      width: 10rem;
      height: 11rem;
      text-align: center;
      padding: 2.8rem 0 0;
      margin: 1rem 1rem 2rem;
      border-radius: 0.5rem;
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      cursor: pointer;
      -webkit-transition: -webkit-transform 200ms ease-in-out;
      transition: -webkit-transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
      box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1), 0 1rem 2rem -1rem rgba(0, 0, 0, 0.3);
    }
    .top3 .item .pic {
      position: absolute;
      top: -3rem;
      left: 2.5rem;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      margin-right: 1rem;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2), 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    }
    .top3 .item .pos {
      font-weight: 900;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
    .top3 .item .name {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }
    .top3 .item .score {
      color: white;
    }
    .top3 .item .score:after {
      display: block;
      content: "points";
      opacity: 0.7;
      color: antiquewhite;
    }

    .top3 .item.one {
      width: 12rem;
      height: 13rem;
      padding-top: 3.5rem;
    }
    .top3 .item.one .pic {
      width: 7rem;
      height: 7rem;
      top: -4rem ;
      left: 2.5rem;
    }
    .top3 .item:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    .list {
      padding-left: 2rem;
      margin: 0 auto;
    }
    .list .item {
      position: relative;
      display: -webkit-box;
      display: flex;
      width: 48rem;
      -webkit-box-align: center;
      align-items: center;
      color: white;
      height: 5rem;
      border-radius: 4rem;
      margin-bottom: 2rem;
      background-image: linear-gradient(45deg, darkblue, red);
      -webkit-transform-origin: left;
      transform-origin: left;
      cursor: pointer;
      -webkit-transition: -webkit-transform 200ms ease-in-out;
      transition: -webkit-transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
      box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1), 0 1rem 2rem -1rem rgba(0, 0, 0, 0.3);
    }
    .list .item .pos {
      font-weight: 900;
      position: absolute;
      left: -2rem;
      text-align: center;
      font-size: 1.25rem;
      width: 1.5rem;
      color: black;
      opacity: 0.6;
      -webkit-transition: opacity 200ms ease-in-out;
      transition: opacity 200ms ease-in-out;
    }
    .list .item .pic {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      margin-right: 1rem;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2), 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    }
    .list .item .name {
      -webkit-box-flex: 2;
      flex-grow: 2;
      flex-basis: 10rem;
      font-size: 1.6rem;
    }
    .list .item .score {
      margin-right: 1.5rem;
      color: white;
      font-size: 1.4rem;
    }
    .list .item .score:after {
      margin-right: 1rem;
      content: " points";
      opacity: 0.7;
      color: antiquewhite;
    }
    .list .item:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
    .list .item:hover .pos {
      opacity: 0.8;
    }
    //pagination

    .pagination {
      display: inline-block;
    }

    .pagination a {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color 0.3s;
      border: 1px solid #ddd;
    }

    .pagination a.active {
      background-color: #4caf50;
      color: white;
      border: 1px solid #4caf50;
    }

    .pagination a:hover:not(.active) {
      background-color: #ddd;
    }
  }
  @media (max-width: 426px) {
    .main {
            height: 100% !important;
      width: 100% !important;
      padding: 20px !important;
  }
  .list .item .name {
   
    font-size: 1.1rem !important;
  }
.list .item .score {
    margin-right: 1.5rem;
    font-size: 1.1rem !important;
    margin-left: 1.1rem !important;
  }
  .list .item .score:after {
    margin-right: 0rem !important;
  }
  .center{
    width: 100% !important;
    padding: 3% 3% !important;
  }
  .leaderboard-name {
    font-size: 1.5rem !important;
  }
  .top3{
    padding-top: 30% !important;
    // width: 100% !important;
  }
  .list {
    width: 100% !important;
  }
  .top3 .item{
    flex:1;
    width: 100% !important;
  }
  .top3 .item .pic{
    left: 8% !important;
    width: 85% !important;
    height: 50% !important;
    top: -2.5rem !important;
  }
  .top3 .item.one .pic {
    width: 90% !important;
    height: 50% !important;
    top: -3.5rem !important;
    left: 6% !important;
  }
 .list .item {
    width: 100% !important;
}
}
}