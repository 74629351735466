.profile-container{
    background-color: #ebedf0;
    .partTwo{
        display:flex;
        flex-direction: row;
        width: 90%;
    margin: auto;
    margin-top: 5%;
    }
    .upload-container {
        width: 100%;
    }
    .uploadV{
        margin-right: 2%;
    }
}