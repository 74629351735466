* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.excel {
    height: 100vh;
    width: 100%;
}
/* Navbar */
.Menu {
    margin: 5rem 6rem;
}
.border {
    border: .2rem solid #c60f5e; 
    border-radius: 5px;
    padding: 0 2rem;
}
.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
}
.Navbar2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
}
.navItems a {
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 5px;
    transition: 100ms;
    font-weight: bold;
    color: black;
    letter-spacing: 1px;
}
.navItems a:hover {
    border: .2rem solid #c60f5e; 
    padding: 2px 5px;
    font-size: 1.6rem;
}
.navItems {
    display: flex;
    justify-content: space-around;
}
.Heading {
    padding: 5rem 8rem 4rem 8rem;
}
.Heading h1 {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4rem;
}
.Heading h5 {
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
    color: rgb(116, 116, 116);
    margin-bottom: 1rem;
}
.Heading hr {
    width: 100%;
}
/* Telegram Links */

.TelegramLinks {
    padding: 5rem 2rem;
}
.TelegramLinks hr {
    width: 25%;
    background-color: #01579b;
}
.componentStyling {
    padding: 0 2rem 0 2rem;
}
.componentStyling hr {
    background-color: #c60f5e;
}
.round {
    border-radius: 8px;
}
h1 {
letter-spacing: 1px;
}
hr {
    height: 3px;
    width: 33%;
    background-color:#c60f5e;
    margin-bottom: 1rem;
    border: none;
}
.TelegramLinks h5 {
    letter-spacing: 1px;
    color: rgb(116, 116, 116);
    margin-bottom: 1rem;
}

/* Media Query */

@media (max-width: 1024px) {
    html {
        font-size: 50%;
    }
    hr {
        width: 38%;
    }
    .TelegramLinks hr {
        width: 28%;
    }
}
@media (max-width: 768px) {
    html {
        font-size: 40%;
    }
    hr {
        width: 40%;
    }
    .TelegramLinks hr {
        width: 30%;
    }
}
@media (max-width: 600px) {
    html {
        font-size: 30%;
    }
    hr {
        width: 45%;
    }
    .TelegramLinks hr {
        width: 35%;
    }
}
@media (max-width: 480px) {
    html {
        font-size: 20%;
    }
    hr {
        width: 35%;
    }
    .Heading h1 {
        font-size: 6rem;
    }
    .Heading h5 {
        font-size: 2rem;
    }
    .TelegramLinks hr {
        width: 25%;
    }
}
@media (max-width: 340px) {
    html {
        font-size: 15%;
    }
    .Heading h1 {
        font-size: 6rem;
    }
    .Heading h5 {
        font-size: 2.5rem;
    }
    .TelegramLinks h1, .componentStyling h1 {
        font-size: 4rem;
    }
    hr {
        width: 57%;
    }
    .TelegramLinks hr {
        width: 40%;
    }
}
