article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

.footer {
  button {
    background: none;
    border: none;
  }
}

.flex-grid {
  align-items: stretch;
  display: flex !important;
  flex-wrap: wrap;
}

.inline-list {
  font-size: 0;
}

.inline-list > li {
  display: inline-block;
}

[class*="col-"] {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  float: left;
}

.col--centered {
  margin: 0 auto;
  float: none;
}

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.66667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33333%;
}

.col-5 {
  width: 41.66667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33333%;
}

.col-8 {
  width: 66.66667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33333%;
}

.col-11 {
  width: 91.66667%;
}

.col-12 {
  width: 100%;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background: #fff;
  color: #001036;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  font-size: 1.4em;
}

:active,
:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  margin: 0;
}

ol,
ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.footer ol li,
.footer ul li {
  list-style-type: none;
}

.footer {
  background-color: #fff;
  // padding-top: 4rem;
  position: relative;
  width: 100%;
}

.footer:before {
  content: "";
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 4rem;
  right: 0;
  opacity: 0.4;
  background-image: radial-gradient(circle at 48% 0, #95a1a8, #fff);
}

.footer.u-no-padding:before {
  height: 0;
}

svg.icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}

[class*="flag-"] {
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.4rem;
}

h6 {
  font-size: 1.2rem;
}

p {
  line-height: 1.6;
}

p,
p a {
  color: #001036;
}

p a {
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}

// --------------------------- Santy ---------------------------

.footer:before {
  content: "";
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 4rem;
  right: 0;
  opacity: 0.4;
  background-image: radial-gradient(circle at 48% 0, #95a1a8, #fff);
}

.global-footer__list {
  padding: 0 1.5rem;
  z-index: 10;
}

.global-footer--active .global-footer__background:after,
.global-footer--active .global-footer__background:before {
  top: 0;
}

.global-footer__background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../../Assets/footer_bg.jpg");
}

.global-footer__background_follow {
  content: "";
  position: absolute;
  width: 696px;
  height: 81px;
 // background-image: url("../../Assets/Footer_follow_bg.png");
  right: 0%;
  top: 0px;
  background-repeat: no-repeat;
}

.global-footer__branding {
  display: block;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  // padding: 0 1rem
}

.wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.66667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33333%;
}

.col-5 {
  width: 41.66667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33333%;
}

.col-8 {
  width: 66.66667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33333%;
}

.col-11 {
  width: 91.66667%;
}

.col-12 {
  width: 100%;
}

.footer-logo {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 68px;
  padding: 20px 0 0;
  color: #fff;
  position: absolute;
}
.slash-line {
  width: 1px;
  height: 42px;
  border-radius: 100px;
  background-color: #4976b6;
  margin: 0 10px;
}
.poweredby {
  width: 56px;
  height: 11px;
  font-family: Gilroy;
  font-size: 9px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.nexg {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.image-tiktok {
  width: 100%;
}
.roww3{
  padding: 0 3% 1% 3%;
}


.social-follow-footer {
  display: flex;
  justify-content: center;
 // flex-wrap: wrap;
 // z-index: 10;
 // padding: 15px 0 0 0;
}

.social-follow-footer:after {
  content: "";
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
  bottom: -3.2rem;
  right: 0;
  opacity: 0.3;
  background-image: radial-gradient(circle at 48% 0, #fff, hsla(0, 0%, 100%, 0));
}

.social-follow-footer:after {
  display: none;
}

.social-follow-footer__subtitle {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;
  text-transform: uppercase;
}
.follow-us-on {
  // width: 30%;
  padding: 15px 0px 0px 0px;
  white-space: nowrap;
  text-align: -webkit-center;
  color: #ffff;
}

.social-follow-footer__item {
  position: relative;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  margin-right: 1rem;
}

.social-follow-footer__item:last-child {
  margin-right: 0;
}

.social-follow-footer__link {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.social-follow-footer__link .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2.6rem;
  width: 2.6rem;
  transition: fill 0.2s ease-in-out;
  // fill: #fff
}

.social-follow-footer__link.facebook {
  background-color: #3b5998;
}

.social-follow-footer__link.facebook:focus {
  background-color: #fff;
}

.social-follow-footer__link.facebook:focus .icon {
  fill: #3b5998;
}
.social-follow-footer-item {
  position: relative;
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
 // margin-right: 1rem;
}
.showList:hover .dropp_down{
  visibility: visible;
  color: #2f2f2f;
}
.drop_down_link{
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: 1.3rem;
  color: #2f2f2f;
  text-transform: uppercase;
  line-height: 1;
  border-bottom: 1px solid #efefef;
}
.dropp_down{
  min-width: 8rem;
  display: block;
  position: absolute;
  //top: 95%;
  transform: scale(1) translateX(-50%);
  visibility: hidden;
  text-align: left;
  padding: 1.5rem;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  z-index: 210;
  cursor: default;
}

.global-footer__social-container {
  margin-bottom: 3rem;
  padding: 2px 0 0 515px;
}
.global-footer__lists {
  width: 100%;
  padding: 9rem 8rem 0rem 8rem;
  display: flex;
  justify-content: space-between;
}
.global-footer__lists hr.separator {
  display: none;
}
.global-footer__title {
  color: #fff;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1rem;
}
.global-footer__title:hover {
  color: #ff3333;
}

.global-footer__item {
  margin-bottom: 0.8rem;
}

.global-footer__link {
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none;
}
.global-footer__link:focus,
.global-footer__link:hover {
  text-decoration: underline;
  color: #fff;
}

.global-footer__link .icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin: -4px 0 0;
  fill: #fff;
}

hr.separator {
  border-top: 0.1px dotted #fff;
  position: relative;
  opacity: 0.2;
}

.global-footer-corporate {
  position: relative;
  // background-color: #04064d;
  height: 5.7rem;
  background: transparent;
}

.global-footer-corporate__item {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 600;
}

.global-footer-corporate__list {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  color: #51626f;
  opacity: 0.7;
}
.global-footer-corporate__list {
  color: #fff;
  opacity: 1;
}

.global-footer-corporate__link {
  color: #51626f;
  text-decoration: none;
}
.global-footer-corporate__link,
.global-footer-corporate__list {
  color: #fff;
  opacity: 1;
}

.global-footer-corporate__link:focus {
  text-decoration: underline;
  color: #fff;
}

.social-follow-footer__link.twitter {
  background-color: #55acee;
}

.social-follow-footer__link.twitter:focus {
  background-color: #fff;
}

.social-follow-footer__link.twitter:focus .icon {
  fill: #55acee;
}

.social-follow-footer__link.youtube {
  background-color: #b00;
}

.social-follow-footer__link.youtube:focus {
  background-color: #fff;
}

.social-follow-footer__link.youtube:focus .icon {
  fill: #b00;
}
.social-follow-footer__link.tiktok {
  overflow: hidden;
}

.social-follow-footer__link.instagram {
  background-color: #fff;
}

.social-follow-footer__link.instagram .icon {
  z-index: 10;
}

.social-follow-footer__link.instagram:focus:before {
  opacity: 0;
}

.social-follow-footer__link.instagram:focus .icon {
  fill: #000;
}
svg.icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}

.footer-corporate-2nd-line {
  display: contents;
  font-size: 1.5rem;
}

.collapsible {
  display: none;
}
.active {
  display: none;
}

.content {
  display: block;
}
@media (max-width: 1050px) {
  .collapsible {
    background-image: url("../../Assets/arrow-close.png");
    background-repeat: no-repeat;
    background-color: transparent;
    width: 45px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    float: right;
    display: block;
  }

  .active {
    background-image: url("../../Assets/arrow-expand.png");
    width: 36px;
    border: none;
    text-align: left;
    outline: none;
    background-repeat: no-repeat;
    background-color: transparent;
    display: block;
  }
  .copy-right {
    padding-top: 10px;
  }
  .content {
    display: none;
    clear: both;
    padding-top: 10px;
  }

  .global-footer__list {
    padding: 0;
    margin: 1rem 0;
  }

  .footer-menu-head {
    float: left;
  }

  .footer-top-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .global-footer__social-container {
    margin-bottom: 0rem;
    padding: 2px 0px;
  }

  .footer-logo {
    width: 100%;
    height: 61px;
    position: relative;
    padding: 0px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-align: center;
  }

  .footer-logo .image {
    vertical-align: middle;
    border-style: none;
    border-radius: 2px;
    transition: transform 1s;
    width: 86%;
  }

  .global-footer__background_follow {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    background-color: #00166c;
    background-image: none;
    padding: 12px;
  }

  .follow-us-on {
    width: 100%;
    padding: 0px 0 0 2rem;
    white-space: nowrap;
    text-align: left;
    color: #ffff;
    font-size: 1.2rem;
  }

  .social-follow-footer-item {
    position: relative;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    margin-left: 1rem;
  }

  .global-footer__lists {
    width: 100%;
    padding: 0px 1.5em;
    padding-left: 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .global-footer__lists hr.separator {
    display: block;
  }
  .global-footer__item {
    margin: 1rem 0 1rem 1.5rem;
  }

  .global-footer__item {
    margin-bottom: 0.8rem;
  }

  .global-footer__title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0rem;
  }

  .social-follow-footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 10;
    padding-right: 200px;
    padding-left: 200px;
  }
  .social-follow-footer__link {
    display: block;
    height: 83%;
    width: 83%;
    position: relative;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  .social-follow-footer__link .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    transition: fill 0.2s ease-in-out;
  }

  .global-footer-corporate {
    height: 100%;
    padding: 16px;
  }

  .footer-items {
    display: none;
  }

  .global-footer-corporate__list {
    flex-direction: column-reverse;
    width: 100%;
  }

  .global-footer-corporate__item {
    font-size: 1.2rem;
    line-height: 2;
    justify-content: center;
  }

  .footer-corporate-2nd-line {
    display: inline-flex;
    width: 90%;
    justify-content: flex-start;
  }

  .footer-corporate-2nd-line .item-name {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
  }
  .nexg {
    width: 85%;
    object-fit: contain;
  }
}
@media (max-width: 768px) {
  .collapsible {
    background-image: url("../../Assets/arrow-close.png");
    background-repeat: no-repeat;
    background-color: transparent;
    width: 45px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    float: right;
    display: block;
  }

  .active {
    background-image: url("../../Assets/arrow-expand.png");
    width: 36px;
    border: none;
    text-align: left;
    outline: none;
    background-repeat: no-repeat;
    background-color: transparent;
    display: block;
  }
  .copy-right {
    padding-top: 10px;
  }
  .content {
    display: none;
    clear: both;
    padding-top: 10px;
  }

  .global-footer__list {
    padding: 0;
    padding: 1rem 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dotted #999;
  }

  .footer-menu-head {
    float: left;
  }

  .footer-top-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .global-footer__social-container {
    margin-bottom: 0rem;
    padding: 2px 0px;
  }

  .footer-logo {
    width: 100%;
    height: 61px;
    position: relative;
    padding: 0px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-align: center;
  }

  .footer-logo .image {
    vertical-align: middle;
    border-style: none;
    border-radius: 2px;
    transition: transform 1s;
    width: 86%;
  }

  .global-footer__background_follow {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    background-color: #00166c;
    background-image: none;
    padding: 12px;
  }

  .follow-us-on {
    width: 100%;
    padding: 0 0 0 14px;
    white-space: nowrap;
    text-align: left;
    color: #ffff;
    font-size: 1.2rem;
  }

  .social-follow-footer-item {
    position: relative;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    margin-left: 1rem;
  }

  .global-footer__lists {
    width: 100%;
    padding: 0px 1.5em;
    // padding-left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .global-footer__lists hr.separator {
    display: block;
  }
  .global-footer__item {
    margin: 1rem 0 1rem 1.5rem;
  }

  .global-footer__item {
    margin-bottom: 0.8rem;
  }

  .global-footer__title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0rem;
  }

  .social-follow-footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 10;
    padding: 0px;
  }
  .social-follow-footer__link {
    display: block;
    height: 83%;
    width: 83%;
    position: relative;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  .social-follow-footer__link .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    transition: fill 0.2s ease-in-out;
  }

  .global-footer-corporate {
    height: 100%;
    padding: 16px 0 16px 0;
  }

  .footer-items {
    display: none;
  }

  .global-footer-corporate__list {
    flex-direction: column-reverse;
    width: 100%;
  }

  .global-footer-corporate__item {
    font-size: 1.2rem;
    line-height: 2;
    justify-content: center;
  }

  .footer-corporate-2nd-line {
    display: inline-flex;
    width: 90%;
    justify-content: flex-start;
  }

  .footer-corporate-2nd-line .item-name {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
  }
  .nexg {
    width: 85%;
    object-fit: contain;
  }
}
