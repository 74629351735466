.cricview-talnet-contact{
    .error {
        color: #d8302f;
        font-size: 20px;
        margin-bottom: 10px;
    }

    * {
        font-family: 'Poppins', sans-serif;
    }
    form {
        color: #999;
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        line-height: 1.80857;
    }
    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
    }
    b, strong {
        font-weight: 700;
    }
   
    .contact-us input:focus{
        border: 2px solid #1b1919;
        border-radius: 5px;
    }
    section#contant {
        float: left;
        width: 100%;
        padding: 50px 0 25px;
        background: #fff;
    }
    .main-heading {
        display: inline-block;
        text-align: center;
        width: 100%;
        margin-bottom: 35px;
    }
    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
    .container {
        width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .contact {
        float: left;
        width: 100%;
    }
    .col-md-12 {
        width: 100%;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .col-md-4 {
        width: 33.33333333%;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
   
    section.main-heading h2 {
        float: left;
        width: 100%;
        text-align: center;
        font-size: 25px;
        line-height: 21px;
        font-weight: 600;
        color: #222;
        padding: 0;
        margin: 0;
        position: relative;
    }
    section.main-heading h2::after {
        width: 50px;
        height: 4px;
        background: #d8302f;
        margin: 15px auto 0;
        display: block;
        position: relative;
        content: "";
    }

    .contact-us {
        margin-top: 30px;
    }
    #contactform {
        float: left;
        width: 100%;
    }
    #contactform ul {
        float: left;
        width: 100%;
    }
    #contactform ul li {
        float: left;
        width: 100%;
    }
    .contact-us input, .contact-us textarea {
        float: left;
        width: 100%;
        height: 44px;
        font-size: 16px;
        padding: 8px 15px;
        border: solid #ccc 1px;
        margin-bottom: 25px;
    }
    .form-group {
        margin-bottom: 15px;
    }
    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
    }
    .pull-left {
        float: left!important;
    }
    .input-group {
        position: relative;
        display: table;
        border-collapse: separate;
    }
    .input-group .form-control, .input-group-addon, .input-group-btn {
        display: table-cell;
    }
    .input-group-addon, .input-group-btn {
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
    }
    .input-group-btn {
        position: relative;
        font-size: 0;
        white-space: nowrap;
    }
    .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group {
        margin-right: -1px;
    }
    
    .input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn-group:not(:last-child)>.btn, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .input-group-btn>.btn {
        position: relative;
    }
    .btn-file {
        position: relative;
        overflow: hidden;
    }
    .btn {
        float: left;
        background: #d8302f;
        color: #fff;
        padding: 10px 20px;
        font-size: 15px;
        border-radius: 50px;
        margin-top: 5px;
        font-weight: 500;
        transition: ease all 1s;
    }
    .btn:hover, .btn:focus {
        background: #111;
        color: #fff !important;
        transition: ease a11 1s;
    }
    .btn {
        border: 0 !important;
    }
    a, .btn {
        text-decoration: none !important;
        outline: none !important;
        transition: all .3s ease-in-out;
    }
    .active{
        display: block;
        border-radius: 0%;
    }
    .btn.active, .btn:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    }
    .btn-primary.active, .btn-primary:active, .open>.dropdown-toggle.btn-primary {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
        background-image: none;
        #radio1{
            border-radius: 0%;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        #radio6{
            border-radius: 0%;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    }
    .btn-group, .btn-group-vertical {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    
    #radioBtn .notActive {
        color: #3276b1;
        background-color: #fff;
        border: 2px solid #285e8e;
        border-radius: 0%;
        

    }
    #radioBtn{
        cursor: pointer;
        #radio1{
            border-radius: 0%;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        #radio6{
            border-radius: 0%;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    }
    #radioBtn .notActive:hover {
        color: #fff;
        background: #285e8e;
        
    }
    .btn-file input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
    }
    .btnR{
        border-radius: 0%;
    }
    #radio1{
        border-radius: 0%;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }
    #radio6{
        border-radius: 0%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }
    #img-upload {
        width: 40%;
    }
    .input-group-btn {
        position: relative;
        font-size: 0;
        white-space: nowrap;
    }
    .income_radio .btn{
        border-radius: 50px;
    }
    
    #contactform ul li .thbg-color {
        height: 46px;
        background: #d8302f;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        transition: ease all 0.5s;
        text-align: center;
        padding: 10px;
        cursor: pointer;
    }
    #contactform ul li .thbg-color:hover, #contactform ul li .thbg-color:focus {
        background: #111;
    }
    .btn-primary.active:hover{
        background-color: #204d74;
    }
    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }




    .col-md-6{
        width: 50%;
    }
    section#contant h4 {
        float: left;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0 0 10px;
        font-size: 18px;
        color: #222;
    }
    section.team h4 {
        font-size: 21px !important;
        padding: 0 !important;
        font-weight: 600;
        margin-top: 10px !important;
    }
    
    section#contant p {
        float: left;
        width: 100%;
        line-height: 30px;
        font-size: 15px;
        text-align: left;
        font-weight: 300;
    }
   





    



    .contact-info {
        float: left;
        width: 100%;
        background: #f8f8f8;
        border: solid #ccc 1px;
        padding: 32px 35px 30px 35px;
        text-align: left;
        margin-top: 30px;
    }
    .contact-info div.kode-section-title {
        float: left;
        width: 100%;
    }
    .kode-form-list {
        float: left;
        width: 100%;
        position: relative;
        padding-left: 30px;
    }
    .kode-form-list li {
        float: left;
        width: 100%;
    }
    .kode-form-list li img {
        font-size: 24px;
        width: 25px;
        text-align: center;
        position: absolute;
        left: 0;
    }
    section#contant p strong {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        font-weight: 600;
    }
    
    @media (max-width: 768px){
        .container {
            width: 90%;
        
        }
        .main-heading {
           
            margin-bottom: 0;
        }
        .btnR .btn{
            width: 20%;
        }
        .income_radio .btn{
            width: 100%;
        }
        .row {
            margin-right: -15px;
            margin-left: 0px;
        }
        .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7{
            width: unset;
            height: 35%;
        }
        section#contant p {
            float: left;
            width: 100%;
            line-height: 24px;
            font-size: 13px;
            text-align: left;
            font-weight: 400;
        }
        .contact-info {
            padding: 20px 20px 20px 20px;
            margin-top: 30px;
            margin-bottom: 30px;
        }   

    }
    
}