.loader-container {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
}
#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -75px 0 0 -75px;
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #480b48;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Add animation to "page content" */
.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0;
    }
    to {
        bottom: 0px;
        opacity: 1;
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}
.language-bar-container {
    width: 100%;
    height: 40px;
    background-color: #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-size: 13px;
    font-weight: bold;
}
.tab-container {
    // width:33.3%;
    // flex: 1;
    padding: 0 10px;
    &:first-child {
        margin-left: 34px;
        padding-left: 0;
    }
    &:last-child {
        margin-right: 34px;
        padding-right: 0;
    }
}
.flex-3 {
    flex: 3;
}
.flex-6 {
    flex: 6;
}
.flex-9 {
    flex: 9;
}
.drop-down-cont {
    margin: 7px 20px;
    display: table-cell;
    flex: auto;
}
.drop-down-cont select {
    border-radius: 4px;
    padding: 0 10px;
}
.registration-container {
    float: right;
    margin-right: 10px;
}
.rectangle {
    width: 80px;
    height: 30px;
    border-radius: 2px;
    background-color: #ff3333;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.black {
    height: 17px;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00061a;
    text-align: center;
}
.white {
    // width: 33px;
    // height: 17px;
    margin: 0 20px;
    opacity: 0.8;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
@media only screen and (max-width: 768px) {
    .language-bar-container {
        /* width:100%; */
        min-height: 40px;
        background-color: #000;
        position: relative;
        /* display: inline; */
    }
    .drop-down-cont {
        margin: 7px 10px;
        display: table-cell;
        /* flex:auto; */
    }
    .drop-down-cont select {
        border-radius: 4px;
        padding: 0 10px;
    }
    .tab-container {
        // width:33.3%;
        // flex: 1;
        padding: 0 10px;
        &:first-child {
            margin-left: 34px;
            padding-left: 0;
        }
        &:last-child {
            margin-right: 14px;
            padding-right: 0;
        }
    }
}
.language-container {
    width: max-content;
    color: white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    .displayLanguage,
    .displayCountry {
        // text-transform: uppercase;
    }
}
.language-container img {
    // height:28px;
}

.language-container .icon {
    color: white;
    width: 2.5rem;
    height: 2.5rem;
}
.team-icon {
    color: white;
    width: 1.5rem;
    height: 1.5rem;
}
.language-container span {
    padding-left: 10px;
    color: white;
    // text-transform: uppercase;
}
.show {
    display: block;
}
.hide {
    display: none;
}
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    color: black;
    // max-width: 250px;
    // width: max-content;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    // padding: 12px 16px;
    padding: 6% 6%;
    z-index: 9999;
    li {
        list-style-type: none;
        border-bottom: 1px solid #CCC;
        padding: 2% 0;
    }
}

.dropdown:hover .dropdown-content {
    display: block;
}
