.userProfile-container{
    display: flex;
    background-color: white;
    width: 100%;
    padding: 3%;
    flex-direction: row;
    .userInfo{
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-left: 4%;
        padding-top: 2%;
    }
    .userInfo-One {
        display: flex;
        flex-direction: row;
        margin-bottom: 2%;
    padding-bottom: 1%;
    }
    .userInfor-two {
        display: flex;
        flex-direction: row,
     
    }
  .basicc{
    font-size: 12px;
    color: #808080a6;
    flex: 1 1 2%;
    border-right: 1px solid;
    text-align: center;
  }
  .basiccTwo{
    color: black;
    font-size: 18px;
  }
    .userName {
        font-size: 25px;
        font-weight: 900;
        color: #08255d;
        padding-right: 2%; 
    }
    .userImage{
        width: 15vw;
        height: 15vw;
        padding: 0%;
        border-radius: 100%;
        border: 1px solid #808080b3;
        overflow: hidden;
    }
    .userImage img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .userId {
        font-size: 15px;
        margin-left: 5%;
        background-color: #18bda6;
        color: white;
        padding: 1% 4%;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}
