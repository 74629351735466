.editor-Images-carousel{
  .contain{
    width: 100%;
  }
  .loader-container-new {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: #fcfcfcbf;
  }
  #loader-new {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -29px 0 0 -32px;
    border: 5px solid #08255d;
    border-radius: 50%;
    border-top: 5px solid #d6011f;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px #ba2e2e;
  }
  .rowww__inner{
      // display: flex;

      -webkit-transition: 450ms -webkit-transform;
      transition: 450ms -webkit-transform;
      transition: 450ms transform;
      transition: 450ms transform, 450ms -webkit-transform;
      // font-size: 0;
      white-space: nowrap;
      margin: 70.3125px 0;
      padding-bottom: 10px;
  }
  .tile{
    position: relative;
    display: inline-block;
    width: 300px;
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition: 450ms all;
    transition: 450ms all;
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
  .tile:hover .hid{
    margin-left: 40%;
    position: relative;

  }
  .tile__img{
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: contain;
  }
  .tile__media{
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
  }
  .row__inner:hover {
    -webkit-transform: translate3d(-62.5px, 0, 0);
    transform: translate3d(-62.5px, 0, 0);
  }
  .row__inner:hover .tile {
    opacity: 0.3;
  }
  .row__inner:hover .tile:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1;
  }
  .tile:hover ~ .tile {
    -webkit-transform: translate3d(125px, 0, 0);
    transform: translate3d(125px, 0, 0);
  }
 


  .tile__title{
    position: absolute;
    bottom: 0;
    padding: 10px;
  }
  .rowww{
      overflow-y: hidden;
    width: 100%
  }

  .rowww__inner:hover .tile{
    opacity: 0.3;
    &:hover{
      -webkit-transform: scale(1.1);
      transform: scale(1.1,1.1);
      opacity: 1
    }
  // And finally move all tiles right of the current tile to the right,
  // so its flush against the hovered tile
  .tile:hover ~ .tile{
      transform: scale(1.5); 
  }
  }

  .circle {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: #5812bf;
      position: relative;
    
    }
    .plus{
      padding-left: 4%;
      margin-bottom: 3%;
    }
    .circle::after {
      content: " ";
      position: absolute;
      display: block;
      background-color: #fff;
      height: 10px;
      margin-top: -5px;
      top: 50%;
      left: 5px;
      right: 5px;
      z-index: 9;
    }
    .circle::before {
      content: " ";
      position: absolute;
      display: block;
      background-color: #fff;
      width: 10px;
      margin-left: -5px;
      left: 50%;
      top: 5px;
      bottom: 5px;
      z-index: 9;
    }
    .container1{
      display: flex;
      align-items: center;
    }
    .showmore_button {
      background-color: #ff3333;
      border: none;
      color: #fff;
      font-size: 16px;
      border-radius: 50px;
      text-decoration: none;
      text-align: center;
      width: 150px;
      margin-right: 10px;
      padding: 10px 20px;
      font-weight: bold;
      margin-top: 20px;
      cursor: pointer;
  }
  
  .butn{
      justify-content: center;
      align-items: center;
      display: flex;
  }
  .showmore_button_disabled {
      background-color: black;
      border: none;
      color: #fff;
      font-size: 16px;
      border-radius: 50px;
      text-decoration: none;
      text-align: center;
      width: 150px;
      margin-right: 10px;
      padding: 10px 20px;
      font-weight: bold;
      margin-top: 20px;
      cursor: pointer;
  }
  .full{
    margin-top: 5%;
  }
  .container2{
      display: flex;
      justify-content: center;
      margin-top: 39%;
      position: absolute;
      width: 83%;

  }
  .tile input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .hid{
        display: block;
        margin-left: 40%;
        margin-top: 4%;
        position: relative;
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
    
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
    }
    
    /* On mouse-over, add a grey background color */
    .tile:hover input ~ .checkmark {
      background-color: #ccc;
    }
    
    /* When the checkbox is checked, add a blue background */
    .tile input:checked ~ .checkmark {
      background-color: #2196F3;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    .tile input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the checkmark/indicator */
    .tile .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
}
 