.play-box{
    margin: 10px;
    height: 100%;
    display: flex;
    border: 15px double #08255d;
    align-items: center;
    background-clip: padding-box;
    flex-direction: column;
    justify-content: center;
    background-color: #08255d;
    $primary-blue: #0080FF;
$fancy:'Fredoka One';

.flexx{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.part1{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
}
.part2{
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome{
    margin: 5% auto;
    color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.welcome h1{
    letter-spacing: 8px;
}
.welcomme-inner1{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.welcomme-inner2{
    display: flex;
    flex-direction: column;
    /* width: 75%; */
    margin-left: 14%;
    align-items: center;
    justify-content: center;
}
.flexx{
    margin: 5% auto;
}
.btn,
a.btn {
  display: inline-block;
  text-transform: none;
  cursor: pointer;
  text-decoration: none;
  line-height: 1em;
  transition: all .35s cubic-bezier(.1, 0, 0, 1);
  border-radius: 6px;
  padding: 15px 20px;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  margin: auto 30px;
  font-family: $fancy;
  letter-spacing: .1em;
  /*border: 1px solid rgba(0, 0, 0, 0);*/
  text-shadow: 0 0 rgba(0, 0, 0, 0);
}

.btn,
a.btn:hover {
  border-radius: 8px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, .1), 0 4px 8px rgba(0, 0, 0, .2);
  /*border: 1px solid rgba(0, 0, 0, .1);*/
  transform: scale(1.06);
  text-shadow: 0 -1px rgba(0, 0, 0, .1);
}

.btn,
a.btn:active {
  transform: scale(0.98);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .2), 0 1px 3px rgba(0, 0, 0, .3);
  /*border: 1px solid rgba(0, 0, 0, .1);*/
}

.btn,
a.btn:visited {
  transform: scale(1);
}

.blue,
a.blue {
    color: #08255d;
    font-weight: bold;
    background: white;
    white-space: nowrap;
    font-size: 17px;
    position: relative;
    font-family: inherit;

}
.blue, a.image{
    background-image: url(/static/media/cricview_logo-192x192.3347558d.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 50px;
}
.image:hover{
    background-image: url(/static/media/cricview_logo-192x192.3347558d.png) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    padding-left: 50px !important; 
    
}
.blue,
a.blue:hover {
  background: lighten(white, 4%);
}

.blue,
a.blue:active {
  background: darken(white, 5%);
}
@media (max-width: 426px) {
    .welcomme-inner2 {
        flex-direction: column;
        width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0 !important;
    }
    .flexx {
        
        flex-direction: column;
    }
    .welcome {
        flex-direction: column;
    }
    .welcomme-inner1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}







}