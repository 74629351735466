.banner-container{
    background-color: #fff;
    position: relative;

}
.banner-info{
    position: absolute;
    color: white;
    padding:10px 10px;
    max-width: 50%;
    font-size: 16px;
    font-weight: 800;
    margin: 40px;
}
.banner-info-btn-con{
  padding:20px;
}
/* .banner-info-btn{ */
  .banner-info-btn-con a{
    border:1px solid #e42e6a;
    background-color: #e42e6a;
    border-radius: 30px;
    font-size: 14px;
    margin:20px;
    color: white;
    font-weight: 800;
    padding:5px 20px ;
    /* cursor: pointer; */
    text-decoration: none;
}
.modal-close {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
  }
  .img-close {
    height: 50%;
    width: 50%;
  }
  
  /* ----------------banner-css */
  .img-banner{
    width: 100%;       
    object-fit: fill;
  }
  .img-banner img{
    width: 100%;
    height: 100%;
        display: block; 
  }
  @media only screen and (max-width: 768px){
    .banner-info{
        max-width: 100% !important;
        padding-bottom: 0;
        margin-bottom: 0;
        bottom: 0;
    }
    .modal-close {
      position: absolute;
      left: 5px;
      top: 5px;
      cursor: pointer;
      border: none;
      z-index: 100;
    }
    .img-close {
      height: 25%;
      width: 25%;
    }
    /* .banner-container */
  }