.allMatches-container{
    display: flex;
    width: 90%;
    margin: auto;
    .votersList {
      background: linear-gradient(#d6369d, #08255d);
      color: white;
      font-size: 18px;
      letter-spacing: 2px;  
    }
    .namePhone{
      background: linear-gradient(#d6369d, #08255d);
      color: white;
    }
.matchNamePopup * {
  text-transform: uppercase;
  color:white !important;
  text-decoration: none !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: sans-serif !important;
  font-style: normal !important;
}
    .showVotes{
          background-color: #3b841d !important;
         }
         .popUp-container{
          position: absolute;
          width: 50%;
          margin: 0px auto;
          display: flex;
          flex-direction: column;
          font-size: 20px;
          .tablevote{
            max-height: 400px;
            padding: 1% 0;
            overflow: hidden;
            overflow-y: auto;
            table{
              table-layout: fixed;
            }
          }
          .closeVote{
            width: 4%;
            margin-left: 99%;
            cursor: pointer;
            img{
              width: 100%;
              display: block;
            }
          }
          .srNo{
            flex:1
          }
          .player1{
            flex:1
          }
          .player2{
            flex:1
          }
          thead th {
            background: linear-gradient(#d6369d, #08255d);
        }caption {
          font-weight: bold;
          padding: 10px;
          background: linear-gradient(#d6369d, #08255d);
         }
        .customer-table {
          margin: 0 auto;
          box-shadow: 0 2px 2px #a9a9a9;
        }}
         .centerPop{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
         }
    .matchName * {
      text-transform: uppercase;
      color:black !important;
      text-decoration: none !important;
      font-size: 18px !important;
      font-weight: normal !important;
      font-family: sans-serif !important;
      font-style: normal !important;
    }
    .notStartedLive{
      width: 100%;
    display: flex;
    flex-direction: column;
    }
    .loader-container-new {
      position: fixed;
      z-index: 99999;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: #fcfcfced;
    }
    #loader-new {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      // margin: -75px 0 0 -75px;
      border: 5px solid #08255d;
      border-radius: 50%;
      border-top: 5px solid #d6011f;
      width: 60px;
      height: 60px;
      animation: spin 1s linear infinite;
      box-shadow: 2px 6px 16px #2d5ab6, 2px -6px 16px #ba2e2e;
    }
    .delete{
      padding: 9px 18px;
      color: white;
      border: none;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 3px;
      background-color: red;
      box-shadow: 0 10px 6px -6px #777;
      cursor: pointer;
    }
    .table1{
        width:100%
    }
    .table1 th{
        text-align: center;
    }
    .centerText{
        text-align: center;
    }
    
    .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: #2196F3;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: #2196F3;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      //**************************************


.noLiveData{
  padding: 2% 2%;
    font-size: 20px
}


.table{
  overflow-x: auto;
}
/* Table */
.customer-table {
	font-size: 0.625em;
	font-family: "Noto Sans KR", sans-serif;
	width: 100%;
	max-width: 1024px;
	margin: 20px auto;
	border-collapse: collapse;
	text-align: center;
	box-shadow: 0 2px 2px #e6e6e6;
}

caption {
	font-weight: bold;
	padding: 10px;
  background: linear-gradient(#3682d6,#08255d);
  font-size: 21px;
  letter-spacing: 1px;
}
thead{
    font-size: 15px;
}
thead th {
	background: linear-gradient(#3682d6,#08255d);
}

caption,
thead th {
	color: #fff;
}

th {
    padding: 10px 5px;
    font-size: 14px;
}

tfoot {
	text-align: left;
	background-color: #e7dfd5;
	font-weight: lighter;
	font-size: 0.8em;
}

tbody th,
tbody td {
	text-align: center;
}

tbody tr {
	border-bottom: 2px solid transparent;
}

tbody tr:hover {
	border-bottom: 2px solid #174485;
}

tbody tr:nth-of-type(even) {
	background-color: #e7dfd5;
}
.go-to-match {
  padding: 9px 18px;
  color: white;
  border: none;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  background-color: black;
  box-shadow: 0 10px 6px -6px #777;
  cursor: pointer;
  margin: 5px;
}
/* Media Queries */
@media (max-width: 320px) {
	.customer-table {
		font-size: 0.55em;
  }
  
}

@media (min-width: 411px) {
	.customer-table {
		font-size: 0.71875em; /*11.5px*/
	}
}
@media(max-width:426px){
  .closeVote {
    width: 12% !important;
  }
  #loader-new {
    margin: -30px 0 0 -28px;
  }
  caption {
    font-size: 15px;
  }
  th {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
	body {
		font-size: 1.125em; /*16px*/
	}

	caption {
		padding: 20px;
	}
}

@media (min-width: 1024px) {
	body {
		font-size: 1.25em; /*20px*/
	}
}

}
@media(max-width:321px){
//   .allMatches-container {
   
//     overflow-x: scroll;
// }
}
.errorApi{
  position: fixed;
  z-index: 99999;
  top: 13%;
  left: 0;
  right: 0;
  text-align: center;
  width: max-content;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 800;
  color: #f5eeee;
  background-color: #333c;
  padding: 1% 3%;
  margin: auto;
 }

.noData{
    align-items: center;
    justify-content: center;
    min-height: 400px;
    width: 100%;
    color: red;
    font-size: 24px;
}