.appcms {
  background: #ffffff;
  color: #002f87;
  font-family: inherit;

  .twitter-tweet {
    margin: auto;
  }
  .content-external {
    p {
      color: #002f87;
      font-size: 16px;
    }
  }
  .content,
  .content-external {
    img {
      max-width: 100%;
      height: 100%;
      margin: auto;
    }
  }
}
.site-background-color {
  background-color: #002f87;
}
.icon {
  width: 36px;
}
.infinite-article-container .article-container .article__body .content img {
  display: block;
  padding: 10px;
}
.infinite-article-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  background: inherit;
}

.infinite-article-container .article-container {
  background: inherit;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
}

.infinite-article-container .article-container .article__header-container {
  width: 100%;
  position: relative;
}

.infinite-article-container .article-container .article__header-container.bg-img {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  // background-image: linear-gradient(45deg, grey, transparent);
}

.infinite-article-container .article-container .article__header-container img {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.article__ad .rectangle-ad {
  width: 300px;
  height: 600px;
}

.infinite-article-container .article-container .article__header-container .img-gradient {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(transparent, transparent 60%, rgba(0, 0, 0, 0.7));
}

.infinite-article-container .article-container .article__header {
  padding: 10px 15px;
  font-size: 12px;
  width: 100%;
}

.infinite-article-container .article-container .article__header .title {
  color: #585858;
  font-weight: 800;
  font-size: 3em;
  margin-bottom: 10px;
  text-align: left;
}

.infinite-article-container .article-container .article__header.title-under-image .title {
  color: black;
}

.infinite-article-container .article-container .article__header .details {
  color: #a1a1a1;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
}

.infinite-article-container .article-container .article__header .details-left {
  display: flex;
  margin-bottom: 5px;
}

.infinite-article-container .article-container .article__header .details-left div:first-child ~ div:before {
  content: "|";
  margin: 0 8px;
}

.infinite-article-container .article-container .article__header .author {
  color: #7a7a7a;
  font-style: italic;
}

.infinite-article-container .article-container .article__header .divider {
  height: 1px;
  width: 30%;
}

.infinite-article-container .article-container .article__body-external {
  width: 100%;
  padding: 10px 15px 10px 15px;
}

.infinite-article-container .article-container .article__body {
  width: 100%;
  padding: 0px 15px 10px 15px;
  // line-height: 1.5em;
  font-size: 16px;

  .content {
    display: block;
    word-wrap: break-word;
    // text-align: left;
    text-align: justify;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    h4 {
      font-size: 1em;
    }
    h5 {
      font-size: 0.83em;
    }
    h6 {
      font-size: 0.67em;
    }
    li {
      display: list-item;
    }

    a {
      text-decoration: underline;
      color: #23527c;
    }
    strong:before {
      content: "" !important;
      // display: block !important;
      // margin-bottom: 1.5em !important;
    }
    p {
      margin-bottom: 25px;
      line-height: 26px;
      color: inherit;
    }
    .row {
      justify-content: center;
    }
    iframe {
      max-width: 400px;
      height: 100%;
      margin-bottom: 1.5em !important;
    }
  }
}

.infinite-article-container .article-container .article__ad {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
}

.infinite-article-container .sharing-footer {
  width: 1000px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background: inherit;
}

.infinite-article-container .sharing-footer .line {
  margin-top: 5px;
  height: 1px;
  width: 80px;
}

.infinite-article-container .sharing-footer .share-prompt {
  margin-right: 30px;
}
.infinite-article-container .sharing-footer .share-prompt .text {
  font-size: 16px;
}

.infinite-article-container .sharing-footer .share-btns {
  display: flex;
  flex: 1;
}

.infinite-article-container .sharing-footer .share-btns .share-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  width: 30px;
  cursor: pointer;
}

.infinite-article-container .sharing-footer .share-btns .share-btn-container:last-child {
  margin-right: 0;
}

.infinite-article-container .sharing-footer .share-btns i {
  font-size: 36px;
}

.infinite-article-container .sharing-footer .share-btns .underline {
  height: 2px;
  width: 0%;
  transition: width 0.1s ease-out;
}

.infinite-article-container .sharing-footer .share-btns .share-btn-container:hover .underline {
  width: 100%;
  transition: width 0.1s ease-out;
}

.infinite-article-container .sharing-footer .up-next .label {
  font-weight: 800;
}

.infinite-article-container .sharing-footer .up-next .next-article {
  text-transform: uppercase;
}

.infinite-article-container .sharing-footer .up-next .line {
  float: right;
}

@media (min-width: 1025px) {
  .infinite-article-container .article-container .article__header-container {
    width: 66%;
  }
  .infinite-article-container .article-container .article__header {
    font-size: 16px;
  }
  .infinite-article-container .article-container .article__header-container.bg-img .article__header {
    position: absolute;
    bottom: 0;
  }
  .infinite-article-container .article-container .article__header-container.bg-img .article__header.title-under-image {
    position: initial;
  }
  .infinite-article-container .article-container .article__header-container .img-gradient {
    display: block;
  }
  .infinite-article-container .article-container .article__header-container.bg-img .title {
    color: white;
  }
  .infinite-article-container
    .article-container
    .article__header-container.bg-img
    .article__header.title-under-image
    .title {
    color: black;
  }
  .infinite-article-container .article-container .article__header .details {
    justify-content: space-between;
    flex-direction: row;
  }
  .infinite-article-container .article-container .article__header .details-left {
    margin-bottom: 0;
  }
  .infinite-article-container .article-container .article__header .divider {
    margin-bottom: 0;
  }
  .infinite-article-container .article-container .article__body {
    padding-top: 10px;
  }
  .infinite-article-container .article-container .article__body {
    width: 100%;
  }
  .infinite-article-container .article-container .article__ad {
    width: 33%;
  }
}

@media (max-width: 375px) {
    .content,
  .content-external { img{
    width: 300px !important;
    height: 300px !important;
  }}
  .infinite-article-container .article-container .article__header .details {
    font-size: 10px;
  }
  .infinite-article-container .article-container .article__header .title {
    font-size: 16px;
  }
  .infinite-article-container .article-container .article__header {
    font-size: 16px;
  }
  .infinite-article-container .article-container .article__header-container.bg-img .title {
    font-size: 2.5em;
  }
}
.infinite-article-container {
  .fr-emoticon {
    background-repeat: no-repeat !important;
    padding-right: 1em;
  }
  blockquote {
    border-left: solid 2px;
    border-color: inherit;
    color: inherit;
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: inherit;
  }
}
@media(max-width : 425px){
  .content,
  .content-external {
     img{
    width: 350px !important;
    height: 350px !important;
  }
}}
@media (min-width: 768px) {
  .infinite-article-container .subscription-prompt .overlay .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (max-width: 1024px) {
  .infinite-article-container .article-container .article__body li {
    margin-left: 20px;
  }
}
.editArticle {
  height: 30px;
  display: flex;
  align-items: center;
  background: black;
  width: max-content;
  margin: 10px 10px 10px auto;
  padding: 10px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
