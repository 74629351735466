@font-face {
  font-family: "Gilroy";
  src: url("./Assets/Fonts/gilroy/Gilroy-Regular.ttf");
}
@font-face {
  font-family: "Gilroy Black";
  src: url("./Assets/Fonts/gilroy/Gilroy-Black.ttf");
}
body {
  margin: 0;
  font-family: "Gilroy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.center {
  text-align: center;
}

.kNBmiK {
  margin: 0px !important;
}
.modal-close {
  z-index: 1;
}
.jWpUJH {
  bottom: 10px;
}
.dots-bottom .jWpUJH {
  bottom: 10px !important;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}