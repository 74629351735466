@font-face {
  font-family: "Gilroy";
  src: url("../Assets/Fonts/gilroy/Gilroy-Regular.ttf");
}
@font-face {
  font-family: "Gilroy Black";
  src: url("../Assets/Fonts/gilroy/Gilroy-Black.ttf");
}
a {
  text-decoration: none;
}
body {
  margin: 0;
  font-family: "Gilroy";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.add-banner-1 {
  width: 768px;
  margin: auto;
  height: 100%;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.border-animation-mobile {
  display: none;
}
.border-animation-desktop {
  width: 100%;
  display: block;
  height: 42px;
}
@media (max-width: 768px) {
  .add-banner-1 {
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
  }
  .border-animation-mobile {
    display: block;
    width: 100%;
  }
  .border-animation-desktop {
    display: none;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.row_1 {
  width: 1440px;
  margin: 0 auto;
}
.bannerImage {
  width: 1440px;
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .row {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .row {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .row {
    width: 100%;
  }
  .row_1 {
    width: 100%;
  }
  .bannerImage {
    width: 100%;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
@media (max-height: 500px) {
  .modal-video-body {
    max-width: 940px;
    width: 65%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
.playnow-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.playnow-modal-content {
  background-image: linear-gradient(45deg, red, darkblue);
  margin: auto;
  width: 728px;
  position: relative;
  padding-bottom: 75%;
  border-radius: 10px;
}
.playnow-close {
  color: black;
  float: right;
  text-align: center;
  padding: 5px;
}

.playnow-close:hover,
.playnow-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.ReactModal__Overlay {
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframe__class {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  position: absolute;
  padding: 10px;
}
.ReactModal__Content {
  position: unset;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: none;
  background: none;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90% !important;
  /* overflow: hidden !important; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ReactModal__Content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ReactModal__Content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-confirm-alert-body > h1 {
  font-size: 2rem;
}
.react-confirm-alert-body {
  background: #08255d !important;
}
.react-confirm-alert-body {
  border: 15px double #08255d !important;
  background-clip: padding-box !important;
  color: #f5f0f0 !important;
  font-family: inherit !important;
  letter-spacing: 3px !important;
  width: 50% !important;
  margin: auto !important;
  border: 15px double #08255d !important;
}
.react-confirm-alert-button-group {
  display: flex;
  justify-content: center;
}
.react-confirm-alert-button-group > button {
  flex: 1 !important;
  background: #348e43 !important;
  padding: 12px 18px !important;
  color: #fcfcfc !important;
  margin-right: 21px !important;
  font-size: 17px !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
  margin-top: 25px !important;
}
.react-confirm-alert-button-group > button:nth-child(2) {
  background: #d12929 !important;
  margin-right: 0 !important;
}
.react-confirm-alert-overlay {
 
  z-index: 99999;
} 
.react-confirm-alert {
  width: 100%;
}
@media (max-width: 426px) {
  .instaa div iframe {
    min-width: 0 !important;
    max-width: 500px;
  }
  .react-confirm-alert-body {
    width: 90% !important;
}
}

@media (max-height: 500px) {
  .modal-video-body {
    max-width: 940px;
    width: 65%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
@media (max-height: 568px) {
  .ReactModal__Content {
    align-items: normal;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ReactModal__Content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 768px) {
  .row {
    width: 100%;
  }
  .row_1 {
    width: 100%;
  }
  .bannerImage {
    width: 100%;
  }
  .modal-video-close-btn {
    right: 0px;
  }
}
@media (max-width: 1024px) {
  .row {
    width: 100%;
  }
}
@media (max-width: 1440px) {
  .row {
    width: 100%;
  }
}
.loader {
  border: 5px solid #000000;
  border-radius: 50%;
  border-top: 5px solid #db3434;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.react-datetime-picker__wrapper {
  border: thin solid #8080804a;
  background-color: white;
  box-shadow: 2px 4px 22px -6px #62656a;
}