.fullScore-container{
    width: 60%;
    margin: 5% auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border: 1px solid rgba(0, 0, 0, 0.089) ;
    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.479);
      }
    .headingScore{
        background-color: rgba(0, 0, 0, 0.061);
        display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2%;
    .headingText{
        font-size: 34px;
    font-weight: 900;
    color: #0b287a;
    }
    .cricImage{
        width: 4%;
        margin-left: 1%;
    img{
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
    }
}
    }
    .scoreImage{
        padding: 0 4%;
    img{
        width: 100%;
        height: 100%;
        display: block;
    }
}
.scoreFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7%;
    font-size: 20px;
    color: red;
    font-weight: 900;
}
@media(max-width:768px){
    width: 80%;
    .cricImage {
        width: 6% !important;
        margin-left: 2% !important;
    }
}
@media(max-width:450px){
    width: 95%;
    .scoreFooter {
        font-size: 10px;
    }
    .headingText{
        font-size: 20px !important;
    }
    .cricImage {
        width: 7% !important;
        margin-left: 2% !important;
    }
}
}