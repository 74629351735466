.livematch {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1% 2%;
    background-image: url("../../Assets/CricView-Banner1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    
    @keyframes blinkingIpl {
        100% {
          text-shadow: 0 0 2px #cec1c1, 0 0 10px #b3a9a9, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101;
           }
      }
    .team1 {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 14px;
        display: flex;
        width: 100%;
        padding: 1% 1% 1% 2%;
        height: 24%;
       color: white;
        .name{
            width: 20%;
        }
        .score{
            line-height: 1.1;
            width: 80%;
            align-items: center;
            justify-content: flex-end;
            display: flex;
        }
        
    }
    .scoreUpdate {
        height: 100%;
        background-image: radial-gradient(farthest-corner at 114% 55%,#fe0813,#3c7fff,#08255d,#08255d);
        border: 1px solid grey;
        width: 100%;
        .comment {
            line-height: 1.1;
            font-size: 14px;
            padding: 0 1% 1% 1%;
            color: white;
            height: 23%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 900;
        }
        .status {
            text-shadow: 0 0 2px #e6e2e2, 0 0 10px #ebe6e6, 0 0 20px #ce5f72, 0 0 30px #c93535, 0 0 40px #f11b1b, 0 0 50px #ff0101;
            -webkit-animation: blinkingIpl 1.5s infinite alternate;
                    animation: blinkingIpl 1.5s infinite alternate;
            letter-spacing: 1px;
            font-weight: 900;
            height: 16%;
            padding-left: 2%;
            font-size: 16px;
            color: red;
        }
        .location {
            font-size: 10px;
            padding-left: 2%;
            height: 12%;
            color: white;
            font-weight: 900;
        }
    }
    .setMargin{
  margin-left:2%
    }
  .borderCornerOne {
    background:
    linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 0,
    linear-gradient(to right, #c60f5e 7px, transparent 7px) 0 100%,
    linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 0,
    linear-gradient(to left, #c60f5e 7px, transparent 7px) 100% 100%,
    linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 0 0,
    linear-gradient(to bottom, #c60f5e 7px, transparent 7px) 100% 0,
    linear-gradient(to top, #c60f5e 7px, transparent 7px) 0 100%,
    linear-gradient(to top, #c60f5e 7px, transparent 7px) 100% 100%;
  
  background-repeat: no-repeat;
  background-size: 25px 25px;
  
  }
  .borderCornerTwo {
    background:
    linear-gradient(to right, #00aeef 7px, transparent 7px) 0 0,
    linear-gradient(to right, #00aeef 7px, transparent 7px) 0 100%,
    linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 0,
    linear-gradient(to left, #00aeef 7px, transparent 7px) 100% 100%,
    linear-gradient(to bottom, #00aeef 7px, transparent 7px) 0 0,
    linear-gradient(to bottom, #00aeef 7px, transparent 7px) 100% 0,
    linear-gradient(to top, #00aeef 7px, transparent 7px) 0 100%,
    linear-gradient(to top, #00aeef 7px, transparent 7px) 100% 100%;
  
  background-repeat: no-repeat;
  background-size: 25px 25px;
  
  }
  
    .boxOne{
      display: flex;
      width: 25%;
      position: relative;
    }
    .imageBoxCric{
      width: 7% ;
      position: absolute;
      right: 18% !important;
      top: 8.5%;
      img{
        width: 100%;
      height: 100%;
      border-radius: 50%;
      }
    }
    .imageBox {
      width: 85% !important;
      // background-color: red;
      height: 150px;
      padding: 1.7%;
    
  }
  @media(max-width: 1980px){
    .imageBox {
      padding: 1.3% 1.1% 1.3% 1.3%;
  
  width: 85%;
  
    }
    .imageBoxCric {
      right: 18%;
  }
  }
  @media(max-width: 1440px){
  .imageBox {
    padding: 1.7%;
  }
  }
  @media(max-width: 1024px){
    background-size: 1024px 156px;
    .boxOne {
      width: 28%;
    }
    .setMargin{
      margin-left:4%
        }
    .imageBox {
      height: 136px;
      padding: 2%;
    }
  }
  @media(max-width: 768px){
  .boxOne {
    width: 40%;
  }
  .imageBox {
    height: 135px;
  }
  .imageBoxCric { 
    top: 5%;
  }
  }
  @media(max-width: 450px){
  flex-direction: column;
  height: 350px;
  background-image: url("../../Assets/CricView-Banner-Mobile1.jpg");
  background-repeat: no-repeat;
  background-size: 425px 350px;
      .boxOne {
        width: 80%;
        margin-bottom: 5%;
        margin-left: 0%;
        display: flex;
      align-items: center;
      justify-content: center;
        
      }
      .imageBoxCric {
        top: 8%;
        right: 11% !important;
    }
      .imageBox {
        height: 137px;
        padding: 2% 1.9% 1.9% 1.9%;
    }
  } 
  @media(max-width: 375px){
    background-size: 375px 350px;
    .imageBox {
      height: 136px;
      padding: 2.2% 1.9% 1.9% 1.9%;
  }
    }
  @media(max-width: 320px){
    background-size: 320px 350px;
    .imageBox {
      height: 135px;
      padding: 2.2% 1.9% 1.9% 1.9%;
  }
    } 
  }
  
  
  
  